import React, { useEffect, useState } from "react";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import style from "./AcceptCounterAcceptModal.module.scss";
import {
  getByLabelText,
  getByLabelValueIndividual,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import { DECIMAL, OFFER_STATUS } from "../../../../Constant/userConstant";
import { setRedirectionStripSuccess } from "../../../../Features/persist/persistSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getWalletBalance } from "../../../../Features/user/userSlice";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";

const AcceptCounterAcceptModal = ({
  show,
  onHide,
  makeOfferDetails,
  updateStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [walletBalance, setWalletBalance] = useState([]);

  useEffect(() => {
    getUserWalletBalance();
  }, []);

  const getUserWalletBalance = () => {
    dispatch(getWalletBalance()).then((response) => {
      setWalletBalance(response?.data?.data?.user_fiat_account?.balance);
    });
  };

  const callAcceptOFFerApi = () => {
    let data = {
      status: OFFER_STATUS.accepted,
      id: makeOfferDetails.id,
      makeOfferDetails: makeOfferDetails,
    };
    updateStatus(data); //api calling method
  };

  const enteredValue = makeOfferDetails?.offer?.counter_offer / DECIMAL;
  const isButtonDisabled =
    parseFloat(enteredValue) > (Number(walletBalance) / DECIMAL).toFixed(2);

  const addFundsHandler = () => {
    dispatch(setRedirectionStripSuccess("offer"));
    navigate(RoutesUrls.Wallet);
  };

  return (
    <>
      <CommonModal
        className={`${style.offerModal} ${
          makeOfferDetails?.art?.is_portrait
            ? `portraitModal`
            : `landscapeModal`
        }`}
        centered
        show={show}
        onHide={onHide}
      >
        <div className="d-flex flex-md-row flex-column">
          <div
            className={`${style.offerModalLeft} ${
              makeOfferDetails?.is_portrait ? `portraitLeft` : `landscapeLeft`
            }`}
          >
            <div
              className={`${style.offerModalLeftInner} ${
                makeOfferDetails?.is_portrait ? `portraitImg` : `landscapeImg`
              }`}
            >
              <img
                src={`${process.env.REACT_APP_IMG_BASE_URL}${makeOfferDetails?.offer?.nft_art.thumbnail_image}`}
                alt="artist_nft"
              />
            </div>
          </div>
          <div
            className={`${style.offerModalRight} ${
              makeOfferDetails?.is_portrait ? `portraitRight` : `landscapeRight`
            }`}
          >
            <div className={style.artistDetails}>
              <h6>@{makeOfferDetails?.offer?.nft_art.art_user?.username}</h6>
              <h3 title={makeOfferDetails?.offer?.nft_art?.art_name}>
                {sliceChar(makeOfferDetails?.offer?.nft_art?.art_name, 25)}
              </h3>
            </div>

            <ul className={style.pricelist}>
              <li>
                <h6 className={style.offerGst}>
                 <label> Your Offer</label>
                  <span>(Inclusive of GST)</span>
                </h6>
                <span>
                  {" "}
                  {makeOfferDetails?.offer?.total_art_price_inc_tax
                    ? "₹ " +
                      getNumberWithComma(
                        makeOfferDetails?.offer?.total_art_price_inc_tax /
                          DECIMAL
                      )
                    : "--"}{" "}
                </span>
              </li>
              <li>
                <h6>Counter Offer</h6>
                <span>
                  {" "}
                  {makeOfferDetails?.offer?.counter_offer
                    ? "₹ " +
                      getNumberWithComma(
                        makeOfferDetails?.offer?.counter_offer / DECIMAL
                      )
                    : "--"}
                </span>
              </li>
              <li className={style.highvalue}>
                <h6>
                  {getByLabelText(
                    makeOfferDetails?.offer?.total_art_price_inc_tax,
                    makeOfferDetails?.offer?.counter_offer
                  )}
                </h6>
                <span>
                  ₹
                  {getNumberWithComma(
                    getByLabelValueIndividual(
                      makeOfferDetails?.offer?.total_art_price_inc_tax,
                      makeOfferDetails?.offer?.counter_offer
                    )
                  )}
                </span>
              </li>
            </ul>
            {/* <p className={style.paragraph_text}>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p> */}
            <div className={style.buy_btn_box}>
              {isButtonDisabled &&
                makeOfferDetails?.offer?.total_art_price_inc_tax / DECIMAL <=
                  makeOfferDetails?.offer?.counter_offer / DECIMAL && (
                  <div className={style.insufficientBl}>
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Insufficient Balance
                    </p>
                    <button onClick={addFundsHandler}>Add Funds</button>
                  </div>
                )}
              <CommonBtn
                role="btn"
                title="Accept Offer"
                className={`${style.buy_btn} `}
                type="button"
                onClick={() => callAcceptOFFerApi()}
                disabled={
                  isButtonDisabled &&
                  makeOfferDetails?.offer?.total_art_price_inc_tax / DECIMAL <=
                    makeOfferDetails?.offer?.counter_offer / DECIMAL
                }
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AcceptCounterAcceptModal;
