import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import CommonModal from "../../Common/Ui/CommonModal/CommonModal";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import {
  createExportRequest,
  getExportArtDetail,
  calculateExportFee,
} from "../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkFileType, sliceChar } from "../../../Helper/common";
import videoIcon from "../../../Assets/images/videoicon.png";
import style from "./ExportWallet.module.scss";

export const ExportWalletModal = ({ show, handleClose, selectedArtDetail }) => {
  const dispatch = useDispatch();
  const [artDetail, setArtDetail] = useState([]);
  const [artImagesVideo, setArtImagesVideo] = useState([]);
  const [selectedExportImageVideo, setSelectedExportImageVideo] = useState({});
  const [feeDetail, setFeeDetail] = useState({});

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(5, artImagesVideo?.length),
    slidesToScroll: 1,
    // Event handler to capture slide change
    afterChange: (index) => {
      // Assuming you have an array of data for your slides called slideData
      setSelectedExportImageVideo(artImagesVideo[index]);
    },
  };
  const isValidBlockchainAddress = (value) => {
    // Regular expression to validate blockchain address (replace with the appropriate regex)
    let WAValidator = require("multicoin-address-validator");
    let valid = WAValidator.validate(value, "matic");
    return valid;
  };

  const initialValue = {
    walletAddress: "",
  };

  const validationSchema = Yup.object({
    walletAddress: Yup.string()
      .required("*Address is required")
      .test(
        "isValidBlockchainAddress",
        "*Invalid address",
        isValidBlockchainAddress
      ),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createExportRequestHandler(values);
    },
  });

  useEffect(() => {
    getExportArtDetailHandler();
  }, [selectedArtDetail]);

  useEffect(() => {
    if (artImagesVideo?.length > 0) {
      setSelectedExportImageVideo(artImagesVideo[0]);
    }
  }, [artDetail]);

  useEffect(() => {
    if (!formik?.errors?.walletAddress && formik.values.walletAddress != "") {
      calculateExportFeeHandler();
    }
  }, [formik?.values, formik?.errors?.walletAddress]);

  const getExportArtDetailHandler = async () => {
    try {
      let data = {
        artId: selectedArtDetail.art_id,
        editionNo: selectedArtDetail.user_owned_no_of_editions,
      };
      const response = await dispatch(getExportArtDetail(data));
      setArtDetail(response?.data);
      setArtImagesVideo(response?.data?.images);
    } catch (error) {}
  };

  const createExportRequestHandler = async (values) => {
    let data = {
      artId: selectedArtDetail.art_id,
      editionNo: selectedArtDetail.user_owned_no_of_editions,
      artImage:
        artDetail?.art_owner?.art_type_id !== 1
          ? artDetail?.art_owner?.art_image
          : selectedExportImageVideo.image,
      walletAddress: values.walletAddress,
      exportTotalFee: feeDetail?.totalExportFee,
      gasFee: feeDetail.gasFees,
      markupFee: feeDetail.markupFees,
      dynamicRuleId:
        artDetail?.art_owner?.art_type_id === 1
          ? selectedExportImageVideo.id
          : 0,
    };
    try {
      const response = await dispatch(createExportRequest(data));
      if (response) {
        handleClose();
      }
    } catch (error) {}
  };

  const calculateExportFeeHandler = async () => {
    let data = {
      address: formik?.values?.walletAddress,
      editionNo: selectedArtDetail.user_owned_no_of_editions,
      artId: selectedArtDetail.art_id,
    };
    try {
      const response = await dispatch(calculateExportFee(data));
      setFeeDetail(response.data);
    } catch (error) {}
  };

  const getImageOrVideoRender = (src) => {
    let type = checkFileType(src);
    if (type === "MP4" || type === "OGG") {
      return (
        <div className={style.imageSlide}>
          <video key={src} loop controls>
            <source src={src} type="video/mp4" />
          </video>
        </div>
      );
    } else {
      return (
        <div className={style.imageSlide}>
          <img src={src} alt="artist_nft" />
        </div>
      );
    }
  };

  const getSource = useCallback((src, data) => {
    let type = checkFileType(src);
    return (
      <img
        onClick={() => setSelectedExportImageVideo(data)}
        src={type === "MP4" || type === "OGG" ? videoIcon : src}
        alt="artist_nft"
      />
    );
  }, []);

  return (
    <>
      <div className={style.modalExportwallet}>
        <CommonModal
          centered
          className={style.ExportModal}
          backdropclass="fade_clrbg"
          show={show}
          onHide={handleClose}
        >
          <div className="d-flex flex-md-row flex-column-reverse">
            <div className={style.ExportModalLeft}>
              {/* 1 represent for dynamic art type */}
              {Object.keys(artDetail).length > 0 &&
                artDetail?.art_owner?.art_type_id !== 1 && (
                  <div className={style.imageSlide}>
                    {getImageOrVideoRender(
                      `${process.env.REACT_APP_IMG_BASE_URL}${artDetail?.art_owner?.art_image}`
                    )}
                  </div>
                )}
              {/* 1 represent for dynamic art type */}

              {Object.keys(artDetail).length > 0 &&
                artDetail?.art_owner?.art_type_id === 1 && (
                  <>
                    {artImagesVideo.length && (
                      <>
                        {getImageOrVideoRender(
                          `${process.env.REACT_APP_IMG_BASE_URL}${selectedExportImageVideo?.image}`
                        )}
                      </>
                    )}
                    <Slider className="sliderList" {...settings}>
                      {artImagesVideo?.map((data) => (
                        <div>
                          {getSource(
                            `${process.env.REACT_APP_IMG_BASE_URL}${data.image}`,
                            data
                          )}
                        </div>
                      ))}
                    </Slider>
                  </>
                )}
            </div>
            <div className={style.ExportModalRight}>
              <form onSubmit={formik.handleSubmit}>
                <div className={style.artistDetails}>
                  <h4>@ {artDetail?.art_owner?.user?.name}</h4>
                  <h3 title={selectedArtDetail?.art_name}>
                    {sliceChar(selectedArtDetail?.art_name, 25)}
                  </h3>
                  <div className={style.artistDetailsEdition}>
                    <p>Edition :</p>
                    <span>
                      {" "}
                      #{selectedArtDetail?.user_owned_no_of_editions}
                    </span>
                  </div>
                </div>
                <div className={style.infoarts}>
                  {/* <div className={style.dynamicInfo}>
                  <p>Dynamic :</p>
                  <span> Sunrise</span>
                </div> */}
                  <div className={style.walletInfo}>
                    <div className={style.editionDetails}>
                      <div className={style.enter_text}>
                        <label>Enter Wallet Address</label>
                      </div>
                      <FormikControls
                        control="input"
                        type="text"
                        name="walletAddress"
                        placeholder="Enter address"
                        onChange={formik.handleChange}
                        value={formik.values.walletAddress}
                        formik={formik}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className={style.exportfee}>
                      <label>Export Fee</label>
                      <p>
                        {!feeDetail?.totalExportFee ||
                        formik?.errors?.walletAddress
                          ? `₹ 00.00`
                          : `₹ ${feeDetail?.totalExportFee}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={style.buy_btn_box}>
                  <CommonBtn
                    role="btn"
                    title="Proceed to Payment"
                    disabled={Object.keys(feeDetail).length === 0}
                    className={`${style.buy_btn} btn-yellow`}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </CommonModal>
      </div>
    </>
  );
};
