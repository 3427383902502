import React from "react";
import { Link } from "react-router-dom";
import "./CommonBtn.scss";
import PropTypes from "prop-types";

const CommonBtn = ({
  title,
  target,
  className,
  icon,
  onClick,
  to,
  role,
  type,
  disabled,
  tolltip,
}) => {
  return (
    <>
      {(() => {
        switch (role) {
          case "btn":
            return (
              <button
                type={type}
                className={`commonBtn ${className}`}
                onClick={onClick}
                disabled={disabled}
                title={tolltip}
              >
                {title}
                {icon && (
                  <>
                    <span>{icon}</span>
                  </>
                )}
              </button>
            );
          case "link":
            return (
              <Link
                to={to}
                className={`commonBtn ${className}`}
                onClick={onClick}
                target={target}
              >
                {title}
                {icon && (
                  <>
                    <span>{icon}</span>
                  </>
                )}
              </Link>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};
export default CommonBtn;
