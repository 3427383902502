import React, { useEffect, useState } from "react";
import style from "../Dropaction/Dropaction.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import {
  PLATFORM_MAX_VALUE,
  formatDateWithTime,
  getDateFromTimestamp,
  getNumberWithComma,
} from "../../../Helper/common";
import Timer from "../Timer";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import { useNavigate } from "react-router-dom";
import personIcon from "../../../Assets/images/person_icon.jpg";
import { DECIMAL, PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import Notfound from "../../Common/NotFound/Notfound";
import { toasts } from "../../Common/Toast/Toast";
import HowitWork from "../../Common/HowitWork/HowitWork";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import DOMPurify from "dompurify";

const RankedAuctionLive = ({
  artDetail,
  liveActivityDetail,
  page,
  userId,
  type,
  id,
  setShowClosedDrop,
  lastBidAmount,
  ArtistId,
  liveActivityResponse,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const [highestPriceProduct, sethighestPriceProduct] = useState({});

  useEffect(() => {
    sethighestPriceProduct({});
    if (liveActivityDetail.length > 0) {
      const highestPriceProduct = liveActivityDetail.reduce((prev, current) => {
        return prev.offer_price / DECIMAL > current.offer_price / DECIMAL
          ? prev
          : current;
      }, {});
      sethighestPriceProduct({ ...highestPriceProduct });
    }
  }, [liveActivityDetail]);

  const getLowestValue = () => {
    if (Object.keys(artDetail).length) {
      const minIncrement = Number(artDetail?.increment) / DECIMAL;
      let totalEditions = artDetail?.no_of_editions;

      // Sort bids in ascending order
      const sortedBids = liveActivityDetail
        .slice()
        .sort(
          (a, b) =>
            Number(a.offer_price) / DECIMAL - Number(b.offer_price) / DECIMAL
        );

      if (liveActivityDetail.length < totalEditions) {
        // if totalEditions are not filled by bid
        // we will consider floor price as lowest bid
        sortedBids.unshift({
          offer_price: Number(artDetail.price) - Number(artDetail?.increment),
        });
      } else if (liveActivityDetail.length > totalEditions) {
        // if bids are more than total edition then remove lowest bid record
        sortedBids.splice(0, 1);
      }
      // till obove code we will have all the bids that can be considered to calculate lowest bid
      // now we will check which price will be the lowest bid price

      let minEligibleAmount = 0;

      if (sortedBids.length > 1) {
        // if bids lies in between bids
        for (let i = 0; i < sortedBids.length - 1; i++) {
          if (
            Number(sortedBids[i].offer_price) / DECIMAL + minIncrement <=
            Number(sortedBids[i + 1].offer_price) / DECIMAL - minIncrement
          ) {
            minEligibleAmount =
              Number(sortedBids[i].offer_price) / DECIMAL + minIncrement;
            break;
          }
        }
      } else {
        // if there is no bid yet so minEligibleAmount will be the floor price
        if (sortedBids.length == 1 && liveActivityResponse) {
          minEligibleAmount =
            Number(sortedBids[0].offer_price) / DECIMAL + minIncrement;
          return "₹ " + minEligibleAmount.toFixed(2);
        }
      }

      // if (sortedBids.length == 1) {
      //   return "₹ " + " " + Number(artDetail.price) / DECIMAL;
      // }

      if (minEligibleAmount == 0 && liveActivityResponse) {
        // if no bid is in between so the next eligible bid should be last greater bid + min inc
        let lowBid =
          Number(sortedBids[sortedBids.length - 1].offer_price) / DECIMAL +
          minIncrement;
        return `₹${lowBid.toFixed(2)}`;
      } else if (minEligibleAmount > 0) {
        return "₹ " + minEligibleAmount.toFixed(2);
      }
    }
  };

  const getLowestValueWithoutSymbol = () => {
    let totalEditions = artDetail?.no_of_editions;

    // return "₹2000";
    const minIncrement = Number(artDetail?.increment) / DECIMAL;
    // Sort bids in ascending order
    const sortedBids = liveActivityDetail
      .slice()
      .sort(
        (a, b) =>
          Number(a.offer_price) / DECIMAL - Number(b.offer_price) / DECIMAL
      );
    if (totalEditions !== liveActivityDetail.length) {
      sortedBids.unshift({
        offer_price: Number(artDetail.price) - Number(artDetail?.increment),
      });
    }

    let minEligibleAmount = 0;
    if (sortedBids.length > 1) {
      for (let i = 0; i < sortedBids.length - 1; i++) {
        if (
          Number(sortedBids[i].offer_price) / DECIMAL + minIncrement <=
          Number(sortedBids[i + 1].offer_price) / DECIMAL - minIncrement
        ) {
          minEligibleAmount =
            Number(sortedBids[i].offer_price) / DECIMAL + minIncrement;
          break;
        }
      }
    } else {
      if (sortedBids.length == 1) {
        minEligibleAmount =
          Number(sortedBids[0].offer_price) / DECIMAL + minIncrement;
      }
    }
    return minEligibleAmount;
  };

  const initialValue = {
    bidAmount: "",
    is_max_limit: artDetail?.is_max_limit,
  };

  const validationSchema = Yup.object({
    is_max_limit: Yup.boolean(),
    bidAmount: Yup.number()
      .typeError(inputFieldValidation.commonMessage.number)
      .positive(inputFieldValidation.commonMessage.positive)
      .test("is-valid-bid", "Invalid bid amount", function (value) {
        if (!formik.values?.is_max_limit && liveActivityDetail.length > 0) {
          if (!value) {
            return false; // Bid amount is required
          }

          const minIncrement = Number(artDetail?.increment) / DECIMAL;
          let totalEditions = artDetail?.no_of_editions;

          // Sort bids in ascending order
          const sortedBids = liveActivityDetail
            .slice()
            .sort(
              (a, b) =>
                Number(a?.offer_price) / DECIMAL -
                Number(b?.offer_price) / DECIMAL
            );
          if (totalEditions !== liveActivityDetail.length) {
            sortedBids.unshift({
              offer_price:
                Number(artDetail.price) - Number(artDetail?.increment),
            });
          }
          if (
            value >=
            Number(sortedBids[sortedBids.length - 1].offer_price) / DECIMAL +
              minIncrement
          ) {
            return true;
          }

          if (value <= Number(sortedBids[0].offer_price) / DECIMAL) {
            return false;
          }

          // Check if the entered bid is at least 7 greater than the closest smaller bid
          for (let i = sortedBids.length - 1; i >= 0; i--) {
            if (value >= Number(sortedBids[i].offer_price) / DECIMAL) {
              // Check if there is a next higher bid and if the entered bid is less than the next higher bid
              if (
                sortedBids[i + 1] &&
                value >=
                  Number(sortedBids[i].offer_price) / DECIMAL + minIncrement &&
                value + minIncrement <=
                  Number(sortedBids[i + 1].offer_price) / DECIMAL
              ) {
                return true; // Valid bid amount
              }
            }
          }
          return false; // Invalid bid amount
        } else {
          return true;
        }
      })
      .test(
        "check bid price",
        `*Bid amount should be equal or greater than ₹${getNumberWithComma(
          Number(artDetail.price) / DECIMAL
        )}`,
        function (values) {
          if (!artDetail?.is_max_limit && !formik.values.is_max_limit) {
            return Number(values) >= Number(artDetail.price) / DECIMAL;
          } else {
            return true;
          }
        }
      )
      // for normal bid
      .test(
        "check last bid price",
        `*Bid amount should be greater than ₹${getNumberWithComma(
          Number(artDetail.last_bid_amount) / DECIMAL
        )}`,
        function (values) {
          if (!artDetail?.is_max_limit && !formik.values.is_max_limit) {
            return Number(values) > Number(artDetail.last_bid_amount) / DECIMAL;
          } else {
            return true;
          }
        }
      )
      // for max limit
      .test(
        "check max bid price",
        `*Max bid amount should be equal or greater than ₹${getNumberWithComma(
          Number(artDetail.price) / DECIMAL
        )}`,
        function (values) {
          // artDetail?.last_bid_amount == 0
          // if user not have any normal bid
          if (
            !artDetail?.is_max_limit &&
            formik.values.is_max_limit &&
            artDetail?.last_bid_amount == 0 &&
            liveActivityDetail.length == 0
          ) {
            return Number(values) >= Number(artDetail.price) / DECIMAL;
          } else {
            return true;
          }
        }
      )
      .test(
        "check last bid price",
        `*Bid amount should be  equal or greater than ₹${getNumberWithComma(
          Number(artDetail?.increment) / DECIMAL +
            Number(highestPriceProduct?.offer_price / DECIMAL)
        )}`,
        function (values) {
          // Number(artDetail?.user_max_limit)
          if (
            formik.values.is_max_limit &&
            !artDetail?.is_max_limit &&
            highestPriceProduct?.length
          ) {
            return (
              Number(values) >=
              Number(artDetail?.increment) / DECIMAL +
                Number(highestPriceProduct?.offer_price / DECIMAL)
            );
          } else {
            return true;
          }
        }
      )
      .test(
        "check last bid price",
        `*Bid amount should be  equal or greater than ₹${getNumberWithComma(
          Number(getLowestValueWithoutSymbol()) === 0
            ? Number(artDetail?.increment) / DECIMAL +
                Number(highestPriceProduct?.offer_price / DECIMAL)
            : getLowestValueWithoutSymbol()
        )}`,
        function (values) {
          // Number(artDetail?.user_max_limit)
          let LowestBid = getLowestValueWithoutSymbol();
          if (formik.values.is_max_limit && !artDetail?.is_max_limit) {
            if (LowestBid === 0) {
              return (
                Number(values) >=
                Number(artDetail?.increment) / DECIMAL +
                  Number(highestPriceProduct?.offer_price / DECIMAL)
              );
            } else {
              return Number(values) >= LowestBid;
            }
          } else {
            return true;
          }
        }
      )

      .test("notGreaterThanValue", `*Value is not accepted`, function (values) {
        if (values == "" || values == undefined) {
          return true;
        } else {
          return values < PLATFORM_MAX_VALUE;
        }
      })
      .required(inputFieldValidation.commonMessage.required("Bid Amount")),
  });
  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (artDetail?.is_eligible_to_buy) {
        checkoutHandler(values);
      } else {
        setShowClosedDrop(true);
      }
    },
  });

  const checkoutHandler = async (values) => {
    if (isUserLoggedin) {
      try {
        let data = {
          price: values.bidAmount,
          quantity: 1,
          market: "primary",
          is_max_limit: values.is_max_limit ? 1 : 0,
        };

        dispatch(saveLatestPrice(data));
        navigate(`/checkout/${id}`);
      } catch (error) {}
    } else {
      dispatch(saveNextRoute(`/curated-drop/digital-art/${type}/${id}`));
      navigate("/login");
    }
  };

  const bidActivity = () => {
    if (liveActivityDetail.length > 1) {
      const sortedArray = liveActivityDetail?.sort(
        (a, b) => b.offer_price - a.offer_price
      );
      return sortedArray.map((data, index) => (
        <>
          {index < artDetail.no_of_editions && index > 0 && (
            <tr key={data.id}>
              <td>
                #
                {(page - 1) *
                  PER_PAGE_RECORD_LIMIT.ART_DETAIL_PRIMARY_PER_PAGE +
                  (index + 1)}
              </td>
              <td>
                <div className="d-flex align-items-center thumb-outer">
                  <img
                    className="thumb"
                    src={
                      data?.auction_user[0]?.image_path
                        ? `${process.env.REACT_APP_IMG_BASE_URL}${data?.auction_user[0]?.image_path}`
                        : personIcon
                    }
                    alt="thumb"
                  />
                  <h4
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/profile/${data?.auction_user[0]?.username}`);
                    }}
                  >
                    @{data?.auction_user[0]?.username}
                  </h4>
                </div>
              </td>
              <td>{getNumberWithComma(data.offer_price / DECIMAL)}</td>

              <td>{formatDateWithTime(data.createdAt)}</td>
            </tr>
          )}
        </>
      ));
    } else if (
      liveActivityDetail.length == 1 ||
      liveActivityDetail.length == 0
    ) {
      return (
        <tr>
          <td colSpan={4} className="no_data_tablebid">
            <Notfound text={"No activity found."} />
          </td>
        </tr>
      );
    }
  };
  const dynamicClass =
    liveActivityDetail?.length > 0 ? style.ranked_table : style.tableNotdata;

  return (
    <>
      <div className={style.dropaction_ended_rightsec_pricefloor}>
        <div className={style.headprice}>
          <ul>
            <li>
              <label>Floor Price :</label>
              <h6>₹{getNumberWithComma(artDetail?.price / DECIMAL)}</h6>
            </li>
            <li>
              <label>Min. Increment :</label>
              <h6>
                ₹{getNumberWithComma(Number(artDetail?.increment) / DECIMAL)}
              </h6>
            </li>
          </ul>
          <h5 className={style.timeedit}>
            Ends in :{" "}
            <Timer
              startingTimestamp={artDetail?.end_time}
              type="live"
              id={id}
            />
          </h5>
        </div>
        <div className={style.startinfo}>
          <div className={style.bidhighest}>
            <div className={`${style.bidvalues} ${style.auctionInfos}`}>
              <div className={style.highest_bid}>
                <label>Highest Bid</label>
                <span>:</span>
                <h5>
                  {highestPriceProduct?.offer_price
                    ? `₹${getNumberWithComma(
                        highestPriceProduct?.offer_price / DECIMAL
                      )}`
                    : "--"}
                </h5>
              </div>
              <div className={style.minrank_bid}>
                <label>Min. Bid for Rank 1</label>
                <span>:</span>
                <h6>
                  {highestPriceProduct?.offer_price
                    ? `₹${getNumberWithComma(
                        Number(artDetail?.increment) / DECIMAL +
                          Number(highestPriceProduct?.offer_price / DECIMAL)
                      )}`
                    : `₹${getNumberWithComma(
                        Number(artDetail?.price) / DECIMAL
                      )}`}
                </h6>
              </div>
              {(artDetail?.last_bid_amount > 0 || lastBidAmount) && (
                <div className={style.minrank_bid}>
                  <label>Your last bid amount</label>
                  <span>:</span>
                  <h6>
                    {`₹${
                      lastBidAmount
                        ? getNumberWithComma(Number(lastBidAmount) / DECIMAL)
                        : getNumberWithComma(
                            Number(artDetail?.last_bid_amount) / DECIMAL
                          )
                    }`}
                  </h6>
                </div>
              )}
              {artDetail?.user_max_limit > 0 && (
                <div className={style.minrank_bid}>
                  <label>Max Limit Amount</label>
                  <span>:</span>
                  <h6>
                    ₹{getNumberWithComma(Number(artDetail?.user_max_limit))}
                  </h6>
                </div>
              )}

              {/* {liveActivityDetail.length > 0 && ( */}
              <div className={style.minrank_bid}>
                <label>Lowest Eligible Bid</label>
                <span>:</span>
                <h6>{liveActivityResponse ? getLowestValue() : `--`}</h6>
              </div>
              {/* )} */}
            </div>
            {
              /* userId !== artDetail.artist_id &&  */ <div
                className={`${style.bitlimitset} ${style.auctionValues}`}
              >
                <form onSubmit={formik.handleSubmit}>
                  {artDetail?.is_eligible_to_buy && (
                    <FormikControls
                      control="checkbox"
                      type="checkbox"
                      name="is_max_limit"
                      label="Set Max Limit"
                      formik={formik}
                      className={`checkMax ${style.setlimitcheck}`}
                      onChange={(e) => {
                        formik.setFieldValue("bidAmount", "");
                        formik.setFieldValue("is_max_limit", e.target.checked);
                      }}
                      onBlur={formik.handleBlur}
                      checked={formik.values.is_max_limit}
                      disabled={artDetail?.is_max_limit}
                    />
                  )}
                  <FormikControls
                    variant="priceadd"
                    control="input"
                    type="input"
                    name="bidAmount"
                    id="bidAmount"
                    placeholder="Enter Amount"
                    formik={formik}
                    onChange={(e) => {
                      if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    disabled={
                      !isUserLoggedin ||
                      (artDetail?.last_bid_amount ==
                        highestPriceProduct?.offer_price &&
                        !formik.values.is_max_limit)
                    }
                    value={formik.values.bidAmount}
                    onBlur={formik.handleBlur}
                  />
                  {isUserLoggedin && (
                    <CommonBtn
                      title="Place Bid"
                      role="btn"
                      className=""
                      type={"summit"}
                      disabled={
                        artDetail?.last_bid_amount ==
                          highestPriceProduct?.offer_price &&
                        !formik.values.is_max_limit
                      }
                    />
                  )}
                </form>
                {!isUserLoggedin && (
                  <CommonBtn
                    title="Place Bid"
                    role="btn"
                    type={"summit"}
                    className=""
                    onClick={checkoutHandler}
                  />
                )}
              </div>
            }
          </div>
        </div>
      </div>
      <div className={style.dropaction_ended_rightsec_tabpartlist}>
        <Tabs defaultActiveKey="aboutart" id="" className="tablists">
          <Tab eventKey="aboutart" title="About Art">
            <div className={style.artstab_containt}>
              <div
                className="textSection"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(artDetail?.bio),
                }}
              ></div>

              <ul className={style.about_artlist}>
                <li>
                  <label>
                    {artDetail?.no_of_editions == 1 ? "Edition" : "Editions"}
                  </label>
                  <span>:</span>
                  <h6>{artDetail?.no_of_editions}</h6>
                </li>
                <li>
                  <label>Type</label>
                  <span>:</span>
                  <h6>{artDetail?.art_type}</h6>
                </li>
                <li>
                  <label>Released</label>
                  <span>:</span>
                  <h6>{getDateFromTimestamp(artDetail.start_time)}</h6>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab eventKey="bidsactivity" title="Bid Activity">
            <div className={`${style.bid_activity} ${style.bidRanked}`}>
              {Object.keys(highestPriceProduct).length > 0 && (
                <div className={style.bid_rank}>
                  <div className={style.bid_rank_userinfo}>
                    <h6>Rank 1</h6>
                    <h6
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/profile/${highestPriceProduct?.auction_user[0]?.username}`
                        );
                      }}
                    >
                      @{highestPriceProduct?.auction_user[0]?.username}
                    </h6>
                    <div className={style.userIcon}>
                      <img
                        src={
                          highestPriceProduct?.auction_user[0]?.image_path
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${highestPriceProduct?.auction_user[0]?.image_path}`
                            : personIcon
                        }
                        alt="userIcon"
                      />
                    </div>
                  </div>
                  <div className={style.bid_rank_userprice}>
                    <h5>Bid Amount</h5>
                    <h4>
                      ₹
                      {getNumberWithComma(
                        highestPriceProduct?.offer_price / DECIMAL
                      )}
                    </h4>
                    <p>{formatDateWithTime(highestPriceProduct?.createdAt)}</p>
                  </div>
                </div>
              )}
              <div
                className={` table_border ${style.bid_table} ${dynamicClass}`}
              >
                <CommonTable className={style.bidder_table}>
                  <thead>
                    <tr>
                      <th>
                        <span>Rank</span>
                      </th>
                      <th>
                        <span>Collector</span>
                      </th>
                      <th>
                        <span>Bid Amount (₹)</span>
                      </th>
                      <th>
                        <span>Time</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{bidActivity()}</tbody>
                </CommonTable>
              </div>
            </div>
          </Tab>
          <Tab eventKey="howitworks" title="How it Works">
            <div className={`py-0 ${style.artstab_containt}`}>
              {/* <p>
                There’s a stage we reach in life where we start to realize our
                innocence is “gone.” We may have began to realize that we lost
                that childlike innocence ages ago. However, times of innocence
                seem to flutter by in adulthood.
              </p> */}
              <HowitWork artDetail={artDetail} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default RankedAuctionLive;
