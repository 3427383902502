import { createSlice } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "../loading/loadingSlice";
import { store } from "../../App/store";
import { newsService } from "../../Services/newsService";

let token;
const initialState = {};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
});

export const getNewsList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await newsService.newsList(params, token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export default newsSlice.reducer;
