import React, { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import CommonBtn from "../../../../../../Common/Ui/CommonBtn/CommonBtn";
import Commondropdown from "../../../../../../Common/Ui/CommonDropdown/Commondropdown";
import CommonTable from "../../../../../../Common/Ui/CommonTable/CommonTable";
import CustomPagination from "../../../../../../Common/Ui/Pagination/CustomPagination";
import Select from "react-select";
import style from "./FilterTable.module.scss";
import personIcon from "../../../../../../../Assets/images/person_icon.jpg";
import { DECIMAL } from "../../../../../../../Constant/userConstant";
import {
  formatDateActivityTable,
  sliceChar,
} from "../../../../../../../Helper/common";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../../../../../Features/user/userSlice";
import Notfound from "../../../../../../Common/NotFound/Notfound";

const OpenEditionFCFBRafflesTable = ({
  dfaDetail,
  dfaActivity,
  activityPage,
  setActivityPage,
  limit,
  setLimit,
  limitOption,
  setShowOfferModal,
  setMakeOfferDetails,
  setShowGlobalOfferModal,
  navigateToUser,
  sortOption,
  selectedSortBy,
  setSelectedSortBy,
  filterOption,
  setSelectedFilter,
  selectedFilter,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const isDfaActivityLoading = useSelector(
    (state) => state?.loading?.dfaActivityLoading
  );
  const userId = useSelector((state) => state?.users?.profileData?.id);

  const buyHandler = (item) => {
    if (isUserLoggedin) {
      let data = {
        price: item.current_price / DECIMAL,
        market: "secondary",
        quantity: 1,
        editionNumber: item.user_owned_no_of_editions,
      };
      dispatch(saveLatestPrice(data));
      navigate(`/checkout/${id}`);
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  const makeOfferHandler = (item) => {
    let data = {
      art_id: dfaDetail.id,
      user_id: item?.ownedBy?.id,
      user_owned_no_of_editions: item?.user_owned_no_of_editions,
      art_image: dfaDetail.art_image,
      art_name: dfaDetail.art_name,
      drop_type: dfaDetail.drop_type,
      total_no_of_editions: dfaDetail.no_of_editions,
      selling_price: item?.current_price,
      highest_offer_price: item?.highest_price,
      username: dfaDetail.username,
      last_buy_price: item?.last_sale_price,
      last_sell_price: dfaDetail.price, //last sale price
      sold_no_of_editions: dfaDetail.sold_no_of_editions,
      thumbnail_image: dfaDetail.thumbnail_image,
      is_portrait: dfaDetail?.is_portrait,
      feature_file: dfaDetail?.feature_file,
    };
    setMakeOfferDetails(data);
    if (isUserLoggedin) {
      setShowOfferModal(true);
      //logic for make offer and API call if user is logged in
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  const makeGlobalOfferHandler = () => {
    if (isUserLoggedin) {
      //logic for make global offer and API call if user is logged in
      setShowGlobalOfferModal(true);
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  return (
    <>
      <div className={style.filterTable}>
        <div className={style.topHead}>
          <Commondropdown
            dropdowntitle={
              selectedFilter.title === undefined
                ? "Filter"
                : selectedFilter.title
            }
            classdrop="filterDrop"
          >
            {filterOption.map((item) => (
              <Dropdown.Item
                onClick={() => {
                  if (item.value !== selectedFilter?.value) {
                    setActivityPage(1);
                    setSelectedFilter(item);
                  }
                }}
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </Commondropdown>
          <Commondropdown
            dropdowntitle={
              selectedSortBy.title === undefined
                ? "Sort By"
                : selectedSortBy.title
            }
            classdrop="filterSort"
          >
            {sortOption.map((item) => (
              <Dropdown.Item
                onClick={() => {
                  if (item.value !== selectedSortBy?.value) {
                    setActivityPage(1);
                    setSelectedSortBy(item);
                  }
                }}
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </Commondropdown>
        </div>
        <div className={style.tableSec}>
          <CommonTable className={style.bidder_table}>
            <thead>
              <tr>
                <th>Edition</th>
                <th>Owned By</th>
                <th>Date of Last Sale</th>
                <th>Last Sale Price</th>
                <th>Current Price</th>
                <th>
                  {dfaActivity?.user_activity?.length > 0 &&
                    !dfaDetail?.is_global_offer_exist &&
                    dfaDetail?.totalEditionsOwned !=
                      dfaActivity?.totalItems && (
                      <CommonBtn
                        title="Send Global Offer"
                        role="btn"
                        className={`bordred-white btnGlobal`}
                        onClick={() => makeGlobalOfferHandler()}
                      />
                    )}
                </th>
              </tr>
            </thead>
            <tbody>
              {isDfaActivityLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              ) : (
                dfaActivity?.user_activity?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      {item.user_owned_no_of_editions == 0
                        ? "Updating..."
                        : `${item.user_owned_no_of_editions}`}
                    </td>
                    <td>
                      {item.user_id != 0 ? (
                        <div
                          className="ownedBy cursor-pointer"
                          onClick={() =>
                            navigateToUser(item?.ownedBy?.username)
                          }
                          title={item?.ownedBy?.username}
                        >
                          <img
                            src={
                              item?.ownedBy?.image_path
                                ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.ownedBy?.image_path}`
                                : personIcon
                            }
                            alt="Bidder-Icon"
                          />
                          <h6>{`@${sliceChar(
                            item?.ownedBy?.username,
                            15
                          )}`}</h6>
                        </div>
                      ) : (
                        <h6>Offline Sale</h6>
                      )}
                    </td>
                    <td>
                      {formatDateActivityTable(
                        item.user_id != 0 ? item.updatedAt : item.createdAt
                      )}
                    </td>
                    <td className="lastSale">
                      ₹ {item.last_sale_price / DECIMAL}
                    </td>
                    <td className="currentPrice">
                      {item?.current_price === 0
                        ? `--`
                        : `₹ ${item.current_price / DECIMAL}`}
                    </td>
                    <td>
                      {item.user_id !== 0 && (
                        <div className="btnSection">
                          {item.is_offer_exist === false &&
                          item?.is_offer_accepting === 1 &&
                          item?.ownedBy?.id !== userId ? (
                            <CommonBtn
                              title="Make Offer"
                              role="btn"
                              className={`bordred-black btnMake`}
                              onClick={() => makeOfferHandler(item)}
                            />
                          ) : item?.is_offer_accepting === 0 ? (
                            <>Not Accepting Offers</>
                          ) : null}
                          {item?.current_price != 0 &&
                            item?.ownedBy?.id !== userId && (
                              <CommonBtn
                                title="Buy now"
                                role="btn"
                                className={`btnBuy`}
                                onClick={() => buyHandler(item)}
                              />
                            )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )}
              {/* {isDfaActivityLoading && (
                <tr>
                  <td colSpan={6} className='text-center'>
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )} */}
              {dfaActivity?.user_activity?.length === 0 && (
                <tr>
                  <td colSpan={6}>
                    <Notfound text={"No activity found."} />
                  </td>
                </tr>
              )}
            </tbody>
          </CommonTable>
          <div className="filterPagination">
            {dfaActivity?.totalItems > 10 && (
              <div className="selectShow">
                <label>Show</label>
                <div className="selectShow_inner">
                  <Select
                    classNamePrefix="react-select"
                    className="react-select"
                    options={limitOption}
                    isSearchable={false}
                    onChange={(option) => {
                      setActivityPage(1);
                      setLimit(option);
                    }}
                    value={limit}
                  />
                </div>
              </div>
            )}
            {dfaActivity?.totalPages > 1 && (
              <CustomPagination
                className=""
                activePage={activityPage}
                totalPage={dfaActivity?.totalPages}
                setActivePage={setActivityPage}
                maxVisiblePages={5}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenEditionFCFBRafflesTable;
