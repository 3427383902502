import React, { useEffect, useState } from "react";
import style from "./Dropaction.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import {
  PLATFORM_MAX_VALUE,
  formatDateWithTime,
  getDateFromTimestamp,
  getNumberWithComma,
} from "../../../Helper/common";
import Timer from "../Timer";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import personIcon from "../../../Assets/images/person_icon.jpg";
import { DECIMAL, PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import Notfound from "../../Common/NotFound/Notfound";
import HowitWork from "../../Common/HowitWork/HowitWork";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import DOMPurify from "dompurify";

const AuctionLive = ({
  artDetail,
  liveActivityDetail,
  setActivityPage,
  page,
  totalActivityPage,
  userId,
  type,
  id,
  setShowClosedDrop,
  lastBidAmount,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const [highestPriceProduct, sethighestPriceProduct] = useState({});

  useEffect(() => {
    sethighestPriceProduct({});
    if (liveActivityDetail.length > 0) {
      const highestPriceProduct = liveActivityDetail.reduce((prev, current) => {
        return prev.offer_price / DECIMAL > current.offer_price / DECIMAL
          ? prev
          : current;
      }, {});
      sethighestPriceProduct({ ...highestPriceProduct });
    }
  }, [liveActivityDetail]);

  const initialValue = {
    bidAmount: "",
    is_max_limit: artDetail?.is_max_limit,
  };

  const validationSchema = Yup.object({
    is_max_limit: Yup.boolean(),
    bidAmount: Yup.number()
      .typeError(inputFieldValidation.commonMessage.number)
      .positive(inputFieldValidation.commonMessage.positive)
      .test(
        "is-greater-than-bid-amount",
        `*Bid amount should be equal or greater than ₹${getNumberWithComma(
          Number(highestPriceProduct?.offer_price / DECIMAL) +
            Number(artDetail.increment) / DECIMAL
        )}`,
        (values) => {
          if (
            (Object.keys(highestPriceProduct).length &&
              !artDetail.is_max_limit) ||
            (Object.keys(highestPriceProduct).length &&
              formik.values.is_max_limit &&
              !artDetail.is_max_limit)
          ) {
            return (
              Number(values) >=
              Number(highestPriceProduct?.offer_price / DECIMAL) +
                Number(artDetail.increment) / DECIMAL
            );
          }
          return true;
        }
      )
      .test(
        "check bid price",
        `*Bid amount should be equal or greater than ₹${getNumberWithComma(
          Number(artDetail.price) / DECIMAL
        )}`,
        function (values) {
          if (!artDetail?.is_max_limit) {
            return Number(values) >= Number(artDetail.price) / DECIMAL;
          } else {
            return true;
          }
        }
      )
      .test(
        "check last bid price",
        `*Bid amount should be greater than ₹${getNumberWithComma(
          Number(artDetail.last_bid_amount) / DECIMAL
        )}`,
        function (values) {
          if (!artDetail?.is_max_limit) {
            return (
              Number(values) > Number(artDetail?.last_bid_amount) / DECIMAL
            );
          } else {
            return true;
          }
        }
      )
      .test("notGreaterThanValue", `*Value is not accepted`, function (values) {
        if (values == "" || values == undefined) {
          return true;
        } else {
          return values < PLATFORM_MAX_VALUE;
        }
      })

      .required(inputFieldValidation.commonMessage.required("Bid Amount")),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (artDetail?.is_eligible_to_buy) {
        checkoutHandler(values);
      } else {
        setShowClosedDrop(true);
      }
    },
  });

  const checkoutHandler = async (values) => {
    if (isUserLoggedin) {
      try {
        let data = {
          price: values.bidAmount,
          quantity: 1,
          market: "primary",
          is_max_limit: values.is_max_limit ? 1 : 0,
        };
        dispatch(saveLatestPrice(data));
        navigate(`/checkout/${id}`);
      } catch (error) {}
    } else {
      dispatch(saveNextRoute(`/curated-drop/digital-art/${type}/${id}`));
      navigate("/login");
    }
  };

  const bidActivity = () => {
    if (liveActivityDetail.length) {
      const sortedArray = liveActivityDetail?.sort(
        (a, b) => b.offer_price - a.offer_price
      );
      return sortedArray.map((data, index) => (
        <tr key={data.id}>
          <td>
            #
            {(page - 1) * PER_PAGE_RECORD_LIMIT.ART_DETAIL_PRIMARY_PER_PAGE +
              (index + 1)}
          </td>
          <td>
            <div className="d-flex align-items-center thumb-outer">
              <img
                className="thumb"
                src={
                  data?.auction_user[0]?.image_path
                    ? `${process.env.REACT_APP_IMG_BASE_URL}${data?.auction_user[0]?.image_path}`
                    : personIcon
                }
                alt="thumb"
              />
              <h4
                className="cursor-pointer"
                onClick={() => {
                  navigate(`/profile/${data?.auction_user[0]?.username}`);
                }}
              >
                @{data?.auction_user[0]?.username}
              </h4>
            </div>
          </td>
          <td>₹{getNumberWithComma(data.offer_price / DECIMAL)}</td>

          <td>{formatDateWithTime(data.createdAt)}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={4}>
            <Notfound text={"No activity found."} />
          </td>
        </tr>
      );
    }
  };
  const dynamicClass =
    liveActivityDetail?.length > 0 ? style.ranked_table : style.tableNotdata;

  return (
    <>
      <div className={style.dropaction_comingsoon_rightsec_pricefloor}>
        <div className={style.headprice}>
          <ul>
            <li>
              <label>Floor Price :</label>
              <h6>₹{getNumberWithComma(artDetail.price / DECIMAL)}</h6>
            </li>
            <li>
              <label>Min. Increment :</label>
              <h6>
                ₹{getNumberWithComma(Number(artDetail?.increment) / DECIMAL)}
              </h6>
            </li>
          </ul>
          <h5 className={style.timeedit}>
            Ends in :{" "}
            <Timer
              startingTimestamp={artDetail?.end_time}
              type="live"
              id={id}
            />
          </h5>
        </div>
        <div className={style.startinfo}>
          <div className={style.bidhighest}>
            <div className={`${style.bidvalues} ${style.auctionInfos}`}>
              <div className={style.highest_bid}>
                <label>Highest Bid</label>
                <span>:</span>
                <h5>
                  {highestPriceProduct?.offer_price
                    ? `₹${getNumberWithComma(
                        highestPriceProduct?.offer_price / DECIMAL
                      )}`
                    : "--"}
                </h5>
              </div>
              <div className={style.minrank_bid}>
                <label>Min. Bid for Rank 1</label>
                <span>:</span>
                <h6>
                  {highestPriceProduct?.offer_price
                    ? `₹${getNumberWithComma(
                        Number(artDetail?.increment) / DECIMAL +
                          Number(highestPriceProduct?.offer_price / DECIMAL)
                      )}`
                    : `₹${getNumberWithComma(
                        Number(artDetail?.price) / DECIMAL
                      )}`}
                </h6>
              </div>
              {(artDetail?.last_bid_amount > 0 || lastBidAmount) && (
                <div className={style.minrank_bid}>
                  <label>Your last bid amount</label>
                  <span>:</span>
                  <h6>
                    {`₹${
                      lastBidAmount
                        ? getNumberWithComma(Number(lastBidAmount) / DECIMAL)
                        : getNumberWithComma(
                            Number(artDetail?.last_bid_amount) / DECIMAL
                          )
                    }`}
                  </h6>
                </div>
              )}
              {artDetail?.user_max_limit > 0 && (
                <div className={style.minrank_bid}>
                  <label>Max limit amount</label>
                  <span>:</span>
                  <h6>
                    ₹{getNumberWithComma(Number(artDetail?.user_max_limit))}
                  </h6>
                </div>
              )}
            </div>
            {
              /* userId !== artDetail.artist_id &&  */ <div
                className={`${style.bitlimitset} ${style.auctionValues}`}
              >
                <form onSubmit={formik.handleSubmit}>
                  <FormikControls
                    control="checkbox"
                    type="checkbox"
                    name="is_max_limit"
                    label="Set Max Limit"
                    formik={formik}
                    className={`checkMax ${style.setlimitcheck}`}
                    onChange={(e) => {
                      formik.setFieldValue("bidAmount", "");
                      formik.setFieldValue("is_max_limit", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                    checked={formik.values.is_max_limit}
                    disabled={artDetail?.is_max_limit}
                  />
                  <FormikControls
                    variant="priceadd"
                    control="input"
                    type="input"
                    name="bidAmount"
                    id="bidAmount"
                    placeholder="Enter Amount"
                    formik={formik}
                    onChange={(e) => {
                      if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    disabled={!isUserLoggedin}
                    value={formik.values.bidAmount}
                    onBlur={formik.handleBlur}
                  />
                  {isUserLoggedin && (
                    <CommonBtn
                      title="Place Bid"
                      role="btn"
                      className=""
                      disabled={
                        artDetail?.last_bid_amount ==
                          highestPriceProduct?.offer_price &&
                        !formik.values.is_max_limit
                      }
                    />
                  )}
                </form>

                {!isUserLoggedin && (
                  <CommonBtn
                    title="Place Bid"
                    role="btn"
                    className=""
                    onClick={checkoutHandler}
                  />
                )}
              </div>
            }
          </div>
        </div>
      </div>
      <div className={style.dropaction_comingsoon_rightsec_tabpartlist}>
        <Tabs
          defaultActiveKey="aboutart"
          id=""
          className={`tablists ${style.art_tabcont}`}
        >
          <Tab eventKey="aboutart" title="About Art">
            <div className={style.artstab_containt}>
              <div
                className="textSection"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(artDetail?.bio),
                }}
              ></div>

              <ul className={style.about_artlist}>
                <ul className={style.about_artlist}>
                  <li>
                    <label>
                      {artDetail?.no_of_editions == 1 ? "Edition" : "Editions"}
                    </label>
                    <span>:</span>
                    <h6>{artDetail?.no_of_editions}</h6>
                  </li>
                  <li>
                    <label>Type</label>
                    <span>:</span>
                    <h6>{artDetail?.art_type}</h6>
                  </li>
                  <li>
                    <label>Released</label>
                    <span>:</span>
                    <h6>{getDateFromTimestamp(artDetail.start_time)}</h6>
                  </li>
                </ul>
              </ul>
            </div>
          </Tab>
          <Tab eventKey="bidsactivity" title="Bid Activity">
            <div className={`${style.bid_activity} ${style.bidRanked}`}>
              {liveActivityDetail?.length > 0 &&
                Object.keys(highestPriceProduct).length && (
                  <>
                    <div className={style.bid_rank}>
                      <div className={style.bid_rank_userinfo}>
                        <h6>Rank 1</h6>
                        <h6>
                          @{highestPriceProduct?.auction_user[0]?.username}
                        </h6>
                        <div className={style.userIcon}>
                          <img
                            src={
                              highestPriceProduct?.auction_user[0]?.image_path
                                ? `${process.env.REACT_APP_IMG_BASE_URL}${highestPriceProduct?.auction_user[0]?.image_path}`
                                : personIcon
                            }
                            alt="userIcon"
                          />
                        </div>
                      </div>
                      <div className={style.bid_rank_userprice}>
                        <h5>Bid Amount</h5>
                        <h4>
                          ₹
                          {getNumberWithComma(
                            highestPriceProduct?.offer_price / DECIMAL
                          )}
                        </h4>
                        <p>
                          {formatDateWithTime(highestPriceProduct.createdAt)}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              <div className={`${style.bid_table} ${dynamicClass}`}>
                <CommonTable className={style.bidder_table}>
                  <thead>
                    <tr>
                      <th>
                        <span>Rank</span>
                      </th>
                      <th>
                        <span>Collector</span>
                      </th>
                      <th>
                        <span>Bid Amount (₹)</span>
                      </th>
                      <th>
                        <span>Time</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{bidActivity()}</tbody>
                </CommonTable>
                {totalActivityPage > 1 && (
                  <CustomPagination
                    className="ms-auto"
                    activePage={page}
                    totalPage={totalActivityPage}
                    setActivePage={setActivityPage}
                    maxVisiblePages={5}
                  />
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="howitworks" title="How it Works">
            <div className={`py-0 ${style.artstab_containt}`}>
              {/* <p>
                There’s a stage we reach in life where we start to realize our
                innocence is “gone.” We may have began to realize that we lost
                that childlike innocence ages ago. However, times of innocence
                seem to flutter by in adulthood.
              </p> */}
              <HowitWork artDetail={artDetail} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default AuctionLive;
