import React, { useEffect, useState } from "react";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Banner from "../../../Assets/images/profile-bg.png";
import style from "./Userprofile.module.scss";
import Carddetails from "./Carddetails/Carddetails";
import { Container, Nav, Tab, Tabs } from "react-bootstrap";
import Artowned from "./Artowned/Artowned";
import Followers from "./Followers/Followers";
import Following from "./Following/Following";
import Like from "./Like/Like";
import {
  getUserArtFollowersLikes,
  followUnfollowAndRemove,
  getUserLikesData,
  likeUnlikeArt,
  setPageUserProfile,
  setTabTypeUserProfile,
} from "../../../Features/user/userSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  COMPARISION_CONSTANT_NAMES,
  PER_PAGE_RECORD_LIMIT,
  USER_PROFILE_TAB,
  USER_TYPE,
} from "../../../Constant/userConstant";
import { useDebounce } from "../../../customHooks/useDebounce";
import { getArts } from "../../../Features/drop/dropSlice";
import ArtCreated from "./ArtCreated/ArtCreated";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Helmet } from "react-helmet";
import { SearchIcon } from "../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import ImportNftModal from "./Artowned/components/ImportNftModal";
import { SuccessModal } from "../../Pages/NewModals/SuccessModal";
import {
  useDisconnect,
  useSwitchNetwork,
  useWeb3Modal,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import UploadedArt from "./UploadedArt/UploadedArt";

const options = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "40", label: "40" },
  { value: "50", label: "50" },
];

const Userprofile = () => {
  const dispatch = useDispatch();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [likes, setLikes] = useState([]);
  const [artOwned, setArtOwned] = useState([]);
  const [createdArt, setCreatedArt] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const userType = useSelector((state) => state.persist.userType);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const pageUserProfile = useSelector((state) => state.users.pageUserProfile);
  const userDetail = useSelector((state) => state?.users?.profileData);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showImportNft, setShowImportNft] = useState(false);
  const [limit, setLimit] = useState(options[0]);

  const tabTypeUserProfile = useSelector(
    (state) => state.users.tabTypeUserProfile
  );

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const chain = 80002;

  const { switchNetwork } = useSwitchNetwork();

  useEffect(() => {
    if (
      debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
      debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE
    ) {
      if (
        tabTypeUserProfile == USER_PROFILE_TAB.LIKES ||
        tabTypeUserProfile == USER_PROFILE_TAB.ART_OWN
      ) {
        getUserLikes();
      } else if (tabTypeUserProfile == USER_PROFILE_TAB.ART_CREATED) {
        getUserCreatedArt();
      } else {
        getUserArtFollowersLikesAPI();
      }
    }
  }, [
    tabTypeUserProfile,
    pageUserProfile,
    debouncedSearchTerm,
    ArtistId,
    limit,
  ]);

  useEffect(() => {
    if (isConnected) {
      connectionHandler();
    }
  }, [isConnected]);

  const connectionHandler = async () => {
    await switchNetwork(chain);
    setShowImportNft(true);
  };

  const getUserCreatedArt = async () => {
    let data = {
      id: ArtistId,
      size: limit?.value,
      page: search !== "" ? 1 : pageUserProfile,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getArts(data));
      if (response) {
        let record = response.data.data;
        setTotalPage(record.totalPages);
        setTotalItems(record.totalItems);
        setCreatedArt(record?.arts);
      }
    } catch (err) {}
  };

  const getUserLikes = async () => {
    let data = {
      filter: tabTypeUserProfile,
      page: search !== "" ? 1 : pageUserProfile,
      size: limit?.value,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getUserLikesData(data));
      if (response) {
        let record = response.data.data;
        if (tabTypeUserProfile == USER_PROFILE_TAB.ART_OWN) {
          setTotalPage(record.totalPages);
          setTotalItems(record.totalItems);
          setArtOwned(record.data);
        }
        if (tabTypeUserProfile == USER_PROFILE_TAB.LIKES) {
          setTotalPage(record.totalPages);
          setTotalItems(record.totalItems);
          setLikes(record.data);
        }
      }
    } catch (err) {}
  };

  const getUserArtFollowersLikesAPI = async () => {
    let data = {
      filter: tabTypeUserProfile,
      page: search !== "" ? 1 : pageUserProfile,
      size: limit?.value,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getUserArtFollowersLikes(data));
      if (response) {
        let record = response.data.data;
        setTotalPage(record.totalPages);
        setTotalItems(record.totalItems);
        if (tabTypeUserProfile == "FOLLOWING") {
          setFollowing(record.data);
        }
        if (tabTypeUserProfile == "FOLLOWERS") {
          setFollowers(record.data);
        }
      }
    } catch (err) {}
  };

  const onTabChange = (e) => {
    setSearch("");
    dispatch(setPageUserProfile(1));
    setTotalPage(0);
    setLimit(options[0]);
    dispatch(setTabTypeUserProfile(e));
  };

  const followUnfollowAndRemoveApi = async (type, data) => {
    // type=0 (UNFOLLOW) , 1 ( FOLLOW) , 2 (REMOVE)
    let record = {
      type: type == "remove" ? 2 : type == "follow" ? 1 : 0,
      id:
        type == "remove"
          ? data.user.id
          : type == "unfollow"
          ? data.user.id
          : "",
    };
    try {
      let response = await dispatch(followUnfollowAndRemove(record));
      if (response) {
        getUserArtFollowersLikesAPI();
      }
    } catch (err) {}
  };

  const likeUnlikeLikeArt = async (art_id) => {
    let record = {
      type: 0,
      art_id: art_id,
    };
    try {
      let response = await dispatch(likeUnlikeArt(record));
      if (response) {
        getUserLikes();
      }
    } catch (err) {}
  };

  const getPlaceHolder = () => {
    if (tabTypeUserProfile === USER_PROFILE_TAB.ART_OWN) {
      return `Search Arts, Artist`;
    } else if (tabTypeUserProfile === USER_PROFILE_TAB.ART_CREATED) {
      return `Search Arts`;
    } else if (tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWERS) {
      return `Search Artist, User`;
    } else if (tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWING) {
      return `Search Artist, User`;
    } else if (tabTypeUserProfile === USER_PROFILE_TAB.LIKES) {
      return `Search Arts`;
    }
  };

  return (
    <>
      <section className={style.user_profile}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
        </Helmet>
        <CommonHero
          backgroundImg={
            userDetail?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
              : CoverImage
          }
        />
        <Carddetails tab="profile" Btnshare />
        <Container>
          {/* <div className={style.tablist}>
            <Tabs
              activeKey={tabTypeUserProfile}
              id="uncontrolled-tab-example"
              className="tablists"
              onSelect={onTabChange}
            >
              <Tab eventKey={USER_PROFILE_TAB.ART_OWN} title={"ART OWN"}>
                {tabTypeUserProfile === USER_PROFILE_TAB.ART_OWN && (
                  <Artowned
                    data={artOwned}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    getUserLikes={getUserLikes}
                  />
                )}
              </Tab>
              {userType == USER_TYPE.ARTIST && (
                <Tab
                  eventKey={USER_PROFILE_TAB.ART_CREATED}
                  title={"ART CREATED"}
                >
                  {tabTypeUserProfile === USER_PROFILE_TAB.ART_CREATED && (
                    <ArtCreated
                      data={createdArt}
                      setSearch={setSearch}
                      search={search}
                      page={pageUserProfile}
                      totalPage={totalPage}
                      followUser={followUnfollowAndRemoveApi}
                      getUserLikes={getUserLikes}
                    />
                  )}
                </Tab>
              )}
              <Tab
                eventKey={USER_PROFILE_TAB.FOLLOWERS}
                title={USER_PROFILE_TAB.FOLLOWERS}
              >
                {tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWERS && (
                  <Followers
                    data={followers}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    type="self"
                  />
                )}
              </Tab>
              <Tab
                eventKey={USER_PROFILE_TAB.FOLLOWING}
                title={USER_PROFILE_TAB.FOLLOWING}
              >
                {tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWING && (
                  <Following
                    data={following}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    type="self"
                  />
                )}
              </Tab>
              <Tab
                eventKey={USER_PROFILE_TAB.LIKES}
                title={USER_PROFILE_TAB.LIKES}
              >
                {tabTypeUserProfile === USER_PROFILE_TAB.LIKES && (
                  <Like
                    data={likes}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    likeUnlikeArt={likeUnlikeLikeArt}
                    type="self"
                  />
                )}
              </Tab>
            </Tabs>
          </div> */}

          <div className={style.tablist}>
            <Tab.Container
              id="left-tabs-example"
              className="tablists"
              activeKey={tabTypeUserProfile}
              onSelect={onTabChange}
            >
              <div className={style.navtablist}>
                <Nav variant="pills" className="nav_tabslist">
                  {/* user dashboardlist */}
                  <Nav.Item>
                    <Nav.Link eventKey={USER_PROFILE_TAB.ART_OWN}>
                      ART OWNED
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey={USER_PROFILE_TAB.UPLOADED_ARTS}>
                      UPLOADED ARTS
                    </Nav.Link>
                  </Nav.Item> */}
                  {userType == USER_TYPE.ARTIST && (
                    <Nav.Item>
                      <Nav.Link eventKey={USER_PROFILE_TAB.ART_CREATED}>
                        ART CREATED
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey={USER_PROFILE_TAB.FOLLOWERS}>
                      FOLLOWERS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={USER_PROFILE_TAB.FOLLOWING}>
                      FOLLOWING
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={USER_PROFILE_TAB.LIKES}>LIKES</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className={style.rightSearch}>
                  <form>
                    <>
                      <div className="search_input">
                        <div className="search_input_inner">
                          <button type="submit" className="search_input_icon">
                            <SearchIcon />
                          </button>
                          <input
                            type="search"
                            className="form-control"
                            placeholder={getPlaceHolder()}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </>

                    <CommonBtn
                      role="btn"
                      type={"button"}
                      title="Import Art"
                      onClick={async () => {
                        open();
                      }}
                      className={`bordred-black ${style.btnimport}`}
                    />
                  </form>
                </div>
              </div>

              <Tab.Content>
                {/* user dashboardlist */}
                <Tab.Pane eventKey={USER_PROFILE_TAB.ART_OWN}>
                  {tabTypeUserProfile === USER_PROFILE_TAB.ART_OWN && (
                    <Artowned
                      data={artOwned}
                      setSearch={setSearch}
                      search={search}
                      page={pageUserProfile}
                      totalPage={totalPage}
                      totalItems={totalItems}
                      followUser={followUnfollowAndRemoveApi}
                      getUserLikes={getUserLikes}
                      options={options}
                      setLimit={setLimit}
                      limit={limit}
                    />
                  )}
                </Tab.Pane>
                {/* <Tab.Pane eventKey={USER_PROFILE_TAB.UPLOADED_ARTS}>
                  {tabTypeUserProfile === USER_PROFILE_TAB.ART_OWN && (
                    <UploadedArt
                      options={options}
                      setLimit={setLimit}
                      limit={limit}
                    />
                  )}
                </Tab.Pane> */}
                <Tab.Pane eventKey={USER_PROFILE_TAB.ART_CREATED}>
                  {tabTypeUserProfile === USER_PROFILE_TAB.ART_CREATED && (
                    <ArtCreated
                      data={createdArt}
                      setSearch={setSearch}
                      search={search}
                      page={pageUserProfile}
                      totalPage={totalPage}
                      totalItems={totalItems}
                      followUser={followUnfollowAndRemoveApi}
                      getUserLikes={getUserLikes}
                      options={options}
                      setLimit={setLimit}
                      limit={limit}
                    />
                  )}
                </Tab.Pane>
                {/* <Tab.Pane eventKey="uploaded-arts">uploaded-arts</Tab.Pane> */}
                <Tab.Pane eventKey={USER_PROFILE_TAB.FOLLOWERS}>
                  {tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWERS && (
                    <Followers
                      data={followers}
                      setSearch={setSearch}
                      search={search}
                      page={pageUserProfile}
                      totalPage={totalPage}
                      totalItems={totalItems}
                      followUser={followUnfollowAndRemoveApi}
                      type="self"
                      options={options}
                      setLimit={setLimit}
                      limit={limit}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey={USER_PROFILE_TAB.FOLLOWING}>
                  {tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWING && (
                    <Following
                      data={following}
                      setSearch={setSearch}
                      search={search}
                      page={pageUserProfile}
                      totalPage={totalPage}
                      totalItems={totalItems}
                      followUser={followUnfollowAndRemoveApi}
                      type="self"
                      options={options}
                      setLimit={setLimit}
                      limit={limit}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey={USER_PROFILE_TAB.LIKES}>
                  {tabTypeUserProfile === USER_PROFILE_TAB.LIKES && (
                    <Like
                      data={likes}
                      setSearch={setSearch}
                      search={search}
                      page={pageUserProfile}
                      totalPage={totalPage}
                      totalItems={totalItems}
                      followUser={followUnfollowAndRemoveApi}
                      likeUnlikeArt={likeUnlikeLikeArt}
                      type="self"
                      options={options}
                      setLimit={setLimit}
                      limit={limit}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Container>

        {/* <ContactsupportModal
          show={showContact}
          handleClose={() => setShowContact(false)}
        /> */}

        {showImportNft && (
          <ImportNftModal
            show={showImportNft}
            setShowSuccess={setShowSuccess}
            onHide={() => {
              disconnect();
              setShowImportNft(false);
            }}
            address={address}
          />
        )}

        {showSuccess && (
          <SuccessModal
            show={showSuccess}
            onHide={() => {
              disconnect();
              setShowSuccess(false);
            }}
          />
        )}
      </section>
    </>
  );
};

export default Userprofile;
