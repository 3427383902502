import React from "react";
import { Form } from "react-bootstrap";
import "./Commonprofileup.scss";

const Commonprofileup = ({
  className,
  texttitle,
  imageUp,
  id,
  name,
  onBlur,
  onChange,
  title,
  disabled
}) => {
  return (
    <>
      <div className={`common_profileup ${className}`}>
        <img src={imageUp} alt="imageUp" />
        <Form.Control
          type="file"
          className="file_upload"
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          title={title}
          disabled={disabled}
        />
        {texttitle && <h6>{texttitle}</h6>}
      </div>
    </>
  );
};

export default Commonprofileup;
