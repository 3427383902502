// import { API_HOST } from "";
import { fetch } from "../Helper/httpRequest";
import axios from "axios";

const getIp = () => {
  return axios.get("https://ipapi.co/json/");
};


export const authService = {
  getIp,
};
