import React from 'react'
import { useNavigate } from 'react-router-dom';
import CommonBtn from '../Ui/CommonBtn/CommonBtn'
import './NotFound404.scss';

const NotFound404 = () => {
  const navigate = useNavigate();
  return (
    <div className='not_found'>
      <div className="not_found-inner">
        <h2>
          4<span>0</span>4
        </h2>
        <p>Page Not Found</p>
        <CommonBtn onClick={() => navigate(-1)} className='btn-yellow-white ' role='btn' title="Go Back" />
      </div>
    </div>
  )
}

export default NotFound404