import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import './Daterange.scss';

const Daterange = (
    {
        label, name,onChange,onFocus, placeholder, formik, className,isClearable,onBlur, onClear, ...rest
    }) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const today = new Date();
    
    return (
        <>
            <Form.Group className={`common_date common_input ${className}`} controlId={name}>
                {label && <Form.Label>{label}</Form.Label>}
                <div className="common_input_inner">
                    <ReactDatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onChange}
                        isClearable={isClearable}
                        {...rest}
                        placeholderText={placeholder}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onClear={onClear}
                        onKeyDown={(e) => {
                            e.preventDefault();
                         }}
                         minDate={new Date(0)} // Minimum date (January 1, 1970)
                         maxDate={today} // Maximum date (today)
                    />
                </div>
                {formik?.touched[name] && (
                    <p className="error_message">{formik?.errors[name]}</p>
                )}
            </Form.Group>
        </>
    )
}

export default Daterange