import React from "react";
import { DECIMAL, OFFER_STATUS } from "../../../../../Constant/userConstant";
import {
  formatDateWithTime,
  getNumberWithComma,
  sliceChar,
} from "../../../../../Helper/common";
import CommonTable from "../../../../Common/Ui/CommonTable/CommonTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import style from "./Offersmade.module.scss";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import personIcon from "../../../../../Assets/images/person_icon.jpg";
import Notfound from "../../../../Common/NotFound/Notfound";
import { RoutesUrls } from "../../../../../Constant/RoutesUrls";

const IndividualMadeOffers = (props) => {
  const { data, updateStatusOpenModal } = props;
  const profileData = useSelector((state) => state.users.profileData);

  const navigate = useNavigate();

  const goToUserProfile = (username) => {
    navigate(`/profile/${username}`);
  };
  const navigateToArt = (id) => {
    navigate(`/digital-fine-arts/${id}`);
  };
  return (
    <div>
      <div className={style.table_section}>
        <CommonTable className={style.artowned_table}>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="art_details">
                  <div className="field_table">
                    <img
                      src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.nft_art.thumbnail_image}`}
                      alt="artimage"
                      onClick={() => navigateToArt(`${item?.nft_art.id}`)}
                      className="cursor-pointer"
                    />
                    <div className="text_field">
                      <h5 title={item?.nft_art?.art_name}>
                        {sliceChar(item?.nft_art?.art_name, 15)}
                      </h5>
                      <p
                        title={item.artist_name}
                        onClick={() =>
                          goToUserProfile(item?.nft_art?.art_user?.username)
                        }
                      >
                        @{sliceChar(item?.nft_art?.art_user?.username, 15)}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="edition_info">
                  <div className="text_field_all">
                    <h6>Edition No.</h6>
                    <p>#{item?.edition_no}</p>
                  </div>
                </td>
                <td className="last_sale">
                  <div
                    className="field_table radius_50 cursor-pointer"
                    onClick={() => goToUserProfile(item?.owner_detail.username)}
                  >
                    <img
                      src={
                        item?.owner_detail?.image_path
                          ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.owner_detail?.image_path}`
                          : personIcon
                      }
                      alt="artimage"
                    />
                    <div className="text_field_all">
                      <h6>Collector</h6>
                      <p>@{item?.owner_detail?.username}</p>
                    </div>
                  </div>
                </td>
                <td className="offer_my">
                  <div className="text_field_all">
                    <h6>Last Sale Price</h6>
                    <p>
                      ₹{" "}
                      {getNumberWithComma(
                        item?.nft_art?.selling_price / DECIMAL
                      )}
                    </p>
                  </div>
                </td>
                <td className="offer_my">
                  <div className="text_field_all">
                    <h6>My Offer</h6>
                    <p>
                      ₹{" "}
                      {getNumberWithComma(
                        (
                          Number(item?.total_art_price_inc_tax) / DECIMAL
                        ).toFixed(2)
                      )}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="text_field_all">
                    <h6>Offer Expires On</h6>
                    <p>{formatDateWithTime(item?.duration)}</p>
                  </div>
                </td>
                <td>
                  <div className="text_field_all">
                    <h6>
                      {item.status == OFFER_STATUS.coutered
                        ? "Counter Offer"
                        : "Status"}
                    </h6>
                    <p>
                      {item?.counter == 1 &&
                      item.status == OFFER_STATUS.coutered
                        ? `₹ ${getNumberWithComma(
                            Number(item?.counter_offer / DECIMAL).toFixed(2)
                          )}`
                        : item?.status === OFFER_STATUS.sold
                        ? OFFER_STATUS.declined
                        : item?.status}
                    </p>
                    {item?.declined_reason != "Not Interested" && (
                      <span>{item?.declined_reason}</span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="btn_section">
                    {item.counter == 1 &&
                      item.status == OFFER_STATUS.coutered &&
                      item.counter_user_id !== profileData.id && (
                        <CommonBtn
                          role="btn"
                          className={`black-btn-yellow me-4 ${style.btneditsize}`}
                          title="Accept"
                          onClick={() =>
                            updateStatusOpenModal(OFFER_STATUS.accepted, item)
                          }
                        />
                      )}
                    {/* make new offer is optional till now */}
                    {/* {getMakeNewofferStatus(item) && (
                      <CommonBtn
                        role="btn"
                        className={`black-btn-yellow me-4 ${style.btneditsize}`}
                        title={"Make New Offer"}
                        onClick={() => makeNewOfferOpenModal(item)}
                      />
                    )} */}

                    {item.status != OFFER_STATUS.closed &&
                      item.status != OFFER_STATUS.accepted &&
                      item.status != OFFER_STATUS.sold &&
                      item.status != OFFER_STATUS.declined &&
                      item.status != OFFER_STATUS.expired && (
                        <CommonBtn
                          role="btn"
                          className={`bordred-gray ${style.btneditsize}`}
                          title="Decline"
                          onClick={() =>
                            updateStatusOpenModal(OFFER_STATUS.declined, item)
                          }
                        />
                      )}
                  </div>
                </td>
              </tr>
            ))}
            {data.length == 0 && (
              <tr>
                <td colSpan={9} className="border_none">
                  <Notfound text={"No record found."} />
                </td>
              </tr>
            )}
          </tbody>
        </CommonTable>
      </div>
    </div>
  );
};

export default IndividualMadeOffers;
