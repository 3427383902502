import React from "react";
import style from "./Followers.module.scss";
import { Col, Row } from "react-bootstrap";
import CommonCardsecond from "../../../Common/Ui/CommonCardsecond/CommonCardsecond";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import Notfound from "../../../Common/NotFound/Notfound";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { setPageOtherUserProfile } from "../../../../Features/user/userSlice";

const Followers = (props) => {
  let {
    data,
    setSearch,
    page,
    totalPage,
    followUser,
    type,
    setLimit,
    limit,
    totalItems,
    limitOption,
  } = props;

  const dispatch = useDispatch();

  const getButtonText = (item) => {
    if (type == "self") {
      return "Remove";
    } else if (type === "other") {
      if (item.isFollowed) {
        return "Unfollow";
      } else {
        return "Follow";
      }
    }
  };
  
  return (
    <>
      <section className={style.followers}>
        {/* <div className={style.searchbar}>
          <>
            <div className="search_input">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Artist, User"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </>
        </div> */}
        {data.length > 0 && (
          <Row className={style.rowFollow}>
            {data.map((item) => (
              <Col
                xxl={2}
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={6}
                key={item.id}
                className={style.colFollow}
              >
                <CommonCardsecond
                  className={style.followerCard}
                  classtextfields={style.textfeilds}
                  bordergrayBtntitle={getButtonText(item)}
                  followUser={followUser}
                  item={item}
                  type={type}
                />
              </Col>
            ))}
          </Row>
        )}
        {data.length == 0 && (
          <div className={style.noData}>
            <Notfound />
          </div>
        )}

        <div className="filterPagination">
          {totalItems > 10 && (
            <div className="selectShow">
              <label>Show</label>
              <div className="selectShow_inner">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  options={limitOption}
                  isSearchable={false}
                  onChange={(option) => {
                    setLimit(option);
                    dispatch(setPageOtherUserProfile(1));
                  }}
                  value={limit}
                />
              </div>
            </div>
          )}
          {totalPage > 1 && (
            <CustomPagination
              // className="ms-auto"
              activePage={page}
              totalPage={totalPage}
              maxVisiblePages={5}
              dispatch={dispatch}
              componentTypes={"OtherUserProfile"}
              componentType={"Profile"}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Followers;
