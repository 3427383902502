import React, { useEffect, useState } from "react";
import style from "./ArtDetailSecondaryMarketplace.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  HeartIcon,
  HeartIconRed,
  InfoIcom,
} from "../../../Assets/svgIcons/SVGicon";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  followUnfollowArt,
  getSecondaryMarketplaceArtDetail,
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import personIcon from "../../../Assets/images/person_icon.jpg";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import {
  checkFileType,
  getDateFromTimestamp,
  getNumberWithComma,
  sliceChar,
} from "../../../Helper/common";
import { DECIMAL, PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import Notfound from "../../Common/NotFound/Notfound";
import MakeOffermodal from "../../Private/Userprofile/Artowned/components/MakeOffermodal/MakeOffermodal";
import MakeGlobalOfferModal from "../../Private/Userprofile/Artowned/components/MakeOffermodal/MakeGlobalOfferModal";
import HoverVideoPlayer from "react-hover-video-player";
import styles from "../Drop/drop.module.scss";
import Feature_Modal from "../../Common/Feature_Modal/Feature_Modal";
import ShareBtn from "../../Common/Ui/ShareBtn/ShareBtn";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import CommonTooltip from "../../Common/CommonTooltip/CommonTooltip";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
const digitalType = {
  1: "Auction",
  2: "Ranked auction",
  3: "First come first buy",
  4: "Open edition",
  5: "Raffle",
};

const ArtDetailSecondaryMarketplace = () => {
  const [secondaryMarketplaceArtDetail, setSecondaryMarketplaceArtDetail] =
    useState({});
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const latLong = useSelector((state) => state?.users?.latLong);
  const userId = useSelector((state) => state?.users?.profileData?.id);
  const [activityPage, setActivityPage] = useState(1);
  const [showing, setShowing] = useState(false);
  const [makeGlobalOfferShowing, setMakeGlobalOfferShowing] = useState(false);
  const [makeOfferDetails, setMakeOfferDetails] = useState({});
  const [
    secondaryMarketplaceArtDetailList,
    setSecondaryMarketplaceArtDetailList,
  ] = useState([]);
  const [showfeature, setShowfeature] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getSecondaryMarketplaceArtDetailFun();
  }, [id, activityPage, userId, latLong?.lat, latLong?.long]);

  useEffect(() => {
    const refreshApiInterval = setInterval(() => {
      getSecondaryMarketplaceArtDetailFun(false);
    }, 500000);
    return () => clearInterval(refreshApiInterval);
  }, []);

  const getSecondaryMarketplaceArtDetailFun = async (isLoading = true) => {
    let params;
    params = {
      id,
      page: activityPage,
      size: PER_PAGE_RECORD_LIMIT.ART_DETAIL_SECONDARY_PER_PAGE,
    };
    try {
      let response = await dispatch(
        getSecondaryMarketplaceArtDetail(params, isLoading)
      );
      setSecondaryMarketplaceArtDetail(response?.data);
      setSecondaryMarketplaceArtDetailList(response?.data?.list);
    } catch (err) {
      navigate(-1);
    }
  };

  const initialValue = {
    search: "",
    filterOption: "",
  };
  const validationSchema = Yup.object({
    search: Yup.string().required("Required"),
    filterOption: Yup.string().required("Please select blockchain"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const creatorOnclickHandler = (username) => {
    navigate(`/profile/${username}`);
  };

  const followUnfollowArtHandler = async () => {
    if (isUserLoggedin) {
      let data = {
        type: secondaryMarketplaceArtDetail.art_like == true ? 0 : 1,
        art_id: id,
      };
      try {
        const response = await dispatch(followUnfollowArt(data));
        getSecondaryMarketplaceArtDetailFun(false);
      } catch (error) {}
    } else {
      dispatch(saveNextRoute(`marketplace/art/${id}`));
      navigate("/login");
    }
  };

  const makeGlobalOfferHandler = () => {
    if (isUserLoggedin) {
      //logic for make global offer and API call if user is logged in
      setMakeGlobalOfferShowing(true);
    } else {
      dispatch(saveNextRoute(`marketplace/art/${id}`));
      navigate("/login");
    }
  };

  const makeOfferHandler = (item) => {
    let data = {
      art_id: secondaryMarketplaceArtDetail.id,
      user_id: item.user_id,
      user_owned_no_of_editions: item.edition_no,
      art_image: secondaryMarketplaceArtDetail.art_image,
      art_name: secondaryMarketplaceArtDetail.art_name,
      drop_type: secondaryMarketplaceArtDetail.drop_type,
      total_no_of_editions: secondaryMarketplaceArtDetail.no_of_editions,
      selling_price: item.selling_price,
      highest_offer_price: item.highest_price,
      username: secondaryMarketplaceArtDetail.username,
      last_buy_price: item.last_buy_price,
      last_sell_price: secondaryMarketplaceArtDetail.price, //last sale price
      sold_no_of_editions: secondaryMarketplaceArtDetail.sold_no_of_editions,
      thumbnail_image: secondaryMarketplaceArtDetail.thumbnail_image,
    };
    setMakeOfferDetails(data);
    if (isUserLoggedin) {
      setShowing(true);
      //logic for make offer and API call if user is logged in
    } else {
      dispatch(saveNextRoute(`marketplace/art/${id}`));
      navigate("/login");
    }
  };

  const buyHandler = (item) => {
    if (isUserLoggedin) {
      let data = {
        price: item.selling_price / DECIMAL,
        market: "secondary",
        quantity: 1,
        editionNumber: item.edition_no,
      };
      dispatch(saveLatestPrice(data));
      navigate(`/checkout/${id}`);
    } else {
      dispatch(saveNextRoute(`marketplace/art/${id}`));
      navigate("/login");
    }
  };

  const getImageSection = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          muted={false}
          className={`video_hover_sec_second ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <img
              src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
              alt="icon"
            />
          }
        />
      );
    } else {
      return (
        <div className="image_section_hover">
          <img
            className="thumnail_img"
            src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
            alt=""
          />
          <img
            className="featureimg"
            src={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
            alt=""
          />
        </div>
      );
    }
  };

  return (
    <>
      <section className={style.Droprankedauction_ended}>
        <Helmet>
          <title>
            {secondaryMarketplaceArtDetail?.title_se
              ? secondaryMarketplaceArtDetail?.title_se
              : ""}
          </title>
          <meta
            name="description"
            content={
              secondaryMarketplaceArtDetail?.desc_se
                ? secondaryMarketplaceArtDetail?.desc_se
                : ""
            }
          />
        </Helmet>
        <Container>
          <Row>
            <Col xl={5} lg={6}>
              <div className={style.Droprankedauction_ended_leftsec}>
                <div
                  className={style.Droprankedauction_ended_leftsec__imageart}
                  onClick={() => setShowfeature(true)}
                >
                  {getImageSection(secondaryMarketplaceArtDetail)}
                </div>
                <ul className={style.Droprankedauction_ended_leftsec__listing}>
                  {secondaryMarketplaceArtDetail?.tags
                    ? secondaryMarketplaceArtDetail?.tags.map((item) => (
                        <li>{item}</li>
                      ))
                    : " "}
                </ul>
              </div>
            </Col>
            <Col xl={7} lg={6}>
              <div className={style.Droprankedauction_ended_rightsec}>
                <div
                  className={style.Droprankedauction_ended_rightsec_headtext}
                >
                  <h2
                    className={style.titlehead}
                    title={secondaryMarketplaceArtDetail.art_name}
                  >
                    {sliceChar(secondaryMarketplaceArtDetail.art_name, 25)}
                  </h2>
                </div>
                <div
                  className={style.Droprankedauction_ended_rightsec_creatorInfo}
                >
                  <div className={style.creator_details}>
                    <div className={style.creator_details_userImage}>
                      <img
                        className="cursor-pointer"
                        src={
                          secondaryMarketplaceArtDetail.artist_image
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${secondaryMarketplaceArtDetail.artist_image}`
                            : personIcon
                        }
                        onClick={() =>
                          creatorOnclickHandler(
                            secondaryMarketplaceArtDetail.username
                          )
                        }
                        alt="icon"
                      />
                    </div>
                    <div className={style.creator_details_texts}>
                      <label>Creator</label>
                      <h6
                        className="cursor-pointer"
                        onClick={() =>
                          creatorOnclickHandler(
                            secondaryMarketplaceArtDetail.username
                          )
                        }
                      >{`@${secondaryMarketplaceArtDetail.username}`}</h6>
                    </div>
                  </div>
                  <div className={style.series_details}>
                    <div className={style.series_details_texts}>
                      <label>Series</label>
                      <h6>{secondaryMarketplaceArtDetail.series_name}</h6>
                    </div>
                  </div>
                  <div className={style.shareIcon}>
                    <ul className={style.shareIcon_list}>
                      <li>
                        <button
                          onClick={followUnfollowArtHandler}
                          className={style.btnicons}
                        >
                          {secondaryMarketplaceArtDetail.art_like ? (
                            <HeartIconRed />
                          ) : (
                            <HeartIcon />
                          )}
                        </button>
                      </li>
                      <li>
                        <button className={style.btnicons}>
                          <ShareBtn />
                        </button>
                      </li>
                      {/* <li>
                        <button>
                          <ShareIcon />
                        </button>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div
                  className={style.Droprankedauction_ended_rightsec_tabpartlist}
                >
                  <Tabs
                    defaultActiveKey="aboutart"
                    id=""
                    className="tablists tabCursor"
                  >
                    <Tab eventKey="aboutart" title="About Art">
                      <div className={style.artstab_containt}>
                        <div
                          className="textSection"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              secondaryMarketplaceArtDetail?.bio
                            ),
                          }}
                        ></div>
                        <ul className={style.about_artlist}>
                          {/* {artaboutlist.map((data) => ( */}
                          <li>
                            <label>Editions</label>
                            <span>:</span>
                            <h6>
                              {secondaryMarketplaceArtDetail?.drop_type == 4
                                ? secondaryMarketplaceArtDetail?.sold_no_of_editions
                                : secondaryMarketplaceArtDetail.no_of_editions}
                            </h6>
                          </li>
                          <li>
                            <label>Type</label>
                            <span>:</span>
                            <h6>
                              {secondaryMarketplaceArtDetail?.is_imported
                                ? "Imported NFT"
                                : digitalType[
                                    secondaryMarketplaceArtDetail.drop_type
                                  ]}
                            </h6>
                          </li>
                          <li>
                            <label>Category</label>
                            <span>:</span>
                            <h6>{secondaryMarketplaceArtDetail.art_type}</h6>
                          </li>
                          <li>
                            <label>Released</label>
                            <span>:</span>
                            <h6>
                              {secondaryMarketplaceArtDetail.start_time &&
                                getDateFromTimestamp(
                                  secondaryMarketplaceArtDetail.start_time
                                )}
                            </h6>
                          </li>
                          <li>
                            <label>Price</label>
                            <span>:</span>
                            <h6>
                              ₹
                              {secondaryMarketplaceArtDetail.floor_price &&
                                getNumberWithComma(
                                  secondaryMarketplaceArtDetail.floor_price /
                                    DECIMAL
                                )}
                            </h6>
                          </li>
                          {/* ))} */}
                        </ul>
                        {!secondaryMarketplaceArtDetail.is_global_offer_exist &&
                          secondaryMarketplaceArtDetailList.length > 0 && (
                            <CommonBtn
                              className={` ${style.makeoffer}`}
                              role="btn"
                              title={
                                <>
                                  Make Global Offer
                                  <CommonTooltip icon={<InfoIcom />}>
                                    Propose a price that you’re interested in,
                                    irrespective of the edition, opening the
                                    door to negotiation and potential
                                    acquisition.
                                  </CommonTooltip>
                                </>
                              }
                              type="button"
                              onClick={makeGlobalOfferHandler}
                            />
                          )}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                <div
                  className={
                    style.Droprankedauction_ended_rightsec_activitydetails
                  }
                >
                  <Tabs defaultActiveKey="activity" id="" className="tablists">
                    <Tab eventKey="activity" title="Activity">
                      <div className={style.data_activity}>
                        <CommonTable className={style.activity_table}>
                          <thead>
                            <tr>
                              <th>
                                <span>Edition</span>
                              </th>
                              <th>
                                <span>Collector</span>
                              </th>
                              <th>
                                <span>Sell Price</span>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {secondaryMarketplaceArtDetailList.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>
                                    {item.edition_no == 0
                                      ? "Updating..."
                                      : `#${item.edition_no}`}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center thumb-outer">
                                      <img
                                        className="thumb"
                                        src={
                                          item.image_path
                                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item.image_path}`
                                            : personIcon
                                        }
                                        alt="thumb"
                                      />
                                      <h4
                                        className="cursor-pointer"
                                        onClick={() =>
                                          creatorOnclickHandler(item.username)
                                        }
                                      >{`@${item.username}`}</h4>
                                    </div>
                                  </td>
                                  <td>
                                    {item.selling_price === 0
                                      ? "---"
                                      : "₹ " +
                                        getNumberWithComma(
                                          item.selling_price / DECIMAL
                                        )}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center justify-content-end">
                                      {item.selling_price != 0 &&
                                        item.user_id !== userId && (
                                          <button
                                            className={style.notifiedBtn}
                                            onClick={() => buyHandler(item)}
                                          >
                                            Buy now
                                          </button>
                                        )}
                                      {item.is_offer_exist == false &&
                                        item.user_id !== userId && (
                                          <button
                                            className={style.borderBtn}
                                            onClick={() =>
                                              makeOfferHandler(item)
                                            }
                                          >
                                            Make Offer
                                          </button>
                                        )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                            {secondaryMarketplaceArtDetailList.length === 0 && (
                              <tr>
                                <td colSpan={4}>
                                  <Notfound text={"No activity found."} />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </CommonTable>
                        {secondaryMarketplaceArtDetail.totalPages > 1 && (
                          <CustomPagination
                            className="ms-auto"
                            activePage={activityPage}
                            totalPage={secondaryMarketplaceArtDetail.totalPages}
                            setActivePage={setActivityPage}
                            maxVisiblePages={5}
                          />
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* make offer */}
      {showing && (
        <MakeOffermodal
          show={showing}
          setShowing={setShowing}
          makeOfferDetails={makeOfferDetails}
          onHide={() => setShowing(false)}
          getSamePageDetails={getSecondaryMarketplaceArtDetailFun}
          offerPage={"secondaryPage"}
          id={id}
        />
      )}
      {makeGlobalOfferShowing && (
        <MakeGlobalOfferModal
          show={makeGlobalOfferShowing}
          setShowing={setMakeGlobalOfferShowing}
          makeOfferDetails={secondaryMarketplaceArtDetail}
          onHide={() => setMakeGlobalOfferShowing(false)}
          getSamePageDetails={getSecondaryMarketplaceArtDetailFun}
          id={id}
        />
      )}
      {showfeature &&
        Object.keys(secondaryMarketplaceArtDetail)?.length > 0 && (
          <Feature_Modal
            show={showfeature}
            artDetail={secondaryMarketplaceArtDetail}
            handleClose={() => setShowfeature(false)}
          ></Feature_Modal>
        )}
    </>
  );
};

export default ArtDetailSecondaryMarketplace;
