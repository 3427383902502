import React from "react";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../Input/Input.scss";
import styles from "../../../../Public/SignUp/SignUp.module.scss";

const phone = (props) => {
  const {
    className,
    label,
    name,
    variant,
    leftIcon,
    labelIconRight,
    labelIcon,
    rightIcon,
    leftIconClick,
    labelIconClick,
    placeholder,
    onChange,
    value,
    formik,
    autoFocus,
    classinput,
    validationtitle,
    disabled,
  } = props;

  return (
    <Form.Group
      className={`common_input ${variant} ${className}`}
      controlId={name}
    >
      {label && (
        <Form.Label>
          {label}
          {labelIcon && (
            <span
              onClick={labelIconClick}
              className={`labelIcon ${labelIconClick ? "cursor-pointer" : ""} ${
                labelIconRight ? "ms-auto" : ""
              }`}
            >
              {labelIcon}
            </span>
          )}
        </Form.Label>
      )}
      <div
        className={`common_input_inner ${leftIcon ? "leftIconInput" : ""} ${
          rightIcon ? "rightIconInput" : ""
        }`}
      >
        {leftIcon && (
          <span
            className={`${leftIconClick ? "cursor-pointer" : ""} leftIcon`}
            onClick={leftIconClick}
          >
            {leftIcon}
          </span>
        )}
        {/* <Field className={`form-control ${className} `} name={name} {...rest} /> */}

        <PhoneInput
          defaultCountry="IN"
          control="input"
          countryCallingCodeEditable={false}
          type="text"
          variant={styles.spaceField}
          className={`${classinput} ${styles.fieldborder}`}
          placeholder={placeholder}
          name={name}
          maxLength="20"
          international
          formik={formik}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
          onChange={onChange}
          value={value}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      </div>
      {formik?.touched[name] && (
        <p className="error_message">{formik?.errors[name]}</p>
      )}
      {validationtitle ? (
        <h6 className="text_valida">{validationtitle}</h6>
      ) : (
        ""
      )}
    </Form.Group>
  );
};

export default phone;
