import React from "react";
import { useNavigate } from "react-router-dom";
import CommonBtn from "../CommonBtn/CommonBtn";
import style from "./CommonCardsecond.module.scss";
import { getNumberWithComma, sliceChar } from "../../../../Helper/common";
import { DECIMAL } from "../../../../Constant/userConstant";

const CommonCardOther = ({
  className,
  classtextfields,
  onBuyNow,
  showMakeOffer,
  item,
  isUserLoggedin,
}) => {
  const navigate = useNavigate();

  const goToUserProfile = (e, item) => {
    e.stopPropagation();
    navigate(`/profile/${item?.username}`);
  };

  const showOfferModal = () => {
    if (!isUserLoggedin) {
      return true;
    } else {
      return item?.is_offer_accepting === 1 && item.is_offer_exist === false;
    }
  };

  return (
    <>
      <div
        className={`${style.commonCard_second} ${style.commonCard_other} ${
          className || ""
        }`}
      >
        <img
          src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
          alt="imagArt"
          // onClick={(e) => goToUserProfile(e, item)}
          onClick={() => navigate(`/digital-fine-arts/${item.art_id}`)}
        />
        <div className={`${style.card_textfields} ${classtextfields}`}>
          <h6
            title={item?.art_name}
            onClick={() => navigate(`/digital-fine-arts/${item.art_id}`)}
          >
            {sliceChar(item?.art_name, 30)}
          </h6>
          <p onClick={(e) => goToUserProfile(e, item)}>@{item?.username}</p>
          {/* <p onClick={(e) => goToUserProfile(e, item)} className="wight_bold">
            Edition: #{item?.user_owned_no_of_editions}
          </p> */}
          <ul className={style.views_list}>
            <li>
              <label>Views</label>
              <p>{item?.view_count}</p>
            </li>
            <li>
              <label>Likes</label>
              <p>{item.like_count}</p>
            </li>
          </ul>
          <div className={style.pricesslist}>
            {item.selling_price > 0 && (
              <h6>
                Price:{" "}
                <span>
                  ₹ {getNumberWithComma(item?.selling_price / DECIMAL)}
                </span>
              </h6>
            )}
            {item.highest_offer_price && (
              <h6>
                Highest Offer:{" "}
                <span>
                  ₹ {getNumberWithComma(item.highest_offer_price / DECIMAL)}
                </span>
              </h6>
            )}
          </div>
          <div className={`${style.btnsselandview}`}>
            {/* here is_offer_exist =true means user already had an offer on this art  but API is not checking on make offer that offer exist or not */}
            {showOfferModal() && (
              <CommonBtn
                role="btn"
                className={`bordred-black ${style.btneditsize}`}
                title="MAKE OFFER"
                onClick={() => showMakeOffer(item)}
              />
            )}

            {item.selling_price != 0 && (
              <CommonBtn
                role="btn"
                className={`black-btn ${style.btneditsize}`}
                title="BUY NOW"
                onClick={onBuyNow}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonCardOther;
