import React, { useEffect, useState } from "react";
import style from "./Artowned.module.scss";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import Connectwallet from "./components/Connectwallet";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DECIMAL } from "../../../../Constant/userConstant";
import Sellnowmodal from "../../../Pages/Newdesigns/Sellnowmodal/Sellnowmodal";
import AcceptOffermodal from "../../../Pages/Newdesigns/AcceptOffermodal/AcceptOffermodal";
import {
  acceptOfferOnOff,
  updateOfferStatus,
} from "../../../../Features/offerSlice/offerSlice";
import Checkbox from "../../../Common/Ui/Formik/Checkbox";
import { selectDevice } from "../../../../Features/linkedDevice/linkedDeviceSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ExportWalletModal } from "../../../Pages/NewModals/ExportWalletModal";
import { setPageUserProfile } from "../../../../Features/user/userSlice";

const Artowned = (props) => {
  let {
    data,
    page,
    totalPage,
    totalItems,
    getUserLikes,
    options,
    setLimit,
    limit,
  } = props;

  const dispatch = useDispatch();
  const customStyles = {
    option: (provided, state) => {
      // Log information specific to the option style
      return {
        ...provided,
        backgroundColor:
          state.data.is_art_played == 1 ? "#edc45d" : "#00000099", // Use the color property from the option
        color: state.data.is_art_played == 1 ? "black" : "#ffffff66",
      };
    },
  };

  const profileData = useSelector((state) => state?.users?.profileData);
  const navigate = useNavigate();
  const [showsell, setShowsell] = useState(false);
  const [showacceptoffer, setShowacceptoffer] = useState(false);
  const [sellItem, setSellItem] = useState({});
  const [saveActionDetails, setSaveActionDetails] = useState({});

  const [showExport, setShowExport] = useState(false);

  const [selectedArtDetail, setSelectedArtDetail] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);

  const initialValue = {};
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => { },
  });

  const showSellpopUp = (item) => {
    setSellItem(item);
    setShowsell(true);
  };

  const goToUserProfile = (e, item) => {
    e.stopPropagation();
    navigate(`/profile/${item?.username}`);
  };

  const getUpdateOfferStatus = async (item) => {
    let updateStatusData = {
      status: item.status,
      id: item.id,
    };

    try {
      let response = await dispatch(updateOfferStatus(updateStatusData));
      if (response) {
        setShowacceptoffer(false);
        props.getUserLikes();
      }
    } catch (err) { }
  };

  const acceptHighestOfferOpenModal = (item, index) => {
    let data = {
      index: 0,
      item: {
        art_base_price: item.art_base_price,
        gst_amount: item.gst_amount,
        service_charge_amount: item.service_charge_amount,
        gst_amount_on_service_charge: item.gst_amount_on_service_charge,
        offer_price: item.offer_price,
        total_art_price_inc_tax: item.art_price,
        id: item.highest_offer_id,
        edition_no: item.user_owned_no_of_editions,
      },
      art: {
        highest_offer_price: item.highest_offer_price,
        id: item.art_id,
        art_name: item.art_name,
        art_image: item.art_image,
        thumbnail_image: item.thumbnail_image,
        drop_type: item.drop_type,
        sold_no_of_editions: item.sold_no_of_editions,
        no_of_editions: item.total_no_of_editions,
        like_count: item.like_count,
        view_count: item.view_count,
        last_sell_price: item.last_sell_price,
        feature_file: item?.feature_file,
        is_portrait: item?.is_portrait,
        art_user: {
          username: item.username,
          artist_id: item.artist_id,
        },
        art_owner_editions: [
          {
            edition_no: item.user_owned_no_of_editions,
          },
        ],
        art_owners: [
          {
            purchase_price: item.last_buy_price,
            last_buy_price: item.last_buy_price,
            number_of_editions: item.total_no_of_editions,
          },
        ],
        offer_count: 1,
        art_offer: [
          {
            edition_no: item.user_owned_no_of_editions,
          },
        ],
      },
    };
    setSaveActionDetails(data);
    setShowacceptoffer(true);
  };

  const onChangeActiveDeactiveOffer = async (value, item) => {
    //0 means unselected 1 means selected
    let data = {
      art_id: item.art_id,
      is_offer_accepting: value == false ? 0 : 1,
      edition_no: item.user_owned_no_of_editions,
    };
    try {
      let response = await dispatch(acceptOfferOnOff(data));
      props.getUserLikes();
    } catch (err) { }
  };

  const artNavigation = (item) => {
    navigate(`/digital-fine-arts/${item.art_id}`);
  };

  const handleChange = async (device, art_id) => {
    //0 means unselected 1 means selected
    try {
      let data = {
        device_id: device.value,
        is_play_on_device: device.is_art_played === 1 ? 0 : 1,
      };
      let params = {
        art_id: art_id,
      };
      const response = await dispatch(selectDevice(data, params));
      if (response) {
        getUserLikes();
      }
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  const exportHandler = (item) => {
    setSelectedArtDetail(item);
    setShowExport(true);
  };

  const getEditionNumber = (item) => {
    if (item.user_owned_no_of_editions == 0) {
      return `Updating...`;
    } else if (item?.total_no_of_editions === null) {
      return `#${item.user_owned_no_of_editions} of ${item?.sold_no_of_editions}`;
    } else {
      return `#${item.user_owned_no_of_editions} of ${item?.total_no_of_editions}`;
    }
  };

  return (
    <>
      <section className={style.art_owned}>
        {/* <div className={style.searchbar}>
          <form>
            <>
              <div className="search_input">
                <div className="search_input_inner">
                  <button type="submit" className="search_input_icon">
                    <SearchIcon />
                  </button>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Arts, Artist"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </>
            <CommonBtn
              role="btn"
              type={"button"}
              title="Import Digital Art"
              className={`bordred-black ${style.btnimport}`}
              onClick={async () => {
                open();
              }}
            />
          </form>
        </div> */}
        {/* <w3m-button /> */}

        <div className={style.table_section}>
          <CommonTable className={style.artowned_table}>
            <thead>
              <tr>
                <th>Digital Fine Art</th>
                <th>Edition No.</th>
                {/* <th className="username">Artist Name</th> */}
                <th>Views</th>
                <th>Likes</th>
                <th>Price</th>
                <th>Offers</th>
                <th>Highest Offer</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                const array = [];
                item.linked_devices?.map((ele, index) => {
                  array.push({
                    value: ele.device_id,
                    label: ele.device_name,
                    is_art_played: ele?.is_art_played,
                  });
                });
                //0 means unselected 1 means selected
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center thumb-outer">
                        <img
                          className="thumb cursor-pointer"
                          src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
                          alt="thumb"
                          onClick={() => artNavigation(item)}
                        />
                        <div className="textUser">
                          <h4
                            onClick={() => artNavigation(item)}
                            title={item.art_name}
                            className="cursor-pointer"
                          >
                            {sliceChar(item.art_name, 80)}
                          </h4>
                          <p
                            title={item.username}
                            className="cursor-pointer"
                            onClick={(e) => goToUserProfile(e, item)}
                          >
                            @{sliceChar(item?.username, 20)}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">{getEditionNumber(item)}</td>
                    {/* <td
                      className="username clickable"
                      title={item.username}
                      onClick={() => goToUserProfile(item)}
                    >
                      @
                      {item.username?.length > 20
                        ? item.username.slice(0, 20) + "...."
                        : item.username}
                    </td> */}
                    <td className="text-center">{item.view_count}</td>
                    <td className="text-center">{item.like_count}</td>
                    <td className="text-center">
                      ₹ {getNumberWithComma(item.last_buy_price / DECIMAL)}
                    </td>
                    <td className="text-center">{item.offer_count || "-"}</td>
                    <td className="text-center">
                      {item.highest_offer_price
                        ? `₹ ${getNumberWithComma(
                          item.highest_offer_price / DECIMAL
                        )}`
                        : "-"}
                    </td>
                    <td>
                      {item.user_owned_no_of_editions != 0 && (
                        <Checkbox
                          className={"black_label_input mb-0"}
                          label="Accepting Offer"
                          onChange={(e) => {
                            onChangeActiveDeactiveOffer(e.target.checked, item);
                          }}
                          value={item.is_offer_accepting}
                          checked={item.is_offer_accepting}
                        />
                      )}
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <>
                          <Select
                            options={array}
                            className="react-select selectDevice"
                            classNamePrefix="react-select"
                            onChange={(e) => handleChange(e, item?.art_id)}
                            styles={customStyles}
                            defaultValue={array.filter(
                              (item) => item.is_art_played === 1
                            )}
                            placeholder={
                              selectedOption
                                ? "Play on device"
                                : "Play on device"
                            }
                            value={array.filter(
                              (item) => item.is_art_played === 1
                            )}
                            // menuIsOpen
                          />
                        </>

                        {item.user_owned_no_of_editions != 0 && (
                          <>
                            {item.is_on_sell == 0 && (
                              <CommonBtn
                                role="btn"
                                title={"On Sale"}
                                className={`bordred-black ${style.notifiedBtn}`}
                                onClick={() => showSellpopUp(item)}
                              />
                            )}
                            <CommonBtn
                              role="btn"
                              title={"Accept Highest Offer"}
                              className={`bordred-black ${style.borderBtn}`}
                              onClick={() =>
                                acceptHighestOfferOpenModal(item, index)
                              }
                              disabled={!item.highest_offer_id}
                            />
                            <CommonBtn
                              role="btn"
                              title={"Export to External Wallet"}
                              className={`bordred-black ${style.borderBtn}`}
                              onClick={() => exportHandler(item)}
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {data.length == 0 && (
              <tr>
                <td colSpan={11}>
                  <p className="no_recordtable">No records found</p>
                </td>
              </tr>
            )}
          </CommonTable>
          <div className="filterPagination">
            {totalItems > 10 && (
              <div className="selectShow">
                <label>Show</label>
                <div className="selectShow_inner">
                  <Select
                    classNamePrefix="react-select"
                    className="react-select"
                    options={options}
                    isSearchable={false}
                    onChange={(option) => {
                      setLimit(option);
                      dispatch(setPageUserProfile(1));
                    }}
                    value={limit}
                  />
                </div>
              </div>
            )}
            {totalPage > 1 && (
              <CustomPagination
                // className="ms-auto"
                activePage={page}
                totalPage={totalPage}
                maxVisiblePages={5}
                dispatch={dispatch}
                componentType={"Profile"}
              />
            )}
          </div>
        </div>
        {showsell && (
          <Sellnowmodal
            sellItem={sellItem}
            show={showsell}
            onHide={() => setShowsell(false)}
            getUserLikes={getUserLikes}
          />
        )}
      </section>
      {/* {walletshow && (
        <Connectwallet
        show={walletshow}
        handleClose={() => setWalletshow(false)}
        />
      )} */}

      {showacceptoffer && (
        <AcceptOffermodal
          show={showacceptoffer}
          onHide={() => setShowacceptoffer(false)}
          makeOfferDetails={saveActionDetails}
          getUpdateOfferStatus={getUpdateOfferStatus}
          isHighestAccept={true}
        />
      )}
      {showExport && (
        <ExportWalletModal
          show={showExport}
          handleClose={() => setShowExport(false)}
          selectedArtDetail={selectedArtDetail}
        />
      )}
    </>
  );
};

export default Artowned;
