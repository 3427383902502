import React, { useState } from "react";
import { Container } from "react-bootstrap";
import SocialMediaIcon from "../../../Common/Ui/SocialMediaIcon/SocialMediaIcon";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./Carddetails.module.scss";
import { useSelector } from "react-redux";
import { formatDateWithTime } from "../../../../Helper/common";
import ShareBtn from "../../../Common/Ui/ShareBtn/ShareBtn";
import personIcon from "../../../../Assets/images/person_icon.jpg";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";
import DOMPurify from "dompurify";

const Carddetails = ({ classHeading, tab, Btnshare }) => {
  const userDetail = useSelector((state) => state.users.profileData);
  const [isExpanded, setIsExpanded] = useState(false);

  const sanitizedContent = (content) => {
    return { __html: DOMPurify.sanitize(content) };
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getBio = (text, maxLength) => {
    const truncatedText = text?.slice(0, maxLength);
    const displayText = isExpanded ? text : truncatedText;
    return displayText;
  };

  const bioContent = isExpanded
    ? userDetail?.bio
    : getBio(userDetail?.bio, 337);

  const getFullName = () => {
    {
      return `${userDetail?.first_name && userDetail?.first_name} ${userDetail?.middle_name && userDetail?.middle_name != "null"
        ? userDetail?.middle_name
        : ``
        } ${userDetail?.last_name && userDetail?.last_name}`;
    }
  };
  return (
    <>
      {Object.keys(userDetail).length > 0 && (
        <section className={style.card_details}>
          <Container>
            <div className={style.card_details_inner}>
              <div className={style.card_details_innerThumb}>
                <img
                  src={
                    userDetail.image_path
                      ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail.image_path}`
                      : personIcon
                  }
                  alt="img"
                />
              </div>
              <div className={`${style.card_details_inner_headings} ${classHeading}`}>
                <div className={style.texthead}>
                  <h2>{getFullName()}</h2>
                  <h6>@{userDetail?.username} </h6>
                  <p>Joined On : {formatDateWithTime(userDetail.joined_on)}</p>
                  {/* {!Btnshare ? (
                    <>
                      <h6>@{userDetail?.username} </h6>
                    </>
                  ) : (
                    <>
                      <div className={style.userprofileShare}>
                        <h4>@{userDetail?.username} </h4>
                      </div>
                    </>
                  )} */}
                </div>
                <div className={style.shareeditBtn}>
                  <div className={style.shareProfile}>
                    {(userDetail?.social_links?.twitter ||
                      userDetail?.social_links?.facebook ||
                      userDetail?.social_links?.instagram ||
                      userDetail?.social_links?.linkedin) && (
                        <SocialMediaIcon
                          Asuserprofile
                          className={style.socialist}
                          userDetail={userDetail}
                        />
                      )}

                    <ShareBtn className={style.userShareIcon} />
                  </div>
                  {tab == "profile" && (
                    <div className={style.head_btns}>
                      <CommonBtn
                        role="link"
                        className={`bordred-black ${style.btneditsize}`}
                        title="Edit Profile"
                        to={RoutesUrls.User_account_setting}
                      />

                      <CommonBtn
                        role="link"
                        className={`bordred-black ${style.btneditsize}`}
                        title="Dashboard"
                        to={RoutesUrls.User_dashboard}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={style.card_details_containt}>
              {userDetail?.bio.length > 0 && (
                <div className={style.biotext}>
                  {/* <h6>Bio :</h6> */}
                  <div className={style.textInfo}>
                    <div
                      className={`textSection ${isExpanded ? "showData" : "hideData"
                        }`}
                      dangerouslySetInnerHTML={sanitizedContent(bioContent)}
                    ></div>
                    {userDetail?.bio.length > 337 && (
                      <a className={style.readBtn} onClick={toggleExpand}>
                        {isExpanded ? "Read Less" : "Read More..."}
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Container>
        </section>
      )}
    </>
  );
};

export default Carddetails;
