// import { API_HOST } from "../constant";
import { fetch } from "../Helper/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;

const createMakeOffer = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_offer`,
    // `http://10.10.1.191:3004/nft/v1/nft/create_offer`,
    data,
    { token },
    {}
  );
};

const makeOfferList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/make_offer_list`,
    {},
    { token },
    params
  );
};

const globalOffer = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_global_offer`,
    data,
    { token },
    {}
  );
};

const getOfferRecievedList = (params, token) => {
  // recieved_offer_new_list by anurag
  // recieved_offer_list by harpreet
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/recieved_offer_new_list`,
    {},
    { token },
    params
  );
};

const getOfferRecievedListByEdition = (params, token) => {
  // get_offer_by_edition_new  by anurag
  // get_offer_by_edition by harpreet
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_offer_by_edition_new`,
    {},
    { token },
    params
  );
};
const updateOfferStatus = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/update_offer_status`,
    data,
    { token },
    {}
  );
};

const createCounterOffer = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_counter_offer`,
    data,
    { token },
    {}
  );
};
const acceptOfferOnOff = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/update_accepting_offer_status`,
    data,
    { token },
    {}
  );
};
const closeGlobalOffer = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/close_global_offer`,
    data,
    { token },
    {}
  );
};

export const offerService = {
  createMakeOffer,
  makeOfferList,
  getOfferRecievedList,
  updateOfferStatus,
  createCounterOffer,
  globalOffer,
  acceptOfferOnOff,
  getOfferRecievedListByEdition,
  closeGlobalOffer,
};
