import React from 'react';
import collectionbg from '../../../../../Assets/images/collection-bg.jpg';
import CSUDLandscape from '../../../../../Assets/videos/CSUDLandscape.mp4';
import CommonModal from '../../../../Common/Ui/CommonModal/CommonModal';
import './ClickExpendModal.scss';

const ClickExpendModal = ({ show, handleClose, imageActive, }) => {
    return (
        <>
            <CommonModal centered className="expendmodal"
                show={show} onHide={handleClose}
            >
                <div className='modalInner'>
                    {imageActive ? <div className='videoData'>
                        <video
                            // ref={videoRef}
                            autoPlay={true}
                            muted
                            loop
                            // onClick={onClick}
                            controls={true}
                            width="100%"
                            height="auto"
                        >
                            <source
                                src={CSUDLandscape}
                            />
                        </video>
                    </div>
                        :
                        <div className='imgdata'>
                            <img src={collectionbg} alt='artImg' />
                        </div>
                    }
                </div>
            </CommonModal>
        </>
    )
}

export default ClickExpendModal