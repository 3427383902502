import React, { useState } from "react";
import CommonCardsTab from "../../Ui/CommonTabs/CommonCardsTab";
import styles from "./NotificationBox.module.scss";
import personIcon from "../../../../Assets/images/person_icon.jpg";

import { useNavigate } from "react-router-dom";
import {
  NOTIFICATION_CATEGORY,
  tabName,
} from "../../../../Constant/userConstant";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
} from "../../../../Helper/common";
import { useDispatch } from "react-redux";
import {
  clearAllNotificationBox,
  markAsRead,
  setActiveTab,
  setDashboardActiveSubTab,
  setDashboardActiveTab,
} from "../../../../Features/user/userSlice";
import Notfound from "../../NotFound/Notfound";
import CouponIcon from "../../../../Assets/icons/couponIcon.svg";
import DOMPurify from "dompurify";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";
import PropTypes from "prop-types";

const NotificationBox = ({
  notificationList,
  getNotificationHandler,
  setIsOpen,
  setImportData,
  setShowQrAddress,
  setNotificationList,
  setUnreadCount,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTab, setShowTab] = useState("Notification");

  // Function to sanitize HTML content
  const sanitizedContent = (item) => {
    return { __html: DOMPurify.sanitize(item.content) };
  };

  const tabs = [
    {
      id: 1,
      tab: "Notifications",
      eventKey: "Notification",
    },
  ];

  const notificationNavigation = (type) => {
    switch (Number(type?.notificationCategory)) {
      case NOTIFICATION_CATEGORY.PROFILE:
        userNavigation(type);
        break;
      case NOTIFICATION_CATEGORY.NEW_ARTIST:
        userNavigation(type);
        break;
      case NOTIFICATION_CATEGORY.BIO:
        dispatch(setActiveTab("editprofile"));
        navigate(RoutesUrls.User_account_setting);
        break;
      case NOTIFICATION_CATEGORY.DFA_LANDING:
        artNavigation(type);
        break;
      case NOTIFICATION_CATEGORY.DROP:
        dropNavigation(type);
        break;
      case NOTIFICATION_CATEGORY.KYC:
        dispatch(setActiveTab("kyc"));
        navigate(RoutesUrls.User_account_setting);
        break;
      case NOTIFICATION_CATEGORY.OFFERS_MADE:
        dispatch(setDashboardActiveTab("offers"));
        dispatch(setDashboardActiveSubTab(tabName.OFFER_MADE));
        navigate(RoutesUrls.User_dashboard);
        break;
      case NOTIFICATION_CATEGORY.OFFERS_RECEIVED:
        dispatch(setDashboardActiveTab("offers"));
        dispatch(setDashboardActiveSubTab(tabName.OFFER_RECIEVED));
        navigate(RoutesUrls.User_dashboard);
        break;
      case NOTIFICATION_CATEGORY.COUPONS:
        dispatch(setDashboardActiveTab("coupons"));
        navigate(RoutesUrls.User_dashboard);
        break;
      case NOTIFICATION_CATEGORY.IMPORT:
        //Open qr modal
        if (type.notificationType === "import request approved") {
          setImportData(type);
          setShowQrAddress(true);
        } else {
          navigate(RoutesUrls.Notification);
        }
        break;
    }
  };

  const notificationImage = (type) => {
    switch (Number(type?.notificationCategory)) {
      case NOTIFICATION_CATEGORY.PROFILE:
        if (type?.userImage !== "") {
          return `${process.env.REACT_APP_IMG_BASE_URL}${type?.userImage}`;
        } else {
          return personIcon;
        }
      case NOTIFICATION_CATEGORY.BIO:
        if (type?.userImage !== "") {
          return `${process.env.REACT_APP_IMG_BASE_URL}${type?.userImage}`;
        } else {
          return personIcon;
        }
      case NOTIFICATION_CATEGORY.DFA_LANDING:
        return `${process.env.REACT_APP_IMG_BASE_URL}${type?.artImage}`;
      case NOTIFICATION_CATEGORY.DROP:
        return `${process.env.REACT_APP_IMG_BASE_URL}${type?.artImage}`;
      case NOTIFICATION_CATEGORY.KYC:
        if (type?.userImage !== "") {
          return `${process.env.REACT_APP_IMG_BASE_URL}${type?.userImage}`;
        } else {
          return personIcon;
        }
      case NOTIFICATION_CATEGORY.OFFERS_MADE:
        return `${process.env.REACT_APP_IMG_BASE_URL}${type?.artImage}`;
      case NOTIFICATION_CATEGORY.OFFERS_RECEIVED:
        return `${process.env.REACT_APP_IMG_BASE_URL}${type?.artImage}`;
      case NOTIFICATION_CATEGORY.COUPONS:
        return CouponIcon;
      case NOTIFICATION_CATEGORY.NEW_ARTIST:
        if (type?.userImage !== "") {
          return `${process.env.REACT_APP_IMG_BASE_URL}${type?.userImage}`;
        } else {
          return personIcon;
        }
      case NOTIFICATION_CATEGORY.IMPORT:
        return personIcon;
      default:
        return personIcon;
    }
  };

  const userNavigation = (item) => {
    if (item.userName) {
      navigate(`/profile/${item.userName}`);
    }
  };

  const artNavigation = async (item) => {
    navigate(`/digital-fine-arts/${item.artId}`);
  };

  const dropNavigation = (item) => {
    navigate(`/drop/${item.dropId}/all`);
  };

  const markAsReadHandler = async (item) => {
    try {
      let data = {
        notificationId: item.id,
      };
      if (item?.status === "UNREAD") {
        const response = await dispatch(markAsRead(data));
        if (response.status === 200) {
          getNotificationHandler();
        }
      }
    } catch (err) {}
  };

  const clearAllhandler = async () => {
    try {
      setNotificationList([]);
      setUnreadCount(0);
      setTimeout(() => {
        setIsOpen(false);
      }, 5000);
      const response = await dispatch(clearAllNotificationBox());
    } catch (error) {}
  };

  return (
    <div>
      <CommonCardsTab
        ContentclassName={styles.notification_tabsContent}
        className={styles.notification_tabs}
        activeTab={"Notification"}
        setActiveTab={setShowTab}
        tabs={tabs}
      >
        <div className={styles.btnNotfi}>
          {notificationList.length > 0 && (
            <button
              className={`${styles.clearBtn}`}
              onClick={() => {
                clearAllhandler();
              }}
            >
              Clear All
            </button>
          )}
          <button
            className={`${styles.clearBtn}`}
            onClick={() => {
              setIsOpen(false);
              navigate(RoutesUrls.Notification);
            }}
          >
            View All
          </button>
        </div>
        <div className={`notificationList ${styles.CollectionDetailsContent}`}>
          {showTab === "Notification" &&
            notificationList.map((item, index) => (
              <div
                key={index}
                className={styles.notifyBox}
                role="button"
                tabIndex="0"
                onClick={() => {
                  markAsReadHandler(item);
                  notificationNavigation(item);
                  setIsOpen(false);
                }}
              >
                <img
                  className={styles.thumbIcon}
                  src={notificationImage(item)}
                  alt=""
                />
                <div className={styles.notifyBoxInner}>
                  {/* Sanitize HTML content */}
                  <h4 dangerouslySetInnerHTML={sanitizedContent(item)}></h4>
                  <p>{item.time}</p>
                  <span
                    className={`${styles.readDot} ${
                      item.status === "UNREAD" ? styles.active : ""
                    }`}
                  ></span>
                </div>
              </div>
            ))}
          {notificationList.length === 0 && (
            <Notfound text={"No Older Notifications."} />
          )}
        </div>
      </CommonCardsTab>
    </div>
  );
};

NotificationBox.propTypes = {
  notificationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      notificationCategory: PropTypes.number.isRequired,
      userImage: PropTypes.string,
      artImage: PropTypes.string,
      content: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      username: PropTypes.string,
      dropId: PropTypes.number,
      artId: PropTypes.number,
      drop_type: PropTypes.number,
      start_time: PropTypes.string,
      end_time: PropTypes.string,
    })
  ).isRequired,
  getNotificationHandler: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setImportData: PropTypes.func.isRequired,
  setShowQrAddress: PropTypes.func.isRequired,
};

export default NotificationBox;
