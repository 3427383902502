import React from "react";
import { Modal } from "react-bootstrap";
import "./CommonModal.scss";

const CommonModal = (props) => {
  const {
    children,
    show,
    onHide,
    modalTitle,
    subTitle,
    className,
    backdropclass,
    classSub,
    loader,
  } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className={`commonModal ${className}`}
        centered={props.centered}
        backdropClassName={backdropclass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subTitle && (
            <p className={`commonModal-subtitle ${classSub}`}>{subTitle}</p>
            )}
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommonModal;
