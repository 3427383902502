import { createSlice } from "@reduxjs/toolkit";
import { initialStatePersist } from "../InitialState";

const initialState = initialStatePersist;

export const persistSlice = createSlice({
  name: "peristReducer",
  initialState,
  reducers: {
    isUserLogin: (state, action) => {
      state.isUserLoggedin = action.payload;
    },
    saveIp: (state, action) => {
      state.ip = action.payload;
    },
    saveLocation: (state, action) => {
      state.location = action.payload;
    },

    saveAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    saveUserType: (state, action) => {
      state.userType = action.payload;
    },
    setLinkActiveTab: (state, action) => {
      state.linkActiveTab = action.payload;
    },
    saveUserId: (state, action) => {
      state.userId = action.payload;
    },
    setRedirectionStripSuccess: (state, action) => {
      state.redirectionStripSuccess = action.payload;
    },
  },
});

export const {
  saveIp,
  saveLocation,
  saveAccessToken,
  isUserLogin,
  saveUserType,
  setLinkActiveTab,
  saveUserId,
  setRedirectionStripSuccess,
} = persistSlice.actions;
export default persistSlice.reducer;
