import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loadingReducer from "../Features/loading/loadingSlice";
import userReducer from "../Features/user/userSlice";
import walletReducer from "../Features/wallet/walletSlice";
import peristReducer from "../Features/persist/persistSlice";
import marketplaceReducer from "../Features/marketplace/marketplaceSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import offerSliceReducer from "../Features/offerSlice/offerSlice";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import anonymousUserReducer from "../Features/Anonymous/anonymousUserSlice";
import {
  initialStateAnonomaus,
  initialStateLoadingSlice,
  initialStateMarketPlace,
  initialStateOffer,
  initialStatePersist,
  initialStateUser,
  initialStateWallet,
} from "../Features/InitialState";

const combinedReducer = combineReducers({
  loading: loadingReducer,
  users: userReducer,
  wallet: walletReducer,
  persist: peristReducer,
  marketplace: marketplaceReducer,
  offers: offerSliceReducer,
  anonymousUser: anonymousUserReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["persist"],
  // stateReconciler: autoMergeLevel1,
};
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const rootReducer = (state, action) => {
  if (action.type === "users/logoutUser") {
    // Define your initial state for each reducer
    state = {
      ...state,
      loading: initialStateLoadingSlice,
      users: initialStateUser,
      wallet: initialStateWallet,
      persist: initialStatePersist,
      marketplace: initialStateMarketPlace,
      offers: initialStateOffer,
      anonymousUser: initialStateAnonomaus,
    };
    // storage.removeItem("persist:root"); // Clear the root persist storage
    // window.location.reload();
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
