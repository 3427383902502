import React, { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import CommonBtn from "../../../../../../Common/Ui/CommonBtn/CommonBtn";
import Commondropdown from "../../../../../../Common/Ui/CommonDropdown/Commondropdown";
import CommonTable from "../../../../../../Common/Ui/CommonTable/CommonTable";
import CustomPagination from "../../../../../../Common/Ui/Pagination/CustomPagination";
import Select from "react-select";
import style from "../OpenEditionFCFBRafflesTable/FilterTable.module.scss";
import personIcon from "../../../../../../../Assets/images/person_icon.jpg";
import {
  formatDateActivityTable,
  sliceChar,
} from "../../../../../../../Helper/common";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notfound from "../../../../../../Common/NotFound/Notfound";

const RafflesLiveTable = ({
  dfaActivity,
  activityPage,
  setActivityPage,
  limit,
  setLimit,
  limitOption,
  navigateToUser,
}) => {
  const isDfaActivityLoading = useSelector(
    (state) => state?.loading?.dfaActivityLoading
  );
  const sortOption = [
    { href: "#", title: "Newest", value: "date_desc" },
    { href: "#", title: "Oldest", value: "date_asc" },
  ];

  return (
    <>
      <div className={style.filterTable}>
        <div className={style.tableSec}>
          <CommonTable className={style.bidder_table}>
            <thead>
              <tr>
                <th>Entries No.</th>
                <th>Owned By</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {isDfaActivityLoading ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              ) : (
                dfaActivity?.user_activity?.map((item, index) => (
                  <tr key={index}>
                    <td>{(activityPage - 1) * limit.value + (index + 1)}</td>
                    <td>
                      <div
                        className="ownedBy cursor-pointer"
                        onClick={() => navigateToUser(item?.ownedBy?.username)}
                        title={item?.ownedBy?.username}
                      >
                        <img
                          src={
                            item?.ownedBy?.image_path
                              ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.ownedBy?.image_path}`
                              : personIcon
                          }
                          alt="Bidder-Icon"
                        />
                        <h6>{`@${sliceChar(item?.ownedBy?.username, 15)}`}</h6>
                      </div>
                    </td>
                    <td>{formatDateActivityTable(item.createdAt)}</td>
                  </tr>
                ))
              )}
              {/* {isDfaActivityLoading && (
                <tr>
                  <td colSpan={3} className='text-center'>
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )} */}
              {dfaActivity?.user_activity?.length === 0 && (
                <tr>
                  <td colSpan={3}>
                    <Notfound text={"No activity found."} />
                  </td>
                </tr>
              )}
            </tbody>
          </CommonTable>
          <div className="filterPagination">
            {dfaActivity?.totalItems > 10 && (
              <div className="selectShow">
                <label>Show</label>
                <div className="selectShow_inner">
                  <Select
                    classNamePrefix="react-select"
                    className="react-select"
                    options={limitOption}
                    isSearchable={false}
                    onChange={(option) => {
                      setActivityPage(1);
                      setLimit(option);
                    }}
                    value={limit}
                  />
                </div>
              </div>
            )}
            {dfaActivity?.totalPages > 1 && (
              <CustomPagination
                className=""
                activePage={activityPage}
                totalPage={dfaActivity?.totalPages}
                setActivePage={setActivityPage}
                maxVisiblePages={5}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RafflesLiveTable;
