import React from 'react'
import CommonSlider from '../../Common/Ui/CommonSlider/CommonSlider'
import NiftyArtxArticles from '../NewArtxPage/NiftyArtxArticles/NiftyArtxArticles'
import styles from './NiftyApp.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import NewArtApp from "../../../Assets/images/NewArtApp.png";
import mobile1 from '../../../Assets/images/mobile_scrn.png';
import mobile2 from '../../../Assets/images/mobile_scrn_1.png';
import mobile3 from '../../../Assets/images/mobile_scrn_2.png';
import Androidbtn from '../../../Assets/images/Androidbtn.png';
import AppstoreBtn from '../../../Assets/images/AppstoreBtn.png';
import { Helmet } from 'react-helmet';

const NewartxApp = () => {
    return (
        <>
            <section className={styles.niftyAppBanner}>
            <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
                <div className={styles.niftyAppBannerInner}>
                    <div className={styles.newartAppTop}>
                        <h1 className='text-center'>Your Gateway to the Digital Art World:</h1>
                        <img src={NewArtApp} alt='logoBlack' />
                    </div>
                    <div className={`${styles.niftyAppBannerContent} text-center`}>
                        <p>Welcome to NewArtX, where the realms of technology and art converge to redefine the way we experience and showcase digital fine art.
                            The NewArtX app emerges as the portal to a curated universe of mesmerizing Digital Fine Art (DFAs), offering enthusiasts a seamless
                            journey into the immersive world of creativity. Whether you're an avid collector, a digital art aficionado, or a casual admirer, our
                            app, available on both Android and iOS platforms, invites you to explore, acquire, and indulge in the beauty of DFAs with unparalleled
                            ease. Unlock the door to your digital art gallery, where each stroke and pixel is a masterpiece waiting to be discovered. Welcome to
                            NewArtX – where innovation meets expression, and the canvas of the future unfolds in the palm of your hand.</p>
                        <div className={styles.btnAppins}>
                            <button className={`me-3 ${styles.btnApp}`}><img src={Androidbtn} alt="btnApp" /></button>
                            <button className={`ms-3 ${styles.btnApp}`}><img src={AppstoreBtn} alt="btnApp" /></button>
                        </div>
                    </div>
                    <div className={styles.niftyAppBannerImgs}>
                        <img className='d-lg-block d-none' src={mobile1} alt="" />
                        <img src={mobile2} alt="" />
                        <img className='d-lg-block d-none' src={mobile3} alt="" />
                    </div>
                </div>
            </section>
            <NiftyArtxArticles />
            <section className={styles.BenefitsSec}>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={4} >
                            <h2 className='big text-md-start text-center'>
                                How <br className='d-xxl-block d-none' />
                                To <br className='d-xxl-block d-none' />
                                Use
                            </h2>
                            <div className={`${styles.BenefitsSecContent} text-md-start text-center`}>
                                <p>Create and sell your digital creations as NFTs to our vibrant community of 100k+ collectors</p>
                                <p>Our rapidly growing community of thousands of digital creators use MakersPlace to protect and sell their digital creations to a global audience. MakersPlace uses NFT technology to empower visual artists, photographers, musicians, and all manner of creators to monetize their work and connect with a devoted community of collectors.</p>
                            </div>
                        </Col>
                        <Col className='mx-auto' md={8}>

                            <CommonSlider
                                dots={false}
                                autoplay={true}
                                slidesToShow={1}
                                arrows={true}
                                slidesToScroll={1}
                                infinite={true}
                                className={styles.imagesSlider}
                            >
                                <div className={`${styles.BenefitsSecImg}`}>
                                    <iframe className='d-block w-100' src="https://www.youtube.com/embed/X1HOCvfPmQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className={`${styles.BenefitsSecImg}`}>
                                    <iframe className='d-block w-100' src="https://www.youtube.com/embed/X1HOCvfPmQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className={`${styles.BenefitsSecImg}`}>
                                    <iframe className='d-block w-100' src="https://www.youtube.com/embed/X1HOCvfPmQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </CommonSlider>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default NewartxApp
