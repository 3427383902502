import { fetch } from "../Helper/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;

const addAnonymous = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/artist/add/anonymous`,
    data,
    { token },
    {}
  );
};

const editAnonymous = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/users/v1/user/artist/edit/anonymous`,
    data,
    { token },
    {}
  );
};

const getAnonymousData = (token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/artist/get/anonymous_user`,
    {},
    { token },
    {}
  );
};

const enableDisableAnonymousUser = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/users/v1/user/artist/anonymous_toggle`,
     data,
    { token },
    {}
  );
}

const getProfile = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/profile`,
    {},
    { token },
    params
  );
};

export const anonymousUserService = {
    addAnonymous,
    getAnonymousData,
    editAnonymous,
    enableDisableAnonymousUser,
    getProfile,
};
