import React from "react";
import CommonBtn from "../../Ui/CommonBtn/CommonBtn";
import CommonModal from "../../Ui/CommonModal/CommonModal";
import "./AvailableCouponModal.scss";
import Notfound from "../../NotFound/Notfound";
import { DECIMAL } from "../../../../Constant/userConstant";
import { getNumberWithComma } from "../../../../Helper/common";
import PropTypes from "prop-types";

const AvailableCouponModal = ({
  show,
  handleClose,
  couponList,
  formik,
  couponVerifyFunction,
}) => {
  return (
    <CommonModal
      centered
      className="couponModal"
      modalTitle="Available Coupons"
      show={show}
      onHide={handleClose}
    >
      <ul className="couponList">
        {couponList.map((items) => (
          <li key={items.id}>
            <div className="coupon">
              <div className="coupon_text">
                <h3>{items.coupon_code}</h3>
                <p></p>
                {items.coupon_type == "AIRDROP" ? (
                  <p>{`Discount upto ${100}%`}</p>
                ) : items.discount_type == "PERCENTAGE" ? (
                  <p>{`${getNumberWithComma(
                    items.discount_offer / DECIMAL
                  )}% discount upto ₹ ${getNumberWithComma(
                    items.max_discount_amount / DECIMAL
                  )}`}</p>
                ) : items.discount_type == "INR" ? (
                  <p>{`₹ ${getNumberWithComma(
                    items.discount_offer / DECIMAL
                  )} discount on minimum purchase off ₹ ${getNumberWithComma(
                    items.min_purchase_amount / DECIMAL
                  )}`}</p>
                ) : (
                  ""
                )}
              </div>
              <CommonBtn
                title="Apply"
                role="btn"
                className="couponApply"
                onClick={async (e) => {
                  e.stopPropagation();
                  formik.setFieldValue("couponCode", items?.coupon_code);
                  couponVerifyFunction(items?.coupon_code);
                  handleClose();
                }}
              />
            </div>
          </li>
        ))}
        {couponList.length == 0 && (
          <li>
            <Notfound
              className="notFound text-white"
              text={"No coupon found."}
            />{" "}
          </li>
        )}
      </ul>
    </CommonModal>
  );
};

AvailableCouponModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  couponList: PropTypes.arrayOf(
    PropTypes.shape({
      coupon_code: PropTypes.string.isRequired,
      coupon_type: PropTypes.string.isRequired,
      discount_type: PropTypes.string.isRequired,
      discount_offer: PropTypes.number.isRequired,
      max_discount_amount: PropTypes.number.isRequired,
      min_purchase_amount: PropTypes.number.isRequired,
    })
  ).isRequired,
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default AvailableCouponModal;
