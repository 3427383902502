import React, { useState } from "react";
import FormikControls from "../Ui/Formik/FormikControls";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  setMarketplaceFilter,
  setMarketplaceNft,
  setMarketplacePage,
} from "../../../Features/marketplace/marketplaceSlice";
import CommonBtn from "../Ui/CommonBtn/CommonBtn";

const SearchFilter = ({ data, searchType, artist, tag, series, onHide }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  // Sort the data array alphabetically based on the "username" property
  const sortedData = [...data].sort((a, b) => {
    if (searchType == "Artist") {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }

    if (searchType == "Tags") {
      return a.tag_name.toLowerCase().localeCompare(b.tag_name.toLowerCase());
    }
    if (searchType == "Series") {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }
  });

  // Group the data array by first letter of "first_name"
  const groupedData = sortedData.reduce((acc, item) => {
    let firstLetter = "";
    if (searchType == "Artist") {
      firstLetter = item.name.charAt(0).toUpperCase();
    }

    if (searchType == "Tags") {
      firstLetter = item.tag_name.charAt(0).toUpperCase();
    }

    if (searchType == "Series") {
      firstLetter = item.name.charAt(0).toUpperCase();
    }

    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getCheckedStatus = (id) => {
    if (searchType == "Artist") {
      if (artist.includes(id)) {
        return true;
      } else {
        return false;
      }
    }

    if (searchType == "Tags") {
      if (tag.includes(id)) {
        return true;
      } else {
        return false;
      }
    }
    if (searchType == "Series") {
      if (series.includes(id)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const renderList = () => {
    if (searchTerm.trim() !== "") {
      // Filter the data based on the search term
      const filteredData = Object.values(groupedData).reduce((acc, arr) => {
        const filteredItems = arr.filter((item) => {
          if (searchType == "Artist") {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
          }

          if (searchType == "Tags") {
            return item.tag_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          }

          if (searchType == "Series") {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
          }
        });
        if (filteredItems.length > 0) {
          acc.push(filteredItems);
        }
        return acc;
      }, []);

      if (filteredData.length === 0) {
        return <p>No results found.</p>;
      }

      return filteredData.map((group, index) => (
        <div key={index}>
          <>
            {searchType == "Artist" && (
              <li className="titleletter">
                {group[0].name.charAt(0).toUpperCase()}
              </li>
            )}

            {searchType == "Tags" && (
              <li className="titleletter">
                {group[0].tag_name.charAt(0).toUpperCase()}
              </li>
            )}

            {searchType == "Series" && (
              <li className="titleletter">
                {group[0].name.charAt(0).toUpperCase()}
              </li>
            )}
            {group.map((item, i) => (
              <li key={i}>
                <FormikControls
                  key={index}
                  className="fieldBlack"
                  control="checkbox"
                  label={
                    searchType == "Artist"
                      ? item.name
                      : searchType == "Tags"
                      ? item.tag_name
                      : searchType == "Series"
                      ? item.name
                      : ""
                  }
                  checked={getCheckedStatus(
                    searchType == "Tags" ? item.tag_name : item.id
                  )}
                  onChange={(e) => onCheckUncheckFilter(e, item)}
                />
              </li>
            ))}
          </>
        </div>
      ));
    }
    return Object.entries(groupedData).map(([letter, group], index) => (
      <>
        <li className="titleletter">{letter}</li>
        {group.map((item, i) => (
          <li key={i}>
            <FormikControls
              key={index}
              className="fieldBlack"
              control="checkbox"
              label={
                searchType == "Artist"
                  ? item.name
                  : searchType == "Tags"
                  ? item.tag_name
                  : searchType == "Series"
                  ? item.name
                  : ""
              }
              checked={getCheckedStatus(
                searchType == "Tags" ? item.tag_name : item.id
              )}
              onChange={(e) => onCheckUncheckFilter(e, item)}
            />
          </li>
        ))}
      </>
    ));
  };

  const onCheckUncheckFilter = (e, item) => {
    let isChecked = e.target.checked;

    if (searchType == "Artist") {
      let artistItem = [...artist];
      if (isChecked) {
        artistItem.push(item.id);
        dispatch(setMarketplaceFilter({ type: "artist", data: artistItem }));
      } else {
        const indexed = artistItem.indexOf(item.id);
        if (indexed !== -1) {
          artistItem.splice(indexed, 1);
        }
        dispatch(setMarketplaceFilter({ type: "artist", data: artistItem }));
      }
    }

    if (searchType == "Tags") {
      let tagItem = [...tag];
      if (isChecked) {
        tagItem.push(item.tag_name);
        dispatch(setMarketplaceFilter({ type: "tags", data: tagItem }));
      } else {
        const indexed = tagItem.indexOf(item.tag_name);
        if (indexed !== -1) {
          tagItem.splice(indexed, 1);
        }
        dispatch(setMarketplaceFilter({ type: "tags", data: tagItem }));
      }
    }

    if (searchType == "Series") {
      let seriesItem = [...series];

      if (isChecked) {
        seriesItem.push(item.id);
        dispatch(setMarketplaceFilter({ type: "series", data: seriesItem }));
      } else {
        const indexed = seriesItem.indexOf(item.id);
        if (indexed !== -1) {
          seriesItem.splice(indexed, 1);
        }
        dispatch(setMarketplaceFilter({ type: "series", data: seriesItem }));
      }
    }
  };

  return (
    <div className="searchfield">
      <input
        className="searchbar"
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
      />
      <CommonBtn
        role="btn"
        title="Apply"
        className={`btn-yellow btnSize`}
        onClick={() => {
          dispatch(setMarketplacePage(1));
          dispatch(setMarketplaceNft([]));
          onHide();
        }}
      />
      <ul className="text_list">{renderList()}</ul>
    </div>
  );
};

SearchFilter.propTypes = {
  data: PropTypes.array.isRequired,
  searchType: PropTypes.string.isRequired,
  artist: PropTypes.array.isRequired,
  tag: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
};

export default SearchFilter;
