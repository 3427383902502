import "./Loader.scss";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <div className='loader_style'>
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default Loader;
