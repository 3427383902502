import React from "react";
import AuctionAndRankedAuctionLiveTable from "./Component/AuctionAndRankedAuctionLiveTable/AuctionAndRankedAuctionLiveTable";
import OpenEditionFCFBRafflesTable from "./Component/OpenEditionFCFBRafflesTable/OpenEditionFCFBRafflesTable";
import AuctionAndRankedAuctionEndedTable from "./Component/AuctionAndRankedAuctionEndedTable/AuctionAndRankedAuctionEndedTable";
import style from "./DfaTable.module.scss";
import { DROP_TYPE, MARKET_TYPE } from "../../../../../Constant/userConstant";
import RafflesLiveTable from "./Component/RafflesLiveTable/RafflesLiveTable";
const DfaActivityTable = ({
  dfaDetail,
  dfaActivity,
  setDfaActivity,
  activityPage,
  setActivityPage,
  tab,
  limitOption,
  setTab,
  limit,
  setLimit,
  sortOption,
  selectedSortBy,
  setSelectedSortBy,
  filterOption,
  setSelectedFilter,
  selectedFilter,
  setShowOfferModal,
  setMakeOfferDetails,
  setShowGlobalOfferModal,
  navigateToUser,
}) => {
  return (
    <>
      <div className={style.dfaTable}>
        {(dfaDetail?.drop_type === DROP_TYPE?.AUCTION ||
          dfaDetail?.drop_type === DROP_TYPE?.RANKED_AUCTION) &&
          dfaDetail?.market_type === MARKET_TYPE.PRIMARY && (
            <AuctionAndRankedAuctionLiveTable
              dfaActivity={dfaActivity}
              dfaDetail={dfaDetail}
              setDfaActivity={setDfaActivity}
              navigateToUser={navigateToUser}
            />
          )}

        {(dfaDetail?.drop_type === DROP_TYPE?.AUCTION ||
          dfaDetail?.drop_type === DROP_TYPE?.RANKED_AUCTION) &&
          dfaDetail?.market_type === MARKET_TYPE.SECONDARY && (
            <AuctionAndRankedAuctionEndedTable
              dfaActivity={dfaActivity}
              dfaDetail={dfaDetail}
              setDfaActivity={setDfaActivity}
              activityPage={activityPage}
              setActivityPage={setActivityPage}
              selectedTab={tab}
              setTab={setTab}
              limit={limit}
              setLimit={setLimit}
              limitOption={limitOption}
              setShowOfferModal={setShowOfferModal}
              setMakeOfferDetails={setMakeOfferDetails}
              setShowGlobalOfferModal={setShowGlobalOfferModal}
              navigateToUser={navigateToUser}
            />
          )}

        {(dfaDetail?.drop_type === DROP_TYPE?.FIRST_COME_FIRST_BUY ||
          dfaDetail?.drop_type === DROP_TYPE?.OPEN_EDITIONS ||
          (dfaDetail?.drop_type === DROP_TYPE?.RAFFLES &&
            dfaDetail?.market_type === MARKET_TYPE?.SECONDARY)) && (
          <OpenEditionFCFBRafflesTable
            dfaActivity={dfaActivity}
            dfaDetail={dfaDetail}
            activityPage={activityPage}
            setActivityPage={setActivityPage}
            limit={limit}
            setLimit={setLimit}
            limitOption={limitOption}
            setShowOfferModal={setShowOfferModal}
            setMakeOfferDetails={setMakeOfferDetails}
            setShowGlobalOfferModal={setShowGlobalOfferModal}
            navigateToUser={navigateToUser}
            sortOption={sortOption}
            selectedSortBy={selectedSortBy}
            setSelectedSortBy={setSelectedSortBy}
            filterOption={filterOption}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        )}

        {dfaDetail?.drop_type === DROP_TYPE?.RAFFLES &&
          dfaDetail?.market_type === MARKET_TYPE?.PRIMARY && (
            <RafflesLiveTable
              dfaActivity={dfaActivity}
              activityPage={activityPage}
              setActivityPage={setActivityPage}
              limit={limit}
              setLimit={setLimit}
              limitOption={limitOption}
              navigateToUser={navigateToUser}
            />
          )}
      </div>
    </>
  );
};

export default DfaActivityTable;
