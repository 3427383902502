import React from "react";
import { Modal } from "react-bootstrap";
import "../../../Common/Ui/CommonModal/CommonModal.scss";

const AddFundsModal = (props) => {
  const {
    children,
    show,
    onHide,
    modalTitle,
    subTitle,
    className,
    backdropclass,
  } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className={`commonModal ${className}`}
        centered={props.centered}
        backdropClassName={backdropclass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subTitle && <p className="commonModal-subtitle">{subTitle}</p>}
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFundsModal;
