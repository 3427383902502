import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "../Input/Input.scss";
import { EyeClose, EyeOpen } from "../../../../../Assets/svgIcons/SVGicon";
import "./Password.scss";

const Password = (props) => {
  const {
    label,
    name,
    leftIcon,
    variant,
    rightIcon,
    className,
    id,
    onChange,
    value,
    formik,
    onBlur,
    placeholder,
    maxLength,
  } = props;
  const [active, setActive] = useState(false);
  return (
    <Form.Group
      className={`common_input password_input ${variant}`}
      controlId={name}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div
        className={`common_input_inner ${
          leftIcon ? "leftIconInput" : ""
        } rightIconInput`}
      >
        {leftIcon && <span className="leftIcon">{leftIcon}</span>}

        <input type="password" name={name} style={{ display: "none" }} />

        <input
          className={`form-control ${className}`}
          id={id}
          name={name}
          type={active ? "text" : "password"}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          maxLength={maxLength}
          autocomplete="off"
        />
        <span
          onClick={() => setActive(!active)}
          className={`${rightIcon} rightIcon cursor-pointer`}
        >
          {active ? <EyeOpen /> : <EyeClose />}
        </span>
      </div>
      {formik?.touched[name] && (
        <p className="error_message">{formik?.errors[name]}</p>
      )}
    </Form.Group>
  );
};

export default Password;
