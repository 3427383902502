import React, { useDebugValue, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import style from "./Connnecteddevice.module.scss";
import { useDispatch } from "react-redux";
import {
  renameConnectedDevice,
} from "../../../../Features/linkedDevice/linkedDeviceSlice";
import { inputFieldValidation } from "../../../../Constant/formikConstant";

const Renamedevicenamemodal = ({
  show,
  handleClose,
  dataSave,
  ConnectedDeviceList,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (show === false) {
      formik.setFieldValue("namedevice", "");
    }

    if (dataSave) {
      formik.setValues({
        namedevice: dataSave.device_name || "",
      });
    }
  }, [show, dataSave]);

  const initialValue = {
    namedevice: dataSave?.device_name,
  };
  const validationSchema = Yup.object({
    namedevice: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Name should not start or end with a space"
      )
      // .matches(
      //   /^[a-zA-Z0-9\s]*$/,
      //   "*Only alphanumeric characters and spaces are allowed"
      // )
      .min(
        inputFieldValidation.nameDevice.minLength,
        `*Must be between ${inputFieldValidation.nameDevice.minLength}-${inputFieldValidation.nameDevice.maxLength} characters`
      )
      .max(
        inputFieldValidation.nameDevice.maxLength,
        `*Must be between ${inputFieldValidation.nameDevice.minLength}-${inputFieldValidation.nameDevice.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Device Name")),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let data = {
        device_name: values.namedevice,
        device_id: dataSave.device_info,
      };
      try {
        const response = await dispatch(renameConnectedDevice(data));
        if (response?.status == 200) {
          ConnectedDeviceList();
          handleClose();
          formik.resetForm();
        }
      } catch (err) {}
    },
  });

  useEffect(() => {
    if (show == false) {
      formik.setFieldValue("namedevice", "");
    }
  }, [show]);

  return (
    <>
      <CommonModal
        centered
        className="rename_devicemodal"
        backdropclass="fade_clrbg"
        modalTitle="You are about to rename the device"
        show={show}
        onHide={handleClose}
      >
        <div className="main_content">
          <form onSubmit={formik.handleSubmit} className="device_enterform">
            <FormikControls
              variant="device_add"
              control="input"
              type="text"
              name="namedevice"
              id="namedevice"
              placeholder="Name Your Device"
              formik={formik}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik?.values?.namedevice}
              onBlur={formik.handleBlur}
              // maxLength={50}
            />
            <CommonBtn
              title="Submit"
              role="btn"
              type="submit"
              className="btn-yellow-black"
            />
          </form>
        </div>
      </CommonModal>
    </>
  );
};

export default Renamedevicenamemodal;
