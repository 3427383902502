import "./LoaderSuspense.scss";
import { Spinner } from "react-bootstrap";

const LoaderSuspense = () => {
  return (
    <div className='loader_style'>
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default LoaderSuspense;
