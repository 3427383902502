import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import style from "./Devicesadd.module.scss";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import { nameDevice } from "../../../../Features/linkedDevice/linkedDeviceSlice";
import { useDispatch } from "react-redux";

const Activatedevicemodal = ({ show, handleClose, setShowModal, deviceId,formik }) => {
  const dispatch = useDispatch();
  const initialValue = {
    namedevice: "",
  };
  const validationSchema = Yup.object({
    namedevice: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Name should not start or end with a space"
      )
      // .matches(
      //   /^[a-zA-Z0-9\s]*$/,
      //   "*Only alphanumeric characters and spaces are allowed"
      // )
      .min(
        inputFieldValidation.nameDevice.minLength,
        `*Must be between ${inputFieldValidation.nameDevice.minLength}-${inputFieldValidation.nameDevice.maxLength} characters`
      )
      .max(
        inputFieldValidation.nameDevice.maxLength,
        `*Must be between ${inputFieldValidation.nameDevice.minLength}-${inputFieldValidation.nameDevice.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Device Name")),
  });

  useEffect(() => {
    if (show === false) {
      formik.resetForm();
    }
  }, [show]);

  const activateDeviceFormik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      deviceNameHandler(values);
      
    },
  });

  const deviceNameHandler = async (values) => {
    try {
      let data = {
        device_name: values.namedevice,
        device_id: deviceId,
      };
      const response = await dispatch(nameDevice(data));
      setShowModal(false);
      formik.resetForm();
      activateDeviceFormik.resetForm();
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  return (
    <>
      <CommonModal
        centered
        className="activate_devicemodal"
        backdropclass="fade_clrbg"
        modalTitle="Your device is successfully connected!"
        show={show}
        onHide={handleClose}
      >
        <div className="main_content">
          <p>Please provide a name for your connected device.</p>
          <p>
            Naming your device will make it easier to identify and manage in the
            future.
          </p>
          <form onSubmit={activateDeviceFormik.handleSubmit} className="device_enterform">
            <FormikControls
              variant="device_add"
              control="input"
              type="input"
              name="namedevice"
              id="namedevice"
              placeholder="Name Your Device"
              formik={activateDeviceFormik}
              onChange={(e) => {
                // if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {
                  activateDeviceFormik.setFieldValue("namedevice", e.target.value);
                // }
              }}
              value={activateDeviceFormik.values.namedevice}
              onBlur={activateDeviceFormik.handleBlur}
              // maxLength={50}
            />
            <CommonBtn
              title="Submit"
              role="btn"
              type="sumbit"
              className="btn-yellow-black"
            />
          </form>
        </div>
      </CommonModal>
    </>
  );
};

export default Activatedevicemodal;
