import React from "react";
import CommonModal from "../../../../Common/Ui/CommonModal/CommonModal";
import "./SuccessfullyModal.scss";

const ComingSoon = ({ show, handleClose }) => {
  return (
    <>
      <CommonModal
        centered
        show={show}
        onHide={handleClose}
        className="successfullyModal"
        modalTitle="Coming Soon."
      >
        <div className="InnerSec">
          <h3></h3>
        </div>
      </CommonModal>
    </>
  );
};

export default ComingSoon;
