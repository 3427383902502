export const citiesInIndia = {
  "Andaman and Nicobar Islands": [{ value: "Port Blair", label: "Port Blair" }],
  Haryana: [
    { value: "Faridabad", label: "Faridabad" },
    { value: "Hisar", label: "Hisar" },
    { value: "Rohtak", label: "Rohtak" },
    { value: "Panipat", label: "Panipat" },
    { value: "Karnal", label: "Karnal" },
    { value: "Sonipat", label: "Sonipat" },
    { value: "Yamunanagar", label: "Yamunanagar" },
    { value: "Panchkula", label: "Panchkula" },
    { value: "Bhiwani", label: "Bhiwani" },
    { value: "Bahadurgarh", label: "Bahadurgarh" },
    { value: "Jind", label: "Jind" },
    { value: "Sirsa", label: "Sirsa" },
    { value: "Thanesar", label: "Thanesar" },
    { value: "Kaithal", label: "Kaithal" },
    { value: "Palwal", label: "Palwal" },
    { value: "Rewari", label: "Rewari" },
    { value: "Hansi", label: "Hansi" },
    { value: "Narnaul", label: "Narnaul" },
    { value: "Fatehabad", label: "Fatehabad" },
    { value: "Gohana", label: "Gohana" },
    { value: "Tohana", label: "Tohana" },
    { value: "Narwana", label: "Narwana" },
    { value: "Mandi Dabwali", label: "Mandi Dabwali" },
    { value: "Charkhi Dadri", label: "Charkhi Dadri" },
    { value: "Shahbad", label: "Shahbad" },
    { value: "Pehowa", label: "Pehowa" },
    { value: "Samalkha", label: "Samalkha" },
    { value: "Pinjore", label: "Pinjore" },
    { value: "Ladwa", label: "Ladwa" },
    { value: "Sohna", label: "Sohna" },
    { value: "Safidon", label: "Safidon" },
    { value: "Taraori", label: "Taraori" },
    { value: "Mahendragarh", label: "Mahendragarh" },
    { value: "Ratia", label: "Ratia" },
    { value: "Rania", label: "Rania" },
    { value: "Sarsod", label: "Sarsod" },
    { value: "Gurugram", label: "Gurugram" },
    { value: "Ambala", label: "Ambala" },
    { value: "Pundri", label: "Pundri" },
    { value: "Kosli", label: "Kosli" },
  ],

  Tamilnadu: [
    { value: "Chennai", label: "Chennai" },
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Madurai", label: "Madurai" },
    { value: "Tiruchirappalli", label: "Tiruchirappalli" },
    { value: "Salem", label: "Salem" },
    { value: "Tirunelveli", label: "Tirunelveli" },
    { value: "Vellore", label: "Vellore" },
    { value: "Thoothukudi", label: "Thoothukudi" },
    { value: "Nagercoil", label: "Nagercoil" },
    { value: "Thanjavur", label: "Thanjavur" },
    { value: "Dindigul", label: "Dindigul" },
    { value: "Cuddalore", label: "Cuddalore" },
    { value: "Kanchipuram", label: "Kanchipuram" },
    { value: "Karur", label: "Karur" },
    { value: "Erode", label: "Erode" },
    { value: "Namakkal", label: "Namakkal" },
    { value: "Ooty", label: "Ooty" },
    { value: "Kodaikanal", label: "Kodaikanal" },
    { value: "Ramanathapuram", label: "Ramanathapuram" },
    { value: "Hosur", label: "Hosur" },
    { value: "Neyveli", label: "Neyveli" },
    { value: "Tiruvannamalai", label: "Tiruvannamalai" },
    { value: "Pollachi", label: "Pollachi" },
    { value: "Avadi", label: "Avadi" },
    { value: "Kumbakonam", label: "Kumbakonam" },
    { value: "Chidambaram", label: "Chidambaram" },
    { value: "Nagapattinam", label: "Nagapattinam" },
    { value: "Vaniyambadi", label: "Vaniyambadi" },
    { value: "Sivakasi", label: "Sivakasi" },
    { value: "Ambur", label: "Ambur" },
    { value: "Pudukkottai", label: "Pudukkottai" },
    { value: "Karur", label: "Karur" },
    { value: "Krishnagiri", label: "Krishnagiri" },
    { value: "Arakkonam", label: "Arakkonam" },
    { value: "Rajapalayam", label: "Rajapalayam" },
    { value: "Dharmapuri", label: "Dharmapuri" },
    { value: "Tenkasi", label: "Tenkasi" },
    { value: "Karaikudi", label: "Karaikudi" },
    { value: "Neyveli", label: "Neyveli" },
    { value: "Sankarankovil", label: "Sankarankovil" },
    { value: "Mayiladuthurai", label: "Mayiladuthurai" },
    { value: "Vikramasingapuram", label: "Vikramasingapuram" },
    { value: "Udhagamandalam", label: "Udhagamandalam" },
    { value: "Arani", label: "Arani" },
    { value: "Sholingur", label: "Sholingur" },
    { value: "Sirkali", label: "Sirkali" },
    { value: "Tindivanam", label: "Tindivanam" },
    { value: "Ambasamudram", label: "Ambasamudram" },
    { value: "Tiruchengode", label: "Tiruchengode" },
    { value: "Perambalur", label: "Perambalur" },
    { value: "Pattukkottai", label: "Pattukkottai" },
    { value: "Srivilliputhur", label: "Srivilliputhur" },
    { value: "Gobichettipalayam", label: "Gobichettipalayam" },
    { value: "Jammu", label: "Jammu" },
    { value: "Sivaganga", label: "Sivaganga" },
    { value: "Tiruttani", label: "Tiruttani" },
    { value: "Nellikkuppam", label: "Nellikkuppam" },
    { value: "Bodinayakkanur", label: "Bodinayakkanur" },
    { value: "Vadalur", label: "Vadalur" },
    { value: "Tiruvethipuram", label: "Tiruvethipuram" },
    { value: "Udagamandalam", label: "Udagamandalam" },
    { value: "Gudiyatham", label: "Gudiyatham" },
    { value: "Aranthangi", label: "Aranthangi" },
    { value: "Pallipattu", label: "Pallipattu" },
    { value: "Ponneri", label: "Ponneri" },
    { value: "Lalgudi", label: "Lalgudi" },
    { value: "Ranipet", label: "Ranipet" },
    { value: "Pudhukottai", label: "Pudhukottai" },
    { value: "Thirumangalam", label: "Thirumangalam" },
    { value: "Namagiripettai", label: "Namagiripettai" },
    { value: "Thirupuvanam", label: "Thirupuvanam" },
    { value: "Sankarankoil", label: "Sankarankoil" },
    { value: "Puliyankudi", label: "Puliyankudi" },
    { value: "Sattur", label: "Sattur" },
    { value: "Kovilpatti", label: "Kovilpatti" },
    { value: "Ambattur", label: "Ambattur" },
    { value: "Tiruppur", label: "Tiruppur" },
    { value: "Tiruvottiyur", label: "Tiruvottiyur" },
    { value: "Thoothukkudi", label: "Thoothukkudi" },
    { value: "Pallavaram", label: "Pallavaram" },
    { value: "Tambaram", label: "Tambaram" },
    { value: "Kancheepuram", label: "Kancheepuram" },
    { value: "Alandur", label: "Alandur" },
    { value: "Kurichi", label: "Kurichi" },
    { value: "Madavaram", label: "Madavaram" },
  ],

  "Madhya Pradesh": [
    { value: "Bhopal", label: "Bhopal" },
    { value: "Indore", label: "Indore" },
    { value: "Jabalpur", label: "Jabalpur" },
    { value: "Gwalior", label: "Gwalior" },
    { value: "Ujjain", label: "Ujjain" },
    { value: "Sagar", label: "Sagar" },
    { value: "Dewas", label: "Dewas" },
    { value: "Satna", label: "Satna" },
    { value: "Ratlam", label: "Ratlam" },
    { value: "Rewa", label: "Rewa" },
    { value: "Katni", label: "Katni" },
    { value: "Singrauli", label: "Singrauli" },
    { value: "Burhanpur", label: "Burhanpur" },
    { value: "Khandwa", label: "Khandwa" },
    { value: "Morena", label: "Morena" },
    { value: "Bhind", label: "Bhind" },
    { value: "Chhindwara", label: "Chhindwara" },
    { value: "Guna", label: "Guna" },
    { value: "Shivpuri", label: "Shivpuri" },
    { value: "Vidisha", label: "Vidisha" },
    { value: "Damoh", label: "Damoh" },
    { value: "Chhatarpur", label: "Chhatarpur" },
    { value: "Mandsaur", label: "Mandsaur" },
    { value: "Khargone", label: "Khargone" },
    { value: "Neemuch", label: "Neemuch" },
    { value: "Narmadapuram", label: "Narmadapuram" },
    { value: "Pithampur", label: "Pithampur" },
    { value: "Hoshangabad", label: "Hoshangabad" },
    { value: "Itarsi", label: "Itarsi" },
    { value: "Sehore", label: "Sehore" },
    { value: "Betul", label: "Betul" },
    { value: "Seoni", label: "Seoni" },
    { value: "Datia", label: "Datia" },
    { value: "Nagda", label: "Nagda" },
    { value: "Narsimhapur", label: "Narsimhapur" },
    { value: "Harda", label: "Harda" },
    { value: "Mandla", label: "Mandla" },
    { value: "Dindori", label: "Dindori" },
    { value: "Sarni", label: "Sarni" },
    { value: "Maheshwar", label: "Maheshwar" },
    { value: "Shajapur", label: "Shajapur" },
    { value: "Panna", label: "Panna" },
    { value: "Ashoknagar", label: "Ashoknagar" },
    { value: "Sausar", label: "Sausar" },
    { value: "Sidhi", label: "Sidhi" },
    { value: "Biaora", label: "Biaora" },
    { value: "Gadarwara", label: "Gadarwara" },
    { value: "Umaria", label: "Umaria" },
    { value: "Sanawad", label: "Sanawad" },
    { value: "Ghatiya", label: "Ghatiya" },
    { value: "Waraseoni", label: "Waraseoni" },
    { value: "Raghogarh-Vijaypur", label: "Raghogarh-Vijaypur" },
    { value: "Sendhwa", label: "Sendhwa" },
    { value: "Shujalpur", label: "Shujalpur" },
    { value: "Maihar", label: "Maihar" },
    { value: "Mhow Cantonment", label: "Mhow Cantonment" },
    { value: "Pithora", label: "Pithora" },
    { value: "Wara Seoni", label: "Wara Seoni" },
    { value: "Ranapur", label: "Ranapur" },
    { value: "Lahar", label: "Lahar" },
    { value: "Pachore", label: "Pachore" },
    { value: "Mahidpur", label: "Mahidpur" },
    { value: "Nainpur", label: "Nainpur" },
    { value: "Sihora", label: "Sihora" },
    { value: "Panagar", label: "Panagar" },
    { value: "Pasan", label: "Pasan" },
    { value: "Rahatgarh", label: "Rahatgarh" },
    { value: "Sabalgarh", label: "Sabalgarh" },
    { value: "Umaria", label: "Umaria" },
    { value: "Mhowgaon", label: "Mhowgaon" },
    { value: "Sironj", label: "Sironj" },
    { value: "Raghogarh", label: "Raghogarh" },
    { value: "Kotma", label: "Kotma" },
    { value: "Vijaypur", label: "Vijaypur" },
    { value: "Rawatbhata", label: "Rawatbhata" },
    { value: "Nepanagar", label: "Nepanagar" },
    { value: "Sihora", label: "Sihora" },
    { value: "Rehli", label: "Rehli" },
    { value: "Tarana", label: "Tarana" },
    { value: "Rampura", label: "Rampura" },
    { value: "Vadipatti", label: "Vadipatti" },
  ],

  Jharkhand: [
    { value: "Ranchi", label: "Ranchi" },
    { value: "Jamshedpur", label: "Jamshedpur" },
    { value: "Dhanbad", label: "Dhanbad" },
    { value: "Bokaro Steel City", label: "Bokaro Steel City" },
    { value: "Hazaribagh", label: "Hazaribagh" },
    { value: "Deoghar", label: "Deoghar" },
    { value: "Giridih", label: "Giridih" },
    { value: "Ramgarh", label: "Ramgarh" },
    { value: "Medininagar (Daltonganj)", label: "Medininagar (Daltonganj)" },
    { value: "Phusro", label: "Phusro" },
    { value: "Adityapur", label: "Adityapur" },
    { value: "Chirkunda", label: "Chirkunda" },
    { value: "Hazaribagh", label: "Hazaribagh" },
    { value: "Gumia", label: "Gumia" },
    { value: "Jhumri Tilaiya", label: "Jhumri Tilaiya" },
    { value: "Saunda", label: "Saunda" },
    { value: "Simdega", label: "Simdega" },
    { value: "Chaibasa", label: "Chaibasa" },
    { value: "Jagdalpur", label: "Jagdalpur" },
    { value: "Jeypur", label: "Jeypur" },
    { value: "Lohardaga", label: "Lohardaga" },
    { value: "Bundu", label: "Bundu" },
    { value: "Gumla", label: "Gumla" },
    { value: "Chakradharpur", label: "Chakradharpur" },
    { value: "Khunti", label: "Khunti" },
    { value: "Manoharpur", label: "Manoharpur" },
    { value: "Jhumri Telaiya", label: "Jhumri Telaiya" },
  ],

  Mizoram: [
    { value: "Aizawl", label: "Aizawl" },
    { value: "Lunglei", label: "Lunglei" },
    { value: "Saiha", label: "Saiha" },
    { value: "Champhai", label: "Champhai" },
    { value: "Kolasib", label: "Kolasib" },
    { value: "Serchhip", label: "Serchhip" },
    { value: "Lawngtlai", label: "Lawngtlai" },
    { value: "Mamit", label: "Mamit" },
    { value: "Hnahthial", label: "Hnahthial" },
    { value: "Khawzawl", label: "Khawzawl" },
    { value: "Saitual", label: "Saitual" },
    { value: "Zawlnuam", label: "Zawlnuam" },
    { value: "Thenzawl", label: "Thenzawl" },
  ],

  Nagaland: [
    { value: "Dimapur", label: "Dimapur" },
    { value: "Kohima", label: "Kohima" },
    { value: "Mokokchung", label: "Mokokchung" },
    { value: "Tuensang", label: "Tuensang" },
    { value: "Wokha", label: "Wokha" },
    { value: "Zunheboto", label: "Zunheboto" },
    { value: "Phek", label: "Phek" },
    { value: "Mon", label: "Mon" },
    { value: "Longleng", label: "Longleng" },
  ],

  "Himachal Pradesh": [
    { value: "Shimla", label: "Shimla" },
    { value: "Mandi", label: "Mandi" },
    { value: "Dharamshala", label: "Dharamshala" },
    { value: "Solan", label: "Solan" },
    { value: "Kullu", label: "Kullu" },
    { value: "Chamba", label: "Chamba" },
    { value: "Hamirpur", label: "Hamirpur" },
    { value: "Una", label: "Una" },
    { value: "Bilaspur", label: "Bilaspur" },
    { value: "Nahan", label: "Nahan" },
    { value: "Palampur", label: "Palampur" },
    { value: "Sirmaur", label: "Sirmaur" },
    { value: "Baddi", label: "Baddi" },
    { value: "Paonta Sahib", label: "Paonta Sahib" },
    { value: "Sundarnagar", label: "Sundarnagar" },
    { value: "Yol Cantonment", label: "Yol Cantonment" },
    { value: "Nalagarh", label: "Nalagarh" },
    { value: "Nurpur", label: "Nurpur" },
    { value: "Kangra", label: "Kangra" },
    { value: "Baijnath Paprola", label: "Baijnath Paprola" },
    { value: "Santokhgarh", label: "Santokhgarh" },
    { value: "Mehatpur Basdehra", label: "Mehatpur Basdehra" },
    { value: "Shamshi", label: "Shamshi" },
    { value: "Parwanoo", label: "Parwanoo" },
    { value: "Tira Sujanpur", label: "Tira Sujanpur" },
    { value: "Ghumarwin", label: "Ghumarwin" },
    { value: "Dalhousie", label: "Dalhousie" },
    { value: "Rohru", label: "Rohru" },
    { value: "Nagrota Bagwan", label: "Nagrota Bagwan" },
    { value: "Rampur", label: "Rampur" },
    { value: "Jawalamukhi", label: "Jawalamukhi" },
    { value: "Jogindernagar", label: "Jogindernagar" },
    { value: "Dera Gopipur", label: "Dera Gopipur" },
    { value: "Sarkaghat", label: "Sarkaghat" },
    { value: "Jhakhri", label: "Jhakhri" },
    { value: "Indora", label: "Indora" },
    { value: "Bhuntar", label: "Bhuntar" },
    { value: "Nadaun", label: "Nadaun" },
    { value: "Theog", label: "Theog" },
    { value: "Gagret", label: "Gagret" },
    { value: "Chuari Khas", label: "Chuari Khas" },
    { value: "Daulatpur", label: "Daulatpur" },
    { value: "Rajgarh", label: "Rajgarh" },
    { value: "Arki", label: "Arki" },
    { value: "Dagshai", label: "Dagshai" },
    { value: "Seoni", label: "Seoni" },
    { value: "Talai", label: "Talai" },
    { value: "Chaupal", label: "Chaupal" },
    { value: "Rewalsar", label: "Rewalsar" },
    { value: "Jubbal", label: "Jubbal" },
    { value: "Bhota", label: "Bhota" },
    { value: "Banjar", label: "Banjar" },
    { value: "Naina Devi", label: "Naina Devi" },
    { value: "Kotkhai", label: "Kotkhai" },
    { value: "Narkanda", label: "Narkanda" },
  ],

  Tripura: [
    { value: "Agartala", label: "Agartala" },
    { value: "Udaipur", label: "Udaipur" },
    { value: "Dharmanagar", label: "Dharmanagar" },
    { value: "Kailasahar", label: "Kailasahar" },
    { value: "Belonia", label: "Belonia" },
    { value: "Ambassa", label: "Ambassa" },
    { value: "Bishalgarh", label: "Bishalgarh" },
    { value: "Teliamura", label: "Teliamura" },
    { value: "Khowai", label: "Khowai" },
    { value: "Melaghar", label: "Melaghar" },
    { value: "Mohanpur", label: "Belonia" },
    { value: "Ranirbazar", label: "Ranirbazar" },
    { value: "Santirbazar", label: "Santirbazar" },
    { value: "Kumarghat", label: "Kumarghat" },
    { value: "Sonamura", label: "Sonamura" },
    { value: "Panisagar", label: "Panisagar" },
    { value: "Amarpur", label: "Amarpur" },
    { value: "Jirania", label: "Jirania" },
    { value: "Kamalpur", label: "Kamalpur" },
    { value: "Sabroom", label: "Sabroom" },
  ],

  "Andhra Pradesh": [
    { value: "Visakhapatnam", label: "Visakhapatnam" },
    { value: "Vijayawada", label: "Vijayawada" },
    { value: "Guntur", label: "Guntur" },
    { value: "Nellore", label: "Nellore" },
    { value: "Kurnool", label: "Kurnool" },
    { value: "Rajahmundry", label: "Rajahmundry" },
    { value: "Tirupati", label: "Tirupati" },
    { value: "Kakinada", label: "Kakinada" },
    { value: "Kadapa", label: "Kadapa" },
    { value: "Mangalagiri-Tadepalli", label: "Mangalagiri-Tadepalli" },
    { value: "Anantapuram", label: "Anantapuram" },
    { value: "Vizianagaram", label: "Vizianagaram" },
    { value: "Eluru", label: "Eluru" },
    { value: "Nandyal", label: "Nandyal" },
    { value: "Ongole", label: "Ongole" },
    { value: "Adoni", label: "Adoni" },
    { value: "Madanapalle", label: "Madanapalle" },
    { value: "Machilipatnam", label: "Machilipatnam" },
    { value: "Tenali", label: "Tenali" },
    { value: "Proddatur", label: "Proddatur" },
    { value: "Chittoor", label: "Chittoor" },
    { value: "Hindupur", label: "Hindupur" },
    { value: "Srikakulam", label: "Srikakulam" },
    { value: "Bhimavaram", label: "Bhimavaram" },
    { value: "Tadepalligudem", label: "Tadepalligudem" },
    { value: "Guntakal", label: "Guntakal" },
    { value: "Dharmavaram", label: "Dharmavaram" },
    { value: "Gudivada", label: "Gudivada" },
    { value: "Narasaraopet", label: "Narasaraopet" },
    { value: "Kadiri", label: "Kadiri" },
    { value: "Tadipatri", label: "Tadipatri" },
    { value: "Chilakaluripet", label: "Chilakaluripet" },
  ],

  Chandigarh: [{ value: "Chandigarh", label: "Chandigarh" }],

  Punjab: [
    { value: "	Ludhiana", label: "	Ludhiana" },
    { value: "Amritsar", label: "Amritsar" },
    { value: "Jalandhar", label: "Jalandhar" },
    { value: "Patiala", label: "Patiala" },
    { value: "Bathinda", label: "Bathinda" },
    { value: "Mohali", label: "Mohali" },
    { value: "Moga", label: "Moga" },
    { value: "Batala", label: "Batala" },
    { value: "Pathankot", label: "Pathankot" },
    { value: "Ferozpur", label: "Ferozpur" },
    { value: "Abohar", label: "Abohar" },
    { value: "Malerkotla", label: "Malerkotla" },
    { value: "Khanna", label: "Khanna" },
    { value: "Phagwara", label: "Phagwara" },
    { value: "Kapurthala", label: "Kapurthala" },
    { value: "Rajpura", label: "Rajpura" },
    { value: "Muktsar", label: "Muktsar" },
    { value: "Hoshiarpur", label: "Hoshiarpur" },
    { value: "Faridkot", label: "Faridkot" },
    { value: "Barnala", label: "Barnala" },
    { value: "Sunam", label: "Sunam" },
  ],

  Rajasthan: [
    { value: "Jaipur", label: "Jaipur" },
    { value: "Jodhpur", label: "Jodhpur" },
    { value: "Udaipur", label: "Udaipur" },
    { value: "Kota", label: "Kota" },
    { value: "Bikaner", label: "Bikaner" },
    { value: "Bhiwadi", label: "Bhiwadi" },
    { value: "Ajmer", label: "Ajmer" },
    { value: "Bhilwara", label: "Bhilwara" },
    { value: "Alwar", label: "Alwar" },
    { value: "Sikar", label: "Sikar" },
    { value: "Bharatpur", label: "Bharatpur" },
    { value: "Pali", label: "Pali" },
    { value: "Sri Ganganagar", label: "Sri Ganganagar" },
    { value: "Beawar", label: "Beawar" },
    { value: "Baran", label: "Baran" },
    { value: "Dhaulpur", label: "Dhaulpur" },
    { value: "Tonk", label: "Tonk" },
    { value: "Kishangarh", label: "Kishangarh" },
    { value: "Hanumangarh", label: "Hanumangarh" },
    { value: "Banswara", label: "Banswara" },
    { value: "Dungarpur", label: "Dungarpur" },
    { value: "Pratapgarh", label: "Pratapgarh" },
  ],

  Assam: [
    { value: "Guwahati", label: "Guwahati" },
    { value: "Dibrugarh", label: "Dibrugarh" },
    { value: "Jorhat", label: "Jorhat" },
    { value: "Silchar", label: "Silchar" },
    { value: "Nagaon", label: "Nagaon" },
    { value: "Tezpur", label: "Tezpur" },
    { value: "Tinsukia", label: "Tinsukia" },
    { value: "Bongaigaon", label: "Bongaigaon" },
  ],

  Odisha: [
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Cuttack", label: "Cuttack" },
    { value: "Rourkela", label: "Rourkela" },
    { value: "Berhampur", label: "Berhampur" },
    { value: "Sambalpur", label: "Sambalpur" },
    { value: "Puri", label: "Puri" },
    { value: "Balasore", label: "Balasore" },
    { value: "Bhadrak", label: "Bhadrak" },
    { value: "Baripada", label: "Baripada" },
  ],

  Chhattisgarh: [
    { value: "Raipur", label: "Raipur" },
    { value: "Bhilai", label: "Bhilai" },
    { value: "Durg", label: "Durg" },
    { value: "Bilaspur", label: "Bilaspur" },
    { value: "Korba", label: "Korba" },
    { value: "Raigarh", label: "Raigarh" },
    { value: "Ambikapur", label: "Ambikapur" },
    { value: "Rajnandgaon", label: "Rajnandgaon" },
    { value: "Jagdalpur", label: "Jagdalpur" },
    { value: "Janjgir", label: "Janjgir" },
    { value: "Chirmiri", label: "Chirmiri" },
    { value: "Dhamtari", label: "Dhamtari" },
    { value: "Mahasamund", label: "Mahasamund" },
  ],

  "Jammu and Kashmir": [
    { value: "Srinagar", label: "Srinagar" },
    { value: "Jammu", label: "Jammu" },
    { value: "Anantnag", label: "Anantnag" },
    { value: "Baramulla", label: "Baramulla" },
  ],
  Karnataka: [
    { value: "Bangalore", label: "Bangalore" },
    { value: "Mysore", label: "Mysore" },
    { value: "Hubli", label: "Hubli" },
    { value: "Mangalore", label: "Mangalore" },
    { value: "Belagavi", label: "Belagavi" },
    { value: "Kalaburagi", label: "Kalaburagi" },
    { value: "Davanagere", label: "Davanagere" },
    { value: "Bellary", label: "Bellary" },
    { value: "Vijayapura", label: "Vijayapura" },
    { value: "Shimoga", label: "Shimoga" },
    { value: "Tumkur", label: "Tumkur" },
    { value: "Raichur", label: "Raichur" },
    { value: "Bidar", label: "Bidar" },
    { value: "Udupi", label: "Udupi" },
    { value: "Hospet", label: "Hospet" },
    { value: "Gadag-Betageri", label: "Gadag-Betageri" },
    { value: "Robertsonpet", label: "Robertsonpet" },
    { value: "Hassan", label: "Hassan" },
    { value: "Bhadravati", label: "Bhadravati" },
    { value: "Chitradurga", label: "Chitradurga" },
    { value: "Kolar", label: "Kolar" },
    { value: "Mandya", label: "Mandya" },
    { value: "Chikmagalur", label: "Chikmagalur" },
    { value: "Gangavati", label: "Gangavati" },
    { value: "Bagalkot", label: "Bagalkot" },
    { value: "Ranebennuru", label: "Ranebennuru" },
  ],

  Manipur: [
    { value: "Imphal", label: "Imphal" },
    { value: "Thoubal", label: "Thoubal" },
    { value: "Bishnupur", label: "Bishnupur" },
    { value: "Churachandpur", label: "Churachandpur" },
    { value: "Senapati", label: "Senapati" },
    { value: "Ukhrul", label: "Ukhrul" },
    { value: "Tamenglong", label: "Tamenglong" },
    { value: "Kakching", label: "Kakching" },
    { value: "Jiribam", label: "Jiribam" },
    { value: "Tengnoupal", label: "Tengnoupal" },
    { value: "Kangpokpi", label: "Kangpokpi" },
    { value: "Chandel", label: "Chandel" },
    { value: "Kamjong", label: "Kamjong" },
    { value: "Noney", label: "Noney" },
    { value: "Pherzawl", label: "Pherzawl" },
    { value: "Tamenglong", label: "Tamenglong" },
    { value: "Thoubal", label: "Thoubal" },
    { value: "Ukhrul", label: "Ukhrul" },
  ],

  Kerala: [
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Kochi", label: "Kochi" },
    { value: "Kozhikode", label: "Kozhikode" },
    { value: "Thrissur", label: "Thrissur" },
    { value: "Kollam", label: "Kollam" },
    { value: "Kannur", label: "Kannur" },
    { value: "Alappuzha", label: "Alappuzha" },
    { value: "Kottayam", label: "Kottayam" },
    { value: "Palakkad", label: "Palakkad" },
    { value: "Manjeri", label: "Manjeri" },
    { value: "Thalassery", label: "Thalassery" },
    { value: "Thrippunithura", label: "Thrippunithura" },
    { value: "Ponnani", label: "Ponnani" },
    { value: "Vatakara", label: "Vatakara" },
    { value: "Kanhangad", label: "Kanhangad" },
    { value: "Payyanur", label: "Payyanur" },
    { value: "Koyilandy", label: "Koyilandy" },
    { value: "Parappanangadi", label: "Parappanangadi" },
    { value: "Kalamassery", label: "Kalamassery" },
    { value: "Kodungallur", label: "Kodungallur" },
    { value: "Neyyattinkara", label: "Neyyattinkara" },
    { value: "Tanur", label: "Tanur" },
    { value: "Kayamkulam", label: "Kayamkulam" },
    { value: "Malappuram", label: "Malappuram" },
    { value: "Guruvayur", label: "Guruvayur" },
    { value: "Thrikkakkara", label: "Thrikkakkara" },
    { value: "Wadakkancherry", label: "Wadakkancherry" },
    { value: "Nedumangad", label: "Nedumangad" },
    { value: "Kondotty", label: "Kondotty" },
    { value: "Tirurangadi", label: "Tirurangadi" },
    { value: "Tirur", label: "Tirur" },
    { value: "Panoor", label: "Panoor" },
    { value: "Kasaragod", label: "Kasaragod" },
    { value: "Feroke", label: "Feroke" },
    { value: "Kunnamkulam", label: "Kunnamkulam" },
    { value: "Ottappalam", label: "Ottappalam" },
    { value: "Tiruvalla", label: "Tiruvalla" },
    { value: "Thodupuzha", label: "Thodupuzha" },
    { value: "Perinthalmanna", label: "Perinthalmanna" },
    { value: "Chalakudy", label: "Chalakudy" },
    { value: "Payyoli", label: "Payyoli" },
    { value: "Koduvally", label: "Koduvally" },
    { value: "Mananthavady", label: "Mananthavady" },
    { value: "Changanassery", label: "Changanassery" },
    { value: "Mattanur", label: "Mattanur" },
    { value: "Punalur", label: "Punalur" },
    { value: "Nilambur", label: "Nilambur" },
    { value: "Cherthala", label: "Cherthala" },
    { value: "Sultan Bathery", label: "Sultan Bathery" },
    { value: "Maradu", label: "Maradu" },
    { value: "Kottakkal", label: "Kottakkal" },
    { value: "Taliparamba", label: "Taliparamba" },
    { value: "Shornur", label: "Shornur" },
    { value: "Pandalam", label: "Pandalam" },
    { value: "Kattappana", label: "Kattappana" },
    { value: "Cherpulassery", label: "Cherpulassery" },
    { value: "Mukkam", label: "Mukkam" },
    { value: "Iritty", label: "Iritty" },
    { value: "Valanchery", label: "Valanchery" },
    { value: "Varkala", label: "Varkala" },
    { value: "Nileshwaram", label: "Nileshwaram" },
    { value: "Chavakkad", label: "Chavakkad" },
    { value: "Kothamangalam", label: "Kothamangalam" },
    { value: "Pathanamthitta", label: "Pathanamthitta" },
    { value: "Attingal", label: "Attingal" },
    { value: "Paravur", label: "Paravur" },
    { value: "Ramanattukara", label: "Ramanattukara" },
    { value: "Mannarkkad", label: "Mannarkkad" },
    { value: "Erattupetta", label: "Erattupetta" },
    { value: "Sreekandapuram", label: "Sreekandapuram" },
    { value: "Angamaly", label: "Angamaly" },
    { value: "Chittur-Thathamangalam", label: "Chittur-Thathamangalam" },
    { value: "Kalpetta", label: "Kalpetta" },
    { value: "North Paravur", label: "North Paravur" },
    { value: "Haripad", label: "Haripad" },
    { value: "Muvattupuzha", label: "Muvattupuzha" },
    { value: "Kottarakara", label: "Kottarakara" },
    { value: "Kuthuparamba", label: "Kuthuparamba" },
    { value: "Adoor", label: "Adoor" },
    { value: "Irinjalakuda", label: "Irinjalakuda" },
    { value: "Pattambi", label: "Pattambi" },
    { value: "Anthoor", label: "Anthoor" },
    { value: "Perumbavoor", label: "Perumbavoor" },
    { value: "Piravom", label: "Piravom" },
    { value: "Ettumanoor", label: "Ettumanoor" },
    { value: "Mavelikkara", label: "Mavelikkara" },
    { value: "Karunagappalli", label: "Karunagappalli" },
    { value: "Eloor", label: "Eloor" },
    { value: "Chengannur", label: "Chengannur" },
    { value: "Vaikom", label: "Vaikom" },
    { value: "Aluva", label: "Aluva" },
    { value: "Pala", label: "Pala" },
    { value: "Koothattukulam", label: "Koothattukulam" },
  ],

  Delhi: [
    { value: "Connaught Place", label: "Connaught Place" },
    { value: "Karol Bagh", label: "Karol Bagh" },
    { value: "Chandni Chowk", label: "Chandni Chowk" },
    { value: "Saket", label: "Saket" },
    { value: "Vasant Kunj", label: "Vasant Kunj" },
    { value: "Dwarka", label: "Dwarka" },
    { value: "Rohini", label: "Rohini" },
    { value: "Mayur Vihar", label: "Mayur Vihar" },
    { value: "Lajpat Nagar", label: "Lajpat Nagar" },
    { value: "South Extension", label: "South Extension" },
    { value: "Noida", label: "Noida" },
  ],

  "Dadra and Nagar Haveli": [{ value: "Silvassa", label: "Silvassa" }],

  Puducherry: [
    { value: "Puducherry", label: "Puducherry" },
    { value: "Karaikal", label: "Karaikal" },
    { value: "Mahe", label: "Mahe" },
    { value: "Yanam", label: "Yanam" },
  ],

  Uttarakhand: [
    { value: "Dehradun", label: "Dehradun" },
    { value: "Haridwar", label: "Haridwar" },
    { value: "Rishikesh", label: "Rishikesh" },
    { value: "Nainital", label: "Nainital" },
    { value: "Mussoorie", label: "Mussoorie" },
    { value: "Roorkee", label: "Roorkee" },
    { value: "Haldwani", label: "Haldwani" },
    { value: "Kashipur", label: "Kashipur" },
    { value: "Rudrapur", label: "Rudrapur" },
    { value: "Kathgodam", label: "Kathgodam" },
    { value: "Pithoragarh", label: "Pithoragarh" },
    { value: "Almora", label: "Almora" },
    { value: "Pauri", label: "Pauri" },
    { value: "Chamoli", label: "Chamoli" },
    { value: "Tehri", label: "Tehri" },
    { value: "Udham Singh Nagar", label: "Udham Singh Nagar" },
    { value: "Bageshwar", label: "Bageshwar" },
    { value: "Champawat", label: "Champawat" },
  ],
  "Uttar Pradesh": [
    { value: "Agra", label: "Agra" },
    { value: "Lucknow", label: "Lucknow" },
    { value: "Kanpur", label: "Kanpur" },
    { value: "Ghaziabad", label: "Ghaziabad" },
    { value: "Meerut", label: "Meerut" },
    { value: "Varanasi", label: "Varanasi" },
    { value: "Prayagraj", label: "Prayagraj" },
    { value: "Bareilly", label: "Bareilly" },
    { value: "Aligarh", label: "Aligarh" },
    { value: "Moradabad", label: "Moradabad" },
    { value: "Saharanpur", label: "Saharanpur" },
    { value: "Gorakhpur", label: "Gorakhpur" },
    { value: "Noida", label: "Noida" },
    { value: "Firozabad", label: "Firozabad" },
    { value: "Jhansi", label: "Jhansi" },
    { value: "Muzaffarnagar", label: "Muzaffarnagar" },
    { value: "Mathura-Vrindavan", label: "Mathura-Vrindavan" },
    { value: "Ayodhya", label: "Ayodhya" },
    { value: "Rampur", label: "Rampur" },
    { value: "Shahjahanpur", label: "Shahjahanpur" },
    { value: "Farrukhabad-Fatehgarh", label: "Farrukhabad-Fatehgarh" },
    { value: "Budaun", label: "Budaun" },
    { value: "Maunath Bhanjan", label: "Maunath Bhanjan" },
    { value: "Hapur", label: "Hapur" },
    { value: "Etawah", label: "Etawah" },
    { value: "Mirzapur-Vindhyachal", label: "Mirzapur-Vindhyachal" },
    { value: "Bulandshahr", label: "Bulandshahr" },
    { value: "Sambhal", label: "Sambhal" },
    { value: "Amroha", label: "Amroha" },
    { value: "Hardoi", label: "Hardoi" },
    { value: "Fatehpur", label: "Fatehpur" },
    { value: "Raebareli", label: "Raebareli" },
    { value: "Orai", label: "Orai" },
    { value: "Sitapur", label: "Sitapur" },
    { value: "Bahraich", label: "Bahraich" },
    { value: "Modinagar", label: "Modinagar" },
    { value: "Unnao", label: "Unnao" },
    { value: "Jaunpur", label: "Jaunpur" },
    { value: "Lakhimpur", label: "Lakhimpur" },
    { value: "Hathras", label: "Hathras" },
    { value: "Banda", label: "Banda" },
    { value: "Pilibhit", label: "Pilibhit" },
    { value: "Barabanki", label: "Barabanki" },
    { value: "Khurja", label: "Khurja" },
    { value: "Gonda", label: "Gonda" },
    { value: "Mainpuri", label: "Mainpuri" },
    { value: "Lalitpur", label: "Lalitpur" },
    { value: "Etah", label: "Etah" },
    { value: "Deoria", label: "Deoria" },
    { value: "Badaun", label: "Badaun" },
    { value: "Ghazipur", label: "Ghazipur" },
    { value: "Sultanpur", label: "Sultanpur" },
    { value: "Azamgarh", label: "Azamgarh" },
    { value: "Bijnor", label: "Bijnor" },
    { value: "Sahaswan", label: "Sahaswan" },
    { value: "Basti", label: "Basti" },
    { value: "Chandausi", label: "Chandausi" },
    { value: "Akbarpur", label: "Akbarpur" },
    { value: "Ballia", label: "Ballia" },
    { value: "Tanda", label: "Tanda" },
    { value: "Greater Noida", label: "Greater Noida" },
    { value: "Shikohabad", label: "Shikohabad" },
    { value: "Shamli", label: "Shamli" },
    { value: "Awagarh", label: "Awagarh" },
    { value: "Kasganj", label: "Kasganj" },
  ],
  Bihar: [
    { value: "Patna", label: "Patna" },
    { value: "Gaya", label: "Gaya" },
    { value: "Bhagalpur", label: "Bhagalpur" },
    { value: "Muzaffarpur", label: "Muzaffarpur" },
    { value: "Bihar Sharif", label: "Bihar Sharif" },
    { value: "Darbhanga", label: "Darbhanga" },
    { value: "Purnia", label: "Purnia" },
    { value: "Sasaram", label: "Sasaram" },
    { value: "Arrah", label: "Arrah" },
    { value: "Samastipur", label: "Samastipur" },
    { value: "Begusarai", label: "Begusarai" },
    { value: "Katihar", label: "Katihar" },
    { value: "Munger", label: "Munger" },
    { value: "Chapra", label: "Chapra" },
    { value: "Hajipur", label: "Hajipur" },
    { value: "Siwan", label: "Siwan" },
  ],

  Gujarat: [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Surat", label: "Surat" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Bhavnagar", label: "Bhavnagar" },
    { value: "Jamnagar", label: "Jamnagar" },
    { value: "Gandhinagar", label: "Gandhinagar" },
    { value: "Junagadh", label: "Junagadh" },
    { value: "Gandhidham", label: "Gandhidham" },
    { value: "Anand", label: "Anand" },
    { value: "Navsari", label: "Navsari" },
    { value: "Morbi", label: "Morbi" },
    { value: "Nadiad", label: "Nadiad" },
    { value: "Surendranagar", label: "Surendranagar" },
    { value: "Bharuch", label: "Bharuch" },
    { value: "Mehsana", label: "Mehsana" },
    { value: "Bhuj", label: "Bhuj" },
    { value: "Porbandar", label: "Porbandar" },
    { value: "Palanpur", label: "Palanpur" },
    { value: "Valsad", label: "Valsad" },
    { value: "Vapi", label: "Vapi" },
    { value: "Gondal", label: "Gondal" },
    { value: "Veraval", label: "Veraval" },
    { value: "Godhra", label: "Godhra" },
    { value: "Patan", label: "Patan" },
    { value: "Kalol", label: "Kalol" },
    { value: "Dahod", label: "Dahod" },
    { value: "Botad", label: "Botad" },
    { value: "Amreli", label: "Amreli" },
    { value: "Deesa", label: "Deesa" },
    { value: "Jetpur", label: "Jetpur" },
  ],

  Telangana: [
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Warangal", label: "Warangal" },
    { value: "Nizamabad", label: "Nizamabad" },
    { value: "Karimnagar", label: "Karimnagar" },
    { value: "Khammam", label: "Khammam" },
    { value: "Ramagundam", label: "Ramagundam" },
    { value: "Mahabubnagar", label: "Mahabubnagar" },
    { value: "Nalgonda", label: "Nalgonda" },
    { value: "Adilabad", label: "Adilabad" },
    { value: "Suryapet", label: "Suryapet" },
    { value: "Miryalaguda", label: "Miryalaguda" },
    { value: "Siddipet", label: "Siddipet" },
    { value: "Mancherial", label: "Mancherial" },
    { value: "Armoor", label: "Armoor" },
  ],

  Meghalaya: [
    { value: "Shillong", label: "Shillong" },
    { value: "Tura", label: "Tura" },
    { value: "Jowai", label: "Jowai" },
    { value: "Nongstoin", label: "Nongstoin" },
    { value: "Baghmara", label: "Baghmara" },
    { value: "Khliehriat", label: "Khliehriat" },
    { value: "Mairang", label: "Mairang" },
    { value: "Nongpoh", label: "Nongpoh" },
    { value: "Resubelpara", label: "Resubelpara" },
    { value: "Ampati", label: "Ampati" },
    { value: "Khonjoy", label: "Khonjoy" },
    { value: "Cherrapunjee", label: "Cherrapunjee" },
    { value: "Lawsohtun", label: "Lawsohtun" },
    { value: "Madanriting", label: "Madanriting" },
    { value: "Mawlai", label: "Mawlai" },
    { value: "Mawpat", label: "Mawpat" },
    { value: "Nongkseh", label: "Nongkseh" },
    { value: "Nongthymmai", label: "Nongthymmai" },
    { value: "Pynthormukhrah", label: "Pynthormukhrah" },
    { value: "Resubelpara", label: "Resubelpara" },
    { value: "Shillong Cantonment", label: "Shillong Cantonment" },
    { value: "Tura", label: "Tura" },
    { value: "Umlyngka", label: "Umlyngka" },
    { value: "Umpling", label: "Umpling" },
    { value: "Umroi", label: "Umroi" },
    { value: "Williamnagar", label: "Williamnagar" },
  ],

  "Arunachal Pradesh": [
    { value: "Itanagar", label: "Itanagar" },
    { value: "Naharlagun", label: "Naharlagun" },
    { value: "Pasighat", label: "Pasighat" },
    { value: "Ziro", label: "Ziro" },
    { value: "Tawang", label: "Tawang" },
    { value: "Bomdila", label: "Bomdila" },
    { value: "Namsai", label: "Namsai" },
    { value: "Tezu", label: "Tezu" },
    { value: "Aalo", label: "Aalo" },
    { value: "Daporijo", label: "Daporijo" },
    { value: "Roing", label: "Roing" },
    { value: "Anini", label: "Anini" },
    { value: "Along", label: "Along" },
    { value: "Yingkiong", label: "Yingkiong" },
    { value: "Tuting", label: "Tuting" },
    { value: "Mechuka", label: "Mechuka" },
    { value: "Changlang", label: "Changlang" },
    { value: "Khonsa", label: "Khonsa" },
    { value: "Longding", label: "Longding" },
    { value: "Miao", label: "Miao" },
    { value: "Hawai", label: "Hawai" },
    { value: "Basar", label: "Basar" },
    { value: "Boleng", label: "Boleng" },
    { value: "Deomali", label: "Deomali" },
    { value: "Dirang", label: "Dirang" },
    { value: "Jairampur", label: "Jairampur" },
    { value: "Koloriang", label: "Koloriang" },
    { value: "Rupa", label: "Rupa" },
    { value: "Sagalee", label: "Sagalee" },
    { value: "Seppa", label: "Seppa" },
  ],

  Maharashtra: [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Pune", label: "Pune" },
    { value: "Nagpur", label: "Nagpur" },
    { value: "Thane", label: "Thane" },
    { value: "Nashik", label: "Nashik" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Solapur", label: "Solapur" },
    { value: "Kolhapur", label: "Kolhapur" },
    { value: "Amravati", label: "Amravati" },
    { value: "Sangli", label: "Sangli" },
    { value: "Akola", label: "Akola" },
    { value: "Latur", label: "Latur" },
    { value: "Dhule", label: "Dhule" },
    { value: "Ahmednagar", label: "Ahmednagar" },
    { value: "Jalgaon", label: "Jalgaon" },
    { value: "Kalyan-Dombivli", label: "Kalyan-Dombivli" },
    { value: "Vasai-Virar", label: "Vasai-Virar" },
    { value: "Navi Mumbai", label: "Navi Mumbai" },
    { value: "Amaravati", label: "Amaravati" },
    { value: "Bhiwandi", label: "Bhiwandi" },
    { value: "Pimpri-Chinchwad", label: "Pimpri-Chinchwad" },
    { value: "Mira-Bhayandar", label: "Mira-Bhayandar" },
    { value: "Nanded-Waghala", label: "Nanded-Waghala" },
    { value: "Ulhasnagar", label: "Ulhasnagar" },
    { value: "Sangli-Miraj-Kupwad", label: "Sangli-Miraj-Kupwad" },
    { value: "Malegaon", label: "Malegaon" },
    { value: "Chandrapur", label: "	Chandrapur" },
    { value: "Parbhani", label: "Parbhani" },
    { value: "Ichalkaranji", label: "	Ichalkaranji" },
    { value: "Jalna", label: "Jalna" },
    { value: "Ambarnath", label: "Ambarnath" },
    { value: "Bhusawal", label: "Bhusawal" },
    { value: "Panvel", label: "Panvel" },
    { value: "Badlapur", label: "Badlapur" },
    { value: "Beed", label: "Beed" },
    { value: "Gondia", label: "Gondia" },
    { value: "Satara", label: "Satara" },
    { value: "Barshi", label: "Barshi" },
    { value: "Yavatmal", label: "Yavatmal" },
    { value: "Achalpur", label: "Achalpur" },
    { value: "Osmanabad", label: "Osmanabad" },
    { value: "Nandurbar", label: "Nandurbar" },
    { value: "Wardha", label: "Wardha" },
    { value: "Udgir", label: "Udgir" },
    { value: "Hinganghat", label: "Hinganghat" },
  ],

  Goa: [
    { value: "Panaji", label: "Panaji" },
    { value: "Margao", label: "Margao" },
    { value: "Vasco da Gama", label: "Vasco da Gama" },
    { value: "Mapusa", label: "Mapusa" },
    { value: "Ponda", label: "Ponda" },
    { value: "Margao", label: "Margao" },
    { value: "Marmagao", label: "Marmagao" },
    { value: "Curchorem", label: "Curchorem" },
    { value: "Cuncolim", label: "Cuncolim" },
    { value: "Bicholim", label: "Bicholim" },
    { value: "Sanquelim", label: "Sanquelim" },
    { value: "Valpoi", label: "Valpoi" },
    { value: "Quepem", label: "Quepem" },
    { value: "Sanguem", label: "Sanguem" },
    { value: "Canacona", label: "Canacona" },
    { value: "Pernem", label: "Pernem" },
    { value: "Ponda", label: "Ponda" },
    { value: "Quepem", label: "Quepem" },
    { value: "Pernem", label: "Pernem" },
    { value: "Pernem", label: "Pernem" },
  ],

  "West Bengal": [
    { value: "Kolkata", label: "Kolkata" },
    { value: "Howrah", label: "Howrah" },
    { value: "Durgapur", label: "Durgapur" },
    { value: "Asansol", label: "Asansol" },
    { value: "Siliguri", label: "Siliguri" },
    { value: "Malda", label: "Malda" },
    { value: "Kharagpur", label: "Kharagpur" },
    { value: "Bardhaman", label: "Bardhaman" },
    { value: "Haldia", label: "Haldia" },
    { value: "Raniganj", label: "Raniganj" },
    { value: "Raiganj", label: "Raiganj" },
    { value: "Baharampur", label: "Baharampur" },
    { value: "Serampore", label: "Serampore" },
    { value: "Alipurduar", label: "Alipurduar" },
    { value: "Purulia", label: "Purulia" },
    { value: "Balurghat", label: "Balurghat" },
    { value: "Jalpaiguri", label: "Jalpaiguri" },
    { value: "Bankura", label: "Bankura" },
    { value: "Darjeeling", label: "Darjeeling" },
    { value: "Cooch Behar", label: "Cooch Behar" },
    { value: "Santipur", label: "Santipur" },
    { value: "Kalyani", label: "Kalyani" },
    { value: "Mursidabad", label: "Mursidabad" },
    { value: "Medinipur", label: "Medinipur" },
    { value: "Habra", label: "Habra" },
    { value: "Rajarhat", label: "Rajarhat" },
    { value: "Naihati", label: "Naihati" },
    { value: "Murarai", label: "Murarai" },
    { value: "Shantipur", label: "Shantipur" },
    { value: "Dankuni", label: "Dankuni" },
    { value: "Dhulian", label: "Dhulian" },
    { value: "Ranaghat", label: "Ranaghat" },
    { value: "Krishnanagar", label: "Krishnanagar" },
    { value: "Nabadwip", label: "Nabadwip" },
    { value: "Basirhat", label: "Basirhat" },
    { value: "Chakdaha", label: "Chakdaha" },
    { value: "Jangipur", label: "Jangipur" },
    { value: "Bolpur", label: "Bolpur" },
    { value: "Bangaon", label: "Bangaon" },
  ],

  Sikkim: [
    { value: "Sikkim", label: "Sikkim" },
    { value: "Gangtok", label: "Gangtok" },
    { value: "Namchi", label: "Namchi" },
    { value: "Ravangla", label: "Ravangla" },
    { value: "Mangan", label: "Mangan" },
    { value: "Pakyong", label: "Pakyong" },
    { value: "Soreng", label: "Soreng" },
    { value: "Gyalshing", label: "Gyalshing" },
  ],

  Ladakh: [
    { value: "Leh", label: "Leh" },
    { value: "Kargil", label: "Kargil" },
    { value: "Nubra Valley", label: "Nubra Valley" },
    { value: "Pangong Lake", label: "Pangong Lake" },
  ],
  "Daman and Diu": [
    { value: "Daman", label: "Daman" },
    { value: "Diu", label: "Diu" },
  ],

  Lakshadweep: [
    { value: "Agatti Island", label: "Agatti Island" },
    { value: "Andrott", label: "Andrott" },
    { value: "Bangaram Atoll", label: "Bangaram Atoll" },
    { value: "Bitra", label: "Bitra" },
    { value: "Amini", label: "Amini" },
    { value: "Chetlat Island", label: "Chetlat Island" },
    { value: "Kadmat Island", label: "Kadmat Island" },
    { value: "Kalpeni", label: "Kalpeni" },
    { value: "Kavaratti", label: "Kavaratti" },
    { value: "Kiltan", label: "Kiltan" },
  ],
};
