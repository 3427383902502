import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import "./Select.scss";

const CommonSelect = (props) => {
  const {
    name,
    formik,
    label,
    options,
    className,
    value,
    onChange,
    placeholder,
    sm,
    menuIsOpen,
    selectedOption,
    setSelectedOption,
    defaultValue,
    isSearchable,
    isDisabled,
    onBlur,
    isMulti,
    styles,
    ...rest
  } = props;

  return (
    <Form.Group
      className={`common_select ${className || ""} ${
        formik.values[name] ? "hasFilled" : ""
      } ${formik?.touched[name] && formik?.errors[name] ? "hasError" : ""}`}
      controlId={name}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div className="common_select_inner">
        <Select
          {...rest}
          classNamePrefix="react-select"
          className="react-select"
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          options={options}
          menuIsOpen={menuIsOpen}
          isDisabled={isDisabled}
          isSearchable={isSearchable == false ? isSearchable : true}
          isMulti={isMulti}
          styles={styles}
        />
      </div>
      {/* <ErrorMessage name={name} component={TextError} /> */}
      {formik?.touched[name] && (
        <p className="error_message">{formik?.errors[name]}</p>
      )}
    </Form.Group>
  );
};

export default CommonSelect;
