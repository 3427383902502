import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RedirectionComponent = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id && type) {
      navigate(`/curated-drop/digital-art/${type}/${id}`);
    }
  }, [type, id]);

  return <div></div>;
};

export default RedirectionComponent;
