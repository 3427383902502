import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../Component/Common/Layouts/MainLayout/MainLayout";
import "../Component/Common/Layouts/MainLayout/MainLayout.scss";

const AuthGuard = () => {
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  let isAuthenticated = isUserLoggedin;

  return isAuthenticated ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <MainLayout>
      <Navigate to="/home" />;
    </MainLayout>
  );
};
export default AuthGuard;
