import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import style from "./MakeCounterOfferModal.module.scss";
import {
  PLATFORM_MAX_VALUE,
  checkFileType,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import {
  DECIMAL,
  DIGITAL_ART_TYPE,
  OFFER_STATUS,
} from "../../../../Constant/userConstant";
import { useDispatch } from "react-redux";
import { getFee } from "../../../../Features/drop/dropSlice";

const MakeCounterOfferModal = ({
  show,
  onHide,
  makeOfferDetails,
  onMakeOfferRequest,
}) => {
  const dispatch = useDispatch();
  const [feeDetails, setFeeDetails] = useState({});
  const [calculatedDetails, setCalculatedDetails] = useState({
    basicPrice: "",
    gstCharge: "",
    serviceCharge: "",
    receiveAmount: "",
  });
  const MakeSchema = Yup.object({
    offer_price: Yup.string()
      .required("*Offer amount is required")
      .test(
        "greaterThanZero",
        "*Offer amount must be greater than 0",
        (value) => {
          return value != 0;
        }
      )
      .test(
        "not-ending-with-decimal",
        "*Value should not end with a decimal point",
        (value) => {
          if (!isNaN(value) && value !== parseInt(value, 10)) {
            return !/\.+$/.test(value.toString());
          }
          return true;
        }
      )
      .test(
        "notGreaterThanValue",
        "*Value is not accepted",
        (value) => value < PLATFORM_MAX_VALUE
      ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: MakeSchema,
    initialValues: {
      offer_price: "",
    },
    onSubmit: (values) => {
      checkoutHandler(values);
    },
  });

  useEffect(() => {
    getFeeHandler();
  }, []);

  const getFeeHandler = async () => {
    try {
      const response = await dispatch(getFee());
      setFeeDetails(response.data.data[0]);
    } catch (error) {}
  };

  const checkoutHandler = async (values) => {
    let data = {
      status: OFFER_STATUS.coutered,
      id: makeOfferDetails.item.id,
      makeOfferDetails: makeOfferDetails,
      offer_price: values.offer_price,
    };
    onMakeOfferRequest(data);
  };
  const getCalucaltionForCharge = async (totalAmount) => {
    let record = { ...calculatedDetails };
    let gstPercentage = feeDetails.gst_on_assets / DECIMAL;
    let serviceChargeOnArt = feeDetails.service_charges / DECIMAL;
    let gstOnServiceCharge = feeDetails.gst_on_service / DECIMAL;
    let sellPrice = Number(totalAmount);
    if (totalAmount !== "") {
      let gstAmount = sellPrice - sellPrice * (100 / (100 + gstPercentage));
      gstAmount = Number(gstAmount.toFixed(2));
      let artBasePrice = sellPrice - gstAmount;

      let serviceCharge = artBasePrice * (serviceChargeOnArt / 100);
      let gstOnSericeChange = serviceCharge * (gstOnServiceCharge / 100);
      serviceCharge = serviceCharge + gstOnSericeChange;
      record.basicPrice = artBasePrice;
      record.gstCharge = gstAmount;
      record.receiveAmount = artBasePrice - serviceCharge;
      record.serviceCharge = serviceCharge;
      setCalculatedDetails(record);
    } else {
      setCalculatedDetails({
        basicPrice: "",
        gstCharge: "",
        serviceCharge: "",
        receiveAmount: "",
      });
    }
  };

  const getFetureFileSection = (src) => {
    let type = checkFileType(src);
    if (type == "MP4" || type == "OGG") {
      return (
        <video loop autoPlay muted controlsList="nodownload" key={src}>
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return <img src={src} alt="Dfa-Image" />;
    }
  };

  console.log(makeOfferDetails);

  return (
    <>
      <CommonModal
        className={`${style.offerModal} ${
          makeOfferDetails?.art?.is_portrait
            ? `portraitModal`
            : `landscapeModal`
        }`}
        centered
        show={show}
        onHide={onHide}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-md-row flex-column">
            <div
              className={`${style.offerModalLeft} ${
                makeOfferDetails?.art?.is_portrait
                  ? `portraitLeft`
                  : `landscapeLeft`
              }`}
            >
              <div
                className={`${style.offerModalLeftInner} ${
                  makeOfferDetails?.art?.is_portrait
                    ? `portraitImg`
                    : `landscapeImg`
                }`}
              >
                {getFetureFileSection(
                  `${process.env.REACT_APP_IMG_BASE_URL}${makeOfferDetails?.art?.feature_file}`
                )}
              </div>
            </div>
            <div
              className={`${style.offerModalRight} ${
                makeOfferDetails?.art?.is_portrait
                  ? `portraitRight`
                  : `landscapeRight`
              }`}
            >
              <div className={style.artistDetails}>
                <h6>@{makeOfferDetails?.art?.art_user?.username}</h6>
                <h3 title={makeOfferDetails?.art?.art_name}>
                  {sliceChar(makeOfferDetails?.art?.art_name, 75)}
                </h3>
                <div className={style.artistDetailsEdition}>
                  <p>Edition :</p>
                  <span>
                    {" "}
                    #{makeOfferDetails?.item?.edition_no} of{" "}
                    {makeOfferDetails.art.drop_type ==
                    DIGITAL_ART_TYPE.OpenEdition
                      ? makeOfferDetails?.art?.sold_no_of_editions
                      : makeOfferDetails?.art?.no_of_editions}
                  </span>
                </div>
              </div>
              <ul className={style.pricelist}>
                <li>
                  <h6>Purchase Price</h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.art.art_owners[0]?.purchase_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.art.art_owners[0]
                              ?.purchase_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6>Last Sale Price</h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.art.last_sell_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.art.last_sell_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li className={style.highvalue}>
                  <h6> Highest Offer </h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.art?.highest_offer_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.art.highest_offer_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
              </ul>
              <div className={style.editionDetails}>
                <div className={style.enter_text}>
                  <label>Enter Offer Price</label>
                  <p>(Inclusive of GST)</p>
                </div>
                <FormikControls
                  control="input"
                  type="text"
                  name="offer_price"
                  id="offer_price"
                  placeholder="Enter here"
                  leftIcon="₹ "
                  onChange={(e) => {
                    //regex for only digit
                    if (
                      /^\d+(\.\d{0,2})?$/.test(e.target.value) ||
                      e.target.value == ""
                    ) {
                      formik.handleChange(e);
                      getCalucaltionForCharge(e.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.offer_price}
                  formik={formik}
                  maxLength={18}
                />
              </div>
              <ul className={style.pricelist}>
                <li>
                  <h6>Basic Price</h6>
                  <span>
                    {calculatedDetails?.basicPrice
                      ? "₹ " +
                        getNumberWithComma(
                          (calculatedDetails?.basicPrice).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6>Applicable GST</h6>
                  <span>
                    {" "}
                    {calculatedDetails?.gstCharge
                      ? "₹ " +
                        getNumberWithComma(
                          (calculatedDetails?.gstCharge).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6>Service Charge</h6>
                  <span>
                    {" "}
                    {calculatedDetails?.serviceCharge
                      ? "₹ " +
                        getNumberWithComma(
                          calculatedDetails?.serviceCharge.toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <p>(Inclusive of Artist Royalty & Applicable Taxes)</p>
              </ul>
              <div className={style.receive_payment}>
                <h6>
                  <label>You'll receive </label>
                  <span>
                    {" "}
                    {calculatedDetails?.receiveAmount
                      ? "₹ " +
                        getNumberWithComma(
                          calculatedDetails?.receiveAmount.toFixed(2)
                        )
                      : "--"}
                  </span>
                </h6>
              </div>
              {/* <p className={style.paragraph_text}>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </p> */}
              <div className={style.buy_btn_box}>
                <CommonBtn
                  role="btn"
                  title="Make Offer"
                  className={`${style.buy_btn} `}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default MakeCounterOfferModal;
