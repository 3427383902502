import React from "react";
import Form from "react-bootstrap/Form";
import "./Input/Input.scss";

const Togglebtn = ({
  name,
  label,
  className,
  formik,
  statusEnableDisable,
  onChange,
}) => {
  return (
    <>
      <Form.Group
        className={`common_input togglt_btn ${className}`}
        controlId={name}
      >
        <Form.Check
          type="switch"
          label={label}
          onChange={onChange}
          checked={statusEnableDisable == 0 ? false : true}
        />
        {formik?.touched[name] && (
          <p className="error_message">{formik?.errors[name]}</p>
        )}
      </Form.Group>
    </>
  );
};

export default Togglebtn;
