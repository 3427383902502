import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import styles from "../SignUp/SignUp.module.scss";
import {
  Login2FaVerificationAction,
  openPopUp,
  getLoginOtpAction,
} from "../../../Features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { saveUserId } from "../../../Features/persist/persistSlice";
import { QR_LOGIN_TEXT } from "../../../Constant/userConstant";
import { RoutesUrls } from "../../../Constant/RoutesUrls";

function TwoFaAuthentication(props) {
  const navigate = useNavigate();
  const ip = useSelector((state) => state?.persist?.ip);
  const nextRoute = useSelector((state) => state.users.nextRoute);

  const [seconds, setSeconds] = useState(60);
  const [loginUsingQrText, setLoginUsingQrText] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let qrLogin = queryParams.get("qr_login");
    setLoginUsingQrText(qrLogin);
  }, []);

  useEffect(() => {
    // Exit the effect if the timer reaches 0
    if (seconds === 0) return;

    // Decrease the seconds by 1 every second
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the interval when the component unmounts or when seconds reach 0
    return () => clearInterval(timer);
  }, [seconds]);

  const dispatch = useDispatch();
  const TwoFaSchema = Yup.object({
    otp: Yup.number()
      .typeError(inputFieldValidation.commonMessage.number)
      .integer(inputFieldValidation.commonMessage.integer)
      .positive(inputFieldValidation.commonMessage.positive)
      .required(
        inputFieldValidation.commonMessage.required("Verification code")
      )
      .test("lengthCheck", "*Invalid Code", (value) => {
        if (String(value).length < 6) {
          return false;
        }
        return true;
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: TwoFaSchema,
    initialValues: {
      otp: "",
    },

    onSubmit: (values) => {
      let data = {
        otp: values.otp,
        username: props.email,
        browserDetails: "",
        deviceType: 0,
        ipAddress: ip,
      };
      dispatch(Login2FaVerificationAction(data)).then((response) => {
        if (response?.status === 200) {
          dispatch(openPopUp(""));
          dispatch(saveUserId({ realId: response.data.data.uid }));

          if (loginUsingQrText == QR_LOGIN_TEXT) {
            navigate(RoutesUrls.Link_devices);
          } else {
            if (nextRoute != "") {
              navigate(nextRoute);
            }
          }
        }
      });
    },
  });

  useEffect(() => {
    if (props.email != "") {
      getLoginOtpReq();
    }
  }, [props.email]);

  const getLoginOtpReq = () => {
    // send otp to email
    let data = {
      to: props.email,
      type: "EMAIL",
      service: "VERIFY_LOGIN",
    };
    dispatch(getLoginOtpAction(data)).then((response) => {
      if (response?.status === 200) {
        setSeconds(60);
      }
    });
  };

  const getOTPSuccess = async () => {
    getLoginOtpReq();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={12}>
          <FormikControls
            variant={styles.spaceField}
            className={styles.fieldborder}
            label={``}
            control="input"
            name="otp"
            maxLength={6}
            placeholder="Verification Code"
            onChange={(e) => {
              //regex for only digit
              if (/^\d*$/.test(e.target.value)) {
                formik.setFieldValue("otp", e.target.value);
              }
            }}
            value={formik.values.otp}
            formik={formik}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col xs={12}>
          <CommonBtn
            className="btn-yellow hovBtn"
            role="btn"
            title="Confirm"
            type="submit"
          />
        </Col>
      </Row>
      <div className={`btn_resentotp ${seconds > 0 ? "afterOtp" : ""}`}>
        <button
          type="button"
          onClick={() => getOTPSuccess()}
          disabled={seconds > 0 ? true : false}
        >
          {seconds > 0 ? "You can resend code after" : "Resend Code"}
        </button>
        {seconds > 0 && <span>{seconds} Seconds</span>}
      </div>
    </form>
  );
}

export default TwoFaAuthentication;
