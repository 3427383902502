import React from "react";
import NewArtXWebBanner from "../../../../Assets/videos/NewArtXWebBanner.mp4";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./HeroSec.module.scss";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";

const NewHeroSec = ({
  filetitle,
  subtitle,
  btntitle,
  Secbtntitle,
  videoRef,
  onClick,
  navigate,
}) => {
  return (
    <>
      <div className={style.newheroSec}>
        <div className={style.videoSection}>
          <div className={style.imageTextSec}>
            <div className={style.textSec}>
              <h4>{subtitle}</h4>
              <h1>{filetitle}</h1>
            </div>
            <div className={style.btnAction}>
              {btntitle && (
                <CommonBtn
                  title={btntitle}
                  className={`black-btn ${style.btnSize}`}
                  onClick={() => navigate(RoutesUrls.About_dfa)}
                  role="btn"
                />
              )}
              {Secbtntitle && (
                <CommonBtn
                  title={Secbtntitle}
                  className={`${style.btnSize}`}
                  role="btn"
                  onClick={() => navigate(RoutesUrls?.Marketplace)}
                />
              )}
            </div>
          </div>
          <div className={style.videoData}>
            <video
              ref={videoRef}
              autoPlay={true}
              muted
              loop
              onClick={onClick}
              controls={false}
              width="100%"
              height="auto"
            >
              <source
                src={`${process.env.REACT_APP_IMG_BASE_URL}banner/NewArtXWebBanner.mp4`}
              />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHeroSec;
