import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { QR_LOGIN_TEXT } from "../../../Constant/userConstant";
import { getBannerFunction } from "../../../Features/drop/dropSlice";
import {
  getBrowseMarketplaceList,
  openPopUp,
  saveLatestPrice,
} from "../../../Features/user/userSlice";
import BrowseMarketplace from "./BrowseMarketplace/BrowseMarketplace";
import NewHeroSec from "./HeroSec/NewHeroSec";
import HowitsWorks from "./HowitsWorks/HowitsWorks";
import NaxCanvas from "./NaxCanvas/NaxCanvas";
import NewsSec from "./NewsSec/NewsSec";
import OurTeam from "./OurTeam/OurTeam";
import styles from "./Home.module.scss";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [settings, setSettings] = useState({
    dots: false,
    infinite: false,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => {
      // Add your custom logic or function call here
      // Call your function with the current slide index
      handleSlideChange(currentSlide);
    },
  });
  const [marketplaceListData, setMarketplaceListData] = useState([]);
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  useEffect(() => {
    // Get the query parameter from the URL so that if QR LOGIN Exist it will redirect to linked devices
    const queryParams = new URLSearchParams(window.location.search);
    const qrLogin = queryParams.get("qr_login");
    if (!isUserLoggedin && qrLogin == QR_LOGIN_TEXT) {
      navigate("/login");
    }
  }, []);

  // const [player, setPlayer] = useState(null);
  useEffect(() => {
    return () => {
      // clearing curatedDropInfo and latest price object which is persistSlice and userSlice
      dispatch(saveLatestPrice({}));
    };
  }, []);

  const handleSlideChange = (currentSlide) => {
    // Your custom logic or actions based on the slide change
    // Update state, execute functions, etc.
    setSliderIndex(currentSlide);
  };

  useEffect(() => {
    getBannerListHandler();
  }, []);

  const getBannerListHandler = async () => {
    try {
      const response = await dispatch(getBannerFunction());
      setBannerList(response?.data?.data?.bannerList);
      let infinite = false;
      let dot = false;
      if (response?.data?.data?.bannerList?.length > 1) {
        infinite = true;
        dot = true;
      }
      setSettings({
        dots: false,
        infinite: false,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (currentSlide) => {
          // Add your custom logic or function call here
          // Call your function with the current slide index
          handleSlideChange(currentSlide);
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    getBrowseMarketplaceListFun();
  }, []);

  const getBrowseMarketplaceListFun = async () => {
    try {
      let response = await dispatch(getBrowseMarketplaceList());
      setMarketplaceListData(response?.data?.list);
    } catch (err) {
      console.log("error", err);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Digital Fine Art online Platform | digital painting | NewArtX
        </title>
        <meta
          name="description"
          content="Unleash your creativity with NewArtX - the platform for digital fine art in India. Discover, showcase, and share your digital fine art prints, paintings, and creations."
        />
      </Helmet>
      <Container className={styles.heroContainer}>
        <Slider className={styles.slider_hero} {...settings}>
          {/* {Object.keys(settings).length > 0 && (
            {bannerList?.map((item, index) => (
              <HeroSec
                item={item}
                sliderIndex={
                  sliderIndex
                }
              player={player} setPlayer={setPlayer}
              />
            ))}  )} */}
          <NewHeroSec
            filetitle="Digital Fine Art"
            subtitle="A Curated Marketplace for"
            btntitle="What is DFA? "
            Secbtntitle="Explore All Digital Fine Art"
            navigate={navigate}
          />
          {/* <NewHeroSec
            filetitle="Digital Fine Art"
            subtitle="A Curated Marketplace for"
            btntitle="What is DFA? "
            Secbtntitle="Explore All Digital Fine Art"
            navigate={navigate}
          />
          <NewHeroSec
            filetitle="Digital Fine Art"
            subtitle="A Curated Marketplace for"
            btntitle="What is DFA? "
            Secbtntitle="Explore All Digital Fine Art"
            navigate={navigate}
          /> */}
        </Slider>
      </Container>
      {marketplaceListData?.length > 0 && (
        <BrowseMarketplace marketplaceListData={marketplaceListData} />
      )}
      <HowitsWorks />
      {/* <NextRelease /> */}
      {/* <CreateSec
        logoHead={NAXCanvasLogo}
        logoInfo="Flaunt your Digital Fine Art"
        subText="Flaunting your digital fine art has never been this seamless and elegant."
        subTextSec="With NewArtX, you can curate, showcase, and immerse yourself in the world of digital art effortlessly."
        subTextThird="Elevate your artistic expression and share your passion with the world through the NewArtX app and the stunning NAXCanvas."
        mainImage={naxCanvasMain}
      /> */}
      <NaxCanvas />
      <NewsSec />
      {/* <DropsSec /> */}
      <OurTeam />
    </>
  );
};
export default Home;
