import React from "react";
import styles from "./AboutUs.module.scss";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <div className={styles.terms_and_use}>
        <Container>
          <div className={styles.terms_and_use_in}>
            <h2>About us</h2>
            <p>
              Welcome to NewArtX, a curated Digital Fine Art platform that is
              redefining the boundaries of art and technology. We are the helm
              of a unique concept in India, transforming the rich tapestry of
              fine art into digital masterpieces and offering them as investible
              assets.
            </p>

            <h4>A wide spectrum of fine art</h4>
            <p>
              NewArtX is a dynamic marketplace that revels in the multifaceted
              world of art. Our collection spans from the esteemed works of
              prominent artists across Asia to the compelling pieces of emerging
              talents. We curate an extensive range of art that encapsulates a
              universal essence, transcending geographic boundaries. Our mission
              is to democratise access to these treasures, transcending the
              limitations of physical boundaries and conventional art markets.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
