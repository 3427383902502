import React from "react";
import { Container } from "react-bootstrap";
import { ArrowRight } from "../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import styles from "./CreateSec.module.scss";
import { useNavigate } from "react-router";

const CreateSec = (props) => {
  const {
    btnTitle,
    heading,
    subText,
    target,
    scrollToDiv,
    className,
    btnVariant,
    subTextSec,
    subTextThird,
    logoHead,
    logoInfo,
    mainImage,
    icon,
    smallheading,
    onButtonClick,
  } = props;

  const naviagte = useNavigate();
  return (
    <>
      <section className={`${styles.CreateSec} ${className}`}>
        <Container>
          <div className={styles.CreateSec_inner}>
            {heading && <h2>{heading}</h2>}
            {smallheading && <h3>{smallheading}</h3>}
            {logoHead && (
              <div className={styles.headLogo}>
                <img src={logoHead} alt="logoIcon" />
                <p>{logoInfo}</p>
              </div>
            )}
            {mainImage && (
              <div className={styles.innerImage}>
                <img src={mainImage} alt="imageShow" />
              </div>
            )}
            {subText && <p>{subText}</p>}
            {subTextSec && <p>{subTextSec}</p>}
            {subTextThird && <p>{subTextThird}</p>}
            <CommonBtn
              type="button"
              onClick={
                scrollToDiv
                  ? scrollToDiv
                  : () => window.open("https://naxcanvas.com", "_blank")
              }
              role="btn"
              title={btnTitle}
              icon={icon}
              className={` ${styles.btn_create} ${btnVariant}`}
              // target="_blank"
            />
          </div>
        </Container>
      </section>
    </>
  );
};

CreateSec.defaultProps = {
  btnTitle: "Learn More",
  icon: <ArrowRight />,
  // btnVariant: "bordred-yellow"
};
export default CreateSec;
