import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import CommonBtn from "../../../../../../Common/Ui/CommonBtn/CommonBtn";
import FormikControls from "../../../../../../Common/Ui/Formik/FormikControls";
import style from "./RankedAuctionForm.module.scss";
import Timer from "../Timer";
import {
  DECIMAL,
  MARKET_TYPE,
} from "../../../../../../../Constant/userConstant";
import { useNavigate, useParams } from "react-router-dom";
import {
  getNumberWithComma,
  PLATFORM_MAX_VALUE,
} from "../../../../../../../Helper/common";
import { useDispatch, useSelector } from "react-redux";
import { inputFieldValidation } from "../../../../../../../Constant/formikConstant";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../../../../../Features/user/userSlice";

const RankedAuctionForm = ({
  dfaDetail,
  getMinBidToBeRankOne,
  dfaActivity,
  setShowClosedDrop,
  getDfaDetailByIdHandler,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  const initialValue = {
    bidAmount: "",
    isMaxLimit: dfaDetail?.is_max_limit === 0 ? false : true,
  };

  const isValidBid = (bidValue) => {
    // Sort bids in ascending order
    const sortedBids = dfaActivity?.user_activity
      .map((bid) => bid.bid_amount / DECIMAL)
      .sort((a, b) => a - b);
    let lowestBid = sortedBids[0];
    let highestBid = sortedBids[sortedBids.length - 1];
    let basePrice = dfaDetail?.floor_price / DECIMAL;
    let incrementValue = dfaDetail?.increment / DECIMAL;
    // Check if the bid already exists in the current bids (duplicates not allowed)
    if (sortedBids.includes(bidValue)) {
      return false;
    }
    // Check if the bid is below the base price
    if (bidValue < basePrice + incrementValue) {
      return false;
    }
    // Fall in this condition only when Bid Amount is greater than lowest bid and lesser than highest bid.
    if (bidValue > lowestBid && bidValue < highestBid) {
      const currentBidIndex = sortedBids.findIndex((bid) => bid > bidValue);
      // Immediate higher bid (if exists)
      const higherRange =
        currentBidIndex !== -1 ? sortedBids[currentBidIndex] : Number.MAX_VALUE;
      // Determine the second lowest bid
      const lowerRange =
        sortedBids.length > 1 && currentBidIndex > 0
          ? sortedBids[currentBidIndex - 1]
          : basePrice;
      // Check if the bid is in the valid range between secondLowestBid + incrementValue and immediateHigherBid - incrementValue
      if (
        bidValue >= lowerRange + incrementValue &&
        bidValue <= higherRange - incrementValue
      ) {
        return true;
      }
    }
    // Determine the ranges dynamically

    // Check if the bid is higher than the highest bid + incrementValue (valid high bid)
    if (bidValue >= highestBid + incrementValue) {
      return true;
    }

    return false;
  };

  const validationSchema = Yup.object({
    isMaxLimit: Yup.boolean(),
    bidAmount: Yup.number()
      .typeError(inputFieldValidation.commonMessage.number)
      .positive(inputFieldValidation.commonMessage.positive)
      .required(inputFieldValidation.commonMessage.required("Bid Amount"))
      .test(
        "if user is first bidder",
        `*Bid amount should be equal or greater than ${
          dfaDetail?.floor_price / DECIMAL
        }`,
        (values) => {
          if (
            dfaActivity?.user_activity?.length === 0 &&
            !formik?.values?.isMaxLimit
          ) {
            return Number(values) >= dfaDetail?.floor_price / DECIMAL;
          } else {
            return true;
          }
        }
      )
      .test(
        "if bid already exist",
        `*Bid amount should be equal or greater than ${getNumberWithComma(
          getMinBidToBeRankOne
        )}`,
        (values) => {
          if (
            dfaActivity?.user_activity?.length === 1 &&
            !formik?.values?.isMaxLimit
          ) {
            return Number(values) >= getMinBidToBeRankOne;
          } else {
            return true;
          }
        }
      )
      .test("if bid already exist", `*Invalid bid`, (values) => {
        if (
          dfaActivity?.user_activity?.length > 1 &&
          !formik?.values?.isMaxLimit
        ) {
          return isValidBid(values);
        } else {
          return true;
        }
      })
      .test("notGreaterThanValue", `*Value is not accepted`, function (values) {
        if (values == "" || values == undefined) {
          return true;
        } else {
          return values < PLATFORM_MAX_VALUE;
        }
      }),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (dfaDetail?.is_eligible_to_buy) {
        checkoutHandler(values);
      } else {
        setShowClosedDrop(true);
      }
    },
  });

  const checkoutHandler = async (values) => {
    if (isUserLoggedin) {
      try {
        let data = {
          price: values.bidAmount,
          quantity: 1,
          market: "primary",
          is_max_limit: values.isMaxLimit ? 1 : 0,
        };

        dispatch(saveLatestPrice(data));
        navigate(`/checkout/${id}`);
      } catch (error) {}
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };
  return (
    <>
      <div className={style.rankedauctionForm}>
        <form onSubmit={formik.handleSubmit}>
          <div className={style.auctionTophead}>
            <div className={style.bidrank}>
              <label>Min. Bid for Rank 1: </label>

              <h5>₹ {getNumberWithComma(getMinBidToBeRankOne)}</h5>
            </div>
            {dfaDetail?.market_type === MARKET_TYPE?.PRIMARY && (
              <div className={style.timerSet}>
                <label>Ends in: </label>
                <h5>
                  <Timer
                    startingTimestamp={dfaDetail?.end_time}
                    getDfaDetailByIdHandler={getDfaDetailByIdHandler}
                  />
                </h5>
              </div>
            )}
          </div>
          <div className={style.innerForm}>
            <div className={style.innerFormLeftside}>
              <label>Min.Increment :</label>
              <h5> ₹ {getNumberWithComma(dfaDetail?.increment / DECIMAL)}</h5>
            </div>
            <div className={style.innerFormRightside}>
              <div className={style.addqantity}>
                <FormikControls
                  control="checkbox"
                  type="checkbox"
                  name="isMaxLimit"
                  label="Set Max Limit"
                  formik={formik}
                  className={`checkMax ${style.setlimitcheck}`}
                  onChange={(e) => {
                    formik.setFieldValue("bidAmount", "");
                    formik.setFieldValue("isMaxLimit", e.target.checked);
                  }}
                  onBlur={formik.handleBlur}
                  checked={formik.values.isMaxLimit}
                  disabled={dfaDetail?.is_max_limit}
                />
                <FormikControls
                  variant="priceadd"
                  control="input"
                  type="input"
                  name="bidAmount"
                  id="bidAmount"
                  placeholder="Enter Amount"
                  formik={formik}
                  onChange={(e) => {
                    if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  // disabled={!isUserLoggedin}
                  value={formik.values.bidAmount}
                  onBlur={formik.handleBlur}
                />
              </div>

              <CommonBtn
                title="Place Bid"
                role="btn"
                className={`${style.btnSize}`}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RankedAuctionForm;
