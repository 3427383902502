import { createSlice } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "../loading/loadingSlice";
import { dropService } from "../../Services/dropService";
import { store } from "../../App/store";
import { toasts } from "../../Component/Common/Toast/Toast";

let token;
const initialState = {};

export const dropSlice = createSlice({
  name: "drop",
  initialState,
  reducers: {},
});

export const dropsList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.drops(token, params);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getDrops = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.getDrops(token, params);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getArts = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.getArts(token, params);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getDropDetailById = (params) => {
  // if( params.user_id == "" && params.user_id == null && params.user_id == undefined){
  //   delete params.user_id
  // }
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.getDropDetailById(params);
        resolve(response.data.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });

  };


  

};

export const getAllPurchasedSoldList = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.getAllPurchasedSoldList(
          params,
          token
        );
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const sellNftMarketPlace = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.sellNftMarketPlace(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);

        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getFee = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.sellNow(token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getBannerFunction = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await dropService.getBanner(token);
        // toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};



export default dropSlice.reducer;
