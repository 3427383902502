import React, { useState } from "react";
import style from "../Dropaction/Dropaction.module.scss";
import { PlusIcon, SubtractionIcon } from "../../../Assets/svgIcons/SVGicon";
import { Tab, Tabs } from "react-bootstrap";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import {
  formatDateWithTime,
  getDateFromTimestamp,
  getNumberWithComma,
} from "../../../Helper/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import personIcon from "../../../Assets/images/person_icon.jpg";
import Timer from "../Timer";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import { toasts } from "../../Common/Toast/Toast";
import { DECIMAL, SALE_TYPE } from "../../../Constant/userConstant";
import Notfound from "../../Common/NotFound/Notfound";
import HowitWork from "../../Common/HowitWork/HowitWork";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import DOMPurify from "dompurify";

const OpenEditionLive = ({
  artDetail,
  liveActivityDetail,
  setActivityPage,
  page,
  totalActivityPage,
  userId,
  type,
  id,
  setShowClosedDrop,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const [quantity, setQuantity] = useState(1);

  const initialValue = {
    search: "",
    filterOption: "",
  };
  const validationSchema = Yup.object({
    search: Yup.string().required("Required"),
    filterOption: Yup.string().required("Please select blockchain"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const subHanlder = () => {
    if (quantity > 1) {
      setQuantity((prev) => Number(prev) - 1);
    }
  };

  const addHanlder = () => {
    if (artDetail.buy_limit - artDetail.buy_counter > quantity) {
      setQuantity((prev) => Number(prev) + 1);
    } else {
      toasts.error("Your maximum limit is reached.");
    }
  };

  const onClickHandler = () => {
    let userBuyLimit = artDetail.buy_limit - artDetail.buy_counter;

    if (isUserLoggedin) {
      if (artDetail.is_eligible_to_buy) {
        if (quantity > userBuyLimit && quantity) {
          toasts.error("Your maximum limit is reached.");
        } else {
          try {
            let data = {
              price: Number(artDetail.price) / DECIMAL,
              quantity: quantity,
              buyCount: artDetail.buy_limit - artDetail.buy_counter,
              market: "primary",
            };
            dispatch(saveLatestPrice(data));
            navigate(`/checkout/${id}`);
          } catch (error) {}
        }
      } else {
        setShowClosedDrop(true);
      }
    } else {
      dispatch(saveNextRoute(`/curated-drop/digital-art/${type}/${id}`));
      navigate("/login");
    }
  };
  const bidActivity = () => {
    if (liveActivityDetail.length) {
      return liveActivityDetail.map((data) => (
        <tr key={data.id}>
          <td>
            {data.editions_no == 0 ? "Updating..." : `#${data.editions_no}`}
          </td>
          <td>
            <div className="d-flex align-items-center thumb-outer">
              {data?.sale_type == SALE_TYPE.OFFLINE &&
              data?.is_reedemed == 1 ? (
                <>
                  <img className="thumb" src={personIcon} alt="userIcon" />
                  <h4>Offline Sale</h4>
                </>
              ) : (
                <>
                  <img
                    className="thumb"
                    src={
                      data?.auction_user[0]?.image_path
                        ? `${process.env.REACT_APP_IMG_BASE_URL}${data?.auction_user[0]?.image_path}`
                        : personIcon
                    }
                    alt="thumb"
                  />
                  <h4
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/profile/${data?.auction_user[0]?.username}`);
                    }}
                  >
                    @{data?.auction_user[0]?.username}
                  </h4>
                </>
              )}
            </div>
          </td>
          <td>{formatDateWithTime(data.createdAt)}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={4}>
            <Notfound text={"No activity found."} />
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <div className={style.dropaction_ended_rightsec_pricefloor}>
        <div className={style.headprice}>
          <ul>
            <li>
              <label>Price :</label>
              <h6>₹{getNumberWithComma(artDetail.price / DECIMAL)} each</h6>
            </li>
          </ul>
        </div>
        <div className={style.startinfo}>
          <div className={style.buyadd}>
            <div className={style.valuelist}>
              <h3 className={style.left_art}>
                Ends in :{" "}
                <Timer
                  startingTimestamp={artDetail?.end_time}
                  type="live"
                  id={id}
                />
              </h3>
            </div>
            {
              /* userId !== artDetail.artist_id && */ <div
                className={style.bitlimitset}
              >
                {isUserLoggedin && (
                  <>
                    <FormikControls
                      variant={`checkField`}
                      className="text-center"
                      control="input"
                      type="text"
                      name="number"
                      id="number"
                      placeholder="Enter Quantity"
                      onChange={(e) => {
                        // Calculate the buyer limit based on the difference between the buy limit and buy counter
                        let buyerLimit =
                          artDetail.buy_limit - artDetail.buy_counter;
                        // Check if the entered value is not empty
                        if (e.target.value != "") {
                          // Validate if the entered value is a valid integer
                          if (/^-?\d+$/.test(e.target.value)) {
                            setQuantity(Number(e.target.value));

                            // Check if the entered value exceeds the buyer limit
                            if (e.target.value > buyerLimit) {
                              if (buyerLimit == 0) {
                                // If the buyer limit is reached, show an error toast and reset quantity to empty after 1 second
                                toasts.error("Your maximum limit is reached.");
                                setTimeout(() => {
                                  setQuantity("");
                                }, [1000]);
                              } else {
                                // If the entered value exceeds the buyer limit but buyer limit is not 0, show an error toast
                                setTimeout(() => {
                                  setQuantity("");
                                }, [500]);
                                toasts.error(
                                  `You can buy only ${buyerLimit} editions.`
                                );
                              }
                            }
                          }
                        } else {
                          setQuantity(""); // If the entered value is empty, reset the quantity
                        }
                      }}
                      value={quantity}
                      leftIcon={<SubtractionIcon />}
                      leftIconClick={subHanlder}
                      rightIcon={<PlusIcon />}
                      rightIconClick={addHanlder}
                    />
                    <h4 className={style.vaule_totleamount}>
                      ₹
                      {getNumberWithComma(
                        (Number(artDetail?.price) / DECIMAL) * quantity
                      )}
                    </h4>
                  </>
                )}
                <CommonBtn
                  title="Buy now"
                  role="btn"
                  className=""
                  disabled={quantity == 0 || quantity == ""}
                  onClick={onClickHandler}
                />
              </div>
            }
          </div>
        </div>
      </div>
      <div className={style.dropaction_ended_rightsec_tabpartlist}>
        <Tabs defaultActiveKey="aboutart" id="" className="tablists">
          <Tab eventKey="aboutart" title="About Art">
            <div className={style.artstab_containt}>
              <div
                className="textSection"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(artDetail?.bio),
                }}
              ></div>

              <ul className={style.about_artlist}>
                <li>
                  <label>Type</label>
                  <span>:</span>
                  <h6>{artDetail?.art_type}</h6>
                </li>
                <li>
                  <label>Released</label>
                  <span>:</span>
                  <h6>{getDateFromTimestamp(artDetail.start_time)}</h6>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab eventKey="activity" title="Activity">
            <div className={style.bid_activity}>
              <div className={style.bid_table}>
                <CommonTable className={style.bidder_table}>
                  <thead>
                    <tr>
                      <th>
                        <span>Edition</span>
                      </th>
                      <th>
                        <span>Collector</span>
                      </th>
                      <th>
                        <span>Time</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{bidActivity()}</tbody>
                </CommonTable>
                {totalActivityPage > 1 && (
                  <CustomPagination
                    className="ms-auto"
                    activePage={page}
                    totalPage={totalActivityPage}
                    setActivePage={setActivityPage}
                    maxVisiblePages={5}
                  />
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="howitworks" title="How it Works">
            <div className={`py-0 ${style.artstab_containt}`}>
              {/* <p>
                There’s a stage we reach in life where we start to realize our
                innocence is “gone.” We may have began to realize that we lost
                that childlike innocence ages ago. However, times of innocence
                seem to flutter by in adulthood.
              </p> */}
              <HowitWork artDetail={artDetail} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default OpenEditionLive;
