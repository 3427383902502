import { createSlice } from "@reduxjs/toolkit";
import { walletService } from "../../Services/walletService";
import {
  disableButton,
  enableButton,
  startLoading,
  stopLoading,
} from "../loading/loadingSlice";
import { toasts } from "../../Component/Common/Toast/Toast";
import { store } from "../../App/store";
import { initialStateWallet } from "../InitialState";
// import { startLoading, stopLoading } from "../loading/loadingSlice";
let token;

const initialState = initialStateWallet;

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
});

// export const getWalletBalance = () => {
//   return async (dispatch, getState) => {
//     try {
//       dispatch(startLoading());
//       const response = await walletService.getWalletBalance();
//       dispatch(stopLoading());
//     } catch (err) {
//       dispatch(stopLoading());
//     }
//   };
// };



// export const { } = walletSlice.actions;
export default walletSlice.reducer;
