import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useFormik } from "formik";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import styles from "./ContactUs.module.scss";
import * as Yup from "yup";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import { useDispatch } from "react-redux";
import { Contactus } from "../../../Features/user/userSlice";
import SocialMediaIcon from "../../Common/Ui/SocialMediaIcon/SocialMediaIcon";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const dispatch = useDispatch();

  const ContactSchema = Yup.object({
    name: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Name should not start or end with a space"
      )
      // .trim("*Name cannot start with a space")
      .strict(true)
      .min(
        inputFieldValidation.firstName.minLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .max(
        inputFieldValidation.firstName.maxLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .required(inputFieldValidation.commonMessage.required("Name"))
      .matches(
        inputFieldValidation.firstName.regex.expresion,
        inputFieldValidation.firstName.regex.errorMessage
      ),
    email: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),

    description: Yup.string()
      .min(
        inputFieldValidation.bio.minLength,
        `Must be between ${inputFieldValidation.bio.minLength}-${inputFieldValidation.bio.maxLength} letters`
      )
      .max(
        inputFieldValidation.bio.maxLength,
        `Must be between ${inputFieldValidation.bio.minLength}-${inputFieldValidation.bio.maxLength} letters`
      )
      .required("*Message is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ContactSchema,
    initialValues: {
      name: "",
      email: "",
      description: "",
    },
    onSubmit: (values) => {
      ContactUsHandler(values);
      formik.resetForm();
    },
  });

  const ContactUsHandler = async (values) => {
    const response = await dispatch(Contactus(values));
  };

  return (
    <>
      <section className={styles.ContactUsBanner}>
        <Helmet>
          <title>Contact Us | Get in Touch with NewArtX</title>
          <meta
            name="description"
            content="Reach out to NewArtX for support, inquiries, or feedback. Our team is here to help with all your digital fine art needs."
          />
          <link rel="canonical" href="https://www.newartx.com/contact-us" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Contact Us | Get in Touch with NewArtX"
          />
          <meta
            property="og:url"
            content="https://www.newartx.com/contact-us"
          />
          <meta
            property="og:image"
            content="https://www.newartx.com/static/media/Nax%20Canvas.1496f78d0023808b36e5.png"
          />
          <meta
            property="og:description"
            content="Reach out to NewArtX for support, inquiries, or feedback. Our team is here to help with all your digital fine art needs."
          />
          <link rel="canonical" href="https://www.newartx.com/contact-us" />
        </Helmet>

        <h1 className="big">Get In Touch</h1>
      </section>
      <section className={styles.ContactUs}>
        <Container>
          <div className={styles.ContactUsInner}>
            <Row>
              <Col md={6}>
                <Row>
                  <Col xxl={7} className={styles.ContactUsInnerLeft}>
                    <h2>Drop Us A Mail.</h2>
                    <p className={styles.subTitleText}>
                      We're here to anwser any of your questions
                    </p>
                    <form onSubmit={formik.handleSubmit}>
                      <FormikControls
                        className="input_border"
                        control="input"
                        type="name"
                        name="name"
                        id="name"
                        placeholder="Your Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        formik={formik}
                        variant={`${styles.inputBox}`}
                      />
                      <FormikControls
                        className="input_border"
                        control="input"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email Address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        formik={formik}
                        variant={`${styles.inputBox}`}
                      />
                      <FormikControls
                        classField="input_border"
                        control="textarea"
                        name="description"
                        type="description"
                        id="description"
                        placeholder="Your message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        formik={formik}
                      />
                      <CommonBtn
                        role="btn"
                        title="Submit"
                        className={styles.btn_sizing}
                        type="submit"
                      />
                    </form>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <div className={styles.ContactUsDetails}>
                  <div className={styles.ContactUsDetailsBox}>
                    <h2>Follow Us.</h2>
                    <div className="d-flex align-items-center justify-content-md-end">
                      <SocialMediaIcon
                        AsFollow
                        className={`yellow_social ${styles.NextReleaseRightBox}`}
                      />
                    </div>
                  </div>
                  <div className={styles.ContactUsDetailsBox}>
                    <h2>Call Us.</h2>
                    {/* <p className={styles.subDetailText}>+91 22 3540 9522</p> */}
                    <p className={styles.subDetailText}>
                      <a href="tel:+912235409522">+91 22 3540 9522</a>
                    </p>
                  </div>
                  <div className={styles.ContactUsDetailsBox}>
                    <h2>Email Us.</h2>
                    <p className={styles.subDetailText}>
                      <a href={`mailto:namaste@elephantcanvas.com`}>
                        namaste@elephantcanvas.com
                      </a>
                    </p>
                  </div>
                  <div className={styles.ContactUsDetailsBox}>
                    <h2>Our Address</h2>
                    <p className={styles.subDetailText}>
                      B 401-402, Neelam Centre, Worli Mumbai - 400030 INDIA
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
