import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikControls from "../../../../../../Common/Ui/Formik/FormikControls";
import {
  PlusIcon,
  SubtractionIcon,
} from "../../../../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./OpenEditionForm.module.scss";
import Timer from "../Timer";
import {
  DECIMAL,
  MARKET_TYPE,
} from "../../../../../../../Constant/userConstant";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../../../../../Features/user/userSlice";
import { toasts } from "../../../../../../Common/Toast/Toast";
import { getNumberWithComma } from "../../../../../../../Helper/common";

const OpenEditionForm = ({
  dfaDetail,
  setShowClosedDrop,
  getDfaDetailByIdHandler,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  const initialValue = {
    quantity: 1,
  };
  const validationSchema = Yup.object({
    quantity: Yup.string().required("Enter Quantity"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onClickHandler(values);
    },
  });

  const onClickHandler = (values) => {
    let userBuyLimit = dfaDetail.buy_limit - dfaDetail.buy_counter;
    if (isUserLoggedin) {
      if (dfaDetail.is_eligible_to_buy) {
        if (values?.quantity > userBuyLimit && values?.quantity) {
          toasts.error("Your maximum limit is reached.");
        } else {
          try {
            let data = {
              price: dfaDetail.price / DECIMAL,
              quantity: Number(values?.quantity),
              buyCount: userBuyLimit,
              market: "primary",
            };
            dispatch(saveLatestPrice(data));
            navigate(`/checkout/${id}`);
          } catch (error) {}
        }
      } else {
        setShowClosedDrop(true);
      }
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  const subHanlder = () => {
    if (Number(formik?.values?.quantity) > 1) {
      formik?.setFieldValue("quantity", Number(formik?.values?.quantity) - 1);
    }
  };

  const addHanlder = () => {
    let buyerLimit = dfaDetail.buy_limit - dfaDetail.buy_counter;
    if (buyerLimit > Number(formik?.values?.quantity)) {
      formik?.setFieldValue("quantity", Number(formik?.values?.quantity) + 1);
    } else {
      toasts.error("Your maximum limit is reached.");
    }
  };

  const showToastAndSetValue = (value) => {
    toasts.error(`You can buy only ${value} editions.`);
    setTimeout(() => {
      formik?.setFieldValue("quantity", value);
    }, [1000]);
  };

  const onChangeHandler = (inputQuantity) => {
    let buyerLimit = dfaDetail.buy_limit - dfaDetail.buy_counter;
    // Validate if the entered inputQuantity is a valid number
    let userQuantity = Number(inputQuantity);
    if (/^\d+(\.\d*)?$/.test(userQuantity)) {
      if (userQuantity != "") {
        if (userQuantity > buyerLimit) {
          showToastAndSetValue(buyerLimit);
        } else {
          formik?.setFieldValue("quantity", userQuantity);
        }
      } else {
        formik?.setFieldValue("quantity", "");
      }
    }
  };

  return (
    <>
      <div className={style.openForm}>
        <form onSubmit={formik.handleSubmit}>
          {dfaDetail?.market_type === MARKET_TYPE?.PRIMARY && (
            <div className={style.timerSet}>
              <label>Ends in: </label>
              <h5>
                <Timer
                  startingTimestamp={dfaDetail?.end_time}
                  getDfaDetailByIdHandler={getDfaDetailByIdHandler}
                />
              </h5>
            </div>
          )}
          <div className={style.innerForm}>
            <div className={style.innerFormRightside}>
              <h4 className={style.priceValue}>
                Price:
                <span>
                  {" "}
                  ₹ {getNumberWithComma(dfaDetail.price / DECIMAL)} each
                </span>
              </h4>
              <div className={style.addqantity}>
                <FormikControls
                  variant={`checkField`}
                  className="text-center"
                  label="Add Quantity"
                  control="input"
                  type="text"
                  name="quantity"
                  id="number"
                  formik={formik}
                  onChange={(e) => {
                    onChangeHandler(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.quantity}
                  leftIcon={<SubtractionIcon />}
                  leftIconClick={subHanlder}
                  rightIcon={<PlusIcon />}
                  rightIconClick={addHanlder}
                />
                <h4 className={style.totalAmount}>
                  ₹{" "}
                  {getNumberWithComma(
                    (
                      (dfaDetail?.price / DECIMAL) *
                      Number(formik?.values?.quantity)
                    ).toFixed(2)
                  )}
                </h4>
              </div>

              <CommonBtn
                title="Buy now"
                role="btn"
                className={`${style.btnSize}`}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OpenEditionForm;
