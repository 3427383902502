import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LandingNewArtBg from "../../../Assets/images/LandingNewArtBg.png";
import LandscapeImgBg from "../../../Assets/images/LandscapeImgBg.png";
import logoblack from "../../../Assets/images/logo-black.png";
import NAXCanvaslogoGreen from "../../../Assets/images/NAXCanvaslogoGreen.png";
import PortraitImgBg from "../../../Assets/images/PortraitImgBg.png";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import TableLampBg from "../../../Assets/images/TableLampBg.png";
import style from "./LandingPage.module.scss";
import { Helmet } from "react-helmet";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.landingPage}>
        <Helmet>
          <link rel="canonical" href="https://www.newartx.com/" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Digital Fine Art online Platform | digital painting | NewArtX"
          />
          <meta property="og:url" content="https://www.newartx.com/" />
          <meta
            property="og:image"
            content="https://www.newartx.com/static/media/Nax%20Canvas.1496f78d0023808b36e5.png"
          />
          <meta
            property="og:description"
            content="NewArtX - Unleash your creativity with NewArtX - the platform for digital fine art in India."
          />
        </Helmet>
        <div className={style.leftCard}>
          <Link to="/home">
            <div className={style.innerSec}>
              <div className={style.videoImgsec}>
                <img src={LandingNewArtBg} alt="image" />
                <div className={style.videoData}>
                  <video
                    autoPlay={true}
                    muted
                    loop
                    controls={false}
                    width="100%"
                    height="auto"
                  >
                    <source
                      src={`${process.env.REACT_APP_IMG_BASE_URL}banner/GHEG.mp4`}
                    />
                  </video>
                </div>
              </div>
              <div className={style.textSec}>
                <img src={logoblack} alt="image" />
                <CommonBtn
                  title="Digital Fine Art"
                  className={`black-btn ${style.btnSize}`}
                  role="btn"
                  onClick={() => {
                    navigate("/home");
                  }}
                />
              </div>
            </div>
          </Link>
        </div>
        <div className={style.rightCard}>
          <Link to="/">
            <div className={style.innerSec}>
              <div className={style.videoPart}>
                <div className={style.portrait}>
                  <img src={PortraitImgBg} alt="image" />
                  <div className={style.videoData}>
                    <video
                      autoPlay={true}
                      muted
                      loop
                      controls={false}
                      width="100%"
                      height="auto"
                    >
                      <source
                        src={`${process.env.REACT_APP_IMG_BASE_URL}banner/CSUD.mp4`}
                      />
                    </video>
                  </div>
                </div>
                <div className={style.landscape}>
                  <img src={LandscapeImgBg} alt="image" />
                  <div className={style.videoData}>
                    <video
                      autoPlay={true}
                      muted
                      loop
                      controls={false}
                      width="100%"
                      height="auto"
                    >
                      <source
                        src={`${process.env.REACT_APP_IMG_BASE_URL}banner/CSUDLandscape.mp4`}
                      />
                    </video>
                  </div>
                </div>
              </div>
              <div className={style.textSec}>
                <img src={NAXCanvaslogoGreen} alt="image" />
                <CommonBtn
                  title="Explore Digital Fine Art Frames"
                  className={`${style.btnSize}`}
                  role="btn"
                  onClick={() => {
                    window.open("https://naxcanvas.com/", "__blank");
                  }}
                />
              </div>
            </div>
            <div className={style.tableLampImg}>
              <img src={TableLampBg} alt="lamp-Img" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
