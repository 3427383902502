import React from "react";
import styles from "./Modals.module.scss";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import { useFormik } from "formik";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createCounterOffer } from "../../../../Features/offerSlice/offerSlice";
import { DECIMAL, DIGITAL_ART_TYPE } from "../../../../Constant/userConstant";
import {
  PLATFORM_MAX_VALUE,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";

const CreateCounterOfferModal = ({
  show,
  onHide,
  makeOfferDetails,
  getUserOfferRecievedList,
  offer,
}) => {
  const MakeSchema = Yup.object({
    offer_price: Yup.string()
      .required("Required")
      .test(
        "notGreaterThanValue",
        "*Value is not accepted",
        (value) => value < PLATFORM_MAX_VALUE
      ),
  });

  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: MakeSchema,
    initialValues: {
      offer_price: "",
    },
    onSubmit: (value) => {
      let data = {
        offer_price: parseFloat(value.offer_price),
        id: offer.id, // counter offer id
      };
      createCounterOfferRecieved(data);

      // let offerPrice = offer.offer_price / DECIMAL;
      // if (value.offer_price > offerPrice) {
      //   createCounterOfferRecieved(data);
      // } else {
      //   toasts.error("Please enter amount more than the highest offer");
      // }
    },
  });

  const createCounterOfferRecieved = async (data) => {
    try {
      let response = await dispatch(createCounterOffer(data));
      if (response) {
        onHide();

        getUserOfferRecievedList();
      }
    } catch (err) {
      onHide();
    }
  };

  return (
    <>
      <div className={styles.modalsall}>
        <CommonModal
          backdropclass="fade_clrbg"
          className={styles.offerModal}
          show={show}
          onHide={onHide}
        >
          <div className="d-flex flex-md-row flex-column-reverse">
            <div className={styles.offerModalLeft}>
              <div className={styles.offerModalLeftInner}>
                <img
                  src={`${process.env.REACT_APP_IMG_BASE_URL}${makeOfferDetails.thumbnail_image}`}
                />
              </div>
              <span className={styles.bottomLine} />
            </div>
            <div className={styles.offerModalRight}>
              <h3 title={makeOfferDetails.art_name}>
                {sliceChar(makeOfferDetails.art_name, 25)}
              </h3>
              <div className={styles.artistDetails}>
                <h4>@{makeOfferDetails?.username}</h4>
                {makeOfferDetails.drop_type != DIGITAL_ART_TYPE.Auction && (
                  <div className={styles.artistDetailsEdition}>
                    <p>EDITION</p>
                    <span>
                      {offer?.edition_no} / {makeOfferDetails?.no_of_editions}
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.pricelist}>
                <h6>
                  Selling Price:{" "}
                  <span>
                    {makeOfferDetails.selling_price
                      ? "₹ " +
                        getNumberWithComma(
                          makeOfferDetails.selling_price / DECIMAL
                        )
                      : "--"}
                  </span>
                </h6>
                <h6 className={styles.highvalue}>
                  Highest Offer:{" "}
                  {/* <span> ₹{getHighestOffer(makeOfferDetails)}</span> */}
                  <span>
                    {makeOfferDetails.highest_price
                      ? "₹ " +
                        getNumberWithComma(
                          makeOfferDetails.highest_price / DECIMAL
                        )
                      : "--"}
                  </span>
                </h6>
              </div>
              <div className={styles.editionDetails}>
                <form onSubmit={formik.handleSubmit}>
                  <FormikControls
                    className={styles.select_changes}
                    control="input"
                    type="text"
                    name="offer_price"
                    id="offer_price"
                    placeholder="Enter here"
                    onChange={(e) => {
                      //regex for only digit
                      if (
                        /^\d+(\.\d*)?$/.test(e.target.value) ||
                        e.target.value == ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.offer_price}
                    formik={formik}
                  />

                  <div className={styles.buy_btn_box}>
                    <CommonBtn
                      role="btn"
                      title="MAKE OFFER"
                      className={`${styles.buy_btn} btn-yellow`}
                      type="submit"
                    />
                  </div>
                </form>
              </div>

              <span className={styles.bottomLine} />
            </div>
          </div>
        </CommonModal>
      </div>
    </>
  );
};

export default CreateCounterOfferModal;
