import React from "react";
import CommonModal from "../../../../Common/Ui/CommonModal/CommonModal";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import style from "./indexModals.module.scss";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import { toasts } from "../../../../Common/Toast/Toast";
import { confirmTransaction } from "../../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";

const AddressQrModal = ({ show, onHide, importData }) => {
  let dispatch = useDispatch();
  const confirmTransactionHandler = () => {
    let data = {
      importId: importData.importId,
    };
    try {
      const response = dispatch(confirmTransaction(data));
      onHide();
    } catch (error) {}
  };
  return (
    <>
      <div className={style.import_modal}>
        <CommonModal className={style.modal_Import} show={show} onHide={onHide}>
          <div className={style.feilds}>
            <QRCode value={importData?.address} />
            <form className="mt-20">
              <FormikControls
                control="input"
                type="text"
                name="walletAdress"
                value={importData?.address}
              />
              <div className={style.noteInfo}>
                <label>Note :</label>
                <p>
                  Please click "Confirm Transaction" once you've transferred the
                  NFT to the wallet address provided.
                </p>
              </div>
              <CopyToClipboard
                text={importData?.address}
                onCopy={() => toasts.info("Wallet address is copied.")}
              >
                <CommonBtn
                  role="btn"
                  type={"button"}
                  title="Copy Wallet Address"
                  className={`btn-yellow ${style.btnaddress}`}
                />
              </CopyToClipboard>
              <CommonBtn
                role="btn"
                type={"button"}
                title="Confirm Transaction"
                onClick={() => confirmTransactionHandler()}
                className={`btn-yellow ${style.btnaddress}`}
              />
            </form>
          </div>
        </CommonModal>
      </div>
    </>
  );
};

export default AddressQrModal;
