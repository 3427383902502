import React from "react";
import CommonModal from "../Ui/CommonModal/CommonModal";
import style from "./Feature_Modal.module.scss";
import {
  checkFeatureImageRuleBase,
  checkFileType,
} from "../../../Helper/common";
import { store } from "../../../App/store";
import { ORIENTATION } from "../../../Constant/userConstant";
import PropTypes from "prop-types";

const getImageSection = (item) => {
  let type = checkFileType(
    `${process.env.REACT_APP_IMG_BASE_URL}${item?.art_image}`
  );
  if (Object.keys(item?.orientationMedia)?.length) {
    if (store?.getState()?.users?.orientation === ORIENTATION.LANDSCAPE) {
      type = checkFileType(
        `${process.env.REACT_APP_IMG_BASE_URL}${item?.orientationMedia?.landscape}`
      );
    } else {
      type = checkFileType(
        `${process.env.REACT_APP_IMG_BASE_URL}${item?.orientationMedia?.portrait}`
      );
    }
  }

  if (type == "MP4" || type == "OGG") {
    return (
      <video loop controls={true} autoPlay controlsList="nodownload">
        <source src={checkFeatureImageRuleBase(item)} type="video/mp4" />
      </video>
    );
  } else {
    return <img src={checkFeatureImageRuleBase(item)} alt="art_imgs" />;
  }
};

const Feature_Modal = ({ show, handleClose, artDetail, children }) => {
  return (
      <CommonModal
        centered
        className="feature_Modal"
        show={show}
        onHide={handleClose}
      >
        <div className={style.feature_Modal_body}>
          {getImageSection(artDetail)}
        </div>
      </CommonModal>
  );
};


Feature_Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  artDetail: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default Feature_Modal;
