import React from "react";
import Checkbox from "./Checkbox";
import DatePicker from "./Date/DatePicker";
import Input from "./Input/Input";
import Textarea from "./TextArea/Textarea";
import "./FormikControls.scss";
import Search from "./Search/Search";
import Password from "./Password/Password";
import Phone from "./Phone/Phone";
import Togglebtn from "./Togglebtn";
import CommonSelect from "./Select/CommonSelect";
import Daterange from "./Daterange/Daterange";
import Radio from "./Radio";

function FormikControls(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "search":
      return <Search {...rest} />;
    case "password":
      return <Password {...rest} />;
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <CommonSelect {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "radio":
      return <Radio {...rest} />;
    case "phone":
      return <Phone {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "toggle":
      return <Togglebtn {...rest} />;
    case "daterange":
      return <Daterange {...rest} />;
    default:
      return null;
  }
}

export default FormikControls;
