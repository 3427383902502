import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import ArtImagesleft from "./Component/ArtImagesleft/ArtImagesleft";
import DfaCreatorInfo from "./Component/DfaCreatorInfo/DfaCreatorInfo";
import style from "./DigitalFineArtDetailPage.module.scss";
import ArtDetails from "./Component/ArtDetails/ArtDetails";
import DfabuyForm from "./Component/DfabuyForm/DfabuyForm";
import DfaActivityTable from "./Component/DfaTable/DfaActivityTable";
import {
  getDeviceDetailList,
  getDfaActivityById,
  getDfaDetailById,
} from "../../../Features/marketplace/marketplaceSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  followUnfollowArt,
  openPopUp,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import {
  DECIMAL,
  DROP_TYPE,
  MARKET_TYPE,
} from "../../../Constant/userConstant";
import creatorimg from "../../../Assets/images/creator-img.png";
import MakeOffermodal from "../../Private/Userprofile/Artowned/components/MakeOffermodal/MakeOffermodal";
import ClosedDropModal from "../ClosedDropModal/ClosedDropModal";
import MakeGlobalOfferModal from "../../Private/Userprofile/Artowned/components/MakeOffermodal/MakeGlobalOfferModal";
import NotFound404 from "../../Common/NotFound404/NotFound404";
import { sliceChar } from "../../../Helper/common";
import { Helmet } from "react-helmet";

const limitOption = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
];

const filterOption = [
  { title: "All", value: "ALL" },
  { title: "Available for Secondary Sale", value: "SECONDARY" },
  { title: "Accepting Offers", value: "OFFERS" },
];

const sortOption = [
  { title: "Last Sale Price - Low to High", value: "last_sale_asc" },
  { title: "Last Sale Price - High to Low", value: "last_sale_desc" },
  { title: "Current Price - Low to High", value: "current_price_asc" },
  { title: "Current Price - High to Low", value: "current_price_desc" },
];

const DigitalFineArtDetailPage = () => {
  const artimgprofile = [
    { profileImg: creatorimg },
    { profileImg: creatorimg },
    { profileImg: creatorimg },
    { profileImg: creatorimg },
    { profileImg: creatorimg },
    { profileImg: creatorimg },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const latLong = useSelector((state) => state?.users?.latLong);
  const [dfaDetail, setDfaDetail] = useState({});
  const [dfaActivity, setDfaActivity] = useState({});
  const [activityPage, setActivityPage] = useState(1);
  const [tab, setTab] = useState("winner");
  const [limit, setLimit] = useState(limitOption[0]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedSortBy, setSelectedSortBy] = useState({});
  const [makeOfferDetails, setMakeOfferDetails] = useState({});
  const [deviceList, setDeviceList] = useState([]);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showGlobalOfferModal, setShowGlobalOfferModal] = useState(false);
  const [showClosedDrop, setShowClosedDrop] = useState(false);
  const [singleAndRankedHighestBidEnded, setSingleAndRankedHighestBidEnded] =
    useState(0);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!isUserLoggedin) {
      getDfaDetailByIdHandler();
    } else if (isUserLoggedin && ArtistId) {
      getDfaDetailByIdHandler();
    }
  }, [id, ArtistId, latLong?.lat, latLong?.long]);

  useEffect(() => {
    const refreshApiInterval = setInterval(() => {
      getDfaDetailByIdHandler(false);
    }, 500000);
    return () => clearInterval(refreshApiInterval);
  }, []);

  useEffect(() => {
    if (Object.keys(dfaDetail).length && !isError) {
      getDfaActivityByIdHandler(dfaDetail);
    }
  }, [dfaDetail, limit, activityPage, tab, selectedFilter, selectedSortBy]);

  useEffect(() => {
    getDeviceDetailListHandler();
  }, [id, ArtistId]);

  const getDfaDetailByIdHandler = async (isLoading) => {
    try {
      let params = {
        id: id,
      };
      const response = await dispatch(getDfaDetailById(params, isLoading));
      let dfaData = response.data;
      setDfaDetail(dfaData);
    } catch (error) {
      let statusCode = error.response.data.status;
      if (statusCode === 404) {
        setIsError(true);
      } else {
        navigate(-1);
      }
    }
  };

  const getDeviceDetailListHandler = async () => {
    let params = {
      id: id,
    };
    try {
      const response = await dispatch(getDeviceDetailList(params));
      setDeviceList(response.data);
    } catch (error) {}
  };

  const getDfaActivityByIdHandler = async (dfaData) => {
    try {
      let params = {
        id: id,
        market: dfaData?.market_type,
      };
      if (selectedFilter?.value) {
        params.filter = selectedFilter?.value;
      }
      if (selectedSortBy?.value) {
        params.sort = selectedSortBy?.value;
      }
      if (
        (dfaData?.market_type === MARKET_TYPE?.SECONDARY &&
          (dfaDetail?.drop_type === DROP_TYPE?.AUCTION ||
            dfaDetail?.drop_type === DROP_TYPE?.RANKED_AUCTION)) ||
        ((dfaData?.market_type === MARKET_TYPE?.SECONDARY ||
          dfaData?.market_type === MARKET_TYPE?.PRIMARY) &&
          (dfaDetail?.drop_type === DROP_TYPE?.FIRST_COME_FIRST_BUY ||
            dfaDetail?.drop_type === DROP_TYPE?.OPEN_EDITIONS ||
            dfaDetail?.drop_type === DROP_TYPE?.RAFFLES))
      ) {
        params.page = activityPage;
        params.size = limit.value;
      }
      if (
        dfaDetail?.market_type === MARKET_TYPE?.SECONDARY &&
        (dfaDetail?.drop_type === DROP_TYPE?.RANKED_AUCTION ||
          dfaDetail?.drop_type === DROP_TYPE?.AUCTION)
      ) {
        if (tab === "otherBidders") {
          params.activity_type = "BIDDERS";
        }
      }
      const response = await dispatch(getDfaActivityById(params));
      let dfaActivityData = response.data;
      setDfaActivity(dfaActivityData);
      if (tab === "winner") {
        setSingleAndRankedHighestBidEnded(
          dfaActivityData?.user_activity[0].bid_amount / DECIMAL
        );
      }
    } catch (error) {}
  };

  const getDropTypeName = useMemo(() => {
    let allDropType = {
      1: "Auction",
      2: "Ranked Auction",
      3: "First Come First Buy",
      4: "Open Edition",
      5: "Raffles",
    };
    return allDropType[dfaDetail?.drop_type];
  }, [dfaDetail]);

  const getDfaStateByTime = () => {
    if (dfaDetail?.market_type === "PRIMARY") {
      return "LIVE";
    } else {
      return "ENDED";
    }
  };

  const followUnfollowArtHandler = async () => {
    if (isUserLoggedin) {
      let data = {
        type: dfaDetail.is_liked == true ? 0 : 1,
        art_id: id,
      };
      try {
        const response = await dispatch(followUnfollowArt(data));
        getDfaDetailByIdHandler(false);
      } catch (error) {}
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  const onclickHandler = (item, categoryType) => {
    if (categoryType === "art") {
      return `/digital-fine-arts/${item?.id}`;
    } else if (categoryType === "artist") {
      return `/profile/${item?.username}?tab=ART_CREATED`;
    }
  };

  const navigateToUser = (username) => {
    navigate(`/profile/${username}`);
  };

  const safeParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return ""; // Return null or an empty object in case of an error
    }
  };

  return (
    <>
      <Helmet>
        <title>{dfaDetail?.title_se}</title>
        <meta name={dfaDetail?.desc_se} content="" />
        {safeParse(dfaDetail?.art_tags?.og)?.ogTitle && (
          <meta property="og:type" content="article" />
        )}
        {safeParse(dfaDetail?.art_tags?.og)?.ogTitle && (
          <meta
            property="og:title"
            content={safeParse(dfaDetail?.art_tags?.og)?.ogTitle || ""}
          />
        )}
        {safeParse(dfaDetail?.art_tags?.og)?.ogUrl && (
          <meta
            property="og:url"
            content={safeParse(dfaDetail?.art_tags?.og)?.ogUrl}
          />
        )}
        {safeParse(dfaDetail?.art_tags?.og)?.ogImage && (
          <meta
            property="og:image"
            content={safeParse(dfaDetail?.art_tags?.og)?.ogImage}
          />
        )}
        {safeParse(dfaDetail?.art_tags?.og)?.ogDescription && (
          <meta
            property="og:description"
            content={safeParse(dfaDetail?.art_tags?.og)?.ogDescription || ""}
          />
        )}

        {safeParse(dfaDetail?.art_tags?.canonical)?.canonicalLink && (
          <link
            rel="canonical"
            href={safeParse(dfaDetail?.art_tags?.canonical)?.canonicalLink}
          />
        )}
      </Helmet>
      {!isError ? (
        <div className={style.newArtpage}>
          <Container>
            <div className={style.newArtpage_innerPart}>
              <div className={style.leftImgcard}>
                <ArtImagesleft
                  portrait={dfaDetail?.is_portrait}
                  dfaDetail={dfaDetail}
                  getDeviceDetailListHandler={getDeviceDetailListHandler}
                  deviceList={deviceList}
                />
              </div>

              <div className={style.rightSideSec}>
                <DfaCreatorInfo
                  userTitle={dfaDetail?.art_name}
                  statusInfo={
                    getDfaStateByTime() === "LIVE" ? (
                      <span className="liveTag">{getDfaStateByTime()}</span>
                    ) : (
                      getDfaStateByTime()
                    )
                  }
                  artType={`${getDropTypeName}`}
                  artistIcon={`${process.env.REACT_APP_IMG_BASE_URL}${dfaDetail?.artist_image}`}
                  navigateUser={() => navigateToUser(dfaDetail?.username)}
                  artistLabel="Artist"
                  artistTitle={`@${sliceChar(dfaDetail?.username, 15)}`}
                  seriesLabel="Series"
                  seriesTitle={dfaDetail?.series_name}
                  artLike={dfaDetail?.is_liked}
                  followUnfollowArtHandler={followUnfollowArtHandler}
                  isSignleArtistArt={true}
                  artimgprofile={artimgprofile}
                  dfaDetail={dfaDetail}
                />
                <ArtDetails
                  dfaDetail={dfaDetail}
                  dfaActivity={dfaActivity}
                  singleAndRankedHighestBidEnded={
                    singleAndRankedHighestBidEnded
                  }
                />
                {dfaDetail?.market_type === MARKET_TYPE?.PRIMARY && (
                  <DfabuyForm
                    dfaDetail={dfaDetail}
                    dfaActivity={dfaActivity}
                    setShowClosedDrop={setShowClosedDrop}
                    getDfaDetailByIdHandler={getDfaDetailByIdHandler}
                  />
                )}
                <DfaActivityTable
                  dfaDetail={dfaDetail}
                  dfaActivity={dfaActivity}
                  setDfaActivity={setDfaActivity}
                  activityPage={activityPage}
                  setActivityPage={setActivityPage}
                  tab={tab}
                  setTab={setTab}
                  limit={limit}
                  setLimit={setLimit}
                  limitOption={limitOption}
                  sortOption={sortOption}
                  selectedSortBy={selectedSortBy}
                  setSelectedSortBy={setSelectedSortBy}
                  filterOption={filterOption}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  setMakeOfferDetails={setMakeOfferDetails}
                  setShowOfferModal={setShowOfferModal}
                  setShowGlobalOfferModal={setShowGlobalOfferModal}
                  navigateToUser={navigateToUser}
                />
              </div>
            </div>
            {showOfferModal && (
              <MakeOffermodal
                show={showOfferModal}
                setShowing={setShowOfferModal}
                makeOfferDetails={makeOfferDetails}
                onHide={() => setShowOfferModal(false)}
                getSamePageDetails={getDfaDetailByIdHandler}
                offerPage={"secondaryPage"}
                id={id}
              />
            )}
            {showGlobalOfferModal && (
              <MakeGlobalOfferModal
                show={showGlobalOfferModal}
                setShowing={setShowGlobalOfferModal}
                makeOfferDetails={dfaDetail}
                onHide={() => setShowGlobalOfferModal(false)}
                getSamePageDetails={getDfaDetailByIdHandler}
                id={id}
              />
            )}
            {showClosedDrop && (
              <ClosedDropModal
                show={showClosedDrop}
                handleClose={() => setShowClosedDrop(false)}
                artDetail={dfaDetail}
                onclickHandler={onclickHandler}
              />
            )}
          </Container>
        </div>
      ) : (
        <NotFound404 />
      )}
    </>
  );
};

export default DigitalFineArtDetailPage;
