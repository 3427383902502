import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ALL_QR_LINKS } from "../../../Constant/Qr_links_constant";

const QrLink = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let path = location.pathname;

  useEffect(() => {
    redirectToParticularLiveArt();
  }, []);

  const redirectToParticularLiveArt = () => {
    switch (path) {
      case ALL_QR_LINKS?.ART_26.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_26.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_27.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_27.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_22.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_22.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_21.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_21.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_30.QR_LINK:
      case "/k-laxma-goud/untitled/untitled-1": {
        navigate(ALL_QR_LINKS?.ART_30.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_31.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_31.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_32.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_32.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_33.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_33.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_34.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_34.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_35.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_35.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_36.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_36.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_37.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_37.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_38.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_38.redirect_link);
        break;
      }
      case ALL_QR_LINKS?.ART_40.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_40.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_60.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_60.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_52.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_52.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_51.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_51.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_39.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_39.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_55.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_55.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_53.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_53.redirect_link);
        break;
      }

      case ALL_QR_LINKS?.ART_54.QR_LINK: {
        navigate(ALL_QR_LINKS?.ART_54.redirect_link);
        break;
      }
      default:
        navigate("/marketplace");
    }
  };

  return <></>;
};

export default QrLink;
