// import { API_HOST } from "../constant";
import { fetch } from "../Helper/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;

const signup = (data) => {
  return fetch("post", `${API_HOST}/users/v1/user/signup`, data, {}, {});
};

const verifySignup = (data) => {
  return fetch("post", `${API_HOST}/users/v1/user/verify_signup`, data, {}, {});
};

const sendOtp = (data) => {
  return fetch("post", `${API_HOST}/users/v1/send_otp`, data, {}, {});
};

const login = (data) => {
  return fetch("post", `${API_HOST}/users/v1/user/login`, data, {}, {});
};

const ForgetPassword = (data) => {
  return fetch("post", `${API_HOST}/users/v1/forget_password`, data, {}, {});
};

const ResetPassword = (data) => {
  return fetch("post", `${API_HOST}/users/v1/reset_password`, data, {}, {});
};
const changePassword = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/update_password`,
    data,
    { token },
    {}
  );
};
const uploadKyc = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/submit_kyc`,
    data,
    { token },
    {}
  );
};
const getDocList = (token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/get_kyc_doc_list`,
    {},
    { token },
    {}
  );
};
const getWalletBalance = (token) => {
  return fetch("get", `${API_HOST}/nft/v1/nft/get_balance`, {}, { token }, {});
};

const getWalletLedger = (data, token) => {
  return fetch("get", `${API_HOST}/nft/v1/nft/get_ledger`, {}, { token }, data);
};

const getKycDetail = (token) => {
  return fetch("get", `${API_HOST}/users/v1/user/get_kyc`, {}, { token }, {});
};
const uploadDoc = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/kyc/doc`,
    data,
    { token },
    {}
  );
};

const Contactus = (data) => {
  return fetch("post", `${API_HOST}/users/v1/user/contact_us`, data, {}, {});
};

const Createus = (data) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/create_with_us`,
    data,
    {},
    {}
  );
};

const logout = (token) => {
  return fetch("get", `${API_HOST}/users/v1/user/logout`, {}, { token }, {});
};

const getTwoFA = (token) => {
  return fetch("get", `${API_HOST}/users/v1/user/2fa/get`, {}, { token }, {});
};

const getUpdate2FA = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/2fa/update`,
    data,
    { token },
    {}
  );
};

const sendotp = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/send_otp`,
    data,
    { token },
    {}
  );
};

const getUserProfile = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/profile`,
    {},
    { token },
    params
  );
};

const getUserLikesData = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/profile/art_metadata`,
    {},
    { token },
    params
  );
};
const updateProfileData = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/profile/edit`,
    data,
    { token },
    {}
  );
};

const addGstDetail = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/user/gst/add`,
    data,
    { token },
    {}
  );
};
const deleteGst = (data, token) => {
  return fetch(
    "delete",
    `${API_HOST}/nft/v1/nft/user/gst/delete/${data.gst_id}`,
    {},
    { token },
    {}
  );
};
const updateGst = (data, token, params) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/user/gst/update/${params.gst_id}`,
    data,
    { token },
    {}
  );
};

const updateGstPrimary = (data, token, params) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/user/gst/primary/${params.gst_id}`,
    data,
    { token },
    {}
  );
};

const getGstList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/user/gst/list`,
    {},
    { token },
    params
  );
};

const getUserArtFollowersLikes = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/profile/user_metadata`,
    {},
    { token },
    params
  );
};
const followUnfollowAndRemove = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/follow`,
    {},
    { token },
    params
  );
};

const getMakeOfferDetail = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/artDetail/id`,
    {},
    { token },
    params
  );
};

const createMakeOffer = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_offer`,
    data,
    { token },
    {}
  );
};

const likeUnlikeArt = (params, token) => {
  return fetch("get", `${API_HOST}/nft/v1/nft/art/like`, {}, { token }, params);
};

const addFunds = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/payment/add_funds`,
    data,
    { token },
    {}
  );
};
const cancelPayment = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/payment/cancel_order`,
    data,
    { token },
    {}
  );
};

const followUnfollowArt = (data, token) => {
  return fetch("get", `${API_HOST}/nft/v1/nft/art/like`, {}, { token }, data);
};

const incremenentViews = (data, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/get_profile`,
    {},
    { token },
    data
  );
};

const Login2FaVerificationAction = (data) => {
  return fetch("post", `${API_HOST}/users/v1/user/verify_login`, data, {}, {});
};

const getLoginOtp = (data) => {
  return fetch("post", `${API_HOST}/users/v1/send_otp`, data, {}, {});
};

const getFollowStatus = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/follow_status`,
    {},
    { token },
    params
  );
};
const artOfArtist = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_art_by_artist`,
    {},
    { token },
    params
  );
};

const updateNotification = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/users/v1/user/update_notification`,
    data,
    { token },
    {}
  );
};

const getNotificationList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_all_notifications`,
    {},
    { token },
    params
  );
};

const clearAllNotificationBox = (token) => {
  return fetch(
    "put",
    `${API_HOST}/nft/v1/nft/clear_all_notifications`,
    {},
    { token },
    {}
  );
};

const getNotificationSettingList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/get_notification`,
    {},
    { token },
    params
  );
};

const getSearchedRecords = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_landing_page_data`,
    {},
    { token },
    params
  );
};

//get Earnings api

const getEarningsList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/artist/earnings`,
    {},
    { token },
    params
  );
};

//Browse Marketplace

const getBrowseMarketplaceList = (token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/browse_marketplace`,
    {},
    { token },
    {}
  );
};

//Art details secondary marketplace

const getSecondaryMarketplaceArtDetail = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/art_detail_for_marketplace`,
    {},
    { token },
    params
  );
};

// make Global Offer

const makeGlobalOffer = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_global_offer`,
    {},
    { token },
    {}
  );
};

const createCounterGlobalOffer = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_counter_global_offer`,

    data,
    { token },
    {}
  );
};

//Get fee detail
const getFeeDetail = (token) => {
  return fetch("get", `${API_HOST}/nft/v1/nft/user/get_fee`, {}, { token }, {});
};

const getAccountActivity = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/artist_request_history`,
    {},
    { token },
    params
  );
};

const artistActivityRequestCancel = (data, token) => {
  return fetch(
    "put",
    `${API_HOST}/users/v1/user/artist_request_cancel`,
    data,
    { token },
    {}
  );
};

const downloadInvoice = (order_id, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/invoice/${order_id}`,
    {},
    { token },
    {}
  );
};

const getCouponList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/coupon_listing`,
    {},
    { token },
    params
  );
};

const couponVerify = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/verify_coupon`,
    data,
    { token },
    {}
  );
};

const getNotify = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/update_event_notified`,
    data,
    { token },
    {}
  );
};

const getNotifiedStatus = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_notified_status?id=${params.id}`,
    {},
    { token },
    {}
  );
};

const markAsRead = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/mark_notification_read`,
    data,
    { token },
    {}
  );
};

const getRecentActivity = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_recent_activities`,
    {},
    { token },
    params
  );
};

const getLeaderBoard = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_leaderboard`,
    {},
    { token },
    params
  );
};
const getExportArtDetail = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/export_art_info`,
    {},
    { token },
    params
  );
};

const externalPrimaryCheckout = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/payment/order_checkout`,
    data,
    { token },
    {}
  );
};

const createExportRequest = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_export_request`,
    data,
    { token },
    {}
  );
};

const createImportRequest = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/create_import_request`,
    data,
    { token },
    {}
  );
};

const confirmTransaction = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/nft_import_transfer_status`,
    data,
    { token },
    {}
  );
};

const externalSecondaryCheckout = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/payment/secondary_checkout`,
    data,
    { token },
    {}
  );
};

const calculateExportFee = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/export/gas_estimate_transfer`,
    data,
    { token },
    {}
  );
};

const paymentIntentClientSecretApi = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/update_Order`,
    data,
    { token },
    {}
  );
};

const emailSubscribe = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/create/subscription`,
    data,
    { token },
    {}
  );
};

const uploadCoverImage = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/users/v1/user/upload/cover_image`,
    data,
    { token },
    {}
  );
};

const redeemArt = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/offline/redeem_art `,
    data,
    { token },
    {}
  );
};

const getImportExportTransactionList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/transactions/listing`,
    {},
    { token },
    params
  );
};

export const userService = {
  getWalletBalance,
  signup,
  verifySignup,
  sendOtp,
  login,
  ForgetPassword,
  ResetPassword,
  changePassword,
  uploadKyc,
  getDocList,
  getKycDetail,
  uploadDoc,
  Contactus,
  Createus,
  logout,
  getTwoFA,
  getUpdate2FA,
  sendotp,
  emailSubscribe,
  getUserProfile,
  getUserLikesData,
  markAsRead,
  addGstDetail,
  getGstList,
  deleteGst,
  updateGst,
  updateProfileData,
  getUserArtFollowersLikes,
  followUnfollowAndRemove,
  getWalletLedger,
  getMakeOfferDetail,
  createMakeOffer,
  likeUnlikeArt,
  addFunds,
  cancelPayment,
  incremenentViews,
  Login2FaVerificationAction,
  getLoginOtp,
  followUnfollowArt,
  getFollowStatus,
  artOfArtist,
  getNotificationSettingList,
  updateNotification,
  getNotificationList,
  getEarningsList,
  getSearchedRecords,
  getBrowseMarketplaceList,
  getSecondaryMarketplaceArtDetail,
  makeGlobalOffer,
  getFeeDetail,
  createCounterGlobalOffer,
  getAccountActivity,
  artistActivityRequestCancel,
  downloadInvoice,
  updateGstPrimary,
  getCouponList,
  couponVerify,
  getNotify,
  getNotifiedStatus,
  getRecentActivity,
  getLeaderBoard,
  externalPrimaryCheckout,
  getExportArtDetail,
  createExportRequest,
  createImportRequest,
  confirmTransaction,
  calculateExportFee,
  paymentIntentClientSecretApi,
  externalSecondaryCheckout,
  uploadCoverImage,
  redeemArt,
  getImportExportTransactionList,
  clearAllNotificationBox,
};
