import React from 'react';
import { Accordion } from 'react-bootstrap';
import { ArrowRight } from '../../../../Assets/svgIcons/SVGicon';
import './FaqItem.scss';

const FaqItem = (props) => {
    const { eventKey, title, content } = props;
    return (
        <>
            <Accordion.Item eventKey={eventKey}>
                <Accordion.Header>{title} <span className='accordion-arrow'><ArrowRight /></span></Accordion.Header>
                <Accordion.Body>{content}</Accordion.Body>
            </Accordion.Item>
        </>
    )
}

export default FaqItem
