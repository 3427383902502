import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import { useDispatch, useSelector } from "react-redux";
import {
  getFee,
  sellNftMarketPlace,
  sellNow,
} from "../../../../Features/drop/dropSlice";
import {
  PLATFORM_MAX_VALUE,
  checkFileType,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import { DECIMAL, DIGITAL_ART_TYPE } from "../../../../Constant/userConstant";
import { useNavigate } from "react-router-dom";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";
import style from "./Sellnowmodal.module.scss";

const Sellnowmodal = ({ show, onHide, sellItem, getUserLikes }) => {
  const dispatch = useDispatch();

  const [gstPercentages, setGstPercentages] = useState([]);
  const [serviceCharges, setServiceCharges] = useState([]);
  const [gstService, setGstService] = useState([]);
  const [serviceChargePrice, setServiceChargePrice] = useState("");
  const [baseArtPrice, setBaseArtPrice] = useState("");
  const [gstPrice, setGstPrice] = useState("");
  const [baseArtPrices, setBaseArtPrices] = useState("");

  const navigate = useNavigate();

  const MakeSchema = Yup.object({
    selling_price: Yup.string()
      // .positive("*Selling price must be greater than 0")
      .required("*Selling price is required")
      .test(
        "greaterThanZero",
        "*Selling price must be greater than 0",
        (value) => {
          return value != 0;
        }
      )
      .test(
        "not-ending-with-decimal",
        "*Value should not end with a decimal point",
        (value) => {
          if (!isNaN(value) && value !== parseInt(value, 10)) {
            return !/\.+$/.test(value.toString());
          }
          return true;
        }
      )
      .test(
        "notGreaterThanValue",
        "*Value is not accepted",
        (value) => value < PLATFORM_MAX_VALUE
      ),
    // .max(
    //   10000000,
    //   "*Selling price must be less than or equal to 1,00,00,000 "
    // ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: MakeSchema,
    initialValues: {
      selling_price: "",
    },
    onSubmit: (value) => {
      value["art_id"] = sellItem.art_id;
      value["edition_number"] = sellItem.user_owned_no_of_editions;
      sellNftorder(value);
    },
  });

  const sellNftorder = async (data) => {
    try {
      let response = await dispatch(sellNftMarketPlace(data));
      if (response) {
        onHide();
        formik.resetForm();
        getUserLikes();
      }
    } catch (err) {}
  };

  useEffect(() => {
    getSellNow();
  }, []);

  useEffect(() => {
    const result = removeGstFromBaseAmount(
      formik.values.selling_price,
      gstPercentages
    );
  }, [formik.values.selling_price, gstPercentages]);

  const getSellNow = async () => {
    try {
      const response = await dispatch(getFee());
      let feeList = response?.data?.data[0];
      setGstPercentages(feeList?.gst_on_assets / DECIMAL);
      setServiceCharges(feeList?.service_charges / DECIMAL);
      setGstService(feeList?.gst_on_service / DECIMAL);
    } catch (error) {}
  };

  const removeGstFromBaseAmount = async (totalAmount, gstPercentage) => {
    let sellPrice = Number(totalAmount);
    if (totalAmount !== "") {
      let gstAmount = sellPrice - sellPrice * (100 / (100 + gstPercentage));
      gstAmount = Number(gstAmount.toFixed(2));

      let artBasePrice = sellPrice - gstAmount;
      setGstPrice(gstAmount);
      setBaseArtPrices(artBasePrice);

      let serviceChargeCalculation = artBasePrice * (serviceCharges / 100);

      let newArtxGSTCalculation = serviceChargeCalculation * (gstService / 100);

      serviceChargeCalculation =
        Number(serviceChargeCalculation) + Number(newArtxGSTCalculation);
      setServiceChargePrice(serviceChargeCalculation.toFixed(2));

      let baseArtPriceCalculation = artBasePrice - serviceChargeCalculation;
      setBaseArtPrice(baseArtPriceCalculation.toFixed(2));
      return {
        gstAmount,
        artBasePrice,
      };
    }
  };

  const redirect = () => {
    navigate(`/profile/${sellItem?.username}`);
    onHide();
  };

  const getFetureFileSection = (src) => {
    let type = checkFileType(src);
    if (type == "MP4" || type == "OGG") {
      return (
        <video loop autoPlay muted controlsList="nodownload" key={src}>
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return <img src={src} alt="Dfa-Image" />;
    }
  };

  return (
    <>
      <CommonModal
        className={`${style.offerModal} ${
          sellItem?.is_portrait ? `portraitModal` : `landscapeModal`
        }`}
        centered
        show={show}
        onHide={onHide}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-md-row flex-column-reverse">
            <div
              className={`${style.offerModalLeft} ${
                sellItem?.is_portrait ? `portraitLeft` : `landscapeLeft`
              }`}
            >
              <div
                className={`${style.offerModalLeftInner} ${
                  sellItem?.is_portrait ? `portraitImg` : `landscapeImg`
                }`}
              >
                {getFetureFileSection(
                  `${process.env.REACT_APP_IMG_BASE_URL}${sellItem?.feature_file}`
                )}
                {/* <img src={collectionbg} alt="landscape" /> */}
              </div>
            </div>
            <div
              className={`${style.offerModalRight} ${
                sellItem?.is_portrait ? `portraitRight` : `landscapeRight`
              }`}
            >
              <div className={style.artistDetails}>
                <h6 className="cursor-pointer" onClick={redirect}>
                  @{sellItem?.username}
                </h6>
                <h3 title={sellItem?.art_name}>
                  {sliceChar(sellItem?.art_name, 75)}
                </h3>
                <div className={style.artistDetailsEdition}>
                  <p>Edition :</p>
                  <span>
                    #{sellItem?.user_owned_no_of_editions} of{" "}
                    {sellItem?.drop_type == DIGITAL_ART_TYPE.OpenEdition
                      ? sellItem?.sold_no_of_editions
                      : sellItem?.total_no_of_editions}
                  </span>
                </div>
              </div>
              <ul className={style.pricelist}>
                <li>
                  <h6> Purchase Price </h6>
                  <span>
                    {sellItem?.last_buy_price
                      ? `₹ 
                  ${getNumberWithComma(sellItem?.last_buy_price / DECIMAL)}`
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6> Last Sale Price </h6>
                  <span title={sellItem?.last_sell_price}>
                    {sellItem?.last_sell_price
                      ? `₹ ${getNumberWithComma(
                          sellItem?.last_sell_price / DECIMAL
                        )}`
                      : "--"}
                  </span>
                </li>
                <li className={style.highvalue}>
                  <h6> Highest Offer </h6>
                  <span>
                    {sellItem?.highest_offer_price
                      ? `₹ ${getNumberWithComma(
                          sellItem?.highest_offer_price / DECIMAL
                        )}`
                      : "--"}
                  </span>
                </li>
              </ul>
              <div className={style.editionDetails}>
                <div className={style.enter_text}>
                  <label>Enter Sale Price</label>
                  <p>(Inclusive of GST)</p>
                </div>
                <FormikControls
                  control="input"
                  type="text"
                  name="selling_price"
                  id="selling_price"
                  placeholder="Enter Price"
                  leftIcon="₹ "
                  onChange={(e) => {
                    //regex for only digit
                    if (
                      /^\d+(\.\d{0,2})?$/.test(e.target.value) ||
                      e.target.value == ""
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.selling_price}
                  formik={formik}
                  maxLength={18}
                />
              </div>
              <ul className={style.pricelist}>
                <li>
                  <label>Basic Price </label>
                  <span title={Number(baseArtPrices).toFixed(2)}>
                    {formik.values.selling_price !== ""
                      ? `₹ ${sliceChar(
                          getNumberWithComma(Number(baseArtPrices).toFixed(2)),
                          30
                        )}`
                      : "--"}
                  </span>
                </li>
                <li>
                  <label>Applicable GST</label>
                  <span title={Number(gstPrice).toFixed(2)}>
                    {" "}
                    {formik.values.selling_price !== ""
                      ? `₹ ${sliceChar(
                          getNumberWithComma(Number(gstPrice).toFixed(2)),
                          30
                        )}`
                      : "--"}
                  </span>
                </li>
                <li>
                  <label>Service Charge</label>
                  <span title={Number(serviceChargePrice).toFixed(2)}>
                    {formik.values.selling_price !== ""
                      ? `₹ ${sliceChar(
                          getNumberWithComma(
                            Number(serviceChargePrice).toFixed(2)
                          ),
                          30
                        )}`
                      : "--"}
                  </span>
                </li>
                <p>(Inclusive of Artist Royalty & Applicable Taxes)</p>
              </ul>
              <div className={style.receive_payment}>
                <h6>
                  <label>You'll receive </label>
                  <span title={Number(baseArtPrice).toFixed(2)}>
                    {" "}
                    {formik.values.selling_price !== ""
                      ? `₹ ${sliceChar(
                          getNumberWithComma(Number(baseArtPrice).toFixed(2)),
                          30
                        )}`
                      : "--"}
                  </span>
                </h6>
              </div>
              {/* <p className={style.paragraph_text}>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </p> */}
              <div className={style.buy_btn_box}>
                <CommonBtn
                  role="btn"
                  title="Sell Now"
                  className={`${style.buy_btn} `}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default Sellnowmodal;
