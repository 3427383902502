import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import {
  PlusIcon,
  SubtractionIcon,
} from "../../../../../Assets/svgIcons/SVGicon";
import { indianStates } from "../../../../../Constant/State";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import * as Yup from "yup";
import style from "./CheckoutDetails.module.scss";
import { parsePhoneNumber } from "react-phone-number-input";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatDateActivityTable,
  getNumberWithComma,
  sliceChar,
} from "../../../../../Helper/common";
import { toasts } from "../../../../Common/Toast/Toast";
import { inputFieldValidation } from "../../../../../Constant/formikConstant";
import { DECIMAL, DROP_TYPE } from "../../../../../Constant/userConstant";
import {
  cancelPayment,
  couponVerify,
  externalPrimaryCheckout,
  externalSecondaryCheckout,
  getCouponList,
  getGstList,
  getWalletBalance,
  saveLatestPrice,
} from "../../../../../Features/user/userSlice";
import AvailableCouponModal from "../../../../Common/Header/AvailableCouponModal/AvailableCouponModal";
import { useDebounce } from "../../../../../customHooks/useDebounce";
import {
  buyArt,
  placeOrderByCurated,
} from "../../../../../Features/marketplace/marketplaceSlice";
import NewartxLogo from "../../../../../Assets/images/newArtX_Logo_Img.png";

const CheckoutDetails = ({ artDetail }) => {
  const [showCoupon, setShowCoupon] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const latestPrice = useSelector((state) => state?.users?.latestPrice);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const loading = useSelector((state) => state?.loading?.loading);

  const [phoneCountryCode, setPhoneNumberCountryCode] = useState("");
  const [couponList, setCouponList] = useState([]);
  const [userWalletBalance, setUserWalletBalance] = useState("");
  const [gstOption, setGstOption] = useState([]);
  const [finalAmount, setFinalAmount] = useState("");
  const [discountAmountData, setDiscountAmountData] = useState({});
  const [isCouponVerify, setIsCouponVerify] = useState(false);

  const validationSchema = Yup.object({
    quantity: Yup.string()
      .required("Enter Quantity")
      .test("isQuantityZero", "*Quantity Should not be 0", (values) => {
        return values != 0;
      }),
    checkAddGstCheckbox: Yup.boolean(),

    gstSelect: Yup.object()
      .when("checkAddGstCheckbox", {
        is: true,
        then: Yup.object().required(
          inputFieldValidation.commonMessage.required("GST")
        ),
        otherwise: Yup.object(),
      })
      .nullable(),
    state: Yup.object()
      .when("gstSelect", {
        is: (value) =>
          value &&
          value.value === "newGst" &&
          formik?.values.checkAddGstCheckbox,
        then: Yup.object().required(
          inputFieldValidation.commonMessage.required("State")
        ),
        otherwise: Yup.object(), // Return empty object when checkAddGstCheckbox is false
      })
      .nullable(),
    gstnumber: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string()
        .matches(/^\S+$/, "*Space is not allowed")
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "*Only alphanumeric characters and spaces are allowed"
        )
        .min(
          inputFieldValidation.gst.minLength,
          `*Must be of ${inputFieldValidation.gst.minLength} digit`
        )
        .max(
          inputFieldValidation.gst.maxLength,
          `*Must be of ${inputFieldValidation.gst.minLength} digit`
        )
        .required(inputFieldValidation.commonMessage.required("GST number")),
      otherwise: Yup.string(),
    }),
    legalBusinessName: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "*Only alphanumeric characters and spaces are allowed"
        )
        .matches(
          inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
          "*Business name should not start or end with a space"
        )
        .min(
          inputFieldValidation.businessName.minLength,
          `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
        )
        .max(
          inputFieldValidation.businessName.maxLength,
          `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
        )
        .required(inputFieldValidation.commonMessage.required("Business name")),
      otherwise: Yup.string(),
    }),
    addressLine1: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string()
        .matches(
          inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
          "*Address should not start or end with a space"
        )
        .min(
          inputFieldValidation.address.minLength,
          `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
        )
        .max(
          inputFieldValidation.address.maxLength,
          `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
        )
        .required(inputFieldValidation.commonMessage.required("Address")),
      otherwise: Yup.string(),
    }),
    addressLine2: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string()
        .matches(
          inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
          "*Address should not start or end with a space"
        )
        .min(
          inputFieldValidation.address.minLength,
          `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
        )
        .max(
          inputFieldValidation.address.maxLength,
          `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
        )
        .required(inputFieldValidation.commonMessage.required("Address")),
      otherwise: Yup.string(),
    }),
    city: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string().required(
        inputFieldValidation.commonMessage.required("City")
      ),
      otherwise: Yup.string(),
    }),
    pinCode: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string()
        .required(inputFieldValidation.commonMessage.required("Pin code"))
        .matches(/^[1-9]\d{5}$/, "*Invalid PIN code"),
      otherwise: Yup.string(),
    }),
    phoneNumber: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string()
        .required(inputFieldValidation.commonMessage.required("Phone number"))
        .phone(phoneCountryCode, true, "*Invalid phone number"),
      otherwise: Yup.string(),
    }),
    email: Yup.string().when("gstSelect", {
      is: (value) =>
        value && value.value === "newGst" && formik?.values.checkAddGstCheckbox,
      then: Yup.string()
        .matches(
          inputFieldValidation.email.regexForSingup.expresionForSingup,
          inputFieldValidation.email.regexForSingup.errorMessageForSingup
        )
        .required(inputFieldValidation.commonMessage.required("Email")),
      otherwise: Yup.string(),
    }),
    checkCouponCheckbox: Yup.boolean(),
    couponCode: Yup.string().when("checkCouponCheckbox", {
      is: true,
      then: Yup.string()
        .matches(
          inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
          "*Coupon Code should not start or end with a space"
        )
        .min(inputFieldValidation.coupon_code.minLength, `*Invalid Coupon`)
        .max(inputFieldValidation.coupon_code.maxLength, `*Invalid Coupon`)
        .required(inputFieldValidation.commonMessage.required("Coupon Code")),
      otherwise: Yup.string(),
    }),
  });

  const initialValue = {
    legalBusinessName: "",
    gstnumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pinCode: "",
    phoneNumber: "",
    checkAddGstCheckbox: false,
    checkCouponCheckbox: false,
    checkGstProfileCheckbox: false,
    couponCode: "",
    gstSelect: "",
    email: "",
    quantity: latestPrice?.quantity,
    internalPaymentCheck: true,
    externalPaymentCheck: false,
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {
      let data = {
        art_id: id,
        amount: Number(latestPrice?.price),
        is_gst_info: 0,
      };

      if (formik.values?.checkAddGstCheckbox) {
        if (formik?.values?.gstSelect.value === "newGst") {
          data.is_gst_info = 1;
          data.gst_info = {
            gst_number: formik?.values.gstnumber,
            business_name: formik?.values.legalBusinessName,
            country_code: `+${
              parsePhoneNumber(formik?.values?.phoneNumber)?.countryCallingCode
            }`,
            mobile: parsePhoneNumber(formik?.values?.phoneNumber)
              ?.nationalNumber,
            // email: formik?.values.email,
            address_line_1: formik?.values.addressLine1,
            address_line_2: formik?.values.addressLine2,
            city: formik?.values.city,
            state: formik?.values.state.value,
            pincode: formik?.values.pinCode,
            is_add_on_profile: formik?.values?.checkGstProfileCheckbox ? 1 : 0,
          };
        } else {
          data.buyer_gst_id = formik?.values?.gstSelect.value;
        }
      }
      if (
        formik.values.internalPaymentCheck &&
        latestPrice.market == "primary"
      ) {
        checkoutPrimaryHandler(data);
      } else if (
        formik.values.internalPaymentCheck &&
        latestPrice.market == "secondary"
      ) {
        checkoutSecondaryHanlder(data);
      } else if (
        formik.values.externalPaymentCheck &&
        latestPrice.market == "primary"
      ) {
        externalPrimaryCheckoutHandler(data);
      } else if (
        formik.values.externalPaymentCheck &&
        latestPrice.market == "secondary"
      ) {
        externalSecondaryCheckoutHandler(data);
      }
    },
  });

  useEffect(() => {
    // Load the Razorpay script dynamically
    if (formik.values?.externalPaymentCheck) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [formik.values?.externalPaymentCheck]);

  useEffect(() => {
    if (latestPrice?.price > 0 && id) {
      getUserBalance();
      getCouponListFunction();
      getGstListHandler();
    } else {
      navigate(-1);
      dispatch(saveLatestPrice({}));
    }
  }, []);

  useEffect(() => {
    if (isCouponVerify) {
      couponVerifyFunction();
    }
  }, [formik.values.quantity]);

  useEffect(() => {
    //checking if coupon is airdrop than art price become zero so preveting user to do payment of zero rs by external mode.
    if (Number(finalAmount) === 0) {
      formik.setFieldValue("externalPaymentCheck", false);
      formik.setFieldValue("internalPaymentCheck", true);
    }
  }, [isCouponVerify]);

  let checkoutPrimaryHandler = async (data) => {
    data.quantity = formik?.values?.quantity;
    if (latestPrice.hasOwnProperty("is_max_limit")) {
      data["is_max_limit"] = latestPrice?.is_max_limit;
    }
    data.is_internal_wallet = 0;
    if (latestPrice?.market === "primary") {
      data.coupon_code = formik.values.couponCode;
    }
    try {
      await dispatch(placeOrderByCurated(data));
      if (formik?.values.internalPaymentCheck) {
        navigate(-1);
      }
    } catch (err) {
      // navigate(-1);
    }
  };

  let checkoutSecondaryHanlder = async (data) => {
    data.edition_number = latestPrice.editionNumber;
    data.is_internal_wallet = 0;
    try {
      await dispatch(buyArt(data));
      if (formik?.values.internalPaymentCheck) {
        navigate(-1);
      }
    } catch (err) {}
  };

  const externalPrimaryCheckoutHandler = async (data) => {
    data.orderType = 0;
    data.coupon_code = formik.values.code;
    data.quantity = formik?.values?.quantity;
    if (latestPrice.hasOwnProperty("is_max_limit")) {
      data["is_max_limit"] = latestPrice?.is_max_limit;
    }
    data.is_internal_wallet = 1;
    try {
      let response = await dispatch(externalPrimaryCheckout(data));
      handleRazorPayPayment(response.data.data);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  //secondary
  const externalSecondaryCheckoutHandler = async (data) => {
    data.edition_number = latestPrice.editionNumber;
    data.orderType = 1;
    data.quantity = 1; //as per backend static quantity send for secondary
    data.is_internal_wallet = 1;
    try {
      let response = await dispatch(externalSecondaryCheckout(data));
      handleRazorPayPayment(response.data.data);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const handleRazorPayPayment = async (values) => {
    let amount =
      latestPrice.market == "primary"
        ? Number(latestPrice?.price) * 100 * Number(formik.values.quantity)
        : Number(latestPrice?.price) * 100;
    // Create an instance of Razorpay with options
    const rzp = new window.Razorpay({
      key: process.env.REACT_APP_RAZAOR_PAY, // Replace with your Razorpay Key ID
      amount: amount, // Amount is in currency subunits (paise)
      currency: "INR",
      name: "Newartx", // Your business name
      description: "Testing",
      image: NewartxLogo,
      order_id: values?.id,
      timeout: 300,
      theme: {
        color: "#51bc89", //"#3399cc",
      },
      handler: function (response) {
        document.body.style.overflow = "visible";
        if (response.razorpay_payment_id) {
          navigate(`/digital-fine-arts/${id}`);
        }
        // Add any additional logic here after payment success
      },
      // callback_url: "https://newartx.com//", // Your callback URL
      config: {
        display: {
          hide: [{ method: "paylater" }, { method: "wallet" }],
          preferences: { show_default_blocks: true },
        },
      },
      retry: {
        enabled: false,
      },
      modal: {
        ondismiss: function (error) {
          document.body.style.overflow = "visible";
          let data = {
            orderId: values?.id,
          };
          dispatch(cancelPayment(data));
          if (error?.error?.description) {
            toasts.error(error?.error?.description);
          }
          navigate(`/digital-fine-arts/${id}`);
        },
      },
    });
    // Open the payment modal
    rzp.open();
  };

  const couponVerifyFunction = async (values) => {
    try {
      let data = {
        coupon_code: values ? values : formik.values.couponCode,
        amount: latestPrice?.price * Number(formik.values.quantity),
        art_id: artDetail.id,
        quantity: formik.values.quantity,
      };
      const response = await dispatch(couponVerify(data));
      setFinalAmount(response.data.data.final_amount);
      setDiscountAmountData(response.data.data);
      if (response?.status == 200) {
        setIsCouponVerify(true);
      } else {
        setIsCouponVerify(false);
      }
    } catch (err) {
      setIsCouponVerify(false);
    }
  };

  const getCouponListFunction = async () => {
    let params = {
      art_id: id,
    };
    try {
      let response = await dispatch(getCouponList(params));
      setCouponList(response?.data?.list);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const getUserBalance = async () => {
    try {
      const response = await dispatch(getWalletBalance());
      setUserWalletBalance(response.data.data.user_fiat_account.balance);
    } catch (error) {}
  };

  const getGstListHandler = async () => {
    let params = {};
    try {
      const response = await dispatch(getGstList(params));
      let option = response.data?.data?.list.map((item) => {
        return { value: item?.gst_id, label: item?.gst_number };
      });
      let filterOption = option.filter((item) => item.label != "");
      filterOption.push({ label: "Add New GST", value: "newGst" });
      setGstOption(filterOption);
    } catch (error) {}
  };

  const onChangeHandler = (quantity) => {
    if (quantity != "") {
      if (/^-?\d+$/.test(quantity)) {
        formik.setFieldValue("quantity", quantity);
        if (quantity > latestPrice?.buyCount) {
          if (latestPrice?.buyCount == 0) {
            toasts.error("Your maximum limit is reached.");
            setTimeout(() => {
              formik.setFieldValue("quantity", "");
            }, [1000]);
          } else {
            setTimeout(() => {
              formik.setFieldValue("quantity", "");
            }, [500]);
            toasts.error(
              `You can buy only ${latestPrice.buyCount} ${
                latestPrice.dropType ? `entries` : `editions`
              }.`
            );
          }
        }
      }
    } else {
      formik.setFieldValue("quantity", "");
    }
  };

  const subHanlder = () => {
    if (Number(formik.values.quantity) > 1) {
      formik.setFieldValue("quantity", Number(formik.values.quantity) - 1);
    }
  };

  const addHanlder = () => {
    if (latestPrice.buyCount > Number(formik.values.quantity)) {
      formik.setFieldValue("quantity", Number(formik.values.quantity) + 1);
    } else {
      toasts.error("Your maximum limit is reached.");
    }
  };

  const getDiscountAmount = () => {
    if (discountAmountData.coupon_type == "AIRDROP") {
      return getNumberWithComma(
        (discountAmountData.discount_amount / DECIMAL).toFixed(2)
      );
    } else if (discountAmountData.coupon_usage == "MULTIPLE") {
      return getNumberWithComma(
        (discountAmountData.discount_amount / DECIMAL).toFixed(2)
      );
    } else if (discountAmountData.coupon_usage == "SINGLE") {
      return getNumberWithComma(
        (discountAmountData.discount_amount / DECIMAL).toFixed(2)
      );
    } else {
      return 0;
    }
  };

  const onChangeSelectBoxHandler = (checkboxType) => {
    if (checkboxType === "coupon") {
      formik.setFieldValue("couponCode", "");
      formik.setFieldTouched("couponCode", false);
    } else if (checkboxType === "gst") {
      formik.setFieldValue("gstSelect", "");
      formik.setFieldTouched("gstSelect", false);

      formik.setFieldValue("gstnumber", "");
      formik.setFieldTouched("gstnumber", false);

      formik?.setFieldValue("legalBusinessName", "");
      formik.setFieldTouched("legalBusinessName", false);

      formik.setFieldValue("state", "");
      formik.setFieldTouched("state", false);

      formik?.setFieldValue("legalBusinessName", "");
      formik.setFieldTouched("legalBusinessName", false);

      formik.setFieldValue("addressLine1", "");
      formik?.setFieldTouched("addressLine1", false);

      formik.setFieldValue("addressLine2", "");
      formik.setFieldTouched("addressLine2", false);

      formik.setFieldValue("city", "");
      formik.setFieldTouched("city", false);

      formik.setFieldValue("pinCode", "");
      formik.setFieldTouched("pinCode", false);

      formik.setFieldValue("phoneNumber", "");
      formik.setFieldTouched("phoneNumber", false);
    }
  };

  const checkInsufficientBalanceCondition = (isCouponVerify) => {
    let totalArtPrice = isCouponVerify
      ? Number(finalAmount / DECIMAL)
      : Number(latestPrice.price) * Number(formik?.values?.quantity);
    return totalArtPrice > userWalletBalance / DECIMAL;
  };

  return (
    <>
      <section className={style.checkoutDetails}>
        <div className={style.topHeading}>
          <h3 title={artDetail?.art_name}>
            {sliceChar(artDetail?.art_name, 150)}
          </h3>
        </div>
        <div className={style.checkoutDetails_innerSec}>
          <div className={style.checkoutDetails_innerSecLeftside}>
            <ul className={style.listing}>
              <li>
                <label>Artist Name</label>
                <p>: {artDetail?.artist_name}</p>
              </li>
              <li>
                <label>Series Name</label>
                <p>: {artDetail?.series_name}</p>
              </li>
              <li>
                <label>Release Date</label>
                <p>: {formatDateActivityTable(artDetail?.createdAt)}</p>
              </li>
              <li>
                <label>Release Price</label>
                <p
                  title={`₹${getNumberWithComma(
                    (artDetail?.price / DECIMAL).toFixed(2)
                  )}`}
                >
                  : ₹
                  {getNumberWithComma((artDetail?.price / DECIMAL).toFixed(2))}
                </p>
              </li>
            </ul>
          </div>
          <div className={style.checkoutDetails_innerSecRightside}>
            <form onSubmit={formik.handleSubmit}>
              <>
                <ul className={style.checkoutDetails_innerSecRightside_list}>
                  <li>
                    <div className={style.priceInfo}>
                      <label>Price per DFA</label>
                      <span>:</span>{" "}
                      <p
                        title={`₹
                        ${getNumberWithComma(
                          Number(latestPrice?.price).toFixed(2)
                        )}`}
                      >
                        ₹
                        {getNumberWithComma(
                          Number(latestPrice?.price).toFixed(2)
                        )}
                      </p>
                    </div>
                  </li>
                  {artDetail.drop_type !== DROP_TYPE.AUCTION &&
                    artDetail.drop_type !== DROP_TYPE.RANKED_AUCTION &&
                    latestPrice?.market === "primary" && (
                      <li>
                        <div className={style.priceInfo}>
                          <label>Quantity</label>
                          <span>:</span>{" "}
                          <FormikControls
                            variant={`checkField `}
                            className="text-center"
                            control="input"
                            type="text"
                            name="quantity"
                            id="number"
                            placeholder="Enter Quantity"
                            onChange={(e) => {
                              onChangeHandler(e.target.value);
                            }}
                            formik={formik}
                            value={formik.values.quantity}
                            leftIcon={<SubtractionIcon />}
                            leftIconClick={subHanlder}
                            rightIcon={<PlusIcon />}
                            rightIconClick={addHanlder}
                          />
                        </div>
                      </li>
                    )}
                  <li>
                    <div className={style.priceInfo}>
                      <label>Amount</label>
                      <span>:</span>{" "}
                      <p
                        title={`₹${getNumberWithComma(
                          Number(latestPrice?.price) *
                            Number(formik?.values.quantity)
                        )}`}
                      >
                        ₹
                        {getNumberWithComma(
                          (
                            Number(latestPrice?.price) *
                            Number(formik?.values.quantity)
                          ).toFixed(2)
                        )}
                      </p>
                    </div>
                  </li>
                  {artDetail.drop_type != DROP_TYPE.AUCTION &&
                    artDetail.drop_type != DROP_TYPE.RANKED_AUCTION &&
                    latestPrice?.market === "primary" && (
                      <li>
                        <div className={style.couponField}>
                          <FormikControls
                            className="checkselect"
                            control="checkbox"
                            name="checkCouponCheckbox"
                            formik={formik}
                            onChange={(e) => {
                              onChangeSelectBoxHandler("coupon");
                              setIsCouponVerify(false);
                              formik.setFieldValue(
                                "checkCouponCheckbox",
                                e.target.checked
                              );
                            }}
                            value={formik?.values?.checkCouponCheckbox}
                            label="Apply Coupon"
                          />
                          {formik?.values?.checkCouponCheckbox && (
                            <>
                              <div className={style.availCoupen}>
                                <div className={style.inputField}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="couponCode"
                                    placeholder="Enter Coupon Code"
                                    formik={formik}
                                    onChange={(e) => {
                                      if (
                                        /^[a-zA-Z0-9\s]*$/.test(e.target.value)
                                      ) {
                                        formik.setFieldValue(
                                          "couponCode",
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={formik.values.couponCode}
                                    onBlur={formik.handleBlur}
                                    maxLength={10}
                                  />
                                  <CommonBtn
                                    title="Available Coupons"
                                    role="btn"
                                    type="button"
                                    className={`bg-border-without ${style.btnAvail}`}
                                    onClick={() => setShowCoupon(true)}
                                  />
                                </div>
                                <CommonBtn
                                  title="Apply"
                                  role="btn"
                                  type="button"
                                  className={`bordred-black ${style.btnApply}`}
                                  onClick={() => couponVerifyFunction()}
                                  disabled={
                                    formik?.values?.couponCode.length < 10
                                  }
                                />
                              </div>
                              <div className={style.discountValue}>
                                <label>Discount Applied</label>
                                <span>:</span>{" "}
                                <p>
                                  ₹{isCouponVerify ? getDiscountAmount() : 0}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      </li>
                    )}
                  <li>
                    <div className={`${style.form} ${style.form_gstinfo}`}>
                      <FormikControls
                        className="checkselect"
                        control="checkbox"
                        name="checkAddGstCheckbox"
                        formik={formik}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "checkAddGstCheckbox",
                            e.target.checked
                          );
                          onChangeSelectBoxHandler("gst");
                          //   formik.resetForm();
                        }}
                        label="Enter GST Information (Optional)"
                        value={formik?.values.checkAddGstCheckbox}
                      />
                      {formik?.values.checkAddGstCheckbox && (
                        <>
                          <div className={style.inputField}>
                            <FormikControls
                              options={gstOption}
                              type="select"
                              className={style.select}
                              name="gstSelect"
                              formik={formik}
                              control="select"
                              placeholder="Select / Add GST"
                              onChange={(value) => {
                                formik.setFieldValue("gstSelect", value);
                              }}
                              value={formik.values.gstSelect}
                            />
                          </div>
                          {formik.values.gstSelect.value === "newGst" && (
                            <div className={style.gst_details}>
                              <Row>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    options={indianStates}
                                    type="select"
                                    className={style.select}
                                    name="state"
                                    formik={formik}
                                    control="select"
                                    placeholder="Select State"
                                    onChange={(value) => {
                                      formik.setFieldValue("state", value);
                                    }}
                                    value={formik.values.state}
                                  />
                                </Col>
                                <Col lg={6} sm={6} className=""></Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="legalBusinessName"
                                    placeholder="Enter Business Name"
                                    formik={formik}
                                    onChange={formik.handleChange}
                                    value={formik.values.legalBusinessName}
                                    onBlur={formik.handleBlur}
                                  />
                                </Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="gstnumber"
                                    placeholder="Enter GST Number"
                                    formik={formik}
                                    maxLength={15}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                      if (
                                        /^[a-zA-Z0-9\s]*$/.test(e.target.value)
                                      ) {
                                        formik.setFieldValue(
                                          "gstnumber",
                                          e.target.value.toUpperCase()
                                        );
                                      }
                                    }}
                                    value={formik.values.gstnumber}
                                  />
                                </Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="addressLine1"
                                    placeholder="Address Line 1"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                  />
                                </Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="addressLine2"
                                    placeholder="Address Line 2"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                  />
                                </Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="city"
                                    placeholder="City"
                                    // label="Select City"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    disabled={!formik.values.state.value}
                                  />
                                </Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="pinCode"
                                    placeholder="Pincode"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    maxLength={6}
                                    onChange={(e) => {
                                      //regex for only digit
                                      if (/^\d*$/.test(e.target.value)) {
                                        formik.setFieldValue(
                                          "pinCode",
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={formik.values.pinCode}
                                  />
                                </Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    control="phone"
                                    classinput="phone_border"
                                    type="input"
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    variant="input_number"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    onChange={async (value) => {
                                      await formik.setFieldTouched(
                                        "phoneNumber",
                                        true
                                      );
                                      if (value) {
                                        setPhoneNumberCountryCode(
                                          parsePhoneNumber(value)?.country
                                        );
                                      }
                                      formik.setFieldValue(
                                        "phoneNumber",
                                        value
                                      );
                                    }}
                                    value={formik.values.phoneNumber}
                                  />
                                </Col>
                                <Col lg={6} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="email"
                                    placeholder="Enter Email"
                                    formik={formik}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                  />
                                </Col>
                              </Row>
                              <FormikControls
                                className="checkselect"
                                control="checkbox"
                                name="checkGstProfileCheckbox"
                                formik={formik}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "checkGstProfileCheckbox",
                                    e.target.checked
                                  );
                                }}
                                label={"Add this to profile"}
                                value={formik?.values?.checkGstProfileCheckbox}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                  {isCouponVerify && formik?.values.checkCouponCheckbox ? (
                    <li>
                      <div className={style.totalAmount}>
                        <label>Total Amount</label>
                        <span>:</span>{" "}
                        <p>
                          {" "}
                          ₹
                          {getNumberWithComma(
                            (Number(finalAmount) / DECIMAL).toFixed(2)
                          )}
                        </p>
                      </div>
                    </li>
                  ) : (
                    <li>
                      <div className={style.totalAmount}>
                        <label>Total Amount</label>
                        <span>:</span>{" "}
                        <p>
                          {" "}
                          ₹
                          {getNumberWithComma(
                            (
                              Number(latestPrice?.price) *
                              Number(formik?.values?.quantity)
                            ).toFixed(2)
                          )}
                        </p>
                      </div>
                    </li>
                  )}

                  <li>
                    <FormikControls
                      className="radio_btn"
                      control="radio"
                      name="internalPaymentCheck"
                      formik={formik}
                      value={formik?.values?.internalPaymentCheck}
                      checked={formik?.values?.internalPaymentCheck}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "internalPaymentCheck",
                          e.target.checked
                        );
                        if (e.target.checked === true) {
                          formik.setFieldValue("externalPaymentCheck", false);
                        }
                      }}
                      label={
                        <>
                          <strong>Internal Wallet</strong>{" "}
                          <span>
                            Balance: ₹
                            {getNumberWithComma(
                              (userWalletBalance / DECIMAL).toFixed(2)
                            )}
                          </span>
                        </>
                      }
                    >
                      {checkInsufficientBalanceCondition(isCouponVerify) && (
                        <p className="error_message">
                          Insufficient Wallet Balance
                        </p>
                      )}
                    </FormikControls>
                    <FormikControls
                      className="radio_btn"
                      control="radio"
                      name="labelcheck"
                      formik={formik}
                      value={formik?.values?.externalPaymentCheck}
                      checked={formik?.values?.externalPaymentCheck}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "externalPaymentCheck",
                          e.target.checked
                        );
                        if (e.target.checked === true) {
                          formik.setFieldValue("internalPaymentCheck", false);
                        }
                      }}
                      label={
                        <>
                          <strong>Other Payment Method</strong>
                        </>
                      }
                      disabled={
                        formik.values.couponCode !== "" &&
                        isCouponVerify &&
                        (finalAmount / DECIMAL).toFixed(2) == 0
                      }
                    />
                  </li>
                </ul>
                <CommonBtn
                  className={style.paymentBtn}
                  role="btn"
                  title="Proceed Payment"
                  type="submit"
                />
              </>
            </form>
          </div>
        </div>
      </section>
      <AvailableCouponModal
        show={showCoupon}
        handleClose={() => setShowCoupon(false)}
        couponList={couponList}
        formik={formik}
        couponVerifyFunction={couponVerifyFunction}
      />
    </>
  );
};

export default CheckoutDetails;
