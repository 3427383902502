import React from "react";
import { Form } from "react-bootstrap";
import { EditIcom } from "../../../../Assets/svgIcons/SVGicon";
import styles from "./CommonHero.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";

const CommonHero = ({ backgroundImg, tab, uploadCoverImageHandler }) => {
  const validationSchema = Yup.object({
    coverImage: Yup.mixed()
      .test("fileFormat", "*File must be of type jpeg, jpg , png", (value) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(value && value.type)
      )
      .test(
        "fileSize",
        "*Image size should be less than or equal to 5MB",
        (value) => value && value.size <= 5 * 1024 * 1024 // 2MB
      ),
  });

  const formik = useFormik({
    initialValues: {
      coverImage: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const uploadCoverImageValidator = (e) => {
    const file = e.target.files[0];
    const allowedImageFormats = ["image/jpeg", "image/png", "image/jpg"];
    const isImage = allowedImageFormats.includes(file.type);
    formik.setFieldValue("coverImage", e.target.files[0]);
    if (isImage && file.size <= 5242880) {
      let result = uploadCoverImageHandler(file);
      if (!result) {
        formik.setFieldValue("coverImage", "");
      }
    }
  };
  return (
    <>
      {backgroundImg && (
        <div
          className={styles.CommonHero}
          // style={{ backgroundImage: "url(" + backgroundImg + ")" }}
        >
          <img src={backgroundImg} alt="bgImage" />
          <form onSubmit={formik.handleSubmit}>
            {tab === "editprofile" && (
              <div className={styles.imageUperror}>
                <div className={styles.imageUpbtn}>
                  <Form.Control
                    type="file"
                    name="coverImage"
                    onChange={(e) => {
                      uploadCoverImageValidator(e);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <button className={styles.editBtn}>
                    <EditIcom />
                  </button>
                </div>
                <span className={styles.error_msg}>
                  {formik.errors?.coverImage &&
                    formik.touched?.coverImage && formik.values.coverImage !=="" &&
                    formik.errors?.coverImage}
                </span>
              </div>
            )}
          </form>
        </div>
      )}
      {/* {tab === "editprofile" && (
        <div className={styles.uploadImg}>
          <img src={backgroundImg} alt="bgImage" />
        </div>
      )} */}
    </>
  );
};

export default CommonHero;
