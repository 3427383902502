import React, { useEffect, useState } from "react";
import MainLayout from "../Component/Common/Layouts/MainLayout/MainLayout";
import { useSelector } from "react-redux";
import { QR_LOGIN_TEXT } from "../Constant/userConstant";
import { Navigate, Outlet } from "react-router-dom";
import { RoutesUrls } from "../Constant/RoutesUrls";

const GuardLess = () => {
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const [isAuthenticated, setIsAuthenticated] = useState(isUserLoggedin);
  const [isQrLogin, setIsQrLogin] = useState("");

  useEffect(() => {
    // Get the query parameter from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const qrLogin = queryParams.get("qr_login");
    setIsQrLogin(qrLogin);
  }, []);

  return isAuthenticated && isQrLogin === QR_LOGIN_TEXT ? (
    <MainLayout>
      <Navigate to={RoutesUrls.Link_devices} />
    </MainLayout>
  ) : (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export default GuardLess;
