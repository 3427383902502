import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import style from "./Offertab.module.scss";
import Offersmade from "./OffertabComponent/Offersmade";
import Offersreceived from "./OffertabComponent/Offersreceived";
import {
  makeOfferList,
  getOfferRecievedList,
  acceptOfferOnOff,
  getOfferRecievedListByEdition,
} from "../../../../Features/offerSlice/offerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../customHooks/useDebounce";
import {
  COMPARISION_CONSTANT_NAMES,
  offerArtOfferPerPage,
  offerArtPerPage,
  offerType,
  sortByOnOfferRecieved,
  tabName,
} from "../../../../Constant/userConstant";
import { setDashboardActiveSubTab } from "../../../../Features/user/userSlice";

const Offertab = ({ ArtistId }) => {
  const dispatch = useDispatch();
  const subActiveTab = useSelector((state) => state?.users?.activeSubTab);
  const [offerList, setOfferList] = useState([]);
  const [offerListMadeGlobal, setOfferMadeListGlobal] = useState([]);
  const [madeOfferList, setMadeOfferList] = useState([]);
  const [totalPageOnrecieve, setTotalPageOnRecieve] = useState(0);
  const [totalPageOnMade, setTotalPageOnMade] = useState(0);
  const [totalMadeList, setToalMadeList] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [filterByStatus, setFilterByStatus] = useState([]);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [totalMade, setTotalMade] = useState("");
  const [isPageLoad, setIsPageLoad] = useState(false);
  const [selectedList] = useState(offerType.SINGLE);
  const [selecetdMadeOfferType, setSelectedMadeOfferType] = useState(
    offerType.SINGLE
  );

  const elementToScroll = useRef(null);

  useEffect(() => {
    if (debouncedSearchTerm != "") {
      setPage(1);
    }

    if (
      debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
      debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE
    ) {
      if (isPageLoad) {
        // this Will call on every time any change on pagination , tab or any search and filters
        if (subActiveTab == tabName.OFFER_MADE) {
          //this is offer made list call
          getUserOfferList();
        } else {
          // this is offer recieve API call
          getUserOfferRecievedList();
        }
      } else {
        // on page load we need to show count for both made and recieved offer
        // so both API's are initially called
        getUserOfferList();
        getUserOfferRecievedList();
        setIsPageLoad(true);
      }
    }
    // Delayed scroll to the middle of the tab section after rendering
    // setTimeout(() => {
    //   if (elementToScroll.current) {
    //     const offsetY = elementToScroll.current.offsetTop;
    //     const windowHeight = window.innerHeight;
    //     const tabHeight = elementToScroll.current.offsetHeight;
    //     const scrollToMiddle = offsetY - (windowHeight - tabHeight) / 2;
    //     window.scrollTo(0, scrollToMiddle);
    //   }
    // }, 100);
  }, [
    subActiveTab,
    debouncedSearchTerm,
    filter,
    page,
    filterByStatus,
    selectedList,
    selecetdMadeOfferType,
    ArtistId,
  ]);

  const getUserOfferList = async () => {
    let data = {
      sort_by: filter, //key changed from filter to sort_by
      page: search !== "" ? 1 : page,
      size: offerArtPerPage,
      search: debouncedSearchTerm.trim(),
      filter: filterByStatus,
      offer_type: selecetdMadeOfferType,
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }
    try {
      let response = await dispatch(makeOfferList(data));
      if (response) {
        let data = response;
        if (selecetdMadeOfferType == offerType.SINGLE) {
          // new checks
          setOfferList(data?.data.data.list);
        } else {
          setOfferMadeListGlobal(data?.data.data.list);
        }
        setTotalPageOnMade(data.data.data.totalPages);
        setToalMadeList(data.data.data.totalItems);
      }
      scrollToElement();
    } catch (err) {}
  };
  const getUserOfferRecievedList = async () => {
    let data = {
      filter: filter,
      page: search !== "" ? 1 : page,
      // size: 1,
      size: offerArtPerPage,
      search: debouncedSearchTerm.trim(),
      offer_type: selectedList,
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getOfferRecievedList(data));
      if (response) {
        let data = response;
        let offers = [...data.data.data.list];
        offers.forEach((element) => {
          element.art_owner_editions.sort(
            (a, b) => a.edition_no - b.edition_no
          );
        });
        offers.forEach((element) => {
          element.offer_type = offerType.SINGLE;
          element.selectedEditionIndex = 0;
          element.page = 1;
        });
        setMadeOfferList(offers);
        setTotalMade(data?.data?.data?.totalItems);
        setTotalPageOnRecieve(data?.data?.data?.totalPages);
      }
      scrollToElement();
    } catch (err) {}
  };

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const onTabChange = (e) => {
    dispatch(setDashboardActiveSubTab(e));
    setSearch("");
    setPage(1);
  };

  const onChangeActiveDeactiveOffer = async (value, art, artIndex) => {
    let editionNumber =
      art?.art_owner_editions[art?.selectedEditionIndex].edition_no;
    let data = {
      art_id: art.id,
      is_offer_accepting: value == 1 ? 0 : 1,
      edition_no: editionNumber,
    };

    try {
      let response = await dispatch(acceptOfferOnOff(data));
      if (response) {
        let oldData = [...madeOfferList];
        oldData[artIndex].art_owners[0].is_offer_accepting = value == 1 ? 0 : 1;
        setMadeOfferList(oldData);
      }
    } catch (err) {}
  };

  const getOffersById = async (data) => {
    let finalData = {
      art_id: data.art_id,
      edition_no: data.edition_no,
      offer_type: data.offer_type,
      page: data.page,
      sort_by: sortByOnOfferRecieved.NEWEST,
      size: offerArtOfferPerPage,
    };

    try {
      let response = await dispatch(getOfferRecievedListByEdition(finalData));
      if (response) {
        let artOffers = [];
        let artData = [...madeOfferList];

        if (response.data.data.list.length) {
          artOffers = response.data.data.list[0];
          artOffers.art_owner_editions.sort(
            (a, b) => a.edition_no - b.edition_no
          );
          artOffers.selectedEditionIndex = data.selectedEditionIndex;
          artOffers.offer_type = data.offer_type;
          artOffers.offer_count = response.data.data.totalItems;
          artOffers.page = data.page;
          artData[data.index] = artOffers;
          setMadeOfferList(artData);
        } else {
          artData[data.index].art_offer = [];
          setMadeOfferList(artData);
        }
      }
    } catch (err) {}
  };

  const onCloseOfferSuccess = (artIndex) => {
    getUserOfferList();
  };

  return (
    <>
      <section className={style.offer_tab} ref={elementToScroll}>
        <Tabs
          activeKey={subActiveTab}
          id="uncontrolled-tab-example"
          className="tablists"
          onSelect={onTabChange}
        >
          <Tab
            eventKey={tabName.OFFER_RECIEVED}
            title={`Offers Received (${totalMade || 0}) `}
          >
            {subActiveTab == tabName.OFFER_RECIEVED && (
              <Offersreceived
                data={madeOfferList}
                setRecieveOfferList={setMadeOfferList}
                totalPage={totalPageOnrecieve}
                page={page}
                setSearch={setSearch}
                getUserOfferRecievedList={getUserOfferRecievedList}
                onChangeActiveDeactiveOffer={onChangeActiveDeactiveOffer}
                getOffersById={getOffersById}
                setPage={setPage}
                setTotalMade={setTotalMade}
                totalMade={totalMade}
                getUserOfferMadeList={getUserOfferList}
              />
            )}
          </Tab>
          <Tab
            eventKey={tabName.OFFER_MADE}
            title={`Offers Made (${totalMadeList || 0})`}
          >
            {subActiveTab == tabName.OFFER_MADE && (
              <Offersmade
                data={offerList}
                dataGlobal={offerListMadeGlobal}
                totalPage={totalPageOnMade}
                page={page}
                setSearch={setSearch}
                setFilter={setFilter}
                setPage={setPage}
                getUserOfferMadeList={getUserOfferList}
                setFilterByStatus={setFilterByStatus}
                filterByStatus={filterByStatus}
                selectedList={selecetdMadeOfferType}
                setSelectedList={setSelectedMadeOfferType}
                setToalMadeList={setToalMadeList}
                onCloseOfferSuccess={onCloseOfferSuccess}
              />
            )}
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Offertab;
