import { createSlice } from "@reduxjs/toolkit";
import { toasts } from "../../Component/Common/Toast/Toast";
import {
  startDfaActivityLoading,
  startLoading,
  startMarketplaceLoading,
  stopDfaActivityLoading,
  stopLoading,
  stopMarketplaceLoading,
} from "../loading/loadingSlice";
import { store } from "../../App/store";
import { marketplaceService } from "../../Services/marketplaceService";
import { initialStateMarketPlace } from "../InitialState";

let token;

const initialState = initialStateMarketPlace;
export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    setMarketplaceNft: (state, action) => {
      state.marketplaceNft = [...action.payload];
    },
    setMarketplacePage: (state, action) => {
      state.page = action.payload;
    },
    setMarketplaceFilter: (state, action) => {
      state.marketplaceFilter[action.payload.type] = action.payload.data;
    },
    setTotalDfa: (state, action) => {
      state.totalDfa = action.payload;
    },
    resetMarketplaceFilter: (state) => {
      state.marketplaceFilter = {
        artist: [],
        series: [],
        price: [],
        rarity: [],
        tags: [],
        status: [1],
        artType: [],
        optionFilter: { title: "Newest", value: "date_desc" },
        priceInputValue: {
          minVal: "",
          maxVal: "",
        },
      };
      state.marketplaceNft = [];
      state.page = 1;
    },
  },
});

export const {
  setMarketplaceNft,
  setMarketplacePage,
  setMarketplaceFilter,
  resetMarketplaceFilter,
  setTotalDfa,
} = marketplaceSlice.actions;

export const getAllMarketPlaceNft = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startMarketplaceLoading());
        const response = await marketplaceService.getAllMarketPlaceNft(params);

        resolve(response);
        dispatch(stopMarketplaceLoading());
      } catch (err) {
        reject(err);
        dispatch(stopMarketplaceLoading());
      }
    });
  };
};

export const getAllSeries = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getAllSeries();
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getAllArtist = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getAllArtist();
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getAllTags = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getAllTags();
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getCoupon = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getCoupon(token, params);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const getArtDetailById = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getArtDetailById(
          token,
          params
        );
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const placeOrder = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.placeOrder(token, data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getDigitalArtDetail = (params, isLoading) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        if (isLoading) {
          dispatch(startLoading());
        }
        const response = await marketplaceService.getDigitalArtDetail(
          token,
          params
        );
        resolve(response.data);
        if (isLoading) {
          dispatch(stopLoading());
        }
      } catch (err) {
        reject(err);
        if (isLoading) {
          dispatch(stopLoading());
        }
      }
    });
  };
};

export const getDfaDetailById = (params, isLoading = true) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        if (isLoading) {
          dispatch(startMarketplaceLoading());
        }
        const response = await marketplaceService.getDfaDetailById(
          token,
          params
        );
        resolve(response.data);
        if (isLoading) {
          dispatch(stopMarketplaceLoading());
        }
      } catch (err) {
        reject(err);
        if (isLoading) {
          dispatch(stopMarketplaceLoading());
        }
      }
    });
  };
};

export const getDeviceDetailList = (params, isLoading = true) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        if (isLoading) {
          dispatch(startMarketplaceLoading());
        }
        const response = await marketplaceService.getDeviceDetailList(
          token,
          params
        );
        resolve(response.data);
        if (isLoading) {
          dispatch(stopMarketplaceLoading());
        }
      } catch (err) {
        reject(err);
        if (isLoading) {
          dispatch(stopMarketplaceLoading());
        }
      }
    });
  };
};

export const getDfaActivityById = (params, isLoading = true) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        if (isLoading) {
          dispatch(startDfaActivityLoading());
        }
        const response = await marketplaceService.getDfaActivityById(
          token,
          params
        );
        resolve(response.data);
        if (isLoading) {
          dispatch(stopDfaActivityLoading());
        }
      } catch (err) {
        reject(err);
        if (isLoading) {
          dispatch(stopDfaActivityLoading());
        }
      }
    });
  };
};

export const placeOrderByCurated = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.placeOrderByCurated(
          token,
          data
        );
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const buyArt = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.buyArt(token, data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const getActivity = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getActivity(token, params);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export default marketplaceSlice.reducer;
