import React from "react";
import Form from "react-bootstrap/Form";
import "./Search.scss";
import { SearchIcon } from "../../../../../Assets/svgIcons/SVGicon";

const Search = (props) => {
  const {
    label,
    formik,
    rightIconClick,
    lg,
    name,
    rightIcon,
    variant,
    value,
    btnClick,
    ...rest
  } = props;
  return (
    <Form.Group
      className={` ${
        lg ? "common_input  search_input search_input-lg" : "search_input"
      } ${variant} ${formik.values[name] ? "hasFilled" : ""} ${
        formik?.touched[name] && formik?.errors[name] ? "hasError" : ""
      }`}
      controlId={name}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div
        className={`${lg ? "common_input_inner" : "search_input_inner"} ${
          rightIcon ? "rightIconSearch" : ""
        }`}
      >
        <button type="submit" onClick={btnClick} className="search_input_icon">
          <SearchIcon />
        </button>
        <input className="form-control" value={value} name={name} {...rest} />
        {rightIcon && (
          <span
            className={`${rightIconClick ? "cursor-pointer" : ""} rightIcon`}
          >
            {rightIcon}
          </span>
        )}
      </div>
      {formik?.touched[name] && (
        <p className="error_message">{formik?.errors[name]}</p>
      )}
    </Form.Group>
  );
};

export default Search;
