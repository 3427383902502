import React, { useEffect } from "react";
import CommonTable from "../../../../../../Common/Ui/CommonTable/CommonTable";
import style from "./CardTable.module.scss";
import {
  getNumberWithComma,
  sliceChar,
  timeAgo,
} from "../../../../../../../Helper/common";
import Notfound from "../../../../../../Common/NotFound/Notfound";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  DECIMAL,
  DROP_TYPE,
  MARKET_TYPE,
} from "../../../../../../../Constant/userConstant";
import personIcon from "../../../../../../../Assets/images/person_icon.jpg";
import { useParams } from "react-router-dom";
import socket from "../../../../../../../Socket/socket";

const AuctionAndRankedAuctionLiveTable = ({
  dfaActivity,
  dfaDetail,
  setDfaActivity,
  navigateToUser,
}) => {
  const { id } = useParams();
  const isDfaActivityLoading = useSelector(
    (state) => state?.loading?.dfaActivityLoading
  );

  useEffect(() => {
    // Emit socket event when the art ID or type changes
    socket.emit("currentArt", { art_id: id });
    return () => {
      // Emit socket event to turn off current art and reset live activity detail
      socket.emit(`currentArtOff`, { art_id: id });
    };
  }, [id]);

  useEffect(() => {
    // Clone the liveActivityDetail array to avoid mutating the state directly
    let oldBidderActivity = [];
    if (Object.keys(dfaActivity).length) {
      oldBidderActivity = [...dfaActivity?.user_activity];
    }
    const updateBidHandler = (data) => {
      // Check if the incoming data is related to the current art
      if (id == data.id) {
        // Check the drop type of the art
        if (dfaDetail.drop_type == DROP_TYPE?.RANKED_AUCTION) {
          // Ranked Auction
          // If the current user placed a bid, update the last bid amount
          // if (data?.auction_user[0].id == userId) {
          //   setLastBidAmount(data?.offer_price);
          // }
          const index = oldBidderActivity?.findIndex(
            (element) => element?.bidder_user?.id == data?.bidder_user?.id
          );
          // If the bid already exists in the array, update it with the new data
          if (index >= 0) {
            oldBidderActivity[index] = {
              ...data,
            };
          } else {
            // If the bid doesn't exist, add it to the array
            oldBidderActivity.push({
              ...data,
            });
          }
          //sorting the array to sjow bid in desending order.
          oldBidderActivity.sort((a, b) => b.bid_amount - a.bid_amount);
          if (oldBidderActivity.length > dfaDetail?.no_of_editions) {
            oldBidderActivity.pop();
          }
          // Update the liveActivityDetail state with the updated array
          setDfaActivity({
            ...dfaActivity,
            user_activity: oldBidderActivity,
          });
        } else if (dfaDetail?.drop_type == DROP_TYPE?.AUCTION) {
          // Auction
          // For Auctions, replace the entire array with the new bid
          setDfaActivity({
            ...dfaActivity,
            user_activity: [{ ...data }],
          });
        }
      }
    };
    // Subscribe to the "updateArtBids" event from the socket server
    if (dfaDetail?.market_type === MARKET_TYPE?.PRIMARY) {
      socket.on("updateArtBids", updateBidHandler);
    }
  }, [id, dfaActivity]);

  return (
    <>
      <div className={style.cardTable}>
        {/* {dfaActivity?.user_activity?.length > 0 && (
          <div className={style.cardTableleftSide}>
            <div className={style.cardSec}>
              <div className={style.topHead}>
                <p>Rank no 1</p>
                <h5>@{dfaActivity?.user_activity[0]?.bidder_user?.username}</h5>
                <img
                  src={
                    dfaActivity?.user_activity[0]?.bidder_user?.image_path
                      ? `${process.env.REACT_APP_IMG_BASE_URL}${dfaActivity?.user_activity[0]?.bidder_user?.image_path}`
                      : personIcon
                  }
                  alt="bidder-Icon"
                />
              </div>
              <div className={style.bottomHead}>
                <p>Bid Amount</p>
                <h5>
                  ₹{" "}
                  {getNumberWithComma(
                    dfaActivity?.user_activity[0]?.bid_amount / DECIMAL
                  )}
                </h5>
                <p>{timeAgo(dfaActivity?.user_activity[0]?.createdAt)}</p>
              </div>
            </div>
          </div>
        )} */}
        <div className={style.cardTablerightSide}>
          <div className={style.tableSec}>
            <CommonTable className={style.bidder_table}>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Bidder</th>
                  <th>Bid Amount</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {isDfaActivityLoading ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                ) : (
                  dfaActivity?.user_activity?.map((item, index) => (
                    <tr key={index}>
                      {/* <td>{(activityPage - 1) * limit.value + (index + 1)}</td> */}
                      <td>{index + 1}</td>
                      <td>
                        <div
                          className="ownedBy cursor-pointer"
                          onClick={() =>
                            navigateToUser(item?.bidder_user?.username)
                          }
                          title={item?.bidder_user?.username}
                        >
                          <img
                            src={
                              item?.bidder_user?.image_path
                                ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.bidder_user?.image_path}`
                                : personIcon
                            }
                            alt="bidder-Icon"
                          />
                          <h6>{`@${sliceChar(
                            item?.bidder_user?.username,
                            15
                          )}`}</h6>
                        </div>
                      </td>
                      <td>
                        ₹ {getNumberWithComma(item?.bid_amount / DECIMAL)}
                      </td>
                      <td>{timeAgo(item?.createdAt)}</td>
                    </tr>
                  ))
                )}
                {/* {isDfaActivityLoading && (
                  <tr>
                    <td colSpan={4} className='text-center'>
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                )} */}
                {dfaActivity?.user_activity?.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <Notfound text={"No activity found."} />
                    </td>
                  </tr>
                )}
              </tbody>
            </CommonTable>
          </div>
          {/* <div className="filterPagination">
            <div className="selectShow">
              <label>Show</label>
              <div className="selectShow_inner">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  placeholder=""
                  options={showoption}
                />
              </div>
            </div>
            <CustomPagination
              className=""
              activePage="2"
              totalPage="10"
              setActivePage={setActivityPage}
              maxVisiblePages={5}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AuctionAndRankedAuctionLiveTable;
