import { useFormik } from "formik";
import * as Yup from "yup";
import "./Filter.scss";
import PropTypes from "prop-types";

const Filter = ({ className, setTimeRange, timeRange }) => {

  const initialValue = {
    filter: "",
  };
  const validationSchema = Yup.object({
    filter: Yup.string().required("Please select an option"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {

    }
  })

  const handleTimeRangeClick = (value) => {
    setTimeRange(value);
  };

  return (
    <div className={`customFilter ${className}`}>
      <ul>
        <li>
          <button
            className={timeRange == "1" ? "active" : ""}
            onClick={() => handleTimeRangeClick(1)}
          >
            1d
          </button>
        </li>
        <li>
          <button
            className={timeRange == "7" ? "active" : ""}
            onClick={() => handleTimeRangeClick(7)}
          >
            7d
          </button>
        </li>
        <li>
          <button
            className={timeRange == "30" ? "active" : ""}
            onClick={() => handleTimeRangeClick(30)}
          >
            30d
          </button>
        </li>
        <li>
          <button
            className={timeRange == "ALL" ? "active" : ""}
            onClick={() => handleTimeRangeClick("ALL")}
          >
            All
          </button>
        </li>
      </ul>
      <form onSubmit={formik.handleSubmit}></form>
    </div>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  setTimeRange: PropTypes.func.isRequired,
  timeRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default Filter;
