import React from "react";
import styles from "./Modals.module.scss";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import { useFormik } from "formik";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import * as Yup from "yup";

const SellModal = ({
  show,
  onHide,
  artImg,
  artistName,
  edition,
  totalEditions,
}) => {
  const initialValue = {
    edition: "",
  };
  const validationSchema = Yup.object({
    edition: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });
  return (
    <>
      <div className={styles.modalsall}>
        <CommonModal backdropclass='fade_clrbg' className={styles.sellsModal} show={show} onHide={onHide}>
          <div className="d-flex flex-md-row flex-column-reverse">
            <div className={styles.sellsModalLeft}>
              <div className={styles.sellsModalLeftInner}>
                <img src={artImg} alt="" />
              </div>
              <span className={styles.bottomLine} />
            </div>
            <div className={styles.sellsModalRight}>
              <h3>Art Name</h3>
              <div className={styles.artistDetails}>
                <h4>{artistName}</h4>
                <div className={styles.artistDetailsEdition}>
                  <p>EDITION</p>
                  <span>
                    {edition} / {totalEditions}
                  </span>
                </div>
              </div>
              <div className={styles.pricelist}>
                <h6>
                  Selling Price: <span>₹37,890</span>
                </h6>
                <h6 className={styles.highvalue}>
                  Highest Offer: <span>₹78,789</span>
                </h6>
              </div>
              <div className={styles.editionDetails}>
                <form onSubmit={formik.handleSubmit}>
                  <FormikControls
                    control="input"
                    type="text"
                    name="edition"
                    placeholder="Enter Selling Price*"
                    formik={formik}
                    variant="mb-0"
                  />
                </form>
              </div>

              <div className={styles.buy_btn_box}>
                <CommonBtn
                  role="btn"
                  title="SELL NOW"
                  className={`${styles.buy_btn} btn-yellow`}
                />
              </div>
              <span className={styles.bottomLine} />
            </div>
          </div>
        </CommonModal>
      </div>
    </>
  );
};

export default SellModal;
