import React, { useEffect } from "react";
import CommonModal from "../../Common/Ui/CommonModal/CommonModal";
import "./IndexModal.scss";
import { useDisconnect } from "@web3modal/ethers/react";

export const SuccessModal = ({ show, onHide }) => {
  const { disconnect } = useDisconnect();

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);
  return (
    <>
      <div className="successModal">
        <CommonModal
          centered
          className="modalSuccess"
          backdropclass="fade_clrbg"
          show={show}
          onHide={onHide}
        >
          <div className="innerModal">
            <h3>Success!</h3>
            <p>Your request has been sent to the admin for review.</p>
            <p>We'll get back to you shortly.</p>
          </div>
        </CommonModal>
      </div>
    </>
  );
};
