import React, { useEffect, useState } from "react";
import styles from "../SignUp.module.scss";
import { Container } from "react-bootstrap";
import SignUpForm from "./SignUpForm";
import SignupConfirm from "./SignupConfirm";
import { Helmet } from "react-helmet";

const SignUp = () => {
  const [signupStep, setSignupStep] = useState(1);
  const [signupData, setSignupData] = useState({});
  const [selelectedInputToEdit, setSelelectedInputToEdit] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
  }, [signupStep]);

  return (
    <>
      <section className={styles.SignUp}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://www.newartx.com/signup" />
        </Helmet>

        <Container>
          <div className={styles.SignUp__Section}>
            <div className={styles.Heading}>
              <h2>Create Account.</h2>
              <p>Discover and own exquisite DFAs from talented Fine Artists.</p>
            </div>
            <div className={styles.SignUp__Form}>
              {signupStep === 1 && (
                <SignUpForm
                  setSignupStep={setSignupStep}
                  setSignupData={setSignupData}
                  signupData={signupData}
                  selelectedInputToEdit={selelectedInputToEdit}
                />
              )}
              {signupStep === 2 && (
                <SignupConfirm
                  setSignupStep={setSignupStep}
                  signupData={signupData}
                  setSelelectedInputToEdit={setSelelectedInputToEdit}
                />
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SignUp;
