import React, { useEffect, useState } from "react";
import CommonModal from "../../../../Common/Ui/CommonModal/CommonModal";
import style from "./indexModals.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import {
  CoinbaseIcon,
  MetamaskIcon,
  TrustIcon,
} from "../../../../../Assets/svgIcons/SVGicon";
import AddressQrModal from "./AddressQrModal";
import { inputFieldValidation } from "../../../../../Constant/formikConstant";
import { createImportRequest } from "../../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import { CONTRACT_DETAIL } from "../../../../../Constant/contract";
import {
  useDisconnect,
  useWalletInfo,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract } from "ethers";
import { toasts } from "../../../../Common/Toast/Toast";

const ImportNftModal = ({ show, onHide, address, setShowSuccess }) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { walletInfo } = useWalletInfo();
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();
  const [addresShow, setAddressShow] = useState(false);
  const initialValue = {
    nftAddress: "",
    tokenId: "",
    quantity: 1,
    description: "",
  };

  const isValidBlockchainAddress = (value) => {
    // Regular expression to validate blockchain address (replace with the appropriate regex)
    let WAValidator = require("multicoin-address-validator");
    let valid = WAValidator.validate(value, "matic");
    return valid;
  };

  const validationSchema = Yup.object({
    nftAddress: Yup.string()
      .test(
        "isValidBlockchainAddress",
        "*Invalid address",
        isValidBlockchainAddress
      )
      .required(inputFieldValidation.commonMessage.required("NFT address")),
    tokenId: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      )
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Token Id should not start or end with a space"
      )
      .required(inputFieldValidation.commonMessage.required("Token ID")),
    description: Yup.string()
      .min(5, `Must be between 5-1000 letters`)
      .max(1000, `Must be between 5-1000 letters`)
      .required(inputFieldValidation.commonMessage.required("Description")),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createImportRequestHandler(values);
    },
  });

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const createImportRequestHandler = async (values) => {
    let data = {
      nftAddress: values.nftAddress,
      nftTokenId: values.tokenId,
      quantity: values.quantity,
      description: values.description,
      walletAddress: address,
    };
    try {
      const isVerified = await verifyNftOwner(
        values.tokenId,
        values.nftAddress
      );
      if (isVerified) {
        const response = await dispatch(createImportRequest(data));
        onHide();
        setShowSuccess(true);
      } else {
        toasts.error("This NFT doesn't belongs to you.");
      }
    } catch (error) {}
  };

  const verifyNftOwner = async (tokenId, contractAddress) => {
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const nftContract = new Contract(
        contractAddress,
        CONTRACT_DETAIL.abi,
        signer
      );
      const owner = await nftContract.ownerOf(tokenId);
      return owner.toLowerCase() === address.toLowerCase();
    } catch (error) {
      console.log(error);
    }
  };

  const walletIconRender = () => {
    if (walletInfo?.name === "MetaMask") {
      return <MetamaskIcon />;
    } else if (walletInfo?.name === "Trust Wallet") {
      return <TrustIcon />;
    } else if (walletInfo?.name === "Coinbase Wallet") {
      return <CoinbaseIcon />;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={style.connect_toaddress}>
        <CommonModal
          className={style.modal_address}
          show={show}
          onHide={onHide}
        >
          <div className={style.feilds}>
            <div className={style.headings}>
              <h3>Connected to</h3>
              <h5>
                {walletIconRender()}
                <span title={address}>{`${address?.slice(
                  0,
                  4
                )}....${address?.slice(-4)}`}</span>
              </h5>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <FormikControls
                control="input"
                type="text"
                name="nftAddress"
                placeholder="Enter Contract Address"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.nftAddress}
                onBlur={formik.handleBlur}
              />
              <FormikControls
                control="input"
                type="text"
                name="tokenId"
                placeholder="Enter NFT Token ID"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.tokenId}
                onBlur={formik.handleBlur}
              />
              {/* <FormikControls
                control="input"
                type="text"
                name="quantity"
                placeholder="Enter Quantity"
                formik={formik}
                onChange={(e) => {
                  //regex for only digit
                  if (/^\d*$/.test(e.target.value)) {
                    formik.setFieldValue("quantity", e.target.value);
                  }
                }}
                value={formik.values.quantity}
                onBlur={formik.handleBlur}
              /> */}
              <FormikControls
                classField="resize-none"
                control="textarea"
                type="text"
                name="description"
                placeholder="Enter Description"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
              />
              <CommonBtn
                role="btn"
                title="Proceed"
                type={"sumbit"}
                className={`btn-yellow ${style.btnaddress}`}
                // onClick={() => {
                //   setAddressShow(true);
                //   onHide();
                // }}
              />
            </form>
          </div>
        </CommonModal>
      </div>
      {addresShow && (
        <AddressQrModal
          show={addresShow}
          onHide={() => setAddressShow(false)}
        />
      )}
    </>
  );
};

export default ImportNftModal;
