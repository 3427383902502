import { io } from "socket.io-client";
import { store } from "../App/store";

const URL = process.env.REACT_APP_SOCKET_URL;

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999,
  transports: ["websocket"],
  extraHeaders: {
    Authorization: store.getState().persist.accessToken
      ? store.getState().persist.accessToken
      : "",
  },
});
socket.on("connect", () => {
  console.log("connected to server");
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

socket.on("disconnect", () => {
  console.log("disconnected to server");
});

export default socket;
