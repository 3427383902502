import { fetch } from "../Helper/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;

const drops = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/drops`,
    {},
    { token },
    params
  );
};

const getDrops = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/artist/get_drops`,
    {},
    { token },
    params
  );
};

const getArts = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/artist/get_arts`,
    {},
    { token },
    params
  );
};

const getDropDetailById = (params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/ArtsByDropId`,
    {},
    {},
    params
  );
};

const getAllPurchasedSoldList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/transactions`,
    {},
    { token },
    params
  );
};

const sellNftMarketPlace = (data, token) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/sell_on_marketplace`,
    data,
    { token },
    {}
  );
};

const sellNow = (token) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/user/get_fee`,
    {},
    { token },
    {}
  );
};

const getBanner = (token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/banner/list`,
    {},
    { token },
    {}
  );
};

export const dropService = {
  drops,
  getDrops,
  getArts,
  getDropDetailById,
  getAllPurchasedSoldList,
  sellNftMarketPlace,
  sellNow,
  getBanner,
};
