import React from 'react';
import styles from './NiftyArtxBanner.module.scss';
import logo from '../../../../Assets/images/logo-black.png';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';


const NiftyArtxBanner = () => {
    return (
        <section className={styles.nifty_banner}>
             <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
            <Container>
                {/* <img src={logo} alt="" /> */}
                <div className={styles.niftyBannerCenter}>
                    <h3 className={styles.bannerTopHead}>What is DFA?</h3>
                    <div className={styles.nifty_banner_content}>
                        {/* <h4>About NewArtX</h4> */}
                        <div className={styles.aboutText}>
                            <p>Digital Fine Art is a form of visual art considered to have been created digitally primarily for aesthetic purposes and is judged for its beauty and meaningfulness.</p>
                            <p>DFAs on NewArtX are minted on the blockchain which is created with meticulous attention to detail capturing every brushstroke, colour and texture with utmost fidelity. Artistic brilliance combines with cutting edge technology to give the user an interactive experience.</p>
                            <p>Digital Art represents a vibrant fusion of creativity and technology. Artists harness digital tools—ranging from sophisticated software to tablets—to craft captivating visual expressions. These artworks transcend traditional mediums, allowing for boundless experimentation. Digital artists manipulate pixels, vectors, and algorithms to breathe life into their visions. The canvas is virtual, yet the impact is real.</p>
                            <p>Now, let’s delve into the intriguing intersection with Blockchain. Imagine a decentralized ledger—a secure chain of interconnected blocks, each containing data. This technology underpins cryptocurrencies like Bitcoin, but its potential extends far beyond finance. When applied to art, Blockchain ensures immutability, provenance, and authenticity.</p>
                            <p>NewArtX, our platform, takes this synergy further. Every Digital Fine Art listed here is minted on the Polygon blockchain. This process establishes an unalterable record, tracing the artwork’s journey from inception to ownership. As collectors explore these digital masterpieces, they engage with a fusion of artistry and cutting-edge technology, all while contributing to a thriving ecosystem.</p>
                            <p>In essence, Digital Art and Blockchain converge, forging a new era where creativity meets cryptographic security.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default NiftyArtxBanner;