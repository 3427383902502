import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import styles from "./Stats.module.scss";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import {
  followUnfollowAndRemove,
  getLeaderBoard,
  openPopUp,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import { DECIMAL, PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import { getNumberWithComma, sliceChar } from "../../../Helper/common";
import { useNavigate } from "react-router-dom";
import personIcon from "../../../Assets/images/person_icon.jpg";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import { Helmet } from "react-helmet";

const filterOption = [
  { label: "Collector", value: "1" },
  { label: "Artist", value: "2" },
];

const LeaderBoards = () => {
  const [leadrerBoardListData, setLeadrerBoardListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState(1);
  const userId = useSelector((state) => state?.users?.profileData?.id);

  useEffect(() => {
    getLeaderBoardHadler();
  }, [page, userId, selectedOption]);

  const getLeaderBoardHadler = async () => {
    try {
      let params = {
        filter: selectedOption,
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
      let response = await dispatch(getLeaderBoard(params));
      setPaginationData(response?.data);
      setLeadrerBoardListData(response?.data?.list);
    } catch (err) {}
  };

  const filterHandleOption = (e) => {
    setPage(1);
    setSelectedOption(e.value);
  };
  const navigate = useNavigate();

  const creatorOnclickUsers = (item) => {
    navigate(`/profile/${item?.username}`);
  };

  const followUnfollowHandler = async (type, item) => {
    try {
      let data = {
        type: type,
        id: item.id,
      };
      const response = await dispatch(followUnfollowAndRemove(data));
      if (response?.data?.status == 200) {
        getLeaderBoardHadler();
      }
    } catch (error) {}
  };

  const getCollectedArtCount = (item) => {
    let artCreatedCount = item.collectedArtCount - item.createdArtCount;
    return artCreatedCount;
  };
  return (
    <>
      <section className={styles.stats}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
        </Helmet>
        <Container>
          <div className={styles.statsHead}>
            <Row className="align-items-center">
              <Col sm={6}>
                <h2 className="text-center text-md-start">Leaderboard</h2>
              </Col>
              <Col sm={6}>
                <div className="text-center text-md-end"></div>
              </Col>
            </Row>
          </div>
          <div className={styles.statsTable}>
            <CommonTable>
              <thead>
                <tr>
                  <th>
                    <Select
                      value={selectedOption}
                      onChange={(e) => {
                        filterHandleOption(e);
                      }}
                      options={filterOption}
                      placeholder={selectedOption == 1 ? "Collector" : "Artist"}
                      classNamePrefix="react-select"
                      className="react-select"
                    />
                  </th>
                  <th>{selectedOption == 2 && <span>Art Created</span>}</th>
                  <th>
                    <span>Art Collected</span>
                  </th>
                  <th>
                    <span>Editions Own</span>
                  </th>
                  <th>
                    <span>Editions Sold</span>
                  </th>
                  <th>
                    <span>Sales Volume</span>
                  </th>
                  <th>
                    <span></span>
                  </th>
                </tr>
              </thead>
              {leadrerBoardListData?.length > 0 ? (
                <tbody>
                  {leadrerBoardListData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center thumb-outer">
                          <img
                            src={
                              item.image_path == ""
                                ? personIcon
                                : `${process.env.REACT_APP_IMG_BASE_URL}${item?.image_path}`
                            }
                            alt="artimage"
                            className="thumb"
                          />
                          <div className="text_field cursor-pointer">
                            <p
                              title={item.username}
                              onClick={() => creatorOnclickUsers(item)}
                            >
                              @{sliceChar(item.username, 15)}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {selectedOption == 2 &&
                          (item.createdArtCount !== null
                            ? item.createdArtCount
                            : 0)}
                      </td>
                      <td>
                        {item.collectedArtCount !== null
                          ? item.collectedArtCount
                          : 0}
                      </td>
                      <td>
                        {item.ownedArtCount !== null ? item.ownedArtCount : 0}
                      </td>

                      <td>
                        {item.soldArtCount !== null ? item.soldArtCount : 0}
                      </td>
                      <td>
                        ₹{" "}
                        {getNumberWithComma(
                          (item.totalSalesVolume / DECIMAL).toFixed(2)
                        )}
                      </td>

                      <td>
                        {item.id !== userId && item.is_followed === 0 && (
                          <CommonBtn
                            role="btn"
                            title={"Follow"}
                            onClick={() => followUnfollowHandler(1, item)}
                          />
                        )}
                        {item.id !== userId && item.is_followed !== 0 && (
                          <CommonBtn
                            role="btn"
                            title={"Unfollow"}
                            onClick={() => followUnfollowHandler(0, item)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={6} className="text-center">
                      No data found
                    </td>
                  </tr>
                </tbody>
              )}
            </CommonTable>
            {paginationData.totalPages > 1 && (
              <CustomPagination
                className="ms-auto"
                activePage={page}
                totalPage={paginationData.totalPages}
                setActivePage={setPage}
                maxVisiblePages={5}
              />
            )}
          </div>
        </Container>
      </section>
    </>
  );
};
export default LeaderBoards;
