export const RoutesUrls = {
  Login: "/login",
  Signup: "/signup",
  // Artist_page: "/artist",
  // Collection: "/collection",
  Curated_Drop_page: "/curated-drops",
  // Digital_art: "/digital-art",
  Contact_us: "/contact-us",
  // Nax: "/nax",
  // Artist_profile: "/artist-profile",
  // Marketplace_digital: "/marketplace-digitalart",
  User_account_setting: "/useraccount-setting",
  Wallet: "/wallet",
  // User_Profile: "/user-profile",
  Forgot: "forgot",
  Reset_password: "reset-password",
  User_dashboard: "/dashboard",
  Link_devices: "/display-devices",
  // Transaction_status: "/transaction-status",
  Notification: "/notification",
  LeaderBoard: "leader-boards",
  Create: "/create",
  Curated_drops: "/curated-drops",
  // Upcoming_drop: "/upcoming-drop",
  Previous_drop: "/previous-drops",
  // curatedDrop: "/curated-drop",
  temporaryMarketplaceRoute: "/currated-drops",
  Marketplace: "/marketplace",
  // Marketplace_demo: "/marketplace_demo",
  // Artist_dashboard: "/artist-dashboard",
  Recent_activity: "activity",
  Terms_of_use: "/terms_of_use",
  Shiping_policy: "/shipping-policy",
  Refund_return_policy: "/refund-returns-policy",
  PrivacyPolicy: "/privacy_policy",
  About_us: "/about-us",
  FlauntPage: "/flaunt-nfts",
  NewArtXApp: "/newartx-app",
  // Buy_display: "/buy-display",
  About_dfa: "/about-dfa",
  About_newArtX: "/about-newartx",
  Faq: "/faq",
  Coming_Soon: "/coming-soon",
  Art_detail_secondary_marketplace: "/marketplace/art/:id",
  Stats: "/stats",
  // Other_user_profile: "/Other-userprofile/:id",
  Curated_checkout: "/checkout/art/:id",
  Curated_drop_digital_art: "/curated-drop/digital-art/:type/:id",
  Drops_id: "/drop/:id/:type",
  News: "/news",
  digitalFineArts: "/digital-fine-arts",
  Checkout: "/checkout/:id",
  Howflaunt: "/how-to-flaunt",
};
