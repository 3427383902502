import React from "react";
import Slider from "react-slick";
import styles from "./CommonSlider.module.scss";
const CommonSlider = (props) => {
  var settings = {
    arrows: props.arrows,
    dots: props.dots,
    infinite: props.infinite,
    speed: props.speed ? props.speed : 1000,

    slidesToShow: props.slidesToShow,
    slidesToScroll: props.slidesToScroll,
    autoplay: props.autoplay,
    // rest
    autoplaySpeed: props.autoplaySpeed ? props.autoplaySpeed : 2000,
    draggable: false,
    focusOnSelect: false,
    pauseOnFocus: false,
    pauseOnHover: props?.pauseOnHover ? props?.pauseOnHover : false,
    responsive: props.responsive ? props.responsive : null,
  };
  return (
    <>
      <Slider
        {...settings}
        className={`${styles.commonSlider} ${props.className}`}
      >
        {props.children}
      </Slider>
    </>
  );
};
export default CommonSlider;
