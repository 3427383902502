import { createSlice } from "@reduxjs/toolkit";
import { authService } from "../../Services/authService";
import { startLoading, stopLoading } from "../loading/loadingSlice";
import { saveIp, saveLocation } from "../persist/persistSlice";

const initialState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
});

export const getIp = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        const response = await authService.getIp();
        resolve(response);
        dispatch(saveIp(response?.data?.ip));
        dispatch(
          saveLocation(`${response?.data?.city}, ${response?.data?.region}`)
        );
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export default authSlice.reducer;
