import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ArtImagesleft from "./Component/ArtImagesleft/ArtImagesleft";
import CheckoutDetails from "./Component/CheckoutDetails/CheckoutDetails";
import style from "./NewCheckout.module.scss";
import { getArtDetailById } from "../../../Features/marketplace/marketplaceSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const NewCheckout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [artDetail, setArtDetail] = useState({});

  useEffect(() => {
    getArtDetailByIdHandler();
  }, [id]);

  const getArtDetailByIdHandler = async () => {
    let params = {
      id: id,
    };
    try {
      const response = await dispatch(getArtDetailById(params));
      setArtDetail(response.data.data);
      //   getCouponListFunction(response.data.data.id);
    } catch (err) {
      navigate(-1);
    }
  };
  return (
    <>
      <section className={style.newcheckout}>
        <Container>
          <div className={style.newcheckout_inner}>
            <div className={style.newcheckout_innerLeftSide}>
              <ArtImagesleft artDetail={artDetail} />
            </div>
            <div className={style.newcheckout_innerRightSide}>
              <CheckoutDetails artDetail={artDetail} />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default NewCheckout;
