import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import style from "./Earnings.module.scss";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import Earningstable from "./Commontable/Earningstable";
import { getEarningsList } from "../../../../Features/user/userSlice";

import { useDispatch } from "react-redux";
import moment from "moment";
import {
  EVENT_KEYS_FOR_TABS,
  PER_PAGE_RECORD_LIMIT,
} from "../../../../Constant/userConstant";

const Earnings = () => {
  const [tab, setTab] = useState(EVENT_KEYS_FOR_TABS.TWENTYFOUR_HOURS);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [earningsData, setEarningsData] = useState({});
  const [earningsList, setEarningsList] = useState([]);
  const [page, setPage] = useState(1);
  const elementToScroll = useRef(null);

  const dispatch = useDispatch();

  const initialValue = {
    datarange: "",
  };
  const validationSchema = Yup.object({
    datarange: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  const dateConversion = async () => {
    const currentDate = new Date();
    let endDate = new Date(currentDate);
    let startDate;
    switch (tab) {
      case EVENT_KEYS_FOR_TABS.TWENTYFOUR_HOURS:
        startDate = new Date(endDate);
        startDate.setHours(endDate.getHours() - 24);
        break;
      case EVENT_KEYS_FOR_TABS.SEVEN_DAYS:
        startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 7);
        break;
      case EVENT_KEYS_FOR_TABS.THREE_MONTH:
        startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 3);
        break;
      case EVENT_KEYS_FOR_TABS.SIX_MONTH:
        startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      case EVENT_KEYS_FOR_TABS.TWELVE_MONTH:
        startDate = new Date(endDate);
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        startDate = new Date(endDate);
        startDate.setHours(endDate.getHours() - 24);
        break;
    }
    return { startDate, endDate };
  };

  const tabChange = async (tab) => {
    setTab(tab);
  };

  useEffect(() => {
    if ((startDate !== null && endDate !== null) || tab) {
      getEarningsListFun();
    }
  }, [endDate, tab, page]);

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const getEarningsListFun = async () => {
    let params;
    const dateT = await dateConversion();
    // For calender option
    if (startDate !== null && endDate !== null) {
      params = {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.TWENTYFOUR_HOURS) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.SEVEN_DAYS) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.THREE_MONTH) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.SIX_MONTH) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    } else if (tab == EVENT_KEYS_FOR_TABS.TWELVE_MONTH) {
      params = {
        startDate: moment(dateT.startDate).format("YYYY-MM-DD"),
        endDate: moment(dateT.endDate).format("YYYY-MM-DD"),
        page: page,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
    }

    try {
      let response = await dispatch(getEarningsList(params));
      setEarningsData(response?.data);
      setEarningsList(response?.data?.list);
      scrollToElement();
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const handleClean = () => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      setTab("24hours");
    }
  };

  return (
    <>
      <section className={style.earnings} ref={elementToScroll}>
        {/* <button type="button" onClick={()=>downloadPdf()} >CLick me</button> */}
        <div className={style.tablist}>
          <div className={style.calander_field}>
            <form onSubmit={formik.handleSubmit}>
              <FormikControls
                control="daterange"
                name="daterange"
                startDate={startDate}
                endDate={endDate}
                placeholder="From Date - To Date"
                formik={formik}
                className={`${style.createInputBox}`}
                onChange={(selectedDate) => {
                  setDateRange(selectedDate);
                  handleClean();
                }}
                onFocus={() => {
                  setTab("");
                }}
                isClearable={true}
              />
            </form>
          </div>
          <Tabs
            activeKey={tab}
            defaultActiveKey="24hours"
            id="uncontrolled-tab-example"
            className="tablists"
            onSelect={(e) => tabChange(e)}
          >
            <Tab eventKey="24hours" title="24 hours">
              {/* {tab === "24hours" && ( */}
              <Earningstable
                earningsData={earningsData}
                earningsList={earningsList}
                setPage={setPage}
                page={page}
              />
              {/* )} */}
            </Tab>
            <Tab eventKey="7days" title="7 days">
              {tab === "7days" && (
                <Earningstable
                  earningsData={earningsData}
                  earningsList={earningsList}
                  setPage={setPage}
                  page={page}
                />
              )}
            </Tab>
            <Tab eventKey="3month" title="3 months">
              {tab === "3month" && (
                <Earningstable
                  earningsData={earningsData}
                  earningsList={earningsList}
                  setPage={setPage}
                  page={page}
                />
              )}
            </Tab>

            <Tab eventKey="6month" title="6 months">
              {tab === "6month" && (
                <Earningstable
                  earningsData={earningsData}
                  earningsList={earningsList}
                  setPage={setPage}
                  page={page}
                />
              )}
            </Tab>
            <Tab eventKey="12month" title="12 months">
              {tab === "12month" && (
                <Earningstable
                  earningsData={earningsData}
                  earningsList={earningsList}
                  setPage={setPage}
                  page={page}
                />
              )}
            </Tab>
          </Tabs>
          {tab == "" && (
            <Earningstable
              earningsData={earningsData}
              earningsList={earningsList}
              setPage={setPage}
              page={page}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Earnings;
