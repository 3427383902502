import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../../../../Assets/images/logo-black.png';
import style from './NewArtXBanner.module.scss';

const NewArtXBanner = () => {
    return (
        <>
            <section className={style.newArtXBanner}>
                <Container>
                    <div className={style.newArtXBannerCenter}>
                        <div className={style.bannerTopHead}>
                            <img src={logo} alt="" />
                        </div>
                        <div className={style.newArtXBanner_content}>
                            <div className={style.aboutText}>
                                <p>Welcome to NewArtX, a curated Digital Fine Art platform that is redefining the boundaries of art and technology. We are the
                                    helm of a unique concept in India, transforming the rich tapestry of fine art into digital masterpieces and offering them as
                                    investable assets.</p>
                                <h5>A wide spectrum of Digital Fine Art</h5>
                                <p>NewArtX is a dynamic marketplace that revels in the multifaceted world of art. Our collection spans from the esteemed works of
                                    prominent artists across Asia to the compelling pieces of emerging talents. We curate an extensive range of art that
                                    encapsulates a universal essence, transcending geographic boundaries. Our mission is to democratise access to these treasures,
                                    transcending the limitations of physical boundaries and conventional art markets.</p>
                            </div>
                            {/* <CommonBtn
                                type="button"
                                role="btn"
                                to="#"
                                title='Learn More'
                                icon={<ArrowRight />}
                                className={` ${style.btnLearn}`}
                            /> */}
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default NewArtXBanner