import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import style from "./Accountactivity.module.scss";
import Requestedit from "./Component/Requestedit";
import Requesthistory from "./Component/Requesthistory";
import { useDispatch } from "react-redux";
import { PER_PAGE_RECORD_LIMIT } from "../../../../Constant/userConstant";
import { getAccountActivity } from "../../../../Features/user/userSlice";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";

const Accountactivity = () => {
  const dispatch = useDispatch();
  const [filterKycStatus, setFilterKycStatus] = useState(1);
  const [accountDetail, setAccountDetail] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    AccountLists();
  }, [filterKycStatus, page]);

  const AccountLists = async () => {
    let params = {
      limit: PER_PAGE_RECORD_LIMIT.PREVIOUS_DROP_LIST_PER_PAGE_LIMIT,
      page: page,
      status: filterKycStatus,
    };
    try {
      //Calling get artist list API
      const response = await dispatch(getAccountActivity(params));
      setAccountDetail(response.data.data.artistRequestData);
      setTotalPages(response.data.data.totalPages);
    } catch (err) {}
  };

  return (
    <>
      <section className={style.account_activity}>
        <Tabs
          defaultActiveKey="requestedit"
          id="uncontrolled-tab-example"
          className="tablists"
          activeKey={filterKycStatus}
          onSelect={(e) => {
            setAccountDetail([]);
            setFilterKycStatus(e);
          }}
        >
          <Tab eventKey="1" title="Request Edit">
            <Requestedit
              accountDetail={accountDetail}
              AccountLists={AccountLists}
            />
          </Tab>
          <Tab eventKey="0" title="Request History">
            <Requesthistory accountDetail={accountDetail} />
          </Tab>
        </Tabs>
        {totalPages > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPages}
            setActivePage={setPage}
            maxVisiblePages={3}
          />
        )}
      </section>
    </>
  );
};

export default Accountactivity;
