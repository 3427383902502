import React from "react";
import CommonModal from "../CommonModal/CommonModal";
import FormikControls from "../Formik/FormikControls";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./ApplyCreateModal.module.scss";
import CommonBtn from "../CommonBtn/CommonBtn";
import {
  FBIcon,
  InstaIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../../../../Assets/svgIcons/SVGicon";
import PropTypes from "prop-types";

const ApplyCreateModal = (props) => {
  const { show, onHide } = props;

  const initialValue = {
    fname: "",
    lname: "",
    date: "",
    about: "",
  };
  const validationSchema = Yup.object({
    fname: Yup.string().required("Required"),
    lname: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    about: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => { },
  });



  return (
    <CommonModal className={styles.createModal} show={show} onHide={onHide}>
      <h2>Apply To Create</h2>
      <p className={styles.subtitleText}>
        Tell us about yourself and join the community
      </p>
      <form onSubmit={formik.handleSubmit}>
        <FormikControls
          control="input"
          type="text"
          name="fname"
          placeholder="First Name"
          formik={formik}
          variant={`${styles.createInputBox}`}
        />
        <FormikControls
          control="input"
          type="text"
          name="lname"
          placeholder="Last Name"
          formik={formik}
          variant={`${styles.createInputBox}`}
        />
        <FormikControls
          control="date"
          type="text"
          name="date"
          placeholder="DD | MM | YYYY"
          formik={formik}
          className={`${styles.createInputBox}`}
        />
        <FormikControls
          control="textarea"
          type="text"
          name="about"
          placeholder="Tell Us About You."
          formik={formik}
          className={`${styles.createInputBox}`}
        />
        <ul className={styles.socialLinks}>
          <li>
            <FormikControls
              control="input"
              type="text"
              name="insta"
              formik={formik}
              className={`${styles.createInputBox}`}
              leftIcon={<InstaIcon />}
            />
          </li>
          <li>
            <FormikControls
              control="input"
              type="text"
              name="insta"
              formik={formik}
              className={`${styles.createInputBox}`}
              leftIcon={<TwitterIcon />}
            />
          </li>
          <li>
            <FormikControls
              control="input"
              type="text"
              name="insta"
              formik={formik}
              className={`${styles.createInputBox}`}
              leftIcon={<YoutubeIcon />}
            />
          </li>
          <li>
            <FormikControls
              control="input"
              type="text"
              name="insta"
              formik={formik}
              className={`${styles.createInputBox}`}
              leftIcon={<FBIcon />}
            />
          </li>
        </ul>
        <label className={styles.browse_file} htmlFor="file">
          <input id="file" type="file" />
          <p>
            Drag & Drop Attachments OR <span>Browse</span>
          </p>
        </label>
        <FormikControls
          control="checkbox"
          type="checkbox"
          name="terms"
          label={
            <>
              I agree to NewArt X’s <a href="/home">terms and conditions</a> and{" "}
              <a href="/home">privacy policy</a>.
            </>
          }
          formik={formik}
          className={styles.terms_input}
        />
        <CommonBtn title="Submit" role="btn" className="btn-yellow" />
      </form>
    </CommonModal>
  );
};

ApplyCreateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ApplyCreateModal;
