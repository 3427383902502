import React from 'react'
import {  Container} from 'react-bootstrap';
import CommonSlider from '../../../Common/Ui/CommonSlider/CommonSlider';
import styles from './NiftyArtxArticles.module.scss';

const NiftyArtxArticles = () => {
    const articles = [
        {
            id: 1,
            title: "Regulatory",
            content: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."]
        },
        {
            id: 2,
            title: "Compliances",
            content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit']
        },
        {
            id: 3,
            title: "Anything Else",
            content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in vol']
        },
        {
            id: 4,
            title: "Anything Else",
            content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in vol']
        }
    ]
    return (
        <>
            <section className={styles.articlesSec}>
                <Container>
                    <CommonSlider
                        dots={false}
                        autoplay={true}
                        slidesToShow={3}
                        arrows={true}
                        slidesToScroll={1}
                        className={styles.articlesSecSlider}
                        responsive={[
                            {
                                breakpoint: 991,
                                settings: {
                                    slidesToShow: 2
                                }
                            },
                            {
                                breakpoint: 575,
                                settings: {
                                    slidesToShow: 1
                                }
                            }
                        ]}
                    >
                        {
                            articles.map(article => (
                                <div className={styles.ArticleBox} key={article.id}>
                                    <h2>{article.title}</h2>
                                    <div className={styles.articlesSecContent}>
                                    {
                                        article.content.map(item => (
                                            <p key={item}>{item}</p>
                                        ))
                                    }
                                    </div>
                                </div>
                            ))
                        }
                    </CommonSlider>
                </Container>
            </section>
        </>
    )
}

export default NiftyArtxArticles
