import React from 'react'
import Slide1 from "../../../../Assets/images/drop.jpg";
import Slide2 from "../../../../Assets/images/drop_1.jpg";
import Slide3 from "../../../../Assets/images/drop_3.jpg";
import Slide4 from "../../../../Assets/images/drop_4.jpg";
import styles from "./WhoCanApply.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";

const WhoCanApply = () => {
    var settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 7000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 1,
        cssEase: "linear",
        vertical: true,
        draggable: false,
        focusOnSelect: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        slidesToScroll: 1,
        centerMode: true,
        initialSlide: 1,
    };
    const leftSliderImages = [
        {
            id: 1,
            image: Slide1,
        },
        {
            id: 2,
            image: Slide2,
        },
        {
            id: 3,
            image: Slide3,
        },
    ]
    const RightSliderImages = [
        {
            id: 1,
            image: Slide1,
        },
        {
            id: 2,
            image: Slide4,
        },
        {
            id: 3,
            image: Slide3,
        },
    ]
    return (
        <section className={`${styles.whoCanApply}`}>
            <Container>
                <Row className='align-items-center'>
                    <Col xl={8} className="order-xl-last">
                        <Row>
                            <Col xs={6} className="pe-xl-3">
                                <Slider {...settings} className={styles.whoCanApplyLeftSlider}>
                                    {
                                        leftSliderImages.map(item => (
                                            <div key={item.id} className={styles.whoCanApplyLeftSliderSlide}>
                                                <img src={item.image} alt="Slide1" />
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </Col>
                            <Col xs={6} className="ps-xl-3">
                                <Slider {...settings} className={styles.whoCanApplyRightSlider}>
                                    {
                                        RightSliderImages.map(item => (
                                            <div key={item.id} className={styles.whoCanApplyRightSliderSlide}>
                                                <img src={item.image} alt="Slide1" />
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} className={styles.whoCanApplyRight}>
                        <h2>
                            Why <br className='d-xl-block d-none' />List with<br className='d-xl-block d-none' /> NewArtX?

                        </h2>
                        <p>
                            <span>Exclusive Focus: </span>
                            NewArtX is solely dedicated to Digital Fine Art, ensuring a pure and immersive experience for both artists and collectors. We have no
                            interest in creating or selling physical art—our passion lies solely in the digital realm.
                        </p>
                        <p>
                            <span>Boundless Creativity: </span>
                            With NewArtX, artists enjoy unparalleled creative freedom. Our platform embraces Dynamic DFAs, empowering creators to craft digital
                            masterpieces that transcend boundaries and integrate seamlessly with real-time events and evolving narratives.
                        </p>
                        <p>
                            <span>Empowering Artists: </span>
                            At NewArtX, we believe in the power of art to transform lives. That’s why we ensure that artists receive royalties on each DFA
                            transaction, creating an ongoing revenue stream that sustains them and benefits future generations.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default WhoCanApply
