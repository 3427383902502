import React, { useEffect, useState } from "react";
import style from "./Accountinformation.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import Commonprofileup from "../../../../Common/Ui/Commonprofileup/Commonprofileup";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import { inputFieldValidation } from "../../../../../Constant/formikConstant";
import "yup-phone";
import { fileUpload } from "../../../../../Helper/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAnonymousUserFunction,
  EditAnonymousUserFunction,
} from "../../../../../Features/Anonymous/anonymousUserSlice";
import personIcon from "../../../../../Assets/images/person_icon.jpg";
import CopyToClipboard from "react-copy-to-clipboard";
import { toasts } from "../../../../Common/Toast/Toast";
import { getUserProfile } from "../../../../../Features/user/userSlice";
import TextEditor from "../TextEditor/TextEditor";

const Accountinformation = ({ saveAnonymousUserData, getAnonymousDetail }) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState("");
  const [length, setLength] = useState(0);

  let initialValue = {
    file: "",
    firstName: "",
    middleName: "",
    lastName: "",
    username: "",
    bio: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      // .matches(/^[^\s].*$/, "*First name cannot start with a space")
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*First name should not start or end with a space"
      )
      .min(
        inputFieldValidation.firstName.minLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .max(
        inputFieldValidation.firstName.maxLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .required(inputFieldValidation.commonMessage.required("First name"))
      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed"),

    middleName: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Middle name should not start or end with a space"
      )
      .min(
        inputFieldValidation.middleName.minLength,
        `Must be between ${inputFieldValidation.middleName.minLength}-${inputFieldValidation.middleName.maxLength} letters`
      )
      .max(
        inputFieldValidation.middleName.maxLength,
        `Must be between ${inputFieldValidation.middleName.minLength}-${inputFieldValidation.middleName.maxLength} letters`
      )
      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed"),

    lastName: Yup.string()
      // .trim("*Last Name cannot start with a space")
      // .strict(true)

      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Last name should not start or end with a space"
      )
      .required(inputFieldValidation.commonMessage.required("Last name"))
      .min(
        inputFieldValidation.lastName.minLength,
        `Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      )
      .max(
        inputFieldValidation.lastName.maxLength,
        `Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      )
      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed"),

    username: Yup.string()
      .matches(
        inputFieldValidation.username.regex.spaceExpression,
        inputFieldValidation.username.regex.spaceErrorMessage
      )
      .required(inputFieldValidation.commonMessage.required("Username"))
      .matches(
        inputFieldValidation.username.regex.expresion,
        inputFieldValidation.username.regex.errorMessage
      )
      .min(
        inputFieldValidation.username.minLength,
        `Must be between ${inputFieldValidation.username.minLength}-${inputFieldValidation.username.maxLength} letters`
      )
      .max(
        inputFieldValidation.username.maxLength,
        `Must be between ${inputFieldValidation.username.minLength}-${inputFieldValidation.username.maxLength} letters`
      )
      .test("maxTwoDot", "*Maximum two periods(.) are allowed", (value) => {
        if (value == undefined || value?.split(".").length > 3) {
          return false;
        }
        return true;
      })
      .test(
        "noStartOrEndDot",
        "Periods (.) are not allowed at the start or end",
        (value) => {
          if (value?.startsWith(".") || value?.endsWith(".")) {
            return false;
          }
          return true;
        }
      ),
    bio: Yup.string().test(
      `minMaxLengthCheckk`,
      `*Bio must be between ${inputFieldValidation.bio.minLength}-${inputFieldValidation.bio.userMaxLength}`,
      (value) => {
        const { minLength, userMaxLength } = inputFieldValidation.bio;
        return length >= minLength && length <= userMaxLength;
      }
    ),
    file: Yup.mixed()
      .test(
        "fileFormat",
        "*File must be of type jpeg, jpg , png",
        function (value) {
          if (typeof value == "object") {
            if (!value) return true; // Allow empty value
            const allowedExtensions = [".jpg", ".jpeg", ".png", ".JPG"];

            const fileExtension = value.name
              .substring(value.name.lastIndexOf("."))
              .toLowerCase();
            return allowedExtensions.includes(fileExtension);
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "File size is too large", function (value) {
        if (typeof value == "object") {
          if (!value) return true; // Allow empty value
          const maxSizeInBytes = 5 * 1024 * 1024; // 2MB (adjust as needed)
          return value.size <= maxSizeInBytes;
        } else {
          return true;
        }
      }),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      Object.keys(saveAnonymousUserData).length === 0
        ? AddAnonymous(values)
        : EditAnonymous(values);
    },
  });

  useEffect(() => {
    if (Object.keys(saveAnonymousUserData).length > 0) {
      if (saveAnonymousUserData?.image_path) {
        setPreview(
          `${process.env.REACT_APP_IMG_BASE_URL}${saveAnonymousUserData.image_path}`
        );
      }
      formik.setFieldValue(
        "bio",
        saveAnonymousUserData?.bio ? saveAnonymousUserData?.bio : ""
      );
      formik.setFieldValue(
        "firstName",
        saveAnonymousUserData.first_name ? saveAnonymousUserData.first_name : ""
      );
      formik.setFieldValue(
        "middleName",
        saveAnonymousUserData.middle_name
          ? saveAnonymousUserData.middle_name
          : ""
      );
      formik.setFieldValue(
        "lastName",
        saveAnonymousUserData.last_name ? saveAnonymousUserData.last_name : ""
      );
      formik.setFieldValue(
        "username",
        saveAnonymousUserData.username ? saveAnonymousUserData.username : ""
      );
    }
  }, [saveAnonymousUserData]);

  const handleKeyDownTextArea = (event) => {
    // Prevent Enter key press
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const AddAnonymous = async (values) => {
    let formData = new FormData();
    formData.append("first_name", values?.firstName);
    formData.append("middle_name", values?.middleName);
    formData.append("last_name", values?.lastName);
    formData.append("username", values?.username);
    formData.append("bio", values?.bio);
    formData.append("profilePic", values?.file);
    try {
      let response = await dispatch(AddAnonymousUserFunction(formData));
      if (response?.status == 200) {
        formik.resetForm();
        getAnonymousDetail();
        getUserPersonalDetails();
      }
    } catch (err) {}
  };

  const EditAnonymous = async (values) => {
    let formData = new FormData();
    formData.append("first_name", values?.firstName);
    formData.append("middle_name", values?.middleName);
    formData.append("last_name", values?.lastName);
    formData.append("username", values?.username);
    formData.append("bio", values?.bio);
    formData.append("profilePic", values?.file);
    try {
      let response = await dispatch(EditAnonymousUserFunction(formData));
      if (response?.status == 200) {
        getAnonymousDetail();
        getUserPersonalDetails();
      }
    } catch (err) {
      toasts.error(err.response?.data?.message);
    }
  };

  const handleCopy = (text, result) => {
    if (result) {
      toasts.success("Text copied to clipboard");
    } else {
      toasts.error("Failed to copy text");
    }
  };

  const getUserPersonalDetails = () => {
    try {
      dispatch(getUserProfile());
    } catch (err) {}
  };

  return (
    <>
      <section className={style.account_information}>
        <form onSubmit={formik.handleSubmit}>
          <div className={style.userImage}>
            <Commonprofileup
              texttitle="Update Photo"
              imageUp={preview ? preview : personIcon}
              id="file"
              name="file"
              onBlur={formik.handleBlur}
              onChange={(event) => {
                fileUpload.yupValidationImage(
                  event,
                  formik,
                  setPreview,
                  "file"
                );
              }}
              title={preview == "" ? "No file choosen" : ""}
            />
            {formik?.touched.file && (
              <p className="error_message">{formik?.errors.file}</p>
            )}
            <h4 className="size_tag">Max accepted size 5 MB</h4>
          </div>
          <div className={style.displayfield}>
            <h6>Display Name</h6>
            <FormikControls
              variant={
                formik?.errors["firstName"]
                  ? "border_input error_space"
                  : "border_input"
              }
              control="input"
              label="First Name"
              type="text"
              name="firstName"
              formik={formik}
              placeholder="Enter First Name"
              onBlur={formik.handleBlur}
              value={formik?.values?.firstName}
              onChange={formik.handleChange}
            />
            <FormikControls
              variant={
                formik?.errors["middleName"]
                  ? "border_input error_space"
                  : "border_input"
              }
              control="input"
              label="Middle Name (Optional)"
              type="middleName"
              name="middleName"
              formik={formik}
              placeholder="Enter Middle Name"
              onBlur={formik.handleBlur}
              value={formik.values.middleName}
              onChange={formik.handleChange}
            />
            <FormikControls
              variant={
                formik?.errors["lastName"]
                  ? "border_input error_space"
                  : "border_input"
              }
              control="input"
              label="Last Name"
              type="text"
              name="lastName"
              formik={formik}
              placeholder="Enter Last Name"
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </div>
          <FormikControls
            variant="input_spaces"
            className="input_border"
            control="input"
            label="Username"
            type="text"
            name="username"
            formik={formik}
            placeholder="Enter Username"
            onBlur={formik.handleBlur}
            value={formik.values.username}
            onChange={formik.handleChange}
          />
          {Object.keys(saveAnonymousUserData).length !== 0 && (
            <CopyToClipboard
              text={`${process.env.REACT_APP_USER_PROFILE}${saveAnonymousUserData?.username}`}
              onCopy={handleCopy}
            >
              <p className={style.profile_url}>
                Profile URL:{" "}
                {`${process.env.REACT_APP_USER_PROFILE}${saveAnonymousUserData?.username}`}
              </p>
            </CopyToClipboard>
          )}
          <div className={style.bio_text}>
            <TextEditor
              className={"input_spaces mb-3"}
              label={"Bio"}
              id={"bio"}
              name={"bio"}
              placeholder="Enter Bio"
              formik={formik}
              setLength={setLength}
              maxLength={inputFieldValidation.bio.userMaxLength}
            />
            <span className="infoBio">
              {length}/{inputFieldValidation.bio.userMaxLength}
            </span>
          </div>
          <div className={style.btnsave}>
            <CommonBtn
              role="btn"
              className={`black-btn ${style.btnsize}`}
              title="Submit"
              type={"submit"}
            />
          </div>
        </form>
      </section>
    </>
  );
};

export default Accountinformation;
