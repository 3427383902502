import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../Assets/images/logo.png";
import * as Yup from "yup";
import FormikControls from "../Ui/Formik/FormikControls";
import { useDispatch } from "react-redux";
import { ArrowRight } from "../../../Assets/svgIcons/SVGicon";
import style from "./Footer.module.scss";
import { emailSubscribe } from "../../../Features/user/userSlice";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import SocialMediaIcon from "../Ui/SocialMediaIcon/SocialMediaIcon";
import { RoutesUrls } from "../../../Constant/RoutesUrls";

const Footer = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Reset form state when component unmounts
    return () => {
      emailSubScribeFormik.resetForm();
    };
  }, []);

  useEffect(() => {
    // Reset form state on location change
    emailSubScribeFormik.resetForm();
  }, [location]);

  const emailSubScribeSchema = Yup.object({
    subcribeEmail: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .test("ChecKValidEmail", "Invalid Email", (value) => {
        return isValidEmail(value);
      })
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),
  });
  const emailSubScribeFormik = useFormik({
    validationSchema: emailSubScribeSchema,
    initialValues: {
      subcribeEmail: "",
    },
    onSubmit: (values) => {
      emailSubscribeHandle(values);
    },
  });

  const isValidEmail = (email) => {
    if (email) {
      const emailParts = email?.split("@");
      // Check if there's exactly one '@' symbol and both parts are non-empty
      if (
        emailParts.length !== 2 ||
        emailParts[0] === "" ||
        emailParts[1] === ""
      ) {
        return false;
      }
      const domainPart = emailParts[1];
      const domainParts = domainPart?.split(".");
      // Invalidate if there are more than two parts in the domain
      if (domainParts.length > 2) {
        return false;
      }
      // Check for special characters in the email
      const specialCharsRegex = /[#$%^*+!]/;
      if (specialCharsRegex.test(email)) {
        return false;
      }
      return true;
    }
  };

  const emailSubscribeHandle = async (values) => {
    let data = {
      email: values.subcribeEmail,
    };
    try {
      const result = await dispatch(emailSubscribe(data));
      emailSubScribeFormik.resetForm();
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      <div className={style.footerSec}>
        <Container>
          <div className={style.footerSec_topHead}>
            <Link className={style.footerLogo} to="">
              <img src={Logo} alt="Logo" />
            </Link>
            <p className={style.details}>
              A curated Digital Fine Art marketplace that is redefining the
              boundaries of Fine Art by leveraging technology and blockchain. We
              aim to create an end to end ecosystem of Digital Fine Art - from
              Fine Art digital creation to exchange to display.
            </p>
            <div className={style.searchInput}>
              <form onSubmit={emailSubScribeFormik.handleSubmit}>
                <FormikControls
                  label="Subscribe and be the first to know about to our exclusive drop."
                  variant={`mb-0 ${style.inputBox}`}
                  className="input_border"
                  control="input"
                  type="email"
                  name="subcribeEmail"
                  id="subcribeEmail"
                  placeholder="Your Email Address"
                  onChange={emailSubScribeFormik.handleChange}
                  onBlur={emailSubScribeFormik.handleBlur}
                  value={emailSubScribeFormik.values.subcribeEmail}
                  formik={emailSubScribeFormik}
                  // rightIcon={
                  //     <ArrowRight />
                  // }
                  // rightIconClick={}
                  btntype="sumbit"
                  btnInner={<ArrowRight />}
                />
              </form>
            </div>
          </div>
          <div className={style.footerSec_bottomHead}>
            <Row className={style.rowBottom}>
              <Col xl={2} lg={3} md={3} sm={6} xs={12} className="orderSide">
                <h6 className={style.elephInfo}>&copy;Elephant Canvas LLP</h6>
              </Col>
              <Col xl={8} lg={6} md={6} sm={8} xs={12}>
                <ul className={style.menuListing}>
                  <li>
                    <Link
                      className={style.navLink}
                      to={RoutesUrls.Terms_of_use}
                    >
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={style.navLink}
                      to={RoutesUrls.PrivacyPolicy}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  {/* <li>
                    <Link className={style.navLink} to="">
                      Careers
                    </Link>
                  </li> */}
                </ul>
              </Col>
              <Col xl={2} lg={3} md={3} sm={4} xs={12}>
                <SocialMediaIcon
                  className={` ${style.flex_changes}`}
                  AsFooter
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
