import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Container, Row } from "react-bootstrap";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import { Link, useNavigate } from "react-router-dom";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import styles from "./login.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, openPopUp } from "../../../Features/user/userSlice";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import { QR_LOGIN_TEXT } from "../../../Constant/userConstant";
import { RoutesUrls } from "../../../Constant/RoutesUrls";
import CommonModal from "../../Common/Ui/CommonModal/CommonModal";
import TwoFaAuthentication from "./TwoFaAuthentication";

const Login = (props) => {
  //Other
  const navigate = useNavigate();
  const ip = useSelector((state) => state?.persist?.ip);

  // redux
  const dispatch = useDispatch();
  const buttonStatus = useSelector((state) => state?.loading?.disable);
  const saveDataSignup = useSelector((state) => state?.users?.signUpData);
  const nextRoute = useSelector((state) => state?.users?.nextRoute);
  const [loginUsingQrText, setLoginUsingQrText] = useState("");
  const loginCase = useSelector((state) => state?.users?.loginCase);

  //state

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let qrLogin = queryParams.get("qr_login");
    setLoginUsingQrText(qrLogin);
  }, []);

  //Formik
  const LoginSchema = Yup.object({
    username: Yup.string().required(
      inputFieldValidation.commonMessage.required("Email/Username")
    ),
    password: Yup.string().required(
      inputFieldValidation.commonMessage.required("Password")
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: LoginSchema,
    initialValues: {
      username: saveDataSignup?.email,
      password: saveDataSignup?.password,
      deviceType: 0,
    },
    onSubmit: (values) => {
      values.username = values.username.trim();
      let obj = {
        ipAddress: ip,
        ...values,
      };
      dispatch(loginAction(obj)).then((response) => {
        if (response?.status === 200) {
          // props.setEmail(values.username);
          if (
            response.data.data.is_email_auth_enabled === 0 &&
            response.data.data.is_sms_auth_enabled === 0
          ) {
            if (loginUsingQrText == QR_LOGIN_TEXT) {
              navigate(RoutesUrls.Link_devices);
            } else {
              if (nextRoute != "") {
                navigate(nextRoute);
              }
            }
          }
        }
      });
    },
  });

  return (
    <>
      <section className={styles.login}>
        <Container>
          <div className={styles.login__Section}>
            <div className={styles.Heading}>
              <h2>Welcome Back</h2>
              <p>Discover and own exquisite DFAs from talented Fine Artists.</p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormikControls
                    variant="emailField"
                    control="input"
                    type="username"
                    name="username"
                    id="username"
                    placeholder="Email / Username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    formik={formik}
                  />
                  {/* {formik.touched.username && (
              <p className="error_message">{formik.errors.username}</p>
            )} */}
                </Col>
                <Col xs={12}>
                  <FormikControls
                    className="passwordInput"
                    rightIconClass="iconWhite"
                    control="password"
                    name="password"
                    placeholder="Password"
                    variant="dark black-eye-icon"
                    id="something"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    formik={formik}
                  />
                  {/* {formik.touched.password && (
              <p className="error_message">{formik.errors.password}</p>
            )} */}
                </Col>
                <Col xs={12}>
                  <Link
                    className={styles.LoginFormAsStrong}
                    to="#"
                    onClick={() => dispatch(openPopUp("forgot"))}
                  >
                    Forgot Password?
                  </Link>
                </Col>
                <Col xs={12}>
                  <div className={styles.loginBtns}>
                    <CommonBtn
                      className={`${styles.LoginFormbtnHanlde}`}
                      role="btn"
                      title="Log In"
                      disabled={buttonStatus}
                      type="submit"
                    />
                    <p className={styles.LoginFormSignUp}>
                      Don't have an account?{" "}
                      <Link onClick={props.onClick} to={RoutesUrls.Signup}>
                        Sign up.
                      </Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
        {loginCase === "TwoFa" && (
          <CommonModal
            show={true}
            onHide={() => dispatch(openPopUp(""))}
            modalTitle={<>Two Factor Authentication</>}
            className="authTwomodal"
            centered={true}
            subTitle="Enter verification code sent on your registered email and phone number"
          >
            <TwoFaAuthentication email={formik.values.username} />
          </CommonModal>
        )}
      </section>
    </>
  );
};
export default Login;
