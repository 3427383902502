import { createSlice } from "@reduxjs/toolkit";
import { toasts } from "../../Component/Common/Toast/Toast";
import { startLoading, stopLoading } from "../loading/loadingSlice";

import { store } from "../../App/store";
import { offerService } from "../../Services/offerService";
import { initialStateOffer } from "../InitialState";

let token;
const initialState = initialStateOffer;
export const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {},
});

export const {} = offerSlice.actions;

export default offerSlice.reducer;

export const createMakeOffer = (data, isShowSuccessMsg = true) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.createMakeOffer(data, token);
        if (isShowSuccessMsg) {
          toasts.success(response.data.message);
        }
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);

        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const makeOfferList = (params, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        if (params.filter.length == 0) {
          dispatch(startLoading());
        }
        const response = await offerService.makeOfferList(params, token);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getOfferRecievedList = (params, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.getOfferRecievedList(params, token);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const getOfferRecievedListByEdition = (params, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.getOfferRecievedListByEdition(
          params,
          token
        );
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const updateOfferStatus = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.updateOfferStatus(data, token);
        resolve(response);
        toasts.success(response.data.message);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const globalOffer = (data, isShowSuccessMsg = true) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.globalOffer(data, token);
        resolve(response);
        if (isShowSuccessMsg) {
          toasts.success(response.data.message);
        }
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const createCounterOffer = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.createCounterOffer(data, token);
        resolve(response);
        toasts.success(response.data.message);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const acceptOfferOnOff = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.acceptOfferOnOff(data, token);
        resolve(response);
        // toasts.success(response.data.message);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const closeGlobalOffer = (data, token) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await offerService.closeGlobalOffer(data, token);
        resolve(response);
        toasts.success(response.data.message);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
