import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CommonCard from "../../../Common/Ui/CommonCard/CommonCard";
import CommonSlider from "../../../Common/Ui/CommonSlider/CommonSlider";
import styles from "./NewsSec.module.scss";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import { getNewsList } from "../../../../Features/news/newsSlice";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../Helper/common";
import { useNavigate } from "react-router-dom";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";

const NewsSec = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newsListData, setNewsListData] = useState([]);

  useEffect(() => {
    newsListDataHandler();
  }, []);

  const newsListDataHandler = async () => {
    try {
      let response = await dispatch(getNewsList());
      setNewsListData(response?.data?.data?.list);
    } catch (err) {
      console.log("error", err);
    }
  };

  const rsp = [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  return (
    <>
      <section className={styles.NewsSec}>
        <Container>
          {/* <h2>News About NiftyArt X</h2> */}
          {newsListData?.length > 0 && (
            <h3 className={styles.topHeadings}>NewArtX in News</h3>
          )}
          <CommonSlider
            speed={3000}
            className="arrowsDown"
            dots={false}
            autoplay={true}
            slidesToShow={2}
            arrows={newsListData?.length > 2 ? true : false}
            slidesToScroll={1}
            responsive={rsp}
            infinite={newsListData?.length > 2 ? true : false}
            pauseOnHover={true}
          >
            {newsListData?.length > 0 &&
              newsListData.map((item, index) => (
                <CommonCard
                  className={`card_withouthover `}
                  key={index}
                  imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_image}`}
                  to={item.to}
                  title={item.headline}
                  section="news"
                  // title="NewArtX: A Revolutionary Digital Fine Art Platform, Inaugurated by Sangita Jindal"
                  // subtitle={item.subtitle}
                  // notified={item.notified}
                  onClick={() => window.open(item.url, "_blank")}
                  bottomTitle={formatDate(item?.launch_date)}
                  // bottomSubTitle={item.bottomSubTitle}
                />
              ))}
          </CommonSlider>
          {newsListData?.length > 0 && (
            <CommonBtn
              className={styles.viewbtn_market}
              role="link"
              to={RoutesUrls.News}
              title="View All"
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default NewsSec;
