import { React } from "react";
import PropTypes from "prop-types";


export function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <polygon
        points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"
        fill="#000"
      />
    </svg>
  );
}
export function YoutubeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="Group_836"
        data-name="Group 836"
        transform="translate(-1522 -5289)"
      >
        <g id="Group_758" data-name="Group 758" transform="translate(238 983)">
          <circle
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="12.5"
            cy="12.5"
            r="12.5"
            transform="translate(1284 4306)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_489"
          data-name="Path 489"
          d="M-1035.019-970.826c1.54.06,3.08.115,4.619.184a8.269,8.269,0,0,1,1.084.129,1.9,1.9,0,0,1,1.591,1.661,18.009,18.009,0,0,1,.229,3.8c-.03.888-.115,1.775-.2,2.66a1.986,1.986,0,0,1-.9,1.568,2.524,2.524,0,0,1-1.142.333,42.393,42.393,0,0,1-4.665.193c-1.567-.008-3.134-.055-4.7-.106a13.455,13.455,0,0,1-1.505-.154,1.883,1.883,0,0,1-1.617-1.6,15.869,15.869,0,0,1-.244-2.671,22.632,22.632,0,0,1,.151-3.523,6.369,6.369,0,0,1,.144-.781,1.916,1.916,0,0,1,1.694-1.421,40.914,40.914,0,0,1,4.412-.224c.349-.006.7,0,1.047,0Zm-1.448,3.043v4.486l3.9-2.243Z"
          transform="translate(2569.314 6267.306)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="Group_757"
        data-name="Group 757"
        transform="translate(-1291 -4306)"
      >
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(1291 4306)"
          fill="#fff"
        />
        <path
          id="Path_11367"
          data-name="Path 11367"
          d="M-1465.217-1779.093a6.183,6.183,0,0,0,4.434-1.247,3.125,3.125,0,0,1-2.824-2.115,3.466,3.466,0,0,0,1.338-.055,3.032,3.032,0,0,1-2.372-3,3.041,3.041,0,0,0,1.337.356,3.038,3.038,0,0,1-1.308-2.147,2.95,2.95,0,0,1,.39-1.886,8.633,8.633,0,0,0,6.221,3.163c-.011-.305-.036-.592-.028-.879a3.02,3.02,0,0,1,2.19-2.73,2.987,2.987,0,0,1,2.957.764.193.193,0,0,0,.2.051,6.109,6.109,0,0,0,1.665-.632c.033-.019.068-.034.125-.061a2.347,2.347,0,0,1-.488.921c-.226.257-.485.484-.725.719a6.092,6.092,0,0,0,1.591-.438l.033.036c-.244.285-.475.583-.735.852-.209.217-.457.4-.68.6a.271.271,0,0,0-.082.178,8.712,8.712,0,0,1-3.775,7.462,7.812,7.812,0,0,1-3.622,1.363,8.9,8.9,0,0,1-3.658-.266,8.3,8.3,0,0,1-2.124-.954A.712.712,0,0,1-1465.217-1779.093Z"
          transform="translate(2761.075 6102.006)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export function TwitternewIcon() {
  return (
    <svg
      width="25"
      height="25"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 512"
    >
      <path
        className="circle"
        fill="#edc45d"
        d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z"
      />
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M318.64 157.549h33.401l-72.973 83.407 85.85 113.495h-67.222l-52.647-68.836-60.242 68.836h-33.423l78.052-89.212-82.354-107.69h68.924l47.59 62.917 55.044-62.917zm-11.724 176.908h18.51L205.95 176.493h-19.86l120.826 157.964z"
      />
    </svg>
  );
}
export function InstaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="Group_834"
        data-name="Group 834"
        transform="translate(-1300 -5377)"
      >
        <g id="Group_757" data-name="Group 757" transform="translate(16 1071)">
          <circle
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="12.5"
            cy="12.5"
            r="12.5"
            transform="translate(1284 4306)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_737"
          data-name="Group 737"
          transform="translate(1305.453 5382.452)"
        >
          <path
            id="Path_14"
            data-name="Path 14"
            d="M4.7,7.047A2.349,2.349,0,1,1,7.047,9.4,2.349,2.349,0,0,1,4.7,7.047m-1.27,0A3.619,3.619,0,1,0,7.047,3.429,3.619,3.619,0,0,0,3.428,7.047M9.963,3.285a.846.846,0,1,0,.846-.845h0a.846.846,0,0,0-.846.845M4.2,12.784a3.9,3.9,0,0,1-1.309-.242,2.19,2.19,0,0,1-.811-.527,2.174,2.174,0,0,1-.527-.81A3.891,3.891,0,0,1,1.311,9.9c-.034-.743-.041-.966-.041-2.848s.007-2.1.041-2.848a3.921,3.921,0,0,1,.242-1.309,2.19,2.19,0,0,1,.527-.811,2.172,2.172,0,0,1,.811-.527A3.891,3.891,0,0,1,4.2,1.311c.743-.034.966-.041,2.847-.041s2.1.007,2.848.041a3.921,3.921,0,0,1,1.309.242,2.183,2.183,0,0,1,.811.527,2.183,2.183,0,0,1,.527.811A3.892,3.892,0,0,1,12.784,4.2c.034.743.041.966.041,2.848s-.007,2.1-.041,2.848a3.91,3.91,0,0,1-.242,1.309A2.333,2.333,0,0,1,11.2,12.541a3.891,3.891,0,0,1-1.309.242c-.743.034-.966.041-2.848.041s-2.1-.007-2.847-.041M4.142.043A5.168,5.168,0,0,0,2.431.37a3.457,3.457,0,0,0-1.248.813A3.443,3.443,0,0,0,.37,2.431,5.169,5.169,0,0,0,.043,4.142C.008,4.893,0,5.133,0,7.047S.008,9.2.043,9.953A5.169,5.169,0,0,0,.37,11.663a3.445,3.445,0,0,0,.813,1.248,3.465,3.465,0,0,0,1.248.813,5.172,5.172,0,0,0,1.711.327c.752.034.992.043,2.905.043s2.154-.008,2.905-.043a5.169,5.169,0,0,0,1.711-.327,3.6,3.6,0,0,0,2.061-2.061,5.154,5.154,0,0,0,.327-1.711c.034-.752.041-.992.041-2.905s-.008-2.154-.041-2.905a5.167,5.167,0,0,0-.327-1.711,3.466,3.466,0,0,0-.813-1.248A3.448,3.448,0,0,0,11.664.37,5.161,5.161,0,0,0,9.953.043C9.2.009,8.962,0,7.047,0S4.893.008,4.141.043"
            fill="#fff"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M4.7,7.047A2.349,2.349,0,1,1,7.047,9.4,2.349,2.349,0,0,1,4.7,7.047m-1.27,0A3.619,3.619,0,1,0,7.047,3.429,3.619,3.619,0,0,0,3.428,7.047M9.963,3.285a.846.846,0,1,0,.846-.845h0a.846.846,0,0,0-.846.845M4.2,12.784a3.9,3.9,0,0,1-1.309-.242,2.19,2.19,0,0,1-.811-.527,2.174,2.174,0,0,1-.527-.81A3.891,3.891,0,0,1,1.311,9.9c-.034-.743-.041-.966-.041-2.848s.007-2.1.041-2.848a3.921,3.921,0,0,1,.242-1.309,2.19,2.19,0,0,1,.527-.811,2.172,2.172,0,0,1,.811-.527A3.891,3.891,0,0,1,4.2,1.311c.743-.034.966-.041,2.847-.041s2.1.007,2.848.041a3.921,3.921,0,0,1,1.309.242,2.183,2.183,0,0,1,.811.527,2.183,2.183,0,0,1,.527.811A3.892,3.892,0,0,1,12.784,4.2c.034.743.041.966.041,2.848s-.007,2.1-.041,2.848a3.91,3.91,0,0,1-.242,1.309A2.333,2.333,0,0,1,11.2,12.541a3.891,3.891,0,0,1-1.309.242c-.743.034-.966.041-2.848.041s-2.1-.007-2.847-.041M4.142.043A5.168,5.168,0,0,0,2.431.37a3.457,3.457,0,0,0-1.248.813A3.443,3.443,0,0,0,.37,2.431,5.169,5.169,0,0,0,.043,4.142C.008,4.893,0,5.133,0,7.047S.008,9.2.043,9.953A5.169,5.169,0,0,0,.37,11.663a3.445,3.445,0,0,0,.813,1.248,3.465,3.465,0,0,0,1.248.813,5.172,5.172,0,0,0,1.711.327c.752.034.992.043,2.905.043s2.154-.008,2.905-.043a5.169,5.169,0,0,0,1.711-.327,3.6,3.6,0,0,0,2.061-2.061,5.154,5.154,0,0,0,.327-1.711c.034-.752.041-.992.041-2.905s-.008-2.154-.041-2.905a5.167,5.167,0,0,0-.327-1.711,3.466,3.466,0,0,0-.813-1.248A3.448,3.448,0,0,0,11.664.37,5.161,5.161,0,0,0,9.953.043C9.2.009,8.962,0,7.047,0S4.893.008,4.141.043"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export function FBIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="Group_19332"
        data-name="Group 19332"
        transform="translate(-1388 -4906)"
      >
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(1388 4906)"
          fill="#f7f8fd"
        />
        <path
          id="Path_487"
          data-name="Path 487"
          d="M-1811.229-1924.279c0-.045.008-.09.008-.135q0-3.306,0-6.612v-.147h-2.227v-2.744h2.227c0-.073,0-.131,0-.189.007-.8-.014-1.608.028-2.409a3.274,3.274,0,0,1,1.128-2.443,3.148,3.148,0,0,1,2.056-.736c.721-.007,1.443.054,2.165.085a.522.522,0,0,1,.092.022v2.443h-.177c-.527.009-1.055-.007-1.58.032a.891.891,0,0,0-.928.9c-.045.754-.011,1.513-.011,2.286h2.468l-.341,2.741h-2.138c0,.06-.01.108-.01.156q0,3.306,0,6.612c0,.044.008.089.012.133Z"
          transform="translate(3210.104 6850)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export function LoginIcon() {
  return (
    <svg
      id="sa"
      version="1.1"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18,19v-2c0.45-0.223,1.737-1.755,1.872-2.952c0.354-0.027,0.91-0.352,1.074-1.635c0.088-0.689-0.262-1.076-0.474-1.198  c0,0,0.528-1.003,0.528-2.214c0-2.428-0.953-4.5-3-4.5c0,0-0.711-1.5-3-1.5c-4.242,0-6,2.721-6,6c0,1.104,0.528,2.214,0.528,2.214  c-0.212,0.122-0.562,0.51-0.474,1.198c0.164,1.283,0.72,1.608,1.074,1.635C10.263,15.245,11.55,16.777,12,17v2c-1,3-9,1-9,8h24  C27,20,19,22,18,19z"
        fill="#fff"
      />
    </svg>
  );
}
export function SignUpIcon() {
  return (
    <svg
      id="sas"
      version="1.1"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14,23c0-3.539,2.047-6.591,5.017-8.062c0.409-0.57,0.785-1.273,0.855-1.89c0.354-0.027,0.91-0.352,1.074-1.635  c0.088-0.689-0.262-1.076-0.474-1.198c0,0,0.528-1.003,0.528-2.214c0-2.428-0.953-4.5-3-4.5c0,0-0.711-1.5-3-1.5  c-4.242,0-6,2.721-6,6c0,1.104,0.528,2.214,0.528,2.214c-0.212,0.122-0.562,0.51-0.474,1.198c0.164,1.283,0.72,1.608,1.074,1.635  C10.263,14.245,11.55,15.777,12,16v2c-1,3-9,1-9,8h11.523C14.19,25.06,14,24.054,14,23z"
        fill="#fff"
      />
      <path
        d="M23,16c-3.866,0-7,3.134-7,7c0,3.866,3.134,7,7,7s7-3.134,7-7C30,19.134,26.866,16,23,16z M26,24h-2v2c0,0.553-0.448,1-1,1  s-1-0.447-1-1v-2h-2c-0.552,0-1-0.447-1-1s0.448-1,1-1h2v-2c0-0.553,0.448-1,1-1s1,0.447,1,1v2h2c0.552,0,1,0.447,1,1  S26.552,24,26,24z"
        fill="#fff"
      />
    </svg>
  );
}
export const EyeOpen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/22366EB/svg"
      width="20"
      height="20"
      viewBox="0 0 14.15 9.88"
    >
      <path
        id="Контур_1626"
        data-name="Контур 1626"
        d="M29840.9,1323.64c0,.11-.1.21-.1.29s.1.18.1.3a11.076,11.076,0,0,0,1,1.33,6.194,6.194,0,0,0,4.4,1.9,6,6,0,0,0,4.4-1.9,5.628,5.628,0,0,0,1-1.33c.1-.12.1-.22.2-.3-.1-.08-.1-.18-.2-.29a5.453,5.453,0,0,0-1-1.34,6,6,0,0,0-4.4-1.9,6.193,6.193,0,0,0-4.4,1.9A10.336,10.336,0,0,0,29840.9,1323.64Zm12.4.57-.6-.28.6.28a.562.562,0,0,0,0-.56l-.6.28.6-.28h0v-.06c0-.04-.1-.09-.1-.16a2.775,2.775,0,0,1-.3-.53,5.7,5.7,0,0,0-1.2-1.58,7.421,7.421,0,0,0-10.8,0,19.221,19.221,0,0,0-1.2,1.58,2.775,2.775,0,0,1-.3.53c0,.07,0,.12-.1.16v.06h0l.7.28-.7-.28a.553.553,0,0,0,0,.56l.7-.28-.7.28h0v.07c.1.03.1.09.1.15a2.774,2.774,0,0,1,.3.53,19.221,19.221,0,0,0,1.2,1.58,7.421,7.421,0,0,0,10.8,0,5.7,5.7,0,0,0,1.2-1.58,2.774,2.774,0,0,1,.3-.53c0-.06.1-.12.1-.15v-.07Zm-7,1.13a1.41,1.41,0,1,1,1.4-1.41A1.387,1.387,0,0,1,29846.3,1325.34Zm-2.8-1.41a2.839,2.839,0,0,1,2.8-2.82,2.82,2.82,0,0,1,0,5.64A2.839,2.839,0,0,1,29843.5,1323.93Z"
        transform="translate(-29839.225 -1318.99)"
        fill="#ffffff"
        opacity="0.6"
        fillRule="evenodd"
      />
    </svg>
  );
};
export const EyeClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/22366EB/svg"
      width="19.201"
      height="15.361"
      viewBox="0 0 19.201 15.361"
    >
      <path
        id="Icon_awesome-eye-slash"
        data-name="Icon awesome-eye-slash"
        d="M9.6,12A4.3,4.3,0,0,1,5.313,8.007L2.166,5.575a10,10,0,0,0-1.1,1.668.971.971,0,0,0,0,.876A9.623,9.623,0,0,0,9.6,13.441a9.319,9.319,0,0,0,2.337-.314l-1.557-1.2A4.324,4.324,0,0,1,9.6,12Zm9.415,1.743L15.7,11.18a9.938,9.938,0,0,0,2.438-3.062.971.971,0,0,0,0-.876A9.623,9.623,0,0,0,9.6,1.92a9.245,9.245,0,0,0-4.42,1.131L1.364.1A.48.48,0,0,0,.69.185L.1.943a.48.48,0,0,0,.084.674L17.837,15.26a.48.48,0,0,0,.674-.084l.589-.758a.48.48,0,0,0-.085-.674ZM13.5,9.483l-1.179-.911A2.843,2.843,0,0,0,8.841,4.914a1.43,1.43,0,0,1,.279.846,1.4,1.4,0,0,1-.046.3L6.866,4.353A4.27,4.27,0,0,1,9.6,3.36a4.318,4.318,0,0,1,4.32,4.32,4.218,4.218,0,0,1-.417,1.8Z"
        transform="translate(0 0)"
        fill="#ffffff"
        opacity="0.6"
      />
    </svg>
  );
};
export const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="Group_68748"
        data-name="Group 68748"
        transform="translate(5790 6176)"
      >
        <g
          id="Group_19334"
          data-name="Group 19334"
          transform="translate(-7178 -8986)"
        >
          <circle
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="12.5"
            cy="12.5"
            r="12.5"
            transform="translate(1388 2810)"
            fill="#f7f8fd"
            opacity="0.2"
          />
        </g>
        <g
          id="Group_68747"
          data-name="Group 68747"
          transform="translate(-5784.107 -6170.107)"
        >
          <path
            id="Path_79154"
            data-name="Path 79154"
            d="M0,6.824A6.832,6.832,0,0,1,6.824,0a6.761,6.761,0,0,1,4.16,1.414L9.4,3.474A4.184,4.184,0,0,0,6.824,2.6a4.225,4.225,0,1,0,4.02,5.524H6.824v-2.6h6.824v1.3A6.824,6.824,0,0,1,0,6.824Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
export const AppleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="Group_68750"
        data-name="Group 68750"
        transform="translate(5765 6175)"
      >
        <g
          id="Group_19334"
          data-name="Group 19334"
          transform="translate(-7153 -8985)"
        >
          <circle
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="12.5"
            cy="12.5"
            r="12.5"
            transform="translate(1388 2810)"
            fill="#f7f8fd"
            opacity="0.2"
          />
        </g>
        <g
          id="Group_68749"
          data-name="Group 68749"
          transform="translate(-5796.029 -6169.812)"
        >
          <path
            id="Path_79155"
            data-name="Path 79155"
            d="M250.481,0a3.4,3.4,0,0,0-2.2,1.193,2.957,2.957,0,0,0-.722,2.3,2.873,2.873,0,0,0,2.224-1.136A3.192,3.192,0,0,0,250.481,0Z"
            transform="translate(-204.23)"
            fill="#fff"
          />
          <path
            id="Path_79156"
            data-name="Path 79156"
            d="M49.307,121.312a3.672,3.672,0,0,0-2.772-1.472c-1.3,0-1.851.623-2.755.623-.932,0-1.64-.621-2.765-.621a3.763,3.763,0,0,0-3.028,1.831c-1.049,1.627-.87,4.685.831,7.29.608.932,1.421,1.98,2.483,1.989.946.009,1.212-.607,2.493-.613s1.524.621,2.468.611c1.064-.008,1.92-1.17,2.529-2.1a10.452,10.452,0,0,0,.937-1.759A3.2,3.2,0,0,1,49.307,121.312Z"
            transform="translate(0 -116.444)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
export function HeartIcon() {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 121 122"
      width="121"
      height="122"
    >
      <title>like</title>
      <g id="like">
        <path
          className="bg_path"
          fill="#ecc35c"
          d="m60.5 1c33.4 0 60.4 27 60.4 60.4 0 33.3-27 60.3-60.4 60.3-33.3 0-60.4-27-60.4-60.3 0-33.4 27.1-60.4 60.4-60.4z"
        />
        <path
          className="Icon_path"
          fill="#1b1b1b"
          d="m95.2 64c-2.1 6.8-6.9 10.8-12.3 15.4-3.3 2.9-3.3 2.2-12.3 9.2-3.6 2.9-8 5.2-10.6 8.9q-0.1 0.1-0.2 0.2-0.1-0.1-0.1-0.2c-2.7-3.7-7.1-6-10.7-8.9-9-7-9-6.3-12.3-9.2-5.4-4.6-10.2-8.6-12.3-15.4-0.9-2.6-2.8-8.7 0-15.4 2.4-6 8.1-11.3 15.4-12.3 7.6-1.2 15.5 2.6 20 9.5 4.5-6.9 12.4-10.7 20-9.5 7.3 1 13 6.3 15.4 12.3 2.8 6.7 0.9 12.8 0 15.4z"
        />
      </g>
    </svg>
  );
}
export function HeartIconRed() {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
          fill="#ff0000"
        />{" "}
      </g>
    </svg>
  );
}
export function HeartIconActive() {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 121 122"
      width="121"
      height="122"
    >
      <title>heart</title>
      <g id="heart">
        <path
          fill="#1b1b1b"
          d="m60.5 1c33.4 0 60.4 27 60.4 60.4 0 33.3-27 60.3-60.4 60.3-33.3 0-60.4-27-60.4-60.3 0-33.4 27.1-60.4 60.4-60.4z"
        />
        <path
          fill="#ecc35c"
          d="m95.2 64c-2.1 6.8-6.9 10.8-12.3 15.4-3.3 2.9-3.3 2.2-12.3 9.2-3.6 2.9-8 5.2-10.6 8.9q-0.1 0.1-0.2 0.2-0.1-0.1-0.1-0.2c-2.7-3.7-7.1-6-10.7-8.9-9-7-9-6.3-12.3-9.2-5.4-4.6-10.2-8.6-12.3-15.4-0.9-2.6-2.8-8.7 0-15.4 2.4-6 8.1-11.3 15.4-12.3 7.6-1.2 15.5 2.6 20 9.5 4.5-6.9 12.4-10.7 20-9.5 7.3 1 13 6.3 15.4 12.3 2.8 6.7 0.9 12.8 0 15.4z"
        />
      </g>
    </svg>
  );
}
export function ShareIcon() {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 121 122"
      width="121"
      height="122"
    >
      <title>Share</title>
      <g id="Share">
        <path
          className="bg_path"
          id="&lt;Path&gt;"
          fill="#ecc35c"
          d="m60.4 0.4c33.4 0 60.4 27 60.4 60.3 0 33.4-27 60.4-60.4 60.4-33.3 0-60.3-27-60.3-60.4 0-33.3 27-60.3 60.3-60.3z"
        />
        <path
          className="Icon_path"
          id="&lt;Path&gt;"
          fill="#000000"
          d="m85.2 57.8c0 5.1-4.2 9.2-9.2 9.2-1.8 0-3.4-0.5-4.8-1.4l-19.5 18.4c0.8 1.3 1.2 2.9 1.2 4.5 0 5.1-4.1 9.2-9.2 9.2-5.1 0-9.2-4.1-9.2-9.2 0-5.1 4.1-9.2 9.2-9.2 1.9 0 3.6 0.6 5 1.5l19.4-18.3c-0.9-1.4-1.4-3-1.4-4.7 0-1.3 0.3-2.6 0.8-3.7l-18.1-14c-1.5 1.3-3.5 2-5.7 2-5.1 0-9.2-4.1-9.2-9.2 0-5.1 4.1-9.2 9.2-9.2 5.1 0 9.2 4.1 9.2 9.2 0 1.3-0.2 2.6-0.7 3.7l18 14c1.6-1.3 3.6-2 5.8-2 5 0 9.2 4.1 9.2 9.2z"
        />
      </g>
    </svg>
  );
}
export function LinkIcon() {
  return (
    <svg
      className="bi bi-link"
      fill="$white"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ffffff"
        d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"
      />
      <path
        fill="#ffffff"
        d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"
      />
    </svg>
  );
}
export function TwitterTransparentIcon() {
  return (
    <svg
      enableBackground="new 0 0 56.693 56.693"
      height="56.693px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 56.693 56.693"
      width="56.693px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ffffff"
        d="M52.837,15.065c-1.811,0.805-3.76,1.348-5.805,1.591c2.088-1.25,3.689-3.23,4.444-5.592c-1.953,1.159-4.115,2-6.418,2.454  c-1.843-1.964-4.47-3.192-7.377-3.192c-5.581,0-10.106,4.525-10.106,10.107c0,0.791,0.089,1.562,0.262,2.303  c-8.4-0.422-15.848-4.445-20.833-10.56c-0.87,1.492-1.368,3.228-1.368,5.082c0,3.506,1.784,6.6,4.496,8.412  c-1.656-0.053-3.215-0.508-4.578-1.265c-0.001,0.042-0.001,0.085-0.001,0.128c0,4.896,3.484,8.98,8.108,9.91  c-0.848,0.23-1.741,0.354-2.663,0.354c-0.652,0-1.285-0.063-1.902-0.182c1.287,4.015,5.019,6.938,9.441,7.019  c-3.459,2.711-7.816,4.327-12.552,4.327c-0.815,0-1.62-0.048-2.411-0.142c4.474,2.869,9.786,4.541,15.493,4.541  c18.591,0,28.756-15.4,28.756-28.756c0-0.438-0.009-0.875-0.028-1.309C49.769,18.873,51.483,17.092,52.837,15.065z"
      />
    </svg>
  );
}
export function WhatsAppIcon() {
  return (
    <svg
      height="56.693px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 56.693 56.693"
      width="56.693px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="#ffffff"
          d="M46.3802,10.7138c-4.6512-4.6565-10.8365-7.222-17.4266-7.2247c-13.5785,0-24.63,11.0506-24.6353,24.6333   c-0.0019,4.342,1.1325,8.58,3.2884,12.3159l-3.495,12.7657l13.0595-3.4257c3.5982,1.9626,7.6495,2.9971,11.7726,2.9985h0.01   c0.0008,0-0.0006,0,0.0002,0c13.5771,0,24.6293-11.0517,24.635-24.6347C53.5914,21.5595,51.0313,15.3701,46.3802,10.7138z    M28.9537,48.6163h-0.0083c-3.674-0.0014-7.2777-0.9886-10.4215-2.8541l-0.7476-0.4437l-7.7497,2.0328l2.0686-7.5558   l-0.4869-0.7748c-2.0496-3.26-3.1321-7.028-3.1305-10.8969c0.0044-11.2894,9.19-20.474,20.4842-20.474   c5.469,0.0017,10.6101,2.1344,14.476,6.0047c3.8658,3.8703,5.9936,9.0148,5.9914,14.4859   C49.4248,39.4307,40.2395,48.6163,28.9537,48.6163z"
        />
        <path
          fill="#ffffff"
          d="M40.1851,33.281c-0.6155-0.3081-3.6419-1.797-4.2061-2.0026c-0.5642-0.2054-0.9746-0.3081-1.3849,0.3081   c-0.4103,0.6161-1.59,2.0027-1.9491,2.4136c-0.359,0.4106-0.7182,0.4623-1.3336,0.1539c-0.6155-0.3081-2.5989-0.958-4.95-3.0551   c-1.83-1.6323-3.0653-3.6479-3.4245-4.2643c-0.359-0.6161-0.0382-0.9492,0.27-1.2562c0.2769-0.2759,0.6156-0.7189,0.9234-1.0784   c0.3077-0.3593,0.4103-0.6163,0.6155-1.0268c0.2052-0.4109,0.1027-0.7704-0.0513-1.0784   c-0.1539-0.3081-1.3849-3.3379-1.8978-4.5706c-0.4998-1.2001-1.0072-1.0375-1.3851-1.0566   c-0.3585-0.0179-0.7694-0.0216-1.1797-0.0216s-1.0773,0.1541-1.6414,0.7702c-0.5642,0.6163-2.1545,2.1056-2.1545,5.1351   c0,3.0299,2.2057,5.9569,2.5135,6.3676c0.3077,0.411,4.3405,6.6282,10.5153,9.2945c1.4686,0.6343,2.6152,1.013,3.5091,1.2966   c1.4746,0.4686,2.8165,0.4024,3.8771,0.2439c1.1827-0.1767,3.6419-1.489,4.1548-2.9267c0.513-1.438,0.513-2.6706,0.359-2.9272   C41.211,33.7433,40.8006,33.5892,40.1851,33.281z"
        />
      </g>
    </svg>
  );
}
export function FBtransparentIcon() {
  return (
    <svg
      enableBackground="new 0 0 56.693 56.693"
      height="56.693px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 56.693 56.693"
      width="56.693px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ffffff"
        d="M40.43,21.739h-7.645v-5.014c0-1.883,1.248-2.322,2.127-2.322c0.877,0,5.395,0,5.395,0V6.125l-7.43-0.029  c-8.248,0-10.125,6.174-10.125,10.125v5.518h-4.77v8.53h4.77c0,10.947,0,24.137,0,24.137h10.033c0,0,0-13.32,0-24.137h6.77  L40.43,21.739z"
      />
    </svg>
  );
}
export function EmailIcon() {
  return (
    <svg
      id="Layer_1_1_"
      version="1.1"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="0,4 0,14 16,14 16,4 8,9 " fill="#ffffff" />
      <polygon fill="#ffffff" points="0,3 8,8 16,3 16,2 0,2 " />
    </svg>
  );
}
export function GridIcon() {
  return (
    <svg
      className="grid"
      fill="none"
      height="24"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="7" width="7" x="3" y="3" />
      <rect height="7" width="7" x="14" y="3" />
      <rect height="7" width="7" x="14" y="14" />
      <rect height="7" width="7" x="3" y="14" />
    </svg>
  );
}
export function ListIcon() {
  return (
    <svg
      enableBackground="new 0 0 32 32"
      id="Editable-line"
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5"
        cy="6"
        fill="none"
        id="XMLID_303_"
        r="1"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        cx="5"
        cy="16"
        fill="none"
        id="XMLID_305_"
        r="1"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        cx="5"
        cy="26"
        fill="none"
        id="XMLID_304_"
        r="1"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        fill="none"
        id="XMLID_29_"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="10"
        x2="28"
        y1="6"
        y2="6"
      />
      <line
        fill="none"
        id="XMLID_30_"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="10"
        x2="28"
        y1="16"
        y2="16"
      />
      <line
        fill="none"
        id="XMLID_31_"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="10"
        x2="28"
        y1="26"
        y2="26"
      />
    </svg>
  );
}
export function SearchIcon() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 20.00 20.00"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#d6d6d6"
      strokeWidth="0.0002"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="0.4"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill="#d6d6d6"
          fillRule="evenodd"
          d="M4 9a5 5 0 1110 0A5 5 0 014 9zm5-7a7 7 0 104.2 12.6.999.999 0 00.093.107l3 3a1 1 0 001.414-1.414l-3-3a.999.999 0 00-.107-.093A7 7 0 009 2z"
        />{" "}
      </g>
    </svg>
  );
}
export function FilterIcon() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 48.00 48.00"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <title>filter-horizontal</title>{" "}
        <g id="Layer_2" data-name="Layer 2">
          {" "}
          <g id="invisible_box" data-name="invisible box">
            {" "}
            <rect width="48" height="48" fill="none" />{" "}
          </g>{" "}
          <g id="icons_Q2" data-name="icons Q2">
            {" "}
            <path d="M41.8,8H21.7A6.2,6.2,0,0,0,16,4a6,6,0,0,0-5.6,4H6.2A2.1,2.1,0,0,0,4,10a2.1,2.1,0,0,0,2.2,2h4.2A6,6,0,0,0,16,16a6.2,6.2,0,0,0,5.7-4H41.8A2.1,2.1,0,0,0,44,10,2.1,2.1,0,0,0,41.8,8ZM16,12a2,2,0,1,1,2-2A2,2,0,0,1,16,12Z" />{" "}
            <path d="M41.8,22H37.7A6.2,6.2,0,0,0,32,18a6,6,0,0,0-5.6,4H6.2a2,2,0,1,0,0,4H26.4A6,6,0,0,0,32,30a6.2,6.2,0,0,0,5.7-4h4.1a2,2,0,1,0,0-4ZM32,26a2,2,0,1,1,2-2A2,2,0,0,1,32,26Z" />{" "}
            <path d="M41.8,36H24.7A6.2,6.2,0,0,0,19,32a6,6,0,0,0-5.6,4H6.2a2,2,0,1,0,0,4h7.2A6,6,0,0,0,19,44a6.2,6.2,0,0,0,5.7-4H41.8a2,2,0,1,0,0-4ZM19,40a2,2,0,1,1,2-2A2,2,0,0,1,19,40Z" />{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}
export function PlusIcon() {
  return (
    <svg
      onClick={() => {
      }}
      width="30px"
      height="30px"
      viewBox="0 0 24.00 24.00"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z"
          fill="#c0c0c0"
        />{" "}
      </g>
    </svg>
  );
}
export function SubtractionIcon(props) {
  return (
    <svg
      onClick={() => {

      }}
      width="30px"
      height="30px"
      viewBox="0 0 24.00 24.00"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      stroke="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <title />{" "}
        <g id="Complete">
          {" "}
          <g id="minus">
            {" "}
            <line
              fill="none"
              stroke="#c0c0c0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              x1="4"
              x2="20"
              y1="12"
              y2="12"
            />{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}
export function BrowsercircleIcon() {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="-15.36 -15.36 222.72 222.72"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#fff"
    >
      <g
        id="SVGRepo_bgCarrier"
        strokeWidth="0"
        transform="translate(5.760000000000005,5.760000000000005), scale(0.94)"
      >
        <rect
          x="-15.36"
          y="-15.36"
          width="222.72"
          height="222.72"
          rx="111.36"
          fill="#51bc89"
          strokeWidth="0"
        />
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier" className="svgiconcircle">
        <circle cx="96" cy="96" r="74" stroke="#fff" strokeWidth="12" />
        <ellipse
          cx="96"
          cy="96"
          stroke="#fff"
          strokeWidth="12"
          rx="30"
          ry="74"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="12"
          d="M28 72h136M28 120h136"
        />
      </g>
    </svg>
  );
}
export function LocationcircleIcon() {
  return (
    <svg
      fill="#fff"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="-79.32 -79.32 625.22 625.22"
      stroke="#fff"
    >
      <g
        id="SVGRepo_bgCarrier"
        strokeWidth="0"
        transform="translate(13.997489999999999,13.997489999999999), scale(0.94)"
      >
        <rect
          x="-79.32"
          y="-79.32"
          width="625.22"
          height="625.22"
          rx="312.61"
          fill="#51bc89"
          strokeWidth="0"
        />
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <path d="M233.292,0c-85.1,0-154.334,69.234-154.334,154.333c0,34.275,21.887,90.155,66.908,170.834 c31.846,57.063,63.168,104.643,64.484,106.64l22.942,34.775l22.941-34.774c1.317-1.998,32.641-49.577,64.483-106.64 c45.023-80.68,66.908-136.559,66.908-170.834C387.625,69.234,318.391,0,233.292,0z M233.292,233.291c-44.182,0-80-35.817-80-80 s35.818-80,80-80c44.182,0,80,35.817,80,80S277.473,233.291,233.292,233.291z" />{" "}
        </g>{" "}
      </g>
    </svg>
  );
}
export function MetamaskIcon() {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3618 1.6413L17.9092 10.8792L20.2248 5.43498L30.3618 1.6413Z"
        fill="#E17726"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.416 1.612C30.4307 1.63896 30.4232 1.67261 30.3986 1.69089L17.9459 10.9288C17.9236 10.9453 17.893 10.9449 17.8711 10.9277C17.8493 10.9105 17.8417 10.8808 17.8526 10.8552L20.1682 5.41101C20.1748 5.39549 20.1875 5.38335 20.2033 5.37744L30.3403 1.58375C30.3691 1.57299 30.4013 1.58504 30.416 1.612ZM20.2712 5.48353L18.0547 10.6947L29.9744 1.8522L20.2712 5.48353Z"
        fill="#E17726"
      />
      <path
        d="M1.63867 1.6413L13.9805 10.9654L11.7757 5.43498L1.63867 1.6413Z"
        fill="#E27625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58379 1.61177C1.59852 1.58496 1.6307 1.57303 1.65935 1.58375L11.7964 5.37744C11.8126 5.38352 11.8256 5.39618 11.832 5.41231L14.0368 10.9427C14.047 10.9684 14.0389 10.9977 14.0169 11.0145C13.9949 11.0313 13.9645 11.0313 13.9424 11.0147L1.60064 1.69057C1.57623 1.67213 1.56906 1.63857 1.58379 1.61177ZM2.0127 1.8475L13.8407 10.7834L11.7277 5.48324L2.0127 1.8475Z"
        fill="#E27625"
      />
      <path
        d="M25.8787 23.061L22.5654 28.1357L29.6601 30.0941L31.6924 23.1719L25.8787 23.061Z"
        fill="#E27625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8272 23.0272C25.8388 23.0094 25.8588 22.9989 25.88 22.9993L31.6937 23.1101C31.7129 23.1105 31.7308 23.1198 31.7422 23.1353C31.7535 23.1507 31.757 23.1706 31.7516 23.1891L29.7193 30.1113C29.7098 30.1436 29.6762 30.1623 29.6438 30.1533L22.5491 28.1949C22.5302 28.1897 22.5148 28.1757 22.5079 28.1573C22.5009 28.1389 22.5032 28.1183 22.5139 28.1019L25.8272 23.0272ZM25.9117 23.1231L22.6632 28.0986L29.6182 30.0185L31.6107 23.2317L25.9117 23.1231Z"
        fill="#E27625"
      />
      <path
        d="M0.320312 23.1719L2.34033 30.0941L9.4227 28.1357L6.1217 23.061L0.320312 23.1719Z"
        fill="#E27625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12058 22.9993C6.14184 22.9989 6.1618 23.0095 6.17339 23.0273L9.47439 28.1019C9.4851 28.1184 9.48731 28.139 9.48035 28.1573C9.4734 28.1757 9.4581 28.1897 9.43918 28.1949L2.3568 30.1533C2.32435 30.1623 2.2907 30.1435 2.28127 30.1112L0.261255 23.189C0.255883 23.1705 0.259394 23.1507 0.270753 23.1352C0.282113 23.1198 0.300023 23.1105 0.319199 23.1101L6.12058 22.9993ZM0.402049 23.2317L2.3825 30.0184L9.32527 28.0986L6.08877 23.1231L0.402049 23.2317Z"
        fill="#E27625"
      />
      <path
        d="M9.04106 14.4883L7.07031 17.469L14.0911 17.7893L13.8571 10.2142L9.04106 14.4883Z"
        fill="#E27625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8815 10.1574C13.9035 10.1669 13.918 10.1882 13.9187 10.2121L14.1527 17.7871C14.1532 17.8043 14.1466 17.8209 14.1344 17.8329C14.1222 17.845 14.1055 17.8514 14.0884 17.8506L7.06757 17.5303C7.04542 17.5293 7.02553 17.5165 7.01548 17.4967C7.00543 17.477 7.00678 17.4533 7.019 17.4348L8.98975 14.4541C8.99271 14.4496 8.99623 14.4455 9.00024 14.442L13.8163 10.1679C13.8341 10.1521 13.8596 10.148 13.8815 10.1574ZM9.08804 14.5288L7.18161 17.4122L14.0276 17.7245L13.7996 10.3474L9.08804 14.5288Z"
        fill="#E27625"
      />
      <path
        d="M22.9592 14.4883L18.0693 10.128L17.9092 17.7893L24.93 17.469L22.9592 14.4883Z"
        fill="#E27625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0448 10.0717C18.0668 10.0621 18.0924 10.0662 18.1104 10.0821L23.0003 14.4424C23.0042 14.446 23.0077 14.45 23.0107 14.4544L24.9814 17.4352C24.9936 17.4537 24.995 17.4773 24.9849 17.4971C24.9749 17.5168 24.955 17.5297 24.9328 17.5307L17.912 17.8509C17.895 17.8517 17.8784 17.8454 17.8662 17.8335C17.854 17.8216 17.8473 17.8051 17.8477 17.7881L18.0078 10.1268C18.0083 10.1028 18.0227 10.0813 18.0448 10.0717ZM18.1281 10.263L17.9722 17.7249L24.8188 17.4126L22.9123 14.529L18.1281 10.263Z"
        fill="#E27625"
      />
      <path
        d="M9.42285 28.1357L13.6723 26.0787L10.0141 23.2211L9.42285 28.1357Z"
        fill="#E27625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9905 23.1644C10.011 23.1559 10.0345 23.159 10.0521 23.1727L13.7102 26.0303C13.727 26.0434 13.7358 26.0642 13.7336 26.0854C13.7313 26.1065 13.7183 26.125 13.6992 26.1343L9.44975 28.1912C9.42948 28.2011 9.40547 28.199 9.38714 28.1859C9.36881 28.1729 9.35908 28.1508 9.36177 28.1285L9.95299 23.2139C9.95565 23.1918 9.96998 23.1729 9.9905 23.1644ZM10.0622 23.337L9.49752 28.0313L13.5565 26.0665L10.0622 23.337Z"
        fill="#E27625"
      />
      <path
        d="M18.3281 26.0787L22.5652 28.1357L21.9863 23.2211L18.3281 26.0787Z"
        fill="#E27625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0101 23.1644C22.0306 23.173 22.0449 23.192 22.0476 23.214L22.6265 28.1286C22.6291 28.151 22.6193 28.173 22.601 28.186C22.5826 28.199 22.5586 28.201 22.5384 28.1912L18.3013 26.1342C18.2822 26.125 18.2692 26.1065 18.2669 26.0853C18.2647 26.0642 18.2735 26.0434 18.2903 26.0303L21.9485 23.1727C21.966 23.159 21.9896 23.1559 22.0101 23.1644ZM18.4439 26.0666L22.491 28.0313L21.938 23.3372L18.4439 26.0666Z"
        fill="#E27625"
      />
      <path
        d="M22.5652 28.1358L18.3281 26.0788L18.673 28.8378L18.6361 30.008L22.5652 28.1358Z"
        fill="#D5BFB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2923 26.0285C18.3107 26.0154 18.3348 26.0133 18.3551 26.0232L22.5922 28.0802C22.6135 28.0905 22.627 28.1121 22.6269 28.1358C22.6268 28.1595 22.6132 28.181 22.5918 28.1912L18.6626 30.0634C18.6432 30.0726 18.6204 30.0711 18.6024 30.0593C18.5844 30.0476 18.5739 30.0273 18.5746 30.0058L18.6114 28.8405L18.2671 26.0863C18.2643 26.0638 18.274 26.0417 18.2923 26.0285ZM18.4034 26.1836L18.7342 28.83C18.7346 28.8332 18.7347 28.8364 18.7346 28.8396L18.7009 29.9087L22.4232 28.1351L18.4034 26.1836Z"
        fill="#D5BFB2"
      />
      <path
        d="M9.42285 28.1358L13.3643 30.008L13.3397 28.8378L13.6723 26.0788L9.42285 28.1358Z"
        fill="#D5BFB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7081 26.0285C13.7264 26.0416 13.7362 26.0636 13.7335 26.086L13.4014 28.8407L13.426 30.0065C13.4264 30.0278 13.4158 30.0479 13.3979 30.0595C13.3799 30.0711 13.3573 30.0726 13.338 30.0634L9.39649 28.1912C9.37509 28.181 9.36142 28.1595 9.36133 28.1358C9.36124 28.1121 9.37475 28.0905 9.39608 28.0801L13.6455 26.0232C13.6658 26.0134 13.6898 26.0154 13.7081 26.0285ZM9.56535 28.1351L13.3007 29.9094L13.2782 28.839C13.2781 28.8361 13.2783 28.8332 13.2786 28.8303L13.5977 26.1832L9.56535 28.1351Z"
        fill="#D5BFB2"
      />
      <path
        d="M13.4377 21.3983L9.91504 20.3636L12.4031 19.2181L13.4377 21.3983Z"
        fill="#233447"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3774 19.162C12.4081 19.1479 12.4443 19.1611 12.4588 19.1916L13.4935 21.3717C13.5039 21.3936 13.5004 21.4196 13.4846 21.4381C13.4689 21.4566 13.4437 21.464 13.4205 21.4572L9.89775 20.4226C9.87311 20.4153 9.85555 20.3936 9.85368 20.368C9.85181 20.3423 9.86602 20.3183 9.88935 20.3075L12.3774 19.162ZM10.0901 20.3507L13.3232 21.3003L12.3736 19.2994L10.0901 20.3507Z"
        fill="#233447"
      />
      <path
        d="M18.5625 21.3983L19.5971 19.2181L22.0975 20.3636L18.5625 21.3983Z"
        fill="#233447"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5406 19.1916C19.555 19.1611 19.5913 19.148 19.6219 19.162L22.1223 20.3075C22.1456 20.3182 22.1599 20.3423 22.158 20.3679C22.1562 20.3936 22.1386 20.4154 22.1139 20.4226L18.5789 21.4572C18.5556 21.464 18.5305 21.4565 18.5147 21.4381C18.499 21.4196 18.4955 21.3936 18.5059 21.3717L19.5406 19.1916ZM19.6258 19.2993L18.6761 21.3004L21.9209 20.3507L19.6258 19.2993Z"
        fill="#233447"
      />
      <path
        d="M9.42307 28.1357L10.0389 23.061L6.12207 23.1719L9.42307 28.1357Z"
        fill="#CC6228"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0835 23.0194C10.0956 23.0326 10.1013 23.0505 10.0992 23.0683L9.48329 28.1429C9.48019 28.1685 9.46147 28.1895 9.43638 28.1954C9.41129 28.2014 9.38515 28.1911 9.37088 28.1696L6.06987 23.2058C6.05748 23.1872 6.05617 23.1633 6.06647 23.1434C6.07676 23.1235 6.09704 23.1108 6.11941 23.1101L10.0363 22.9993C10.0542 22.9988 10.0715 23.0061 10.0835 23.0194ZM6.23397 23.2301L9.38111 27.9626L9.96826 23.1244L6.23397 23.2301Z"
        fill="#CC6228"
      />
      <path
        d="M21.9619 23.061L22.5655 28.1357L25.8788 23.1719L21.9619 23.061Z"
        fill="#CC6228"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9165 23.0193C21.9286 23.0061 21.9458 22.9988 21.9637 22.9993L25.8806 23.1101C25.903 23.1108 25.9233 23.1235 25.9335 23.1434C25.9438 23.1633 25.9425 23.1873 25.9301 23.2059L22.6167 28.1697C22.6024 28.1912 22.5763 28.2014 22.5512 28.1954C22.5261 28.1894 22.5074 28.1684 22.5044 28.1428L21.9008 23.0681C21.8987 23.0503 21.9044 23.0325 21.9165 23.0193ZM22.0316 23.1244L22.607 27.9625L25.7658 23.2301L22.0316 23.1244Z"
        fill="#CC6228"
      />
      <path
        d="M24.93 17.4689L17.9092 17.7891L18.562 21.398L19.5966 19.2179L22.097 20.3634L24.93 17.4689Z"
        fill="#CC6228"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9862 17.4438C24.9966 17.4669 24.9917 17.494 24.974 17.5121L22.1411 20.4066C22.1229 20.4252 22.095 20.4303 22.0714 20.4195L19.6263 19.2993L18.6177 21.4246C18.6062 21.4487 18.5805 21.4627 18.5539 21.4592C18.5274 21.4557 18.5062 21.4355 18.5015 21.4091L17.8486 17.8002C17.8455 17.7827 17.85 17.7647 17.8611 17.7508C17.8722 17.737 17.8887 17.7285 17.9064 17.7277L24.9272 17.4075C24.9525 17.4063 24.9759 17.4207 24.9862 17.4438ZM17.9824 17.8476L18.5886 21.1987L19.5411 19.1916C19.5555 19.1612 19.5917 19.148 19.6223 19.162L22.0834 20.2895L24.7767 17.5376L17.9824 17.8476Z"
        fill="#CC6228"
      />
      <path
        d="M9.91558 20.3634L12.4036 19.2179L13.4383 21.398L14.0911 17.7891L7.07031 17.4689L9.91558 20.3634Z"
        fill="#CC6228"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01414 17.4439C7.02446 17.4208 7.04788 17.4063 7.07318 17.4075L14.094 17.7277C14.1117 17.7285 14.1282 17.737 14.1393 17.7508C14.1504 17.7647 14.1549 17.7827 14.1518 17.8002L13.499 21.4091C13.4942 21.4355 13.473 21.4557 13.4465 21.4592C13.4199 21.4627 13.3942 21.4487 13.3827 21.4246L12.3742 19.2994L9.9414 20.4195C9.9178 20.4303 9.88993 20.4252 9.87172 20.4067L7.02646 17.5122C7.0087 17.4941 7.00382 17.467 7.01414 17.4439ZM7.22423 17.5377L9.92921 20.2895L12.378 19.1621C12.4086 19.148 12.4449 19.1612 12.4593 19.1916L13.4119 21.1987L14.018 17.8476L7.22423 17.5377Z"
        fill="#CC6228"
      />
      <path
        d="M7.07031 17.4689L10.0141 23.221L9.91558 20.3634L7.07031 17.4689Z"
        fill="#E27525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03435 17.419C7.05902 17.4013 7.09298 17.4041 7.1143 17.4258L9.95957 20.3204C9.97037 20.3314 9.97666 20.346 9.9772 20.3614L10.0757 23.219C10.0767 23.2478 10.0576 23.2735 10.0296 23.2807C10.0017 23.288 9.9725 23.2749 9.95936 23.2492L7.01556 17.4971C7.0017 17.47 7.00968 17.4368 7.03435 17.419ZM7.3085 17.7991L9.94311 22.9471L9.85492 20.3896L7.3085 17.7991Z"
        fill="#E27525"
      />
      <path
        d="M22.0972 20.3634L21.9863 23.221L24.9301 17.4689L22.0972 20.3634Z"
        fill="#E27525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9662 17.419C24.9909 17.4368 24.9989 17.47 24.985 17.4971L22.0412 23.2492C22.028 23.2749 21.9988 23.288 21.9708 23.2807C21.9428 23.2734 21.9237 23.2476 21.9249 23.2187L22.0357 20.3611C22.0363 20.3459 22.0425 20.3314 22.0532 20.3205L24.8862 17.4259C24.9075 17.4042 24.9415 17.4012 24.9662 17.419ZM22.1579 20.3896L22.0588 22.9445L24.6907 17.8017L22.1579 20.3896Z"
        fill="#E27525"
      />
      <path
        d="M14.0903 17.7891L13.4375 21.398L14.2627 25.6597L14.4475 20.0431L14.0903 17.7891Z"
        fill="#E27525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.092 17.7276C14.1221 17.7279 14.1475 17.7499 14.1522 17.7795L14.5094 20.0336C14.51 20.0374 14.5102 20.0413 14.5101 20.0453L14.3253 25.6619C14.3243 25.6932 14.2999 25.7188 14.2687 25.7212C14.2374 25.7237 14.2093 25.7023 14.2033 25.6716L13.3781 21.4098C13.3766 21.4023 13.3766 21.3947 13.3779 21.3872L14.0307 17.7782C14.0361 17.7487 14.062 17.7273 14.092 17.7276ZM13.5012 21.3977L14.2202 25.1109L14.3868 20.0471L14.0873 18.1574L13.5012 21.3977Z"
        fill="#E27525"
      />
      <path
        d="M17.9093 17.7891L17.5645 20.0308L17.7369 25.6597L18.5621 21.398L17.9093 17.7891Z"
        fill="#E27525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9086 17.7276C17.9387 17.7272 17.9646 17.7486 17.97 17.7782L18.6228 21.3872C18.6242 21.3947 18.6241 21.4023 18.6227 21.4098L17.7974 25.6716C17.7915 25.7024 17.7633 25.7238 17.732 25.7212C17.7007 25.7187 17.6764 25.6931 17.6754 25.6617L17.503 20.0328C17.5028 20.029 17.5031 20.0253 17.5036 20.0215L17.8485 17.7798C17.8531 17.7501 17.8785 17.728 17.9086 17.7276ZM17.9143 18.1623L17.6262 20.0347L17.7816 25.1054L18.4996 21.3977L17.9143 18.1623Z"
        fill="#E27525"
      />
      <path
        d="M18.5616 21.398L17.7363 25.6598L18.3276 26.0786L21.9858 23.221L22.0966 20.3634L18.5616 21.398Z"
        fill="#F5841F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1345 20.315C22.1502 20.3272 22.159 20.3462 22.1582 20.366L22.0474 23.2236C22.0467 23.2417 22.038 23.2585 22.0237 23.2697L18.3655 26.1273C18.3441 26.144 18.3142 26.1447 18.292 26.129L17.7008 25.7102C17.6811 25.6962 17.6713 25.672 17.6759 25.6483L18.5012 21.3865C18.5056 21.3639 18.5222 21.3456 18.5443 21.3391L22.0794 20.3045C22.0984 20.2989 22.1189 20.3028 22.1345 20.315ZM18.615 21.4468L17.8044 25.6327L18.3258 26.002L21.9254 23.1902L22.0318 20.4467L18.615 21.4468Z"
        fill="#F5841F"
      />
      <path
        d="M9.91504 20.3634L10.0136 23.221L13.6718 26.0786L14.263 25.6598L13.4377 21.398L9.91504 20.3634Z"
        fill="#F5841F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87735 20.3149C9.89298 20.3028 9.91348 20.2989 9.93246 20.3045L13.4552 21.3391C13.4773 21.3456 13.4939 21.3639 13.4983 21.3865L14.3235 25.6483C14.3281 25.672 14.3184 25.6962 14.2987 25.7102L13.7074 26.129C13.6852 26.1447 13.6554 26.144 13.6339 26.1273L9.97573 23.2697C9.96137 23.2585 9.95272 23.2415 9.95209 23.2233L9.85355 20.3657C9.85287 20.3459 9.86172 20.3271 9.87735 20.3149ZM9.97959 20.4467L10.0742 23.1903L13.6736 26.002L14.195 25.6327L13.3845 21.4468L9.97959 20.4467Z"
        fill="#F5841F"
      />
      <path
        d="M18.6361 30.0077L18.673 28.8376L18.3528 28.5666H13.6476L13.3397 28.8376L13.3643 30.0077L9.42285 28.1355L10.8024 29.2687L13.5984 31.2025H18.3897L21.1981 29.2687L22.5653 28.1355L18.6361 30.0077Z"
        fill="#C0AC9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37092 28.1026C9.38759 28.0764 9.42124 28.0667 9.44934 28.08L13.3007 29.9094L13.2782 28.839C13.2778 28.8209 13.2855 28.8035 13.2991 28.7915L13.607 28.5205C13.6183 28.5106 13.6327 28.5051 13.6477 28.5051H18.3529C18.3674 28.5051 18.3815 28.5103 18.3926 28.5197L18.7129 28.7907C18.7272 28.8028 18.7353 28.8209 18.7347 28.8397L18.7009 29.9088L22.5388 28.08C22.5669 28.0667 22.6005 28.0763 22.6172 28.1025C22.634 28.1287 22.6286 28.1632 22.6046 28.183L21.2374 29.3162C21.236 29.3174 21.2346 29.3185 21.2331 29.3195L18.4247 31.2533C18.4145 31.2604 18.4023 31.2642 18.3898 31.2642H13.5984C13.5859 31.2642 13.5737 31.2604 13.5634 31.2533L10.7674 29.3195C10.766 29.3185 10.7647 29.3175 10.7633 29.3164L9.38383 28.1832C9.35979 28.1635 9.35425 28.1289 9.37092 28.1026ZM9.84979 28.4066L10.8396 29.2196L13.6177 31.141H18.3707L21.1609 29.2196L22.1447 28.4043L18.6627 30.0634C18.6433 30.0727 18.6205 30.0711 18.6025 30.0594C18.5845 30.0476 18.5739 30.0274 18.5746 30.0059L18.6106 28.8655L18.3303 28.6283H13.6709L13.402 28.865L13.426 30.0065C13.4264 30.0279 13.4158 30.0479 13.3979 30.0595C13.3799 30.0711 13.3573 30.0726 13.338 30.0635L9.84979 28.4066Z"
        fill="#C0AC9D"
      />
      <path
        d="M18.3283 26.0787L17.7371 25.6599H14.2636L13.6724 26.0787L13.3398 28.8377L13.6478 28.5668H18.3529L18.6732 28.8377L18.3283 26.0787Z"
        fill="#161616"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2271 25.6098C14.2375 25.6024 14.25 25.5984 14.2627 25.5984H17.7362C17.7489 25.5984 17.7614 25.6024 17.7718 25.6098L18.363 26.0286C18.3771 26.0386 18.3863 26.054 18.3885 26.0712L18.7334 28.8302C18.7365 28.8554 18.7239 28.8799 18.7016 28.892C18.6793 28.9041 18.6519 28.9013 18.6325 28.8849L18.3295 28.6285H13.6701L13.3796 28.8841C13.3604 28.901 13.3328 28.9043 13.3102 28.8924C13.2876 28.8805 13.2747 28.8559 13.2778 28.8305L13.6104 26.0715C13.6124 26.0542 13.6217 26.0386 13.6359 26.0286L14.2271 25.6098ZM14.2823 25.7216L13.7294 26.1133L13.4194 28.685L13.6062 28.5207C13.6174 28.5108 13.6319 28.5053 13.6469 28.5053H18.352C18.3666 28.5053 18.3807 28.5105 18.3918 28.5199L18.5916 28.6889L18.2696 26.1134L17.7166 25.7216H14.2823Z"
        fill="#161616"
      />
      <path
        d="M30.8916 11.4827L31.9386 6.38341L30.362 1.6413L18.3281 10.5712L22.9594 14.4881L29.4998 16.3973L30.9409 14.7098L30.3127 14.2541L31.3104 13.3426L30.5468 12.7514L31.5444 11.9877L30.8916 11.4827Z"
        fill="#763E1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3806 1.58269C30.3994 1.58863 30.4143 1.60325 30.4205 1.62199L31.9971 6.3641C32.0005 6.37436 32.0012 6.38533 31.999 6.39592L30.9597 11.4576L31.5822 11.9391C31.5973 11.9508 31.6061 11.9689 31.6061 11.988C31.606 12.0071 31.5971 12.0251 31.5819 12.0367L30.6478 12.7518L31.3482 13.294C31.3626 13.3052 31.3713 13.3221 31.372 13.3402C31.3728 13.3584 31.3654 13.3759 31.352 13.3882L30.4101 14.2487L30.9771 14.6601C30.991 14.6702 31.0001 14.6856 31.0021 14.7027C31.0042 14.7197 30.999 14.7369 30.9878 14.7499L29.5467 16.4374C29.531 16.4558 29.5059 16.4633 29.4826 16.4565L22.9422 14.5473C22.9339 14.5449 22.9262 14.5408 22.9197 14.5353L18.2884 10.6184C18.2741 10.6062 18.266 10.5882 18.2666 10.5694C18.2672 10.5506 18.2764 10.5331 18.2915 10.5219L30.3254 1.59196C30.3412 1.58019 30.3618 1.57675 30.3806 1.58269ZM18.4273 10.5745L22.9892 14.4328L29.4789 16.3271L30.8508 14.7206L30.2766 14.304C30.2616 14.2932 30.2523 14.2761 30.2513 14.2577C30.2503 14.2392 30.2576 14.2212 30.2713 14.2087L31.2149 13.3466L30.5091 12.8002C30.494 12.7885 30.4852 12.7704 30.4852 12.7513C30.4853 12.7322 30.4942 12.7142 30.5094 12.7026L31.4435 11.9876L30.854 11.5315C30.8354 11.5172 30.8266 11.4935 30.8314 11.4705L31.875 6.3873L30.3305 1.74156L18.4273 10.5745Z"
        fill="#763E1A"
      />
      <path
        d="M0.0615234 6.38341L1.1208 11.4827L0.443356 11.9877L1.45336 12.7514L0.689699 13.3426L1.68739 14.2541L1.05921 14.7098L2.50032 16.3973L9.04074 14.4881L13.672 10.5712L1.63812 1.6413L0.0615234 6.38341Z"
        fill="#763E1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.61965 1.58275C1.63848 1.57681 1.65902 1.58026 1.67488 1.59203L13.7088 10.522C13.7239 10.5332 13.733 10.5507 13.7336 10.5695C13.7342 10.5883 13.7262 10.6063 13.7118 10.6185L9.08057 14.5353C9.07399 14.5409 9.06633 14.545 9.05806 14.5474L2.51764 16.4566C2.49438 16.4634 2.46929 16.4559 2.45355 16.4374L1.01244 14.75C1.00129 14.7369 0.996095 14.7198 0.99812 14.7027C1.00015 14.6857 1.00921 14.6702 1.02311 14.6601L1.59014 14.2488L0.648223 13.3883C0.634805 13.376 0.627487 13.3584 0.628227 13.3403C0.628967 13.3221 0.637689 13.3052 0.65206 13.2941L1.35208 12.7521L0.406275 12.037C0.390821 12.0253 0.381767 12.0071 0.381833 11.9877C0.381899 11.9683 0.391077 11.9501 0.40661 11.9385L1.05258 11.457L0.00128725 6.39612C-0.000920778 6.38549 -0.000279805 6.37446 0.00314523 6.36416L1.57974 1.62205C1.58597 1.60331 1.60081 1.5887 1.61965 1.58275ZM1.66979 1.74163L0.125255 6.38729L1.18116 11.4704C1.18603 11.4938 1.17687 11.518 1.15767 11.5323L0.545993 11.9883L1.49057 12.7024C1.50586 12.714 1.5149 12.732 1.51501 12.7512C1.51512 12.7704 1.50629 12.7885 1.49113 12.8003L0.785332 13.3467L1.72899 14.2088C1.74266 14.2213 1.74998 14.2392 1.74894 14.2577C1.7479 14.2762 1.7386 14.2932 1.72362 14.3041L1.1494 14.7207L2.52135 16.3272L9.01101 14.4328L13.573 10.5746L1.66979 1.74163Z"
        fill="#763E1A"
      />
      <path
        d="M29.4998 16.3974L22.9594 14.4883L24.9301 17.469L21.9863 23.2212L25.8786 23.1719H31.6923L29.4998 16.3974Z"
        fill="#F5841F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9099 14.4516C22.9252 14.4308 22.9519 14.4217 22.9767 14.429L29.5171 16.3381C29.5367 16.3439 29.5522 16.3589 29.5585 16.3783L31.7509 23.1527C31.757 23.1715 31.7537 23.192 31.7421 23.2079C31.7305 23.2239 31.712 23.2333 31.6923 23.2333H25.8786L21.9872 23.2826C21.9656 23.2828 21.9454 23.2718 21.9341 23.2534C21.9227 23.2351 21.9217 23.2121 21.9316 23.1929L24.859 17.4728L22.9081 14.5221C22.8938 14.5005 22.8945 14.4724 22.9099 14.4516ZM23.1035 14.5943L24.9816 17.4349C24.9938 17.4534 24.9951 17.4771 24.985 17.4969L22.0878 23.1581L25.8786 23.1101L31.6077 23.1101L29.4513 16.4472L23.1035 14.5943Z"
        fill="#F5841F"
      />
      <path
        d="M9.04087 14.4883L2.50045 16.3974L0.320312 23.1719H6.1217L10.0139 23.2212L7.07012 17.469L9.04087 14.4883Z"
        fill="#F5841F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0905 14.4516C9.10583 14.4724 9.10655 14.5005 9.0923 14.5221L7.14139 17.4728L10.0688 23.1929C10.0786 23.2121 10.0777 23.2351 10.0663 23.2534C10.0549 23.2718 10.0348 23.2828 10.0132 23.2826L6.12176 23.2333H0.320375C0.300692 23.2333 0.282194 23.2239 0.270602 23.208C0.25901 23.1921 0.255721 23.1716 0.261751 23.1528L2.44189 16.3784C2.44815 16.3589 2.46364 16.3439 2.48326 16.3381L9.02367 14.429C9.04848 14.4217 9.07517 14.4308 9.0905 14.4516ZM2.54913 16.4472L0.404891 23.1101H6.12254L9.91263 23.1581L7.01536 17.4969C7.00524 17.4771 7.00655 17.4534 7.01881 17.4349L8.89688 14.5943L2.54913 16.4472Z"
        fill="#F5841F"
      />
      <path
        d="M17.9093 17.7891L18.3281 10.5713L20.225 5.435H11.7754L13.6722 10.5713L14.091 17.7891L14.2511 20.0555L14.2635 25.6598H17.7369L17.7492 20.0555L17.9093 17.7891Z"
        fill="#F5841F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7249 5.39992C11.7364 5.38339 11.7553 5.37354 11.7755 5.37354H20.225C20.2452 5.37354 20.264 5.38339 20.2756 5.39992C20.2871 5.41644 20.2898 5.43756 20.2828 5.45646L18.3891 10.5841L17.9709 17.7928L17.9708 17.7936L17.8109 20.0578L17.7986 25.66C17.7985 25.694 17.7709 25.7215 17.737 25.7215H14.2635C14.2296 25.7215 14.202 25.694 14.2019 25.66L14.1896 20.0578L14.0296 17.7936L14.0296 17.7928L13.6113 10.5841L11.7177 5.45646C11.7107 5.43756 11.7134 5.41644 11.7249 5.39992ZM11.8638 5.49671L13.7301 10.55C13.7322 10.5558 13.7334 10.5617 13.7338 10.5678L14.1525 17.7849L14.1526 17.7857L14.3126 20.0513C14.3127 20.0527 14.3128 20.0541 14.3128 20.0555L14.325 25.5983H17.6755L17.6877 20.0555C17.6877 20.0541 17.6877 20.0527 17.6878 20.0513L17.8479 17.7857L17.848 17.7849L18.2667 10.5678C18.2671 10.5617 18.2683 10.5558 18.2704 10.55L20.1366 5.49671H11.8638Z"
        fill="#F5841F"
      />
    </svg>
  );
}
export function CoinbaseIcon() {
  return (
    <svg
      width="55px"
      height="55px"
      viewBox="-51.2 -51.2 1126.40 1126.40"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <circle cx="512" cy="512" r="512" fill="#0052ff" />
        <path
          d="M516.3 361.83c60.28 0 108.1 37.18 126.26 92.47H764C742 336.09 644.47 256 517.27 256 372.82 256 260 365.65 260 512.49S370 768 517.27 768c124.35 0 223.82-80.09 245.84-199.28H642.55c-17.22 55.3-65 93.45-125.32 93.45-83.23 0-141.56-63.89-141.56-149.68.04-86.77 57.43-150.66 140.63-150.66z"
          fill="#fff"
        />{" "}
      </g>
    </svg>
  );
}
export function TrustIcon() {
  return (
    <svg
      width="55px"
      height="55px"
      viewBox="-28.8 -28.8 249.60 249.60"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#fff"
    >
      <g
        id="SVGRepo_bgCarrier"
        strokeWidth="0"
        transform="translate(10.560000000000002,10.560000000000002), scale(0.89)"
      >
        <rect
          x="-28.8"
          y="-28.8"
          width="249.60"
          height="249.60"
          rx="124.8"
          fill="#3375bb"
          strokeWidth="0"
        />
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="12"
          d="M95.958 22C121.031 42.867 149.785 42 158 42c-1.797 118.676-15 95-62.042 128C49 137 35.798 160.676 34 42c8.13 0 36.883.867 61.958-20Z"
        />
      </g>
    </svg>
  );
}
export function DropdownIconwhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.917"
      height="12.003"
      viewBox="0 0 19.917 12.003"
      fill="#fff"
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M16.15,19.631l7.531-7.944a1.367,1.367,0,0,1,2.01,0,1.572,1.572,0,0,1,0,2.125l-8.533,9a1.369,1.369,0,0,1-1.963.044L6.6,13.819a1.568,1.568,0,0,1,0-2.125,1.367,1.367,0,0,1,2.01,0Z"
        transform="translate(-6.188 -11.246)"
      />
    </svg>
  );
}
const handleSvgClick = (props) => {
  // Your function to be triggered when the SVG is clicked
  props(props.onClick);
};
export function CloseIcon(props) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => props.onClick()}
    >
      <path
        d="M1.93832 0.319068L6.5 4.88056L11.038 0.342703C11.1383 0.236013 11.259 0.150665 11.3931 0.091776C11.5271 0.0328874 11.6716 0.00167138 11.818 0C12.1315 0 12.432 0.124504 12.6537 0.346122C12.8753 0.56774 12.9998 0.868318 12.9998 1.18173C13.0026 1.32662 12.9757 1.47053 12.9208 1.60465C12.8659 1.73877 12.7842 1.86027 12.6807 1.96168L8.08359 6.49953L12.6807 11.0965C12.8755 11.287 12.9897 11.545 12.9998 11.8173C12.9998 12.1307 12.8753 12.4313 12.6537 12.6529C12.432 12.8746 12.1315 12.9991 11.818 12.9991C11.6674 13.0053 11.5171 12.9802 11.3768 12.9252C11.2364 12.8703 11.109 12.7868 11.0026 12.68L6.5 8.11851L1.95013 12.6682C1.85028 12.7713 1.73099 12.8537 1.59915 12.9104C1.4673 12.9672 1.32552 12.9974 1.18198 12.9991C0.868547 12.9991 0.567956 12.8746 0.346329 12.6529C0.124701 12.4313 0.000192284 12.1307 0.000192284 11.8173C-0.00256304 11.6725 0.0243189 11.5285 0.0791885 11.3944C0.134058 11.2603 0.215758 11.1388 0.319274 11.0374L4.91641 6.49953L0.319274 1.90259C0.124498 1.71205 0.0102843 1.45402 0.000192284 1.18173C0.000192284 0.868318 0.124701 0.56774 0.346329 0.346122C0.567956 0.124504 0.868547 0 1.18198 0C1.4656 0.0035452 1.73741 0.118173 1.93832 0.319068Z"
        fill="#000"
      />
    </svg>
  );
}

export function CopylinkIcon() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.63605 5.63605C7.19815 4.07395 9.73081 4.07395 11.2929 5.63605L14.1213 8.46448C15.6834 10.0266 15.6834 12.5592 14.1213 14.1213C13.7308 14.5119 13.0976 14.5119 12.7071 14.1213C12.3166 13.7308 12.3166 13.0976 12.7071 12.7071C13.4882 11.9261 13.4882 10.6597 12.7071 9.87869L9.87869 7.05026C9.09764 6.26922 7.83131 6.26922 7.05026 7.05026C6.26922 7.83131 6.26922 9.09764 7.05026 9.87869L7.75737 10.5858C8.1479 10.9763 8.14789 11.6095 7.75737 12C7.36685 12.3905 6.73368 12.3905 6.34316 12L5.63605 11.2929C4.07395 9.73081 4.07395 7.19815 5.63605 5.63605ZM11.2929 9.8787C11.6834 10.2692 11.6834 10.9024 11.2929 11.2929C10.5119 12.074 10.5119 13.3403 11.2929 14.1213L14.1213 16.9498C14.9024 17.7308 16.1687 17.7308 16.9498 16.9498C17.7308 16.1687 17.7308 14.9024 16.9498 14.1213L16.2427 13.4142C15.8521 13.0237 15.8521 12.3905 16.2427 12C16.6332 11.6095 17.2663 11.6095 17.6569 12L18.364 12.7071C19.9261 14.2692 19.9261 16.8019 18.364 18.364C16.8019 19.9261 14.2692 19.9261 12.7071 18.364L9.8787 15.5356C8.3166 13.9735 8.3166 11.4408 9.8787 9.8787C10.2692 9.48817 10.9024 9.48817 11.2929 9.8787Z"
          fill="#fff"
        />{" "}
      </g>
    </svg>
  );
}

export function LinkedinIcon() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="-11 -11 42.00 42.00"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g
        id="SVGRepo_bgCarrier"
        strokeWidth="0"
        transform="translate(1.0999999999999996,1.0999999999999996),"
      >
        <rect
          x="-11"
          y="-11"
          width="42.00"
          height="42.00"
          rx="21"
          fill="#edc45d"
          strokeWidth="0"
        />
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="0.24"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <title>linkedin [#161]</title> <desc>Created with Sketch.</desc>{" "}
        <defs> </defs>{" "}
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          {" "}
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-180.000000, -7479.000000)"
            fill="#000000"
          >
            {" "}
            <g id="icons" transform="translate(56.000000, 160.000000)">
              {" "}
              <path
                d="M144,7339 L140,7339 L140,7332.001 C140,7330.081 139.153,7329.01 137.634,7329.01 C135.981,7329.01 135,7330.126 135,7332.001 L135,7339 L131,7339 L131,7326 L135,7326 L135,7327.462 C135,7327.462 136.255,7325.26 139.083,7325.26 C141.912,7325.26 144,7326.986 144,7330.558 L144,7339 L144,7339 Z M126.442,7323.921 C125.093,7323.921 124,7322.819 124,7321.46 C124,7320.102 125.093,7319 126.442,7319 C127.79,7319 128.883,7320.102 128.883,7321.46 C128.884,7322.819 127.79,7323.921 126.442,7323.921 L126.442,7323.921 Z M124,7339 L129,7339 L129,7326 L124,7326 L124,7339 Z"
                id="linkedin-[#161]"
              >
                {" "}
              </path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}

export function LeftarrowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6669 13.8334C10.5342 13.834 10.4068 13.7812 10.3135 13.6867L4.98021 8.35339C4.78524 8.15818 4.78524 7.84194 4.98021 7.64673L10.3135 2.31339C10.5106 2.12982 10.8176 2.13524 11.008 2.32565C11.1984 2.51605 11.2038 2.82305 11.0202 3.02006L6.04021 8.00006L11.0202 12.9801C11.2152 13.1753 11.2152 13.4915 11.0202 13.6867C10.927 13.7812 10.7996 13.834 10.6669 13.8334Z"
        fill="white"
      />
    </svg>
  );
}

export function RightarrowwhiteIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33354 13.8334C5.46626 13.834 5.59362 13.7812 5.68688 13.6867L11.0202 8.35339C11.2152 8.15818 11.2152 7.84194 11.0202 7.64673L5.68688 2.31339C5.48987 2.12982 5.18287 2.13524 4.99246 2.32565C4.80206 2.51605 4.79664 2.82305 4.98021 3.02006L9.96021 8.00006L4.98021 12.9801C4.78524 13.1753 4.78524 13.4915 4.98021 13.6867C5.07347 13.7812 5.20083 13.834 5.33354 13.8334Z"
        fill="white"
      />
    </svg>
  );
}

export function InfoIcom() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24.00 24.00"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75Z"
          fill="#000"
        />{" "}
        <path
          d="M12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z"
          fill="#000"
        />{" "}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75Z"
          fill="#000"
        />{" "}
      </g>
    </svg>
  );
}

export function DownloadIcom() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#fff"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="Interface / Download">
          {" "}
          <path
            id="Vector"
            d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />{" "}
        </g>{" "}
      </g>
    </svg>
  );
}

export function EditIcom() {
  return (
    <svg width="35px" height="35px" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg" fill="#fff">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete">
        <g id="edit">
          <g>
            <path d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
            <polygon fill="none" points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polygon>
          </g>
        </g>
      </g>
      </g>
    </svg>
  );
}

export function NewInfoIcom() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
      <defs>
        <clipPath id="clip-path">
          <path id="Path_5595" data-name="Path 5595" d="M4.141,3.168.867,3.682l-.118.373c.137-.007.258-.01.358-.01a.769.769,0,0,1,.516.16.465.465,0,0,1,.19.365,3.6,3.6,0,0,1-.206.972L.176,10.013A2.888,2.888,0,0,0,0,10.894a1.142,1.142,0,0,0,.361.863,1.361,1.361,0,0,0,.973.341q1.6,0,3.013-2.556l-.316-.19a5.878,5.878,0,0,1-1,1.3.468.468,0,0,1-.314.145.238.238,0,0,1-.175-.091.3.3,0,0,1-.083-.2,3.44,3.44,0,0,1,.172-.741L4.721,3.168Z" transform="translate(0 -3.168)" fill="#0a0a0a" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Path_5596" data-name="Path 5596" d="M3.262,0a1.3,1.3,0,0,0-.97.4,1.326,1.326,0,0,0-.4.973,1.335,1.335,0,0,0,.4.979,1.316,1.316,0,0,0,.965.4,1.329,1.329,0,0,0,.972-.4,1.344,1.344,0,0,0,.4-.979A1.331,1.331,0,0,0,4.238.4,1.309,1.309,0,0,0,3.262,0Z" transform="translate(-1.895 0)" fill="#0a0a0a" />
        </clipPath>
      </defs>
      <g id="Group_1250" data-name="Group 1250" transform="translate(-222.142 -354.333)">
        <rect id="Rectangle_8" data-name="Rectangle 8" width="20" height="20" rx="10" transform="translate(222.642 354.833)" fill="#f8f8f8" stroke="#e7e7e7" stroke-width="1" />
        <g id="Group_1247" data-name="Group 1247" transform="translate(230.142 358.333)">
          <g id="Group_1246" data-name="Group 1246">
            <g id="Group_1243" data-name="Group 1243" transform="translate(0 4.3)">
              <g id="Group_1242" data-name="Group 1242" clip-path="url(#clip-path)">
                <rect id="Rectangle_599" data-name="Rectangle 599" width="4.721" height="8.929" transform="translate(0 0)" fill="#0a0a0a" />
              </g>
            </g>
            <g id="Group_1245" data-name="Group 1245" transform="translate(2.572)">
              <g id="Group_1244" data-name="Group 1244" clip-path="url(#clip-path-2)">
                <rect id="Rectangle_600" data-name="Rectangle 600" width="2.739" height="2.757" transform="translate(0 0)" fill="#0a0a0a" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function InfoNewIcom() {
  return (
    <svg id="Group_1282" data-name="Group 1282" xmlns="http://www.w3.org/2000/svg" width="2.632" height="6.557" viewBox="0 0 2.632 6.557">
      <defs>
        <clipPath id="clip-path">
          <path id="Path_5595" data-name="Path 5595" d="M2.052,3.168.43,3.423l-.059.185c.068,0,.128,0,.178,0A.381.381,0,0,1,.8,3.683.231.231,0,0,1,.9,3.864a1.782,1.782,0,0,1-.1.482L.087,6.56A1.431,1.431,0,0,0,0,7a.566.566,0,0,0,.179.428.675.675,0,0,0,.482.169q.791,0,1.493-1.267L2,6.233a2.913,2.913,0,0,1-.5.644.232.232,0,0,1-.155.072A.118.118,0,0,1,1.26,6.9a.148.148,0,0,1-.041-.1A1.7,1.7,0,0,1,1.3,6.437L2.34,3.168Z" transform="translate(0 -3.168)" fill="#707070" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Path_5596" data-name="Path 5596" d="M2.572,0a.644.644,0,0,0-.481.2.657.657,0,0,0-.2.482.662.662,0,0,0,.2.485.678.678,0,0,0,.96,0,.666.666,0,0,0,.2-.485A.66.66,0,0,0,3.056.2.649.649,0,0,0,2.572,0Z" transform="translate(-1.895 0)" fill="#707070" />
        </clipPath>
      </defs>
      <g id="Group_1246" data-name="Group 1246" transform="translate(0)">
        <g id="Group_1243" data-name="Group 1243" transform="translate(0 2.131)">
          <g id="Group_1242" data-name="Group 1242" clip-path="url(#clip-path)">
            <rect id="Rectangle_599" data-name="Rectangle 599" width="2.34" height="4.426" transform="translate(0 0)" fill="#707070" />
          </g>
        </g>
        <g id="Group_1245" data-name="Group 1245" transform="translate(1.275)">
          <g id="Group_1244" data-name="Group 1244" clip-path="url(#clip-path-2)">
            <rect id="Rectangle_600" data-name="Rectangle 600" width="1.358" height="1.366" transform="translate(0 0)" fill="#707070" />
          </g>
        </g>
      </g>
    </svg>
  );
}
// new Social Icons

export function CircleFBIcon() {
  return (
    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5008_9639" maskUnits="userSpaceOnUse" x="0" y="0" width="7" height="13">
        <path d="M6.78 0H0V12.237H6.78V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5008_9639)">
        <path d="M4.48966 12.237V6.88399H6.33621L6.68793 4.66899H4.48966V3.23199C4.4737 3.06636 4.49657 2.89937 4.55656 2.74346C4.61655 2.58754 4.71212 2.44669 4.83617 2.33139C4.96021 2.21609 5.10955 2.1293 5.27307 2.07746C5.43659 2.02563 5.6101 2.01009 5.78069 2.03199H6.78V0.149994C6.19319 0.0582509 5.60023 0.00811722 5.00586 -5.68479e-06C4.59678 -0.0307861 4.18571 0.0259382 3.80187 0.166134C3.41804 0.306329 3.07087 0.526552 2.78506 0.811142C2.49925 1.09573 2.28181 1.4377 2.14821 1.81273C2.01461 2.18776 1.96813 2.58663 2.01207 2.98099V4.66899H0V6.88399H2.01207V12.238L4.48966 12.237Z" fill="black" />
      </g>
    </svg>
  );
}

export function CircleLinkedinIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5008_9603" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
        <path d="M12.266 0H0V12.237H12.266V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5008_9603)">
        <path d="M0.2 4.062H2.748V12.237H0.2V4.062ZM1.475 1.35836e-06C1.76629 0.00039693 2.05092 0.0871482 2.29291 0.249288C2.53491 0.411428 2.72339 0.641676 2.83454 0.910924C2.94569 1.18017 2.97452 1.47633 2.91738 1.76196C2.86024 2.04759 2.71969 2.30987 2.51351 2.51563C2.30733 2.72139 2.04477 2.8614 1.75902 2.91796C1.47328 2.97453 1.17718 2.9451 0.908154 2.8334C0.639132 2.7217 0.409268 2.53274 0.247621 2.29042C0.0859745 2.0481 -0.000197145 1.76329 3.38678e-07 1.472C0.000131539 1.27848 0.0383949 1.08688 0.112604 0.908154C0.186813 0.729426 0.295513 0.567073 0.432493 0.430372C0.569472 0.293671 0.732046 0.185302 0.910924 0.111457C1.0898 0.0376118 1.28148 -0.000261444 1.475 1.35836e-06Z" fill="black" />
        <path d="M4.342 4.062H6.78V5.18H6.813C7.05711 4.7629 7.40981 4.41983 7.83351 4.18737C8.25722 3.95492 8.73606 3.84177 9.219 3.86C11.792 3.86 12.266 5.552 12.266 7.754V12.238H9.727V8.263C9.727 7.314 9.709 6.095 8.406 6.095C7.083 6.095 6.882 7.127 6.882 8.195V12.239H4.342V4.062Z" fill="black" />
      </g>
    </svg>
  );
}

export function CircleInstaIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5008_9626" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
        <path d="M16.739 0H0V16.181H16.739V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5008_9626)">
        <path d="M12.8007 2.889C12.6064 2.8888 12.4165 2.94432 12.2549 3.04852C12.0933 3.15272 11.9673 3.30092 11.8929 3.47437C11.8184 3.64782 11.7989 3.83871 11.8367 4.0229C11.8746 4.20708 11.9681 4.37626 12.1055 4.50904C12.2428 4.64182 12.4179 4.73223 12.6084 4.76881C12.7989 4.8054 12.9964 4.78652 13.1758 4.71457C13.3553 4.64262 13.5086 4.52083 13.6164 4.36461C13.7242 4.2084 13.7816 4.02478 13.7814 3.837C13.7814 3.58558 13.6781 3.34445 13.4941 3.16666C13.3102 2.98888 13.0608 2.889 12.8007 2.889Z" fill="black" />
        <path d="M8.43827 4.106C7.62294 4.106 6.82591 4.33972 6.14798 4.7776C5.47005 5.21547 4.94167 5.83785 4.62966 6.56601C4.31764 7.29417 4.23601 8.09542 4.39507 8.86844C4.55413 9.64145 4.94676 10.3515 5.52329 10.9088C6.09982 11.4661 6.83436 11.8457 7.63403 11.9994C8.4337 12.1532 9.26258 12.0743 10.0159 11.7727C10.7691 11.471 11.413 10.9603 11.8659 10.305C12.3189 9.64962 12.5607 8.87916 12.5607 8.091C12.5593 7.03452 12.1246 6.02169 11.3517 5.27464C10.5789 4.5276 9.53118 4.10733 8.43827 4.106ZM8.43827 10.644C7.91584 10.6442 7.40508 10.4946 6.9706 10.2142C6.53613 9.93371 6.19746 9.535 5.99744 9.06846C5.79742 8.60192 5.74503 8.08851 5.8469 7.59318C5.94877 7.09786 6.20033 6.64286 6.56975 6.28576C6.93916 5.92866 7.40984 5.68549 7.92225 5.58701C8.43466 5.48853 8.96577 5.53918 9.4484 5.73253C9.93102 5.92588 10.3435 6.25326 10.6336 6.67326C10.9237 7.09325 11.0785 7.58698 11.0783 8.092C11.0775 8.76859 10.799 9.41724 10.3041 9.89566C9.80921 10.3741 9.13819 10.6432 8.43827 10.644Z" fill="black" />
        <path d="M11.7114 16.181H5.02759C3.69461 16.1797 2.41662 15.6672 1.47406 14.7561C0.5315 13.8449 0.00136927 12.6095 0 11.321V4.86C0.00136927 3.57145 0.5315 2.33606 1.47406 1.42492C2.41662 0.513783 3.69461 0.00132362 5.02759 0L11.7114 0C13.0444 0.00132362 14.3223 0.513783 15.2649 1.42492C16.2075 2.33606 16.7376 3.57145 16.739 4.86V11.321C16.7376 12.6095 16.2075 13.8449 15.2649 14.7561C14.3223 15.6672 13.0444 16.1797 11.7114 16.181ZM5.02759 1.522C4.11202 1.52279 3.23419 1.87473 2.58678 2.50055C1.93938 3.12638 1.5753 3.97495 1.57448 4.86V11.321C1.57558 12.2059 1.93977 13.0542 2.58715 13.6798C3.23452 14.3054 4.1122 14.6572 5.02759 14.658H11.7114C12.6269 14.6572 13.5048 14.3053 14.1522 13.6794C14.7996 13.0536 15.1637 12.205 15.1645 11.32V4.86C15.1637 3.97495 14.7996 3.12638 14.1522 2.50055C13.5048 1.87473 12.6269 1.52279 11.7114 1.522H5.02759Z" fill="black" />
      </g>
    </svg>
  );
}

export function CircleTwitterIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="fillData">
      <path d="M5.46 6.378L5.00379 5.749L1.38103 0.739H2.94103L5.86552 4.782L6.32172 5.411L10.1224 10.666H8.56138L5.46 6.378ZM10.1203 0L6.39621 4.181L3.42828 0H0L4.49379 6.323L0 11.372H1.01586L4.9469 6.957L8.08552 11.372H11.5138L6.85034 4.815L11.1362 0H10.1203Z" fill="black" />
    </svg>
  );
}

export function CircleShareIcon() {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5008_9611" maskUnits="userSpaceOnUse" x="0" y="0" width="11" height="14">
        <path d="M10.3448 0H0V14H10.3448V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5008_9611)">
        <path d="M3.91966 2.156C3.91966 2.51513 3.8095 2.86619 3.60312 3.16481C3.39674 3.46343 3.1034 3.69619 2.76019 3.83367C2.41698 3.97115 2.0393 4.00717 1.67492 3.93718C1.31053 3.86719 0.975787 3.69433 0.713016 3.44046C0.450245 3.18659 0.271243 2.8631 0.198639 2.5109C0.126035 2.15869 0.16309 1.79359 0.30512 1.46174C0.44715 1.12989 0.687776 0.846202 0.996578 0.646538C1.30538 0.446873 1.66849 0.340198 2.04 0.34C2.28679 0.339869 2.53119 0.386744 2.75924 0.477948C2.98728 0.569152 3.1945 0.702897 3.36906 0.871541C3.54361 1.04019 3.68208 1.24042 3.77656 1.46082C3.87103 1.68121 3.91966 1.91744 3.91966 2.156Z" fill="black" />
        <path d="M3.91966 11.843C3.91966 12.2021 3.8095 12.5532 3.60312 12.8518C3.39674 13.1504 3.1034 13.3832 2.76019 13.5207C2.41698 13.6581 2.0393 13.6942 1.67492 13.6242C1.31053 13.5542 0.975787 13.3813 0.713016 13.1275C0.450245 12.8736 0.271243 12.5501 0.198639 12.1979C0.126035 11.8457 0.16309 11.4806 0.30512 11.1487C0.44715 10.8169 0.687776 10.5332 0.996578 10.3335C1.30538 10.1339 1.66849 10.0272 2.04 10.027C2.53824 10.027 3.01608 10.2183 3.36839 10.5589C3.7207 10.8995 3.91966 11.3614 3.91966 11.843Z" fill="black" />
        <path d="M10.1835 7C10.1835 7.35913 10.0733 7.7102 9.86692 8.00881C9.66054 8.30743 9.3672 8.54019 9.02399 8.67767C8.68077 8.81515 8.3031 8.85117 7.93871 8.78118C7.57432 8.71119 7.23958 8.53833 6.97681 8.28446C6.71404 8.03059 6.53504 7.7071 6.46243 7.3549C6.38983 7.00269 6.42688 6.63759 6.56891 6.30574C6.71094 5.97389 6.95157 5.6902 7.26037 5.49054C7.56917 5.29087 7.93228 5.1842 8.3038 5.184C8.80204 5.184 9.27987 5.37533 9.63218 5.7159C9.98449 6.05646 10.1824 6.51837 10.1824 7" fill="black" />
        <path d="M2.08138 1.951L8.25828 6.987" stroke="black" />
        <path d="M8.10931 6.987L2.0069 12.166" stroke="black" />
      </g>
    </svg>
  );
}

export function BaseLinkedinIcon() {
  return (
    <svg width="33px" height="33px" viewBox="0 0 48.00 48.00" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" stroke-width="0" />
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
      <g id="SVGRepo_iconCarrier"> <circle cx="24" cy="24" r="20" fill="#0077B5" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7747 14.2839C18.7747 15.529 17.8267 16.5366 16.3442 16.5366C14.9194 16.5366 13.9713 15.529 14.0007 14.2839C13.9713 12.9783 14.9193 12 16.3726 12C17.8267 12 18.7463 12.9783 18.7747 14.2839ZM14.1199 32.8191V18.3162H18.6271V32.8181H14.1199V32.8191Z" fill="white" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2393 22.9446C22.2393 21.1357 22.1797 19.5935 22.1201 18.3182H26.0351L26.2432 20.305H26.3322C26.9254 19.3854 28.4079 17.9927 30.8101 17.9927C33.7752 17.9927 35.9995 19.9502 35.9995 24.219V32.821H31.4922V24.7838C31.4922 22.9144 30.8404 21.6399 29.2093 21.6399C27.9633 21.6399 27.2224 22.4999 26.9263 23.3297C26.8071 23.6268 26.7484 24.0412 26.7484 24.4574V32.821H22.2411V22.9446H22.2393Z" fill="white" /> </g>
    </svg>
  );
}

CloseIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};
