import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDebounce } from "../../../../../customHooks/useDebounce";
import style from "../index_dashboard.module.scss";
import Purchased from "./Purchased";
import Sold from "./Sold";
import { useDispatch, useSelector } from "react-redux";
import { getAllPurchasedSoldList } from "../../../../../Features/drop/dropSlice";
import { COMPARISION_CONSTANT_NAMES, PER_PAGE_RECORD_LIMIT } from "../../../../../Constant/userConstant";
import {
  setPagePurchase,
  setTabTypeTransaction,
} from "../../../../../Features/user/userSlice";
 
const Transactions = ({ ArtistId }) => {
  const dispatch = useDispatch();
  const elementToScroll = useRef(null);
  const [activeTab, setActiveTab] = useState("buy");
  const [purchasedSoldList, setPurchasedSoldList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  // const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const pagePurchase = useSelector((state) => state.users.pagePurchase);
  const tabTypeTransaction = useSelector(
    (state) => state.users.tabTypeTransaction
  );

  const onTabChange = (e) => {
    // setActiveTab(e);
    dispatch(setTabTypeTransaction(e));
    setSearch("");
    // setPage(1);
    dispatch(setPagePurchase(1));
  };

  useEffect(() => {
    if (
      debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
      debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE
    ) {

    getPuchaseSoldHisotry();
    }
  }, [tabTypeTransaction, debouncedSearchTerm, pagePurchase, ArtistId]);

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const getPuchaseSoldHisotry = async () => {
    let data = {
      type: tabTypeTransaction,
      page: search !== "" ? 1 : pagePurchase,
      size: PER_PAGE_RECORD_LIMIT.DIGTIAL_ART_LIMIT,
      // size: 1,
      search:  debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }


    try {
      let response = await dispatch(getAllPurchasedSoldList(data));
      let record = response.data.data;
      if (response) {
        setTotalPage(record.totalPages);
        setPurchasedSoldList(record.nft_order_list);
      }
      scrollToElement();
    } catch (err) {}
  };

  return (
    <>
      <section className={style.trasnsaction_tab} ref={elementToScroll}>
        <Tabs
          defaultActiveKey="purchased"
          id="uncontrolled-tab-example"
          className="tablists"
          activeKey={tabTypeTransaction}
          onSelect={onTabChange}
        >
          <Tab eventKey="buy" title="Purchased">
            {tabTypeTransaction == "buy" && (
              <Purchased
                data={purchasedSoldList}
                totalPage={totalPage}
                page={pagePurchase}
                // setPage={setPage}
                setSearch={setSearch}
              />
            )}
          </Tab>
          <Tab eventKey="sell" title="Sold">
            {tabTypeTransaction == "sell" && (
              <Sold
                data={purchasedSoldList}
                totalPage={totalPage}
                page={pagePurchase}
                setSearch={setSearch}
              />
            )}
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Transactions;
