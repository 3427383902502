import React from "react";
import styles from "./CommonTabs.module.scss";

const CommonCardsTab = (props) => {
  const {
    tabs,
    children,
    activeTab,
    setActiveTab,
    className,
    ContentclassName,
  } = props;
  return (
    <>
      <div className={`${styles.CollectionDetailsTabs} ${className}`}>
        {tabs.map((item) => (
          <div
            key={item.id}
            onClick={() => setActiveTab(item.eventKey)}
            className={`${styles.CollectionDetailsTab} common_tab ${
              item.eventKey === activeTab ? styles.active : ""
            }`}
          >
            <p>{item.tab}</p>
          </div>
        ))}
      </div>
      <div className={`${styles.CollectionDetailsContent} ${ContentclassName}`}>
        {children}
      </div>
    </>
  );
};

export default CommonCardsTab;
