import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./MainLayout.scss";
import Footer from "../../Footer/NewFooter";
// import Header from "../../Header/Header";
// import HeaderBeforeLogin from "../../Header/HeaderBeforeLogin";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Header from "../../NewHeader/NewHeader";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";

const MainLayout = (props) => {
  const location = useLocation();
  const totalDfa = useSelector((state) => state?.marketplace?.totalDfa);
  const marketPlaceNfts = useSelector(
    (state) => state?.marketplace?.marketplaceNft
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);
  return (
    <div className="mainLayout">
      <div className="mainLayout_innerpart">
        <Header />
        {/* {isUserLoggedin ? <Header /> : <HeaderBeforeLogin />} */}
        <main className="mainWrappe">{props.children}</main>
      </div>
      {/* <Footer /> */}
      {location?.pathname !== RoutesUrls.Marketplace && <Footer />}
      {location?.pathname === RoutesUrls.Marketplace &&
        marketPlaceNfts.length === totalDfa && <Footer />}
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
