import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { COOKIE_NAME } from "../constant";
// import { cookie } from "../helper/cookies";
import { useSelector } from "react-redux";
import MainLayout from "../Component/Common/Layouts/MainLayout/MainLayout";

const NoGuard = () => {
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  let isAuthenticated = isUserLoggedin;

  return !isAuthenticated ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <MainLayout>
      <Navigate to="/home" />
    </MainLayout>
  );
};

export default NoGuard;
