import React, { useRef, useState } from "react";
import ClickExpendModal from "../../../NewArtPage/Component/ClickExpendModal/ClickExpendModal";
import style from "./ArtImagesleft.module.scss";
import { checkFeatureImageRuleBase, checkFileType } from "../../../../../Helper/common";

const ArtImagesleft = ({ artDetail }) => {
  const [clickShow, setClickShow] = useState(false);
  const mediaRef = useRef();

  const openFullScreen = () => {
    // Create a new media element dynamically
    let mediaType = checkFileType(artDetail?.feature_file);
    let mediaElement;
    if (mediaType === "MP4" || mediaType === "OGG") {
      mediaElement = document.createElement("video");
      mediaElement.src = checkFeatureImageRuleBase(artDetail);
      mediaElement.controls = true; // Add controls if needed
      mediaElement.autoplay = true; // Enable autoplay
      mediaElement.muted = true; // Mute the video to allow autoplay
      mediaElement.loop = true;
    } else {
      mediaElement = document.createElement("img");
      mediaElement.src = checkFeatureImageRuleBase(artDetail);
      mediaElement.alt = "Full Screen Image";
    }

    // Style the media element for full screen (optional)
    mediaElement.style.width = "100%";
    mediaElement.style.height = "100%";
    mediaElement.style.objectFit = "contain";

    // Append the media element to the body (to make it available for full-screen)
    document.body.appendChild(mediaElement);
    mediaRef.current = mediaElement; // Keep a reference to remove it later

    // Enter full-screen mode
    if (mediaElement.requestFullscreen) {
      mediaElement.requestFullscreen();
    } else if (mediaElement.webkitRequestFullscreen) {
      // Safari support
      mediaElement.webkitRequestFullscreen();
    } else if (mediaElement.msRequestFullscreen) {
      // IE/Edge support
      mediaElement.msRequestFullscreen();
    }

    // Handle full-screen exit to remove the media element
    mediaElement.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        // Full-screen mode exited, remove the media element from the DOM
        document.body.removeChild(mediaElement);
      }
    };
  };

  const getFetureFileSection = (src) => {
    let type = checkFileType(src);
    if (type == "MP4" || type == "OGG") {
      return (
        <video
          loop
          autoPlay
          muted
          controlsList="nodownload"
          ref={mediaRef}
          key={src}
        >
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return <img src={src} alt="Dfa-Image" ref={mediaRef} />;
    }
  };

  return (
    <>
      <div className={style.artImages}>
        {artDetail?.is_portrait === 0 ? (
          <div className={style.landscapeImg}>
            <div className={style.imageSec}>
              {getFetureFileSection(
                `${process.env.REACT_APP_IMG_BASE_URL}${artDetail?.feature_file}`
              )}
              {/* <CommonBtn
                className={`bg-border-without ${style.expandBtn}`}
                title="Click to Expand"
                role="btn"
                onClick={() => {
                  setClickShow(true);
                  handleFullScreen(true);
                }}
              /> */}
            </div>
          </div>
        ) : (
          <div className={style.portraitImg}>
            <div className={style.imageSec}>
              {getFetureFileSection(
                `${process.env.REACT_APP_IMG_BASE_URL}${artDetail?.feature_file}`
              )}
              {/* <CommonBtn
                className={`bg-border-without ${style.expandBtn}`}
                title="Click to Expand"
                role="btn"
                onClick={() => {
                  setClickShow(true);
                  handleFullScreen(true);
                }}
              /> */}
            </div>
          </div>
        )}
      </div>
      <ClickExpendModal
        show={clickShow}
        handleClose={() => setClickShow(false)}
        imageActive={true}
      />
    </>
  );
};

export default ArtImagesleft;
