import React from "react";
import Form from "react-bootstrap/Form";
import "./TextArea.scss";
import "../Input/Input.scss";
const Textarea = (props) => {
  const {
    label,
    formik,
    name,
    className,
    classField,
    maxLength,
    row,
    validationtitle,
    ...rest
  } = props;
  const handleKeyDown = (event) => {
    // Prevent Enter key press
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <Form.Group
      className={`common_textarea common_input ${className} ${
        formik.values[name] ? "hasFilled" : ""
      } ${formik?.touched[name] && formik?.errors[name] ? "hasError" : ""}`}
      controlId={name}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div className="common_textarea_inner common_input_inner">
        <textarea
          as="textarea"
          className={`form-control ${classField}`}
          name={name}
          {...rest}
          maxLength={maxLength}
          onKeyDown={handleKeyDown}
        />
      </div>
      {/* <ErrorMessage name={name} component={TextError} /> */}
      {formik?.touched[name] && (
        <p className="error_message">{formik?.errors[name]}</p>
      )}
      {validationtitle ? (
        <h6 className="text_valida">{validationtitle}</h6>
      ) : (
        ""
      )}
    </Form.Group>
  );
};

export default Textarea;
