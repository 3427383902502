import React from "react";
import Otheruserprofile from "../../Private/Userprofile/Otheruserprofile";
import { useSelector } from "react-redux";
import Userprofile from "../../Private/Userprofile/Userprofile";
import { useParams } from "react-router-dom";

const Profile = () => {
  const { username } = useParams();
  const userProfile = useSelector((state) => state?.users?.profileData);
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  return (
    <>
      {userProfile.username === username && isUserLoggedin === true ? (
        <>
          <Userprofile />
        </>
      ) : (
        <>
          <Otheruserprofile />
        </>
      )}
    </>
  );
};

export default Profile;
