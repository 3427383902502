import React from "react";
import "./Notfound.scss";
import PropTypes from "prop-types";

const Notfound = ({ className, text }) => {
  return (
      <div className={`not_recard ${className || ""}`}>
        <h6>{text ? text : "No record found"}</h6>
      </div>
  );
};

Notfound.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default Notfound;
