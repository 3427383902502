import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CommonCard from "../../../Common/Ui/CommonCard/CommonCard";
import CommonSlider from "../../../Common/Ui/CommonSlider/CommonSlider";
import styles from "./BrowseMarketplace.module.scss";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import { getBrowseMarketplaceList } from "../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notfound from "../../../Common/NotFound/Notfound";
import { checkFileType } from "../../../../Helper/common";
import HoverVideoPlayer from "react-hover-video-player";
import LoadingOverlay from "../../../VideoGif/LoadingOverlay";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";

const BrowseMarketplace = ({ marketplaceListData }) => {
  const navigate = useNavigate();

  const rsp = [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const getImageSectionBrowseMarket = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          muted={false}
          onClick={() => navigate(`/digital-fine-arts/${item.art_id}`)}
          className={`video_hover_sec ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <CommonCard
              className={` ${styles.cardhover}`}
              imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
              title={item.art_name}
              subtitle={`@${item.username}`}
              item={{ ...item, artist_id: item.user_id }}
            />
          }
          loadingOverlay={<LoadingOverlay />}
        />
      );
    } else {
      return (
        <CommonCard
          // className={`card_withouthover ${styles.cardhover}`}
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          title={item.art_name}
          subtitle={`@${item.username}`}
          item={{ ...item, artist_id: item.user_id }}
          onClick={() => navigate(`/digital-fine-arts/${item.art_id}`)}
        />
      );
    }
  };

  return (
    <>
      <section className={styles.BrowseMarketplace}>
        <Container>
          <h2>Browse Digital Fine Art</h2>
          {marketplaceListData?.length > 0 && (
            <>
              <CommonSlider
                className="arrowsDown"
                speed={3000}
                dots={false}
                autoplay={true}
                slidesToShow={5}
                arrows={marketplaceListData?.length > 5 ? true : false}
                slidesToScroll={1}
                responsive={rsp}
                infinite={marketplaceListData?.length > 5 ? true : false}
                pauseOnHover={true}
              >
                {marketplaceListData?.map((item, index) => (
                  <div key={index}>{getImageSectionBrowseMarket(item)}</div>
                ))}
              </CommonSlider>

              <div className={styles.actionsBtn}>
                <CommonBtn
                  className={styles.btnSize}
                  role="link"
                  to={RoutesUrls.Marketplace}
                  title="Explore All Digital Fine Art"
                  onClick={() => navigate(RoutesUrls?.Marketplace)}
                />
                <CommonBtn
                  title="What is DFA?"
                  className={`bg-border-without ${styles.btnSize}`}
                  role="btn"
                  onClick={() => navigate(RoutesUrls.About_dfa)}
                />
              </div>
            </>
          )}
          {marketplaceListData?.length === 0 && (
            <Notfound
              className={`my-5 ${styles.notFound}`}
              text={"No digital art found."}
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default BrowseMarketplace;
