import React from "react";
import style from "./Following.module.scss";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import profileSquare from "../../../../Assets/images/profileSquare.svg";
import { Col, Row } from "react-bootstrap";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notfound from "../../../Common/NotFound/Notfound";
import Select from "react-select";
import {
  setPageOtherUserProfile,
  setPageUserProfile,
} from "../../../../Features/user/userSlice";
import { sliceChar } from "../../../../Helper/common";

const Following = (props) => {
  let {
    data,
    page,
    totalPage,
    followUser,
    type,
    setLimit,
    limit,
    totalItems,
    limitOption,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state?.users?.profileData);

  const getButtonText = (item) => {
    if (type == "self") {
      return "Unfollow";
    } else if (type === "other") {
      if (item.isFollowed) {
        return "Unfollow";
      } else {
        return "Follow";
      }
    }
  };

  const followUnfollowUser = (item) => {
    if (type == "self") {
      followUser("unfollow", item);
    } else {
      if (item.isFollowed === 0) {
        followUser("follow", item);
      } else {
        followUser("unfollow", item);
      }
    }
  };

  const goToUserProfile = (item) => {
    navigate(`/profile/${item?.user?.username}`);
  };

  return (
    <>
      <section className={style.following}>
        {/* <div className={style.searchbar}>
          <>
            <div className="search_input">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Artist, User"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </>
        </div> */}
        <div className={style.following_section}>
          <div className={style.following_section_arttable}>
            {/* <h5>Art</h5> */}
            <div className={style.following_table}>
              {data.length > 0 && (
                <Row className={style.rowFollow}>
                  {data.map((item, index) => (
                    <Col
                      xxl={2}
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={6}
                      className={style.colFollow}
                      key={index}
                    >
                      <div className={style.info_user}>
                        <div className={style.info_user_innerSec}>
                          <img
                            src={
                              item.user.image_path
                                ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.user.image_path}`
                                : profileSquare
                            }
                            alt="userImg"
                            style={{ cursor: "pointer" }}
                            onClick={() => goToUserProfile(item)}
                          />

                          <div className={style.text_info}>
                            <h6
                              onClick={() => goToUserProfile(item)}
                              title={item?.user?.name}
                            >
                              {sliceChar(item?.user.name, 40)}
                            </h6>
                            <p
                              onClick={() => goToUserProfile(item)}
                              title={item?.user?.username}
                            >
                              @{sliceChar(item?.user.username, 17)}
                            </p>
                            {userProfile.id != item.user.id && (
                              <CommonBtn
                                role="btn"
                                title={getButtonText(item)}
                                className={`bordred-black ${style.btnfollow}`}
                                onClick={() => followUnfollowUser(item)}
                                type={type}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
              {data.length == 0 && (
                <div className={style.noData}>
                  <Notfound />
                </div>
              )}

              <div className="filterPagination">
                {totalItems > 10 && (
                  <div className="selectShow">
                    <label>Show</label>
                    <div className="selectShow_inner">
                      <Select
                        classNamePrefix="react-select"
                        className="react-select"
                        options={limitOption}
                        isSearchable={false}
                        onChange={(option) => {
                          setLimit(option);
                          dispatch(setPageOtherUserProfile(1));
                        }}
                        value={limit}
                      />
                    </div>
                  </div>
                )}
                {totalPage > 1 && (
                  <CustomPagination
                    // className="ms-auto"
                    activePage={page}
                    totalPage={totalPage}
                    maxVisiblePages={5}
                    dispatch={dispatch}
                    componentType={"Profile"}
                    componentTypes={"OtherUserProfile"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Following;
