import React, { useState, useEffect } from "react";
import socket from "../../Socket/socket";

const NextReleaseTimer = ({ startingTimestamp }) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  let timeDifference;
  //   if (startingTimestamp > currentTime) {
  timeDifference = startingTimestamp - currentTime;

  useEffect(() => {
    if (timeDifference <= 0) {
      socket.emit("latest_release_time");
    }
    return () => {
      socket.off("latest_release_time");
    };
  }, [timeDifference]);
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const getTime = () => {
    if (hours > 0 && minutes > 0 && seconds > 0) {
      return `${hours}Hrs ${minutes % 60}Mins ${seconds % 60}Secs`;
    } else if (hours == 0 && minutes > 0 && seconds > 0) {
      return `${minutes % 60}Mins ${seconds % 60}Secs`;
    } else if (minutes == 0 && seconds > 0) {
      return `${seconds % 60}Secs`;
    }
  };

  return (
    <>
      {timeDifference > 0 && (
        <>
          <p>{getTime()}</p>
        </>
      )}
    </>
  );
};

export default NextReleaseTimer;
