import React from 'react';
import './CommonTable.scss';

const CommonTable = ({ children, className }) => {
    return (
        <>
            <div className={`commonTable ${className}`}>
                <div className="table-responsive">
                    <table>
                        {children}
                    </table>
                </div>
            </div>
        </>
    )
}
export default CommonTable