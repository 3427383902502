import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikControls from "../../../../../../Common/Ui/Formik/FormikControls";
import {
  PlusIcon,
  SubtractionIcon,
} from "../../../../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./RafflesForm.module.scss";
import Timer from "../Timer";
import {
  DECIMAL,
  MARKET_TYPE,
} from "../../../../../../../Constant/userConstant";
import { useNavigate, useParams } from "react-router-dom";
import { getNumberWithComma } from "../../../../../../../Helper/common";
import { toasts } from "../../../../../../Common/Toast/Toast";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../../../../../Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";

const RafflesForm = ({
  dfaDetail,
  setShowClosedDrop,
  getDfaDetailByIdHandler,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  const initialValue = {
    quantity: 1,
  };
  const validationSchema = Yup.object({
    quantity: Yup.string().required("Enter Quantity"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onClickHandler(values);
    },
  });

  const onClickHandler = (values) => {
    let userBuyLimit = dfaDetail.buy_limit - dfaDetail.buy_counter;
    if (isUserLoggedin) {
      if (dfaDetail.is_eligible_to_buy) {
        if (values?.quantity > userBuyLimit && values?.quantity) {
          toasts.error("Your maximum limit is reached.");
        } else {
          try {
            let data = {
              price: dfaDetail.price / DECIMAL,
              quantity: Number(values?.quantity),
              buyCount: userBuyLimit,
              market: "primary",
            };
            dispatch(saveLatestPrice(data));
            navigate(`/checkout/${id}`);
          } catch (error) {}
        }
      } else {
        setShowClosedDrop(true);
      }
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  const subHanlder = () => {
    if (Number(formik?.values?.quantity) > 1) {
      formik?.setFieldValue("quantity", Number(formik?.values?.quantity) - 1);
    }
  };

  const addHanlder = () => {
    let buyerLimit = dfaDetail.buy_limit - dfaDetail.buy_counter;
    const unSoldEditions =
      Number(dfaDetail?.no_of_entries) - Number(dfaDetail?.sold_no_of_entries);
    if (
      buyerLimit > Number(formik?.values?.quantity) &&
      Number(formik?.values?.quantity) < unSoldEditions
    ) {
      formik?.setFieldValue("quantity", Number(formik?.values?.quantity) + 1);
    } else {
      toasts.error("Your maximum limit is reached.");
    }
  };

  const showToastAndSetValue = (value) => {
    toasts.error(`You can buy only ${value} editions.`);
    setTimeout(() => {
      formik?.setFieldValue("quantity", value);
    }, [1000]);
  };

  const onChangeHandler = (inputQuantity) => {
    let buyerLimit = dfaDetail.buy_limit - dfaDetail.buy_counter;
    const unSoldEditions =
      Number(dfaDetail?.no_of_entries) - Number(dfaDetail?.sold_no_of_entries);
    // Validate if the entered inputQuantity is a valid number
    let userQuantity = Number(inputQuantity);
    if (/^\d+(\.\d*)?$/.test(userQuantity)) {
      if (userQuantity != "") {
        //If user enter quatity less than unsold edition and their buyer limit
        //allow to set the quantity
        if (userQuantity <= unSoldEditions && userQuantity <= buyerLimit) {
          formik?.setFieldValue("quantity", userQuantity);
        }
        //If user enter quantity gretear than unsold edition and less than buyer limit
        // Set quantity to unsold edition with message
        else if (unSoldEditions <= userQuantity && userQuantity <= buyerLimit) {
          showToastAndSetValue(unSoldEditions);
        }
        // If user enter quantity lesser than unsold edition and greater buyer limit
        // Set quantity to buyer limit with message
        else if (unSoldEditions >= userQuantity && userQuantity >= buyerLimit) {
          showToastAndSetValue(buyerLimit);
        }
        //if user enter greater than both unsold edition and buyer limit
        //Comparing which is small value
        //if unsold edition is less than buyer limit, set quantity to unsold edition and vise versa
        else if (unSoldEditions <= userQuantity && userQuantity >= buyerLimit) {
          if (unSoldEditions === buyerLimit) {
            showToastAndSetValue(unSoldEditions);
          } else if (unSoldEditions < buyerLimit) {
            showToastAndSetValue(unSoldEditions);
          } else {
            showToastAndSetValue(buyerLimit);
          }
        }
      } else {
        formik?.setFieldValue("quantity", "");
      }
    }
  };

  return (
    <>
      <div className={style.rafflesForm}>
        <form onSubmit={formik.handleSubmit}>
          {dfaDetail?.market_type === MARKET_TYPE?.PRIMARY && (
            <div className={style.timerSet}>
              <label>Ends in: </label>
              <h5>
                <Timer
                  startingTimestamp={dfaDetail?.end_time}
                  getDfaDetailByIdHandler={getDfaDetailByIdHandler}
                />
              </h5>
            </div>
          )}
          <div className={style.innerForm}>
            <div className={style.innerFormLeftside}>
              <label>Entries :</label>
              <h5>{getNumberWithComma(dfaDetail.no_of_entries)}</h5>
            </div>
            <div className={style.innerFormRightside}>
              {Number(dfaDetail?.no_of_entries) -
                Number(dfaDetail?.sold_no_of_entries) !==
              0 ? (
                <>
                  <h4 className={style.priceValue}>
                    Price:{" "}
                    <span>
                      {" "}
                      ₹ {getNumberWithComma(dfaDetail.price / DECIMAL)} each
                    </span>
                  </h4>

                  <div className={style.addqantity}>
                    <FormikControls
                      variant={`checkField`}
                      className="text-center"
                      label="Add Quantity"
                      control="input"
                      type="text"
                      name="quantity"
                      id="number"
                      formik={formik}
                      onChange={(e) => {
                        onChangeHandler(e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.quantity}
                      leftIcon={<SubtractionIcon />}
                      leftIconClick={subHanlder}
                      rightIcon={<PlusIcon />}
                      rightIconClick={addHanlder}
                    />
                    <h4 className={style.totalAmount}>
                      ₹{" "}
                      {(dfaDetail?.price / DECIMAL) *
                        Number(formik?.values?.quantity)}
                    </h4>
                  </div>

                  <CommonBtn
                    title="Buy now"
                    role="btn"
                    className={`${style.btnSize}`}
                  />
                </>
              ) : (
                <>
                  <h4 className={style.priceValue}>Sold Out</h4>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RafflesForm;
