import React from "react";
import NiftyArtxBanner from "./NiftyArtxBanner/NiftyArtxBanner";
import styles from "./NiftyArtxPage.module.scss";
const NiftyArtxPage = () => {
  return (
    <>
      <NiftyArtxBanner />
      <section className={styles.HowItWorks}>
        {/* <Container>
                    <h2 className="text-center big">NFTs & Blockchain</h2>
                    <h4 className='text-center'>How It Works</h4>
                    <p className='text-center'>TLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <img src={how} alt="" />
                </Container> */}
      </section>
      {/* <NiftyArtxArticles />
            <section className={styles.BenefitsSec}>
                <Container>
                    <Row className='align-items-center'>
                        <Col xxl={4} md={6}>
                            <h2 className='big text-md-start text-center'>
                                Benefits <br className='d-md-block d-none' />
                                Of <br className='d-md-block d-none' />
                                <img src={logo} alt="/" />
                            </h2>
                            <div className={`${styles.BenefitsSecContent} text-md-start text-center`}>
                                <p>Create and sell your digital creations as NFTs to our vibrant community of 100k+ collectors</p>
                                <p>Our rapidly growing community of thousands of digital creators use MakersPlace to protect and sell their digital creations to a global audience. MakersPlace uses NFT technology to empower visual artists, photographers, musicians, and all manner of creators to monetize their work and connect with a devoted community of collectors.</p>
                            </div>
                        </Col>
                        <Col className='mx-auto' xxl={4} md={6}>

                            <CommonSlider
                                dots={false}
                                autoplay={true}
                                slidesToShow={1}
                                arrows={true}
                                slidesToScroll={1}
                                infinite={true}
                                className={styles.imagesSlider}
                            >
                                <div className={`${styles.BenefitsSecImg}`}>
                                    <img src={benefitsimg} alt="" />
                                </div>
                                <div className={`${styles.BenefitsSecImg}`}>
                                    <img src={benefitsimg} alt="" />
                                </div>
                                <div className={`${styles.BenefitsSecImg}`}>
                                    <img src={benefitsimg} alt="" />
                                </div>
                                <div className={`${styles.BenefitsSecImg}`}>
                                    <img src={benefitsimg} alt="" />
                                </div>
                            </CommonSlider>
                        </Col>
                    </Row>
                </Container>
            </section> */}
    </>
  );
};

export default NiftyArtxPage;
