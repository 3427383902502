import React from "react";
import {
  HeartIcon,
  HeartIconRed,
} from "../../../../../Assets/svgIcons/SVGicon";
import ShareBtn from "../../../../Common/Ui/ShareBtn/ShareBtn";
import style from "./UserInfo.module.scss";
import { useSelector } from "react-redux";
import Slider from "react-slick/lib/slider";
import { sliceChar } from "../../../../../Helper/common";

const DfaCreatorInfo = ({
  userTitle,
  statusInfo,
  artType,
  artLike,
  artistIcon,
  artistLabel,
  artistTitle,
  seriesLabel,
  seriesTitle,
  followUnfollowArtHandler,
  isSignleArtistArt,
  artimgprofile,
  navigateUser,
  dfaDetail,
}) => {
  const isLoading = useSelector((state) => state?.loading?.marketplaceLoading);
  const settings = {
    dots: false,
    infinite: true,
    arrow: true,
    className: "center",
    centerMode: true,
    centerPadding: "0",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className={style.userInfo}>
        <div className={style.userTophead}>
          <h3 title={userTitle}>{sliceChar(userTitle, 115)}</h3>
          <div className={style.rightInfo}>
            <h5>{statusInfo}</h5>
            <p>{artType}</p>
          </div>
        </div>
        <div className={style.artDetails}>
          <div className={style.artistInfo}>
            {isSignleArtistArt ? (
              <div
                className={`${style.singlePorfile} cursor-pointer`}
                onClick={navigateUser}
              >
                <div className={style.imgSec}>
                  <img src={artistIcon} alt="artist-icon" />
                </div>
                <div className={style.textSec}>
                  <label>{artistLabel}</label>
                  <h6 title={dfaDetail?.username}>{artistTitle}</h6>
                </div>
              </div>
            ) : (
              <div className={style.multiPorfile}>
                <label>{artistLabel}</label>
                <div className={style.textSec}>
                  <Slider {...settings}>
                    {artimgprofile.map((data, index) => (
                      <div className={style.imgSlide} key={index}>
                        <img src={data.profileImg} alt="sliderImg" />
                      </div>
                    ))}
                  </Slider>
                  <h6>{artistTitle}</h6>
                </div>
              </div>
            )}
          </div>
          <div className={style.seriesInfo}>
            <label>{seriesLabel}</label>
            <h6>{seriesTitle}</h6>
          </div>
          <div className={style.shareInfo}>
            <ul className={style.shareInfo_list}>
              <li>
                <button
                  type="button"
                  className={style.btnlikeshare}
                  onClick={followUnfollowArtHandler}
                  disabled={isLoading}
                >
                  {artLike ? <HeartIconRed /> : <HeartIcon />}
                </button>
              </li>
              <li>
                <ShareBtn className={style.sharebtn} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DfaCreatorInfo;
