import { fetch } from "../Helper/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;

const newsList = (params, token) => {
  return fetch(
    "get",
    `${API_HOST}/users/v1/user/get_news`,
    {},
    { token },
    params
  );
};

export const newsService = {
    newsList,
};
