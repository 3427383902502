import React from "react";
import { useNavigate } from "react-router-dom";
import CommonBtn from "../CommonBtn/CommonBtn";
import style from "./CommonCardsecond.module.scss";
import profileSquare from "../../../../Assets/images/profileSquare.svg";
import { useSelector } from "react-redux";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";
import { sliceChar } from "../../../../Helper/common";

const CommonCardsecond = ({
  className,
  bordergrayBtntitle,
  classtextfields,
  followUser,
  item,
  type,
}) => {
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state?.users?.profileData);
  const followUnfollowUser = (item) => {
    if (type == "self") {
      followUser("remove", item);
    } else {
      if (item.isFollowed === 0) {
        followUser("follow", item);
      } else {
        followUser("unfollow", item);
      }
    }
  };
  const goToUserProfile = (item) => {
    if (item.user?.username) {
      navigate(`/profile/${item.user?.username}`);
    }
  };

  return (
    <>
      <div className={`${style.commonCardSecond} ${className || ""}`}>
        <div className={style.commonCardSecond_innersec}>
          <img
            src={
              item?.user?.image_path
                ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.user?.image_path}`
                : profileSquare
            }
            alt="imagArt"
            onClick={() => goToUserProfile(item)}
          />
          <div className={`${style.card_textfields} ${classtextfields || ""}`}>
            <h6 onClick={() => goToUserProfile(item)} title={item?.user?.name}>
              {sliceChar(item?.user.name, 40)}
            </h6>
            <p
              onClick={() => goToUserProfile(item)}
              title={item?.user?.username}
            >
              @{sliceChar(item?.user.username, 17)}
            </p>
            <div className={`btn_border ${style.btnsselandview}`}>
              {bordergrayBtntitle && userProfile.id != item.user.id && (
                <CommonBtn
                  role="btn"
                  className={`bordred-black ${style.btneditsize}`}
                  title={bordergrayBtntitle}
                  onClick={() => followUnfollowUser(item)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonCardsecond;
