import { ORIENTATION, tabName } from "../Constant/userConstant";

export const initialStateLoadingSlice = {
  loading: false,
  profileLoading: false,
  disable: false,
  marketplaceLoading: false,
  dfaActivityLoading: false,
};

export const initialStateUser = {
  loginCase: "",
  latestPrice: {},
  profileData: {},
  profileDataOther: {},
  signUpData: {
    email: "",
    passowrd: "",
  },
  nextRoute: "",
  isSessionExpired: false,
  key: "editprofile",
  userDetail: {},
  activeTab: "offers",
  activeSubTab: tabName.OFFER_RECIEVED,
  orientation: ORIENTATION.LANDSCAPE,
  userTime: "",
  notifyFromTop: false,
  pageMarketplace: 1,
  statusForMarketplace: false,
  pageUserProfile: 1,
  tabTypeUserProfile: "ART_OWN",
  pageOtherUserProfile: 1,
  tabTypeOtherUserProfile: "ART_OWN",
  pagePurchase: 1,
  tabTypeTransaction: "buy",
  pageLinkedDevice: 1,
  pageRecentActivity: 1,
  latLong: { lat: 0, long: 0 },
  isAnoToggleOnCheckout: false,
};
export const initialStateWallet = {};
export const initialStateHistory = {};
export const initialStatePersist = {
  isUserLoggedin: false,
  ip: "",
  location: "",
  accessToken: "",
  curatedDropDigitalArt: {},
  userType: "",
  linkActiveTab: "linkdevices",
  userId: {},
  redirectionStripSuccess: "wallet",
};
export const initialStateMarketPlace = {
  marketplaceNft: [],
  page: 1,
  totalDfa: 0,
  loginCase: "",
  marketplaceFilter: {
    artist: [],
    series: [],
    price: [],
    rarity: [],
    tags: [],
    artType: [],
    status: [1],
    optionFilter: { title: "Newest", value: "date_desc" },
    priceInputValue: {
      minVal: "",
      maxVal: "",
    },
  },
};
export const initialStateOffer = {};
export const initialStateAnonomaus = {
  anonymouseFormData: {},
};
