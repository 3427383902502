import React, { useState, useEffect, useCallback } from "react";
import styles from "../SignUp.module.scss";
import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import { Link, useNavigate } from "react-router-dom";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  openPopUp,
  saveSignUpCredential,
  sendOtp,
  verifySignupAction,
} from "../../../../Features/user/userSlice";
import { parsePhoneNumber } from "react-phone-number-input";
import { obfuscateEmail } from "../../../../Helper/common";

const SignupConfirm = ({
  setSignupStep,
  signupData,
  setSelelectedInputToEdit,
}) => {
  let navigate = useNavigate();
  const [phoneCount, setPhoneCount] = useState(60);
  const [EmailCount, setEmailCount] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (EmailCount > 0) {
        setEmailCount((EmailCount) => EmailCount - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [EmailCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (phoneCount > 0) {
        setPhoneCount((phoneCount) => phoneCount - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [phoneCount]);

  const resendOtpPhone = useCallback(() => {
    // you can start existing timer with an arbitrary value
    // if new value is not passed timer will start with initial value
    let phoneNumber = signupData?.phoneNumber;
    let data = {
      to: phoneNumber,
      // countryCode: countryCode,
      service: "ONBOARD",
      type: "MOBILE",
    };

    dispatch(sendOtp(data)).then((response) => {
      if (response?.status === 200) {
        setPhoneCount(60);
      }
    });
  }, []);

  const resendOtpEmail = useCallback(() => {
    // you can start existing timer with an arbitrary value
    // if new value is not passed timer will start with initial value

    let data = {
      to: signupData.email,
      service: "ONBOARD",
      type: "EMAIL",
    };
    dispatch(sendOtp(data)).then((response) => {
      if (response?.status === 200) {
        setEmailCount(60);
      }
    });
  }, []);

  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    emailOtp: Yup.string()
      .required(
        inputFieldValidation.commonMessage.required("Email verification code")
      )
      .test("lengthCheck", "*Incorrect Code", (value) => {
        let valueString = value?.toString();
        if (valueString && valueString?.length < 6) {
          return false;
        }
        return true;
      }),

    mobileOtp: Yup.string()
      .required(
        inputFieldValidation.commonMessage.required("Mobile verification code")
      )
      .test("lengthCheck", "*Incorrect Code", (value) => {
        let valueString = value?.toString();
        if (valueString && valueString?.length < 6) {
          return false;
        }
        return true;
      }),
  });
  const formik = useFormik({
    initialValues: {
      emailOtp: "",
      mobileOtp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let finalData = {
        emailOtp: values.emailOtp,
        mobileOtp: values.mobileOtp,
        firstName: signupData.firstName,
        lastName: signupData.lastName,
        username: signupData.username,
        email: signupData.email,
        password: signupData.password,
        confirmPassword: signupData.confirmPassword,
        captcha: signupData.captcha,
        phoneNumber: parsePhoneNumber(signupData?.phoneNumber)?.nationalNumber,
        countryCode:
          "+" + parsePhoneNumber(signupData?.phoneNumber)?.countryCallingCode,
      };

      dispatch(verifySignupAction(finalData)).then((response) => {
        if (response?.status === 200) {
          // save email and password so that it will appear on login form
          let savedData = {
            email: signupData.email,
            password: signupData.password,
          };
          dispatch(saveSignUpCredential(savedData));
          navigate("/login");
        }
      });
    },
  });
  return (
    <>
      <div className={styles.confirmForm}>
        <form onSubmit={formik.handleSubmit}>
          <Row className="align-items-center">
            <Col lg={12} md={12} xs={12}>
              <div className={styles.verifyCodesend}>
                <FormikControls
                  variant={styles.spaceField}
                  className={styles.fieldborder} /*  */
                  label={`Enter verification code sent on ${obfuscateEmail(
                    signupData?.email
                  )}`}
                  control="input"
                  // type="number"
                  name="emailOtp"
                  maxLength={6}
                  placeholder="Email Verification Code"
                  onChange={(e) => {
                    //regex for only digit
                    if (/^\d*$/.test(e.target.value)) {
                      formik.setFieldValue("emailOtp", e.target.value);
                    }
                  }}
                  value={formik.values.emailOtp}
                  formik={formik}
                  onBlur={formik.handleBlur}
                />
                <div className={styles.BtnsSend}>
                  <div className={styles.btnSec}>
                    <button
                      type="button"
                      className={styles.btnSize}
                      onClick={() => {
                        formik.setFieldValue("emailOtp", "");
                        resendOtpEmail();
                      }}
                      disabled={EmailCount > 0 ? true : false}
                    >
                      Resend Code
                    </button>

                    <span>|</span>

                    <button
                      type="button"
                      className={styles.btnSize}
                      onClick={() => {
                        setSelelectedInputToEdit("email");

                        setSignupStep(1);
                      }}
                    >
                      Change email address
                    </button>
                  </div>
                  <p>{EmailCount > 0 ? EmailCount + " sec" : ""} </p>
                </div>
              </div>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <div className={styles.verifyCodesend}>
                <FormikControls
                  variant={styles.spaceField}
                  className={styles.fieldborder}
                  label={`Enter verification code sent on ${
                    signupData.phoneNumber.substring(0, 5) +
                    "******" +
                    signupData.phoneNumber.substring(11)
                  }`}
                  control="input"
                  // type="number"
                  maxLength={6}
                  name="mobileOtp"
                  placeholder="Mobile Number Verification Code"
                  onChange={(e) => {
                    //regex for only digit
                    if (/^\d*$/.test(e.target.value)) {
                      formik.setFieldValue("mobileOtp", e.target.value);
                    }
                  }}
                  value={formik.values.mobileOtp}
                  formik={formik}
                  onBlur={formik.handleBlur}
                />

                <div className={styles.BtnsSend}>
                  <div className={styles.btnSec}>
                    <button
                      type="button"
                      className={styles.btnSize}
                      disabled={phoneCount > 0 ? true : false}
                      onClick={() => {
                        formik.setFieldValue("mobileOtp", "");
                        resendOtpPhone();
                      }}
                    >
                      Resend Code
                    </button>

                    <span>|</span>

                    <button
                      type="button"
                      className={styles.btnSize}
                      onClick={() => {
                        setSelelectedInputToEdit("phone");

                        setSignupStep(1);
                      }}
                    >
                      Change mobile number
                    </button>
                  </div>
                  <p>{phoneCount > 0 ? phoneCount + " sec" : ""}</p>
                </div>
              </div>
            </Col>
            <div className={styles.bottomLogin}>
              <div className={styles.leftSec}>
                <p className={`pt-0 border-0 ${styles.LoginFormSignUp}`}>
                  Already have an account? <Link to="/login">Log in.</Link>
                </p>
              </div>
              <CommonBtn
                className={`${styles.signUpBtn}`}
                role="btn"
                title="Sign Up"
                disabled={!formik.isValid}
                type="submit"
              />
            </div>
          </Row>
        </form>
      </div>
    </>
  );
};

export default SignupConfirm;
