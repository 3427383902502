import { createSlice } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "../loading/loadingSlice";
import { store } from "../../App/store";
import { toasts } from "../../Component/Common/Toast/Toast";
import { anonymousUserService } from "../../Services/anonymousUserService";
import { initialStateAnonomaus } from "../InitialState";
import { saveAccessToken } from "../persist/persistSlice";

let token;

const initialState = initialStateAnonomaus;
export const anonymousUserSlice = createSlice({
  name: "Anonymous",
  initialState,
  reducers: {
    saveAnonymousDetail: (state, action) => {
      state.anonymouseFormData = action.payload;
    },
  },
});
export const { saveAnonymousDetail } = anonymousUserSlice.actions;

export const AddAnonymousUserFunction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await anonymousUserService.addAnonymous(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const EditAnonymousUserFunction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await anonymousUserService.editAnonymous(data, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getAnonymousUserFunction = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await anonymousUserService.getAnonymousData(token);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const EnableDisableAnonymousUserFunction = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await anonymousUserService.enableDisableAnonymousUser(
          data,
          token
        );
        await dispatch(saveAccessToken(response?.data?.data?.accessToken));
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getProfileOnDashboard = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await anonymousUserService.getProfile(params, token);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export default anonymousUserSlice.reducer;
