import React, { Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Loader from "./Component/Common/Loader/Loader";
import LoaderSuspense from "./Component/Common/LoaderSuspense/LoaderSuspense";
import Fallback from "./Component/Common/ErrorBoundary/FallBack";
import NoGuard from "./Guard/NoGuard";
import AuthGuard from "./Guard/AuthGuard";
import Home from "./Component/Pages/Home/Home";
import CuratedDropsPage from "./Component/Pages/CuratedDropsPage/CuratedDropsPage";
import RecentActivity from "./Component/Pages/Stats/RecentActivity";
import LeaderBoards from "./Component/Pages/Stats/LeaderBoards";
// import Marketplace from "./Component/Pages/Marketplace/Marketplace";
import ContactUs from "./Component/Pages/ContactUs/ContactUs";
import CreatePage from "./Component/Pages/CreatePage/CreatePage";
import FaqPage from "./Component/Pages/FaqPage/FaqPage";
import NiftyArtxPage from "./Component/Pages/NewArtxPage/NewArtxPage";
import FlauntPage from "./Component/Pages/FlauntPage/FlauntPage";
import NewartxApp from "./Component/Pages/NewartxApp/NewartxApp";
import Forget from "./Component/Public/Forget";
import Reset from "./Component/Public/Reset";
import Drop from "./Component/Pages/Drop/Drop";
import Previousdrops from "./Component/Pages/Previousdrops/Previousdrops";
import Wallets from "./Component/Private/Wallets/Wallets";
import Userdashboard from "./Component/Pages/Userdashboard/Userdashboard";
import Useraccountsetting from "./Component/Pages/Useraccountsetting/Useraccountsetting";
import GuardLess from "./Guard/GuardLess";
import CuratedDropDigitalArt from "./Component/Private/CuratedDropDigitalArt/CuratedDropDigitalArt";
import SignUp from "./Component/Public/SignUp/SignupComps/SignUp";
import ArtDetailSecondaryMarketplace from "./Component/Pages/ArtDetailSecondaryMarketplace/ArtDetailSecondaryMarketplace";
import NotFound404 from "./Component/Common/NotFound404/NotFound404";
import LinkDevices from "./Component/Pages/LinkDevices/LinkDevices";
import TermsOfUse from "./Component/Pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "./Component/Pages/AboutUs/AboutUs";
import { Notification } from "./Component/Pages/Notification/Notification";
import { ErrorBoundary } from "react-error-boundary";
import CuratedCheckout from "./Component/Pages/CuratedCheckout/CuratedCheckout";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import AboutNewArtX from "./Component/Pages/AboutNewArtX/AboutNewArtX";
import { getLongLat } from "./Helper/common";
import ShippingPolicy from "./Component/Pages/ShippingPolicy/ShippingPolicy";
import RefundandReturnsPolicy from "./Component/Pages/RefundandReturnsPolicy/RefundandReturnsPolicy";
import ComingSoon from "./Component/Common/ComingSoon/ComingSoon";
import QrLink from "./Component/Common/QrLink/QrLink";
import NewsAbout from "./Component/Pages/NewsAbout/NewsAbout";
import { RoutesUrls } from "./Constant/RoutesUrls";
import UserProfile from "./Component/Pages/Profile/Profile";
import MaintenancePage from "./Component/Common/MaintenancePage/MaintenancePage";
import NaxCanvasPrivacyPolicy from "./Component/Pages/NaxCanvas/NaxCanvasPrivacyPolicy/NaxCanvasPrivacyPolicy";
import NaxCanvasTermOfUse from "./Component/Pages/NaxCanvas/NaxCanvasTermOfUse/NaxCanvasTermOfUse";
import Marketplace from "./Component/Pages/NewMarketplace/NewMarketplace";
import RedirectionComponent from "./Component/Pages/RedirectionComponent/RedirectionComponent";
import { Helmet } from "react-helmet";
import LandingPage from "./Component/Pages/LandingPage/LandingPage";
import DigitalFineArtDetailPage from "./Component/Pages/DigitalFineArtDetailPage/DigitalFineArtDetailPage";
import Login from "./Component/Public/Login/Login";
import NewCheckout from "./Component/Pages/NewCheckout/NewCheckout";
import HowtoflauntDfa from "./Component/Pages/HowtoflauntDfa/HowtoflauntDfa";

//1. ProjectId
const projectId = "a1800147baec94a8f2f91e1dc85295d3";

const testnet = {
  chainId: 80002,
  name: " Polygon Amoy Testnet",
  currency: "MATIC",
  explorerUrl: " https://amoy.polygonscan.com/",
  rpcUrl: "https://rpc-amoy.polygon.technology/",
};

// 3. Create a metadata object
const metadata = {
  name: "Newartx",
  description: "My Website description",
  url: "http://localhost:3001", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
  enableEIP6963: true,
  enableInjected: false,
  enableCoinbase: true,
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  defaultChain: testnet,
  chains: [testnet],
  allowUnsupportedChain: true,
  featuredWalletIds: [
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const router = createBrowserRouter([
  {
    path: "/naxcanvas/privacy_policy",
    element: <NaxCanvasPrivacyPolicy />,
  },
  {
    path: "/naxcanvas/term_of_use",
    element: <NaxCanvasTermOfUse />,
  },
  {
    path: "/",
    index: true,
    element: <LandingPage />,
  },
  {
    path: "/",
    element: <GuardLess />,
    children: [
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: RoutesUrls.Contact_us,
        element: (
          <ErrorBoundary FallbackComponent={Fallback}>
            <ContactUs />
          </ErrorBoundary>
        ),
      },
      {
        path: RoutesUrls.Create,
        element: <CreatePage />,
      },
      {
        path: RoutesUrls.Curated_drops,
        element: (
          <ErrorBoundary FallbackComponent={Fallback}>
            <CuratedDropsPage />
          </ErrorBoundary>
        ),
      },
      // {
      //   path: RoutesUrls.curatedDrop,
      //   element: <Marketplace />,
      // },
      {
        path: RoutesUrls.Curated_drop_digital_art,
        element: (
          <ErrorBoundary>
            <CuratedDropDigitalArt />
          </ErrorBoundary>
        ),
      },
      {
        path: RoutesUrls.Drops_id,
        element: <Drop />,
      },
      {
        path: `${RoutesUrls.digitalFineArts}/:id`,
        element: <DigitalFineArtDetailPage />,
      },
      {
        path: RoutesUrls.Previous_drop,
        element: <Previousdrops />,
      },
      {
        path: RoutesUrls.temporaryMarketplaceRoute,
        element: <Marketplace />,
      },
      {
        path: RoutesUrls.Marketplace,
        // element: <Marketplace />,
        element: <Marketplace />,
      },
      {
        path: RoutesUrls.Art_detail_secondary_marketplace,
        element: <ArtDetailSecondaryMarketplace />,
      },
      {
        path: "/profile/:username",
        element: <UserProfile />,
      },
      {
        path: RoutesUrls.Stats,
        children: [
          {
            path: RoutesUrls.Recent_activity,
            element: <RecentActivity />,
          },
        ],
      },
      {
        path: RoutesUrls.Terms_of_use,
        element: <TermsOfUse />,
      },
      {
        path: RoutesUrls.Shiping_policy,
        element: <ShippingPolicy />,
      },
      {
        path: RoutesUrls.Refund_return_policy,
        element: <RefundandReturnsPolicy />,
      },
      {
        path: RoutesUrls.PrivacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: RoutesUrls.About_us,
        element: <AboutUs />,
      },

      {
        path: RoutesUrls.FlauntPage,
        element: <FlauntPage />,
      },
      {
        path: RoutesUrls.NewArtXApp,
        element: <NewartxApp />,
      },
      // {
      //   path: "/buy-display",
      //   element: <Buydisplay />,
      // },
      {
        path: RoutesUrls.About_newArtX,
        element: <AboutNewArtX />,
      },
      {
        path: RoutesUrls.About_dfa,
        element: <NiftyArtxPage />,
      },
      {
        path: RoutesUrls.Faq,
        element: <FaqPage />,
      },
      {
        path: RoutesUrls.Coming_Soon,
        element: <ComingSoon />,
      },
      {
        path: RoutesUrls.News,
        element: <NewsAbout />,
      },

      {
        path: RoutesUrls.Howflaunt,
        element: <HowtoflauntDfa />,
      },

      {
        path: "/currated-drop/digital-art/:type/:id",
        element: <RedirectionComponent />,
      },
      //New QR Link urls

      {
        path: "/anjaneyulu-gundu/bottles/goli-soda", //1
        element: <QrLink />,
      },

      {
        path: "/sayam-bharath-Yadav/bottles/doodhwala", //2

        element: <QrLink />,
      },

      {
        path: "/bhaskar-raobotcha/architectural-series/tree-of-life-1", //3
        element: <QrLink />,
      },
      {
        path: "/chippa-sudhakar/migration/migration-1", //4
        element: <QrLink />,
      },
      {
        path: "/ganapati-hegde/gods-in-nature/ganesha-1", //5
        element: <QrLink />,
      },
      {
        path: "/gigi-scaria/phantom-limb/phantom-limb", //6
        element: <QrLink />,
      },
      {
        path: "/jagannath-panda/echoes-of-the-unfathomed-worlds/the-cosmic-vortex", //7
        element: <QrLink />,
      },
      {
        path: "/kandi-narsimlu/telangana-folk/telangana-folk-village-life", //8
        element: <QrLink />,
      },
      {
        path: "/kandi-narsimlu/telangana-folk/telangana-folk-waiting-for-bus", //9
        element: <QrLink />,
      },
      {
        path: "/kandi-narsimlu/telangana-folk/telangana-folk-bus-ride", //10
        element: <QrLink />,
      },
      {
        path: "/k-laxma-goud/untitled/untitled-1", //11
        element: <QrLink />,
      },
      {
        path: "/laxman-aelay/poolamma/poolamma-1", //12
        element: <QrLink />,
      },
      {
        path: "/madhuri-bahaduri/seascapes/transcedent-waves", //13
        element: <QrLink />,
      },

      {
        path: "/masuram-ravikanth/reviving-the-yesterday/reviving-the-yesterday-1", //14
        element: <QrLink />,
      },
      {
        path: "/nagesh-goud/cow-series/kamadhenu-1", //15
        element: <QrLink />,
      },
      {
        path: "/priyanka-aelay/revisiting-the-tale-of-balanagamma/bhoodevis-magical-mango-tree", //16
        element: <QrLink />,
      },
      {
        path: "/priyanka-aelay/revisiting-the-tale-of-balanagamma/the-echo-of-this-forest-is-unbroken", //17
        element: <QrLink />,
      },
      {
        path: "/priyanka-aelay/revisiting-the-tale-of-balanagamma/mantrika-much-bigger-than-cage", //18
        element: <QrLink />,
      },
      {
        path: "/priyanka-aelay/revisiting-the-tale-of-balanagamma/no-hoofprint-in-the-absent-grass", //19
        element: <QrLink />,
      },
      {
        path: "/priyanka-aelay/revisiting-the-tale-of-balanagamma/the-morning-on-the-rainy-wedding-day", //20
        element: <QrLink />,
      },
      {
        path: "/ramesh-gorjala/govardhana-krishna", //21
        element: <QrLink />,
      },
      {
        path: "/shampa-sircar-das/prakriti/prakriti-1", //22
        element: <QrLink />,
      },
      {
        path: "/shovin-bhattacharjee/galaxy-on-earth/galactic-harmony", //23
        element: <QrLink />,
      },
      ,
      {
        path: "/siddharth-shindhade/marriage-series/wedding-procession-1", //24
        element: <QrLink />,
      },
      {
        path: "/suraj-kumar-kashi/metropolitan-lifestyle/touching-the-clouds", //25
        element: <QrLink />,
      },
      {
        path: "/tapasya-gupta/untitled/why-should-boys-have-all-the-fun", //26
        element: <QrLink />,
      },
      {
        path: "/valay-bhagwan-shende/untitled/untitled", //27
        element: <QrLink />,
      },
      {
        path: "/viraj-khanna/portraits/self-talk", //28
        element: <QrLink />,
      },
      {
        path: "/k-laxma-goud /untitled/untitled-1", //51
        element: <QrLink />,
      },
    ],
  },
  {
    path: "/",
    element: <NoGuard />,
    children: [
      {
        path: RoutesUrls.Forgot,
        element: <Forget />,
      },
      {
        path: RoutesUrls.Reset_password,
        element: <Reset />,
      },
      {
        path: RoutesUrls.Login,
        element: <Login />,
      },
      {
        path: RoutesUrls.Signup,
        element: <SignUp />,
      },
      {
        path: RoutesUrls.Curated_Drop_page,
        element: <CuratedDropsPage />,
      },
      {
        path: RoutesUrls.Contact_us,
        element: <ContactUs />,
      },
      // {
      //   path: RoutesUrls.Nax,
      //   element: <NaxPage />,
      // },
    ],
  },
  {
    path: "/",
    element: <AuthGuard />,
    children: [
      {
        path: RoutesUrls.User_account_setting,
        element: <Useraccountsetting />,
      },
      {
        path: RoutesUrls.Wallet,
        element: <Wallets />,
      },
      {
        path: RoutesUrls.User_dashboard,
        element: <Userdashboard />,
      },
      {
        path: RoutesUrls.Curated_checkout,
        element: <CuratedCheckout />,
      },
      {
        path: RoutesUrls.Checkout,
        element: <NewCheckout />,
      },
      {
        path: RoutesUrls.Link_devices,
        element: <LinkDevices />,
      },
      {
        path: RoutesUrls.Stats,
        children: [
          {
            path: RoutesUrls.LeaderBoard,
            element: <LeaderBoards />,
          },
        ],
      },
      {
        path: RoutesUrls.Notification,
        element: <Notification />,
      },
    ],
  },
  // {
  //   path: "/maintenance",
  //   element: <MaintenancePage />,
  // },
  {
    path: "*",
    element: <NotFound404 />,
  },
]);

function App() {
  const loading = useSelector((state) => state?.loading?.loading);
  const profileLoading = useSelector((state) => state?.loading?.profileLoading);
  const marketplaceLoading = useSelector(
    (state) => state?.loading?.marketplaceLoading
  );
  //This is used to push an event to the dataLayer object, typically used in Google Tag Manager (GTM) implementations.
  // useEffect(() => {
  //   // Push event to dataLayer
  //   window.dataLayer.push({
  //     event: "pageview", // Event name
  //     pagePath: window.location.pathname, // Custom data
  //     // Add more custom data as needed
  //   });
  // }, []);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    try {
      await getLongLat();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Suspense fallback={<LoaderSuspense />}>
      <>
        <Helmet>
          <title>NewArtX | Buy Digital Fine Art</title>
          <meta
            name="description"
            content="NewArtX - Unleash your creativity with NewArtX - the platform for digital fine art in India."
          />
          {/* <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Digital Fine Art online Platform | digital painting | NewArtX"
          />
          <meta property="og:url" content="https://www.newartx.com/" />
          <meta
            property="og:image"
            content="https://www.newartx.com/static/media/Nax%20Canvas.1496f78d0023808b36e5.png"
          />
          <meta
            property="og:description"
            content="NewArtX - Unleash your creativity with NewArtX - the platform for digital fine art in India."
          />
          <link rel="canonical" href="https://www.newartx.com/" /> */}
        </Helmet>
        {loading ? <Loader /> : null}
        {profileLoading ? <Loader /> : null}
        {marketplaceLoading ? <Loader /> : null}
        <RouterProvider router={router} fallbackElement={<Loader />} />
      </>
    </Suspense>
  );
}

export default App;
