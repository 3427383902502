import axios from "axios";
import { toasts } from "../Component/Common/Toast/Toast";
import { store } from "../App/store";
import { isUserLogin, saveAccessToken } from "../Features/persist/persistSlice";
import { logoutUser, setSessionExpired } from "../Features/user/userSlice";

export const fetch = (method, url, data, headers = {}, params = {}) => {
  let response;

  function getCurrentTime() {
    const now = new Date();

    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  if (
    method === "GET" ||
    method === "get" ||
    method === "delete" ||
    method === "DELETE"
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        response = await axios({
          method: method,
          url: url,
          headers: {
            "api-access-token": headers.token,
            latitude: store.getState().users?.latLong?.lat,
            longitude: store.getState().users?.latLong?.long,
            user_utc_timestamp: new Date().getTime(),
            hourFormat: getCurrentTime(),
          },
          params: params,
        });
        resolve(response);
      } catch (err) {
        if (err.response.status === 401) {
          store.dispatch(isUserLogin(false));
          store.dispatch(logoutUser());
          store.dispatch(saveAccessToken(""));
          if (store.getState().users.isSessionExpired == false) {
            toasts.error("Session expired");
            store.dispatch(setSessionExpired(true));
          }
        } else {
          reject(err);
        }
      }
    });
  } else if (
    method === "POST" ||
    method === "post" ||
    method === "PUT" ||
    method === "put"
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        response = await axios({
          method: method,
          url: url,
          headers: {
            "api-access-token": headers.token,
            latitude: 0,
            longitude: 0,
            user_utc_timestamp: new Date().getTime(),
            hourFormat: getCurrentTime(),
          },
          data: data,
        });
        resolve(response);
      } catch (err) {
        if (err.response.status === 401) {
          store.dispatch(isUserLogin(false));
          store.dispatch(saveAccessToken(""));
          store.dispatch(logoutUser());
          if (store.getState().users.isSessionExpired == false) {
            toasts.error("Session expired");
            store.dispatch(setSessionExpired(true));
          }
        } else {
          reject(err);
        }
      }
    });
  }
};
