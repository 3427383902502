import React, { useEffect, useState } from "react";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import { useDispatch } from "react-redux";
import { removeConnectedDevice } from "../../../../Features/linkedDevice/linkedDeviceSlice";

const Removedevicemodal = ({ show, handleClose, dataSave, ConnectedDeviceList}) => {
  const dispatch = useDispatch();
  const [id, setId] = useState()


    useEffect(() => {
      // You might want to add a conditional check to avoid unnecessary API calls
      if (id) {
        RemoveDeviceList(id); // Pass the id to the function
      }
    }, [id]);

  const RemoveDeviceList = async () => {
    let params = {
      device_id : id,
    };
    try {
      const response = await dispatch(removeConnectedDevice(params));
    if (response?.status == 200) {
      handleClose()
      ConnectedDeviceList()
    }
    } catch (err) {}
  };

  return (
    <>
      <CommonModal
        centered
        className="remove_devicemodal"
        backdropclass="fade_clrbg"
        modalTitle="Are you sure you want to remove device?"
        show={show}
        onHide={handleClose}
      >
        <div className="main_content">
          <div className="btns_section">
            <CommonBtn
              title="Yes"
              role="btn"
              className="btn-yellow-black btn_sizeing"
              onClick={() => setId(dataSave.device_id)}
            />
            <CommonBtn
              title="No"
              role="btn"
              className="btn-yellow-black btn_sizeing"
              onClick={() => handleClose()}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default Removedevicemodal;
