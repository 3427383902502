import React from "react";
import { Container } from "react-bootstrap";
import { ArrowRight } from "../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonSlider from "../../../Common/Ui/CommonSlider/CommonSlider";
import howitwork1 from "../../../../Assets/images/howitwork1.png";
import howitwork2 from "../../../../Assets/images/howitwork2.png";
import howitwork3 from "../../../../Assets/images/howitwork3.png";
import howitwork4 from "../../../../Assets/images/howitwork4.png";
import howitwork5 from "../../../../Assets/images/howitwork5.png";
import howitarrow1 from "../../../../Assets/icons/howitarrow1.svg";
import howitarrow2 from "../../../../Assets/icons/howitarrow2.svg";
import howitarrow3 from "../../../../Assets/icons/howitarrow3.svg";
import style from "./HowitsWorks.module.scss";
import { useNavigate } from "react-router-dom";
import { RoutesUrls } from "../../../../Constant/RoutesUrls";

const HowitsWorks = () => {
  const navigate = useNavigate();
  const rsp = [
    {
      breakpoint: 1399,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    // {
    //     breakpoint: 991,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //         arrows: true,
    //     },
    // },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ];
  const cardTeams = [
    { worksIcon: howitwork1, title: "Create Account", subtitle: "to start your journey", arroeIcon: howitarrow1, },
    { worksIcon: howitwork2, title: "Explore", subtitle: "a curated selection of exquisite Digital Fine Art", arroeIcon: howitarrow2, },
    {
      worksIcon: howitwork3, title: "Buy and Sell", subtitle: "Digital Fine Art with a few clicks", arroeIcon: howitarrow3,
      testClass: style.maxSpace, imgClass: style.imgSpace,
    },
    { worksIcon: howitwork4, title: "Display", subtitle: "your Digital Fine Art", },
    { worksIcon: howitwork5, learnBtn: true, imgClass: style.imgNexIcon, },
  ];
  return (
    <>
      <section className={style.howitsWorks}>
        <Container>
          <div className={style.howitsWorks_lisitng}>
            <h2>How it Works?</h2>
            <CommonSlider
              className={style.sliderWork}
              speen={3000}
              dots={false}
              autoplay={false}
              slidesToShow={5}
              slidesToScroll={1}
              responsive={rsp}
            >
              {cardTeams.map((data, index) => (
                <div className={style.worksCard} key={index}>
                  <div className={style.worksImg}>
                    <img
                      src={data.worksIcon}
                      alt="img-works"
                      className={`${style.imgSec} ${data.imgClass}`}
                    />
                    {data.title && <h4>{data.title}</h4>}
                  </div>
                  <div className={`${style.worksText} ${data.testClass}`}>
                    {data.subtitle && <h6>{data.subtitle}</h6>}

                    {data.learnBtn && (
                      <CommonBtn
                        type="button"
                        role="btn"
                        title="Learn More"
                        icon={<ArrowRight />}
                        onClick={() => navigate(RoutesUrls.Faq)}
                        className={` ${style.btn_create}`}
                      />
                    )}
                  </div>
                  {data.arroeIcon && (
                    <span className={style.arrowSec}>
                      <img src={data.arroeIcon} alt="img-arrow" />
                    </span>
                  )}
                </div>
              ))}
            </CommonSlider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HowitsWorks;
