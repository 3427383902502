import React from "react";
import { Pagination } from "react-bootstrap";
import { ArrowRight } from "../../../../Assets/svgIcons/SVGicon";
import "./CustomPagination.scss";
import {
  setPageLinkedDevice,
  setPageOtherUserProfile,
  setPagePurchase,
  setPageRecentActivity,
  setPageUserProfile,
} from "../../../../Features/user/userSlice";

const CustomPagination = (props) => {
  const {
    className,
    activePage,
    totalPage,
    handleScroll,
    setSearchParams,
    setActivePage,
    dispatch,
    componentType,
    componentTypes,
  } = props;
  const maxVisiblePages = 5; // Maximum number of visible pages

  const handlePageChange = (page) => {
    if (setSearchParams) {
      setSearchParams({ page: page });
    } else if (dispatch) {
      componentType === "Profile" && dispatch(setPageUserProfile(page));
      componentTypes === "OtherUserProfile" && dispatch(setPageOtherUserProfile(page));
      componentType === "Transaction" &&  dispatch(setPagePurchase(page));
      componentType === "LinkDevice" && dispatch(setPageLinkedDevice(page));
      componentType === "RecentActivity" && dispatch(setPageRecentActivity(page));
    } else {
      setActivePage(page);
    }
    if (handleScroll) {
      handleScroll();
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    let startPage = Math.max(1, activePage - Math.floor(maxVisiblePages / 2));
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPage) {
      endPage = totalPage;
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      // Add a button to navigate to the first page
      paginationItems.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
    }

    for (let page = startPage; page <= endPage; page++) {
      paginationItems.push(
        <Pagination.Item
          key={page}
          active={page === activePage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    if (endPage < totalPage) {
      // Add a button to navigate to the last page
      paginationItems.push(
        <Pagination.Last
          key="last"
          onClick={() => handlePageChange(totalPage)}
        />
      );
    }

    return paginationItems;
  };

  return (
    <div className={`custom_pagination ${className}`}>
      <Pagination>
        <Pagination.Prev
          disabled={activePage === 1}
          onClick={() => handlePageChange(activePage - 1)}
        >
          <ArrowRight />
        </Pagination.Prev>
        {renderPaginationItems()}
        <Pagination.Next
          disabled={activePage === totalPage}
          onClick={() => handlePageChange(activePage + 1)}
        >
          <ArrowRight />
        </Pagination.Next>
      </Pagination>
    </div>
  );
};

export default CustomPagination;
