import React from "react";
import flauntVideo from "../../../../../Assets/videos/flauntVideo.mp4";
import style from "./TopSection.module.scss";

const TopSection = () => {
  return (
    <>
      <div className={style.topsection}>
        <div className={style.textSec}>
          <h1>Ways to Flaunt Your Digital Fine Art</h1>
          <h5>
            Flaunting your digital fine art has never been this seamless and
            elegant. With NewArtX, you can curate, showcase, and immerse
            yourself in the world of digital art effortlessly. Elevate your
            artistic expression and share your passion with the world through
            the NewArtX app and the stunning NAX Canvas.
          </h5>
        </div>
        <div className={style.videoSec}>
          <video
            autoPlay
            muted
            loop
            width="100%"
            height="auto" // ref={videoRef} // controls
          >
            <source
              src={`${process.env.REACT_APP_IMG_BASE_URL}banner/flauntVideo.mp4`}
            />
          </video>
        </div>
      </div>
    </>
  );
};

export default TopSection;
