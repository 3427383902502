import React from "react";
import { Container } from "react-bootstrap";
import team1 from "../../../../Assets/images/team1.png";
import team2 from "../../../../Assets/images/team2.png";
import team3 from "../../../../Assets/images/team3.png";
import { BaseLinkedinIcon } from "../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonSlider from "../../../Common/Ui/CommonSlider/CommonSlider";
import style from "./OurTeam.module.scss";

const OurTeam = () => {
  const rsp = [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
      },
    },
  ];
  const cardTeams = [
    {
      teamIcon: team1,
      title: "Suresh Pareek",
      subtitle: "Chairman",
      subInfo: (
        <>
          {" "}
          A seasoned entrepreneur and founder of Ideal Cures Pvt Ltd, Suresh
          lends his four decades of expertise to shape Elephant Canvas into a
          digital art powerhouse. He is a CA by education with an Honorary
          Doctorate. He is also Chairperson of Sukvi Ventures Family Office with
          investments in numerous start-ups.
        </>
      ),
      link: "https://www.linkedin.com/in/sureshpareek/",
    },
    {
      teamIcon: team2,
      title: "Saryu Pareek Gupta",
      subtitle: "Partner",
      subInfo: (
        <>
          Saryu transitioned from a background in pharmacy to becoming an Art
          Connoisseur, brings a wealth of experience in Intellectual Property
          Rights (IPR) and holds an MBA from the Indian School of Business. She
          is responsible for overseeing the marketing functions at Elephant
          Canvas.
        </>
      ),
      link: "https://www.linkedin.com/in/saryupareek/",
    },
    {
      teamIcon: team3,
      title: "Pulkit Gupta",
      subtitle: "Partner",
      subInfo: (
        <>
          A Mensa International member with an Electrical Engineering background
          from University Of Illinois, Urbana-Champaign and an MBA from Indian
          School Of Business, Hyderabad, Pulkit spearheads creative and
          technical innovation at Elephant Canvas.
        </>
      ),
      link: "https://www.linkedin.com/in/pulkit23/",
    },
  ];
  return (
    <>
      <section className={style.ourTeam}>
        <Container>
          <div className={style.ourTeam_lisitng}>
            <h2>Our Founders</h2>
            <CommonSlider
              className={style.sliderTeam}
              speen={3000}
              dots={false}
              autoplay={false}
              slidesToShow={3}
              slidesToScroll={1}
              responsive={rsp}
            >
              {cardTeams.map((data, index) => (
                <div className={style.teamCard} key={index}>
                  <div className={style.teamImg}>
                    <img src={data.teamIcon} alt="img-Team" />
                  </div>
                  <div className={style.teamText}>
                    <div className={style.topHeadings}>
                      <h4>{data.title}</h4>
                      <h6>{data.subtitle}</h6>
                      <div className={style.socialLink}>
                        <CommonBtn
                          className={`bg-border-without ${style.btnLink}`}
                          role="btn"
                          onClick={() => window.open(data.link)}
                          icon={<BaseLinkedinIcon />}
                        />
                      </div>
                    </div>
                    <p>{data.subInfo}</p>
                  </div>
                </div>
              ))}
            </CommonSlider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurTeam;
