import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownIconwhite } from '../../../../Assets/svgIcons/SVGicon';
import './Commondropdown.scss';

const Commondropdown = ({
    classdrop, children, dropdowntitle, classBtn,
}) => {
    return (
        <>
            <Dropdown className={`common_dropdown ${classdrop}`}>
                <Dropdown.Toggle className={`${classBtn} dropBtn`} id="dropdown-basic">
                    {dropdowntitle}
                    <DropdownIconwhite />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {children}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default Commondropdown
