import React from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import CommonBtn from "../../../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../../../../Common/Ui/CommonTable/CommonTable";
import Select from "react-select";
import style from "./TabTable.module.scss";
import CustomPagination from "../../../../../../Common/Ui/Pagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import Notfound from "../../../../../../Common/NotFound/Notfound";
import {
  formatDateActivityTable,
  getNumberWithComma,
  sliceChar,
} from "../../../../../../../Helper/common";
import { DECIMAL, DROP_TYPE } from "../../../../../../../Constant/userConstant";
import personIcon from "../../../../../../../Assets/images/person_icon.jpg";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../../../../../Features/user/userSlice";
import { useNavigate, useParams } from "react-router-dom";

const AuctionAndRankedAuctionEndedTable = ({
  dfaActivity,
  setDfaActivity,
  dfaDetail,
  activityPage,
  setActivityPage,
  selectedTab,
  setTab,
  limit,
  setLimit,
  limitOption,
  setShowOfferModal,
  setMakeOfferDetails,
  setShowGlobalOfferModal,
  navigateToUser,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const isDfaActivityLoading = useSelector(
    (state) => state?.loading?.dfaActivityLoading
  );
  const userId = useSelector((state) => state?.users?.profileData?.id);

  const buyHandler = (item) => {
    if (isUserLoggedin) {
      let data = {
        price: item.selling_price / DECIMAL,
        market: "secondary",
        quantity: 1,
        editionNumber: item.editions_no,
      };
      dispatch(saveLatestPrice(data));
      navigate(`/checkout/${id}`);
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  const makeOfferHandler = (item) => {
    let data = {
      art_id: dfaDetail.id,
      user_id: item?.bidder_user?.id,
      user_owned_no_of_editions: item.editions_no,
      art_image: dfaDetail.art_image,
      art_name: dfaDetail.art_name,
      drop_type: dfaDetail.drop_type,
      total_no_of_editions: dfaDetail.no_of_editions,
      selling_price: item.selling_price,
      highest_offer_price: item.highest_price,
      username: dfaDetail.username,
      last_buy_price: item.bid_amount,
      last_sell_price: dfaDetail.price, //last sale price
      sold_no_of_editions: dfaDetail.sold_no_of_editions,
      thumbnail_image: dfaDetail.thumbnail_image,
      is_portrait: dfaDetail?.is_portrait,
      feature_file: dfaDetail?.feature_file,
    };
    setMakeOfferDetails(data);
    if (isUserLoggedin) {
      setShowOfferModal(true);
      //logic for make offer and API call if user is logged in
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  const makeGlobalOfferHandler = () => {
    if (isUserLoggedin) {
      //logic for make global offer and API call if user is logged in
      setShowGlobalOfferModal(true);
    } else {
      dispatch(saveNextRoute(`/digital-fine-arts/${id}`));
      navigate("/login");
    }
  };

  return (
    <>
      <div className={style.tabTable}>
        <Tabs
          defaultActiveKey="winner"
          id=""
          onSelect={(tab) => {
            if (selectedTab !== tab) {
              setDfaActivity({});
            }
            if (tab === "otherBidders") {
              setLimit(limitOption[0]);
            }
            setActivityPage(1);
            setTab(tab);
          }}
          className={`tablists ${style.art_tabcont}`}
        >
          <Tab eventKey="winner" title="Winner">
            {selectedTab === "winner" && (
              <div className={style.tableSec}>
                <CommonTable className={style.bidder_table}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Bidder</th>
                      <th>Bid Amount</th>
                      <th>Selling Price</th>
                      <th>
                        {dfaActivity?.user_activity?.length > 0 &&
                          !dfaDetail?.is_global_offer_exist &&
                          dfaDetail?.totalEditionsOwned !=
                            dfaActivity?.totalItems &&
                          dfaDetail?.drop_type === DROP_TYPE.RANKED_AUCTION && (
                            <CommonBtn
                              title="Send Global Offer"
                              role="btn"
                              className={`bordred-white btnGlobal`}
                              onClick={() => makeGlobalOfferHandler()}
                            />
                          )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dfaActivity?.user_activity?.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDateActivityTable(item?.updatedAt)}</td>
                        <td>
                          <div
                            className="ownedBy cursor-pointer"
                            onClick={() =>
                              navigateToUser(item?.bidder_user?.username)
                            }
                            title={item?.bidder_user?.username}
                          >
                            <img
                              src={
                                item?.bidder_user?.image_path
                                  ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.bidder_user?.image_path}`
                                  : personIcon
                              }
                              alt="Bidder-Icon"
                            />
                            <h6>{`@${sliceChar(
                              item?.bidder_user?.username,
                              15
                            )}`}</h6>
                          </div>
                        </td>
                        <td>
                          ₹ {getNumberWithComma(item?.bid_amount / DECIMAL)}
                        </td>
                        <td>
                          {item.selling_price === 0
                            ? "---"
                            : `₹ ${getNumberWithComma(
                                item.selling_price / DECIMAL
                              )}`}
                        </td>
                        <td>
                          <div className="btnSection">
                            {item.is_offer_exist === false &&
                            item?.is_offer_accepting === 1 &&
                            item?.bidder_user?.id !== userId ? (
                              <CommonBtn
                                title="Make Offer"
                                role="btn"
                                className={`bordred-black btnMake`}
                                onClick={() => makeOfferHandler(item)}
                              />
                            ) : item?.is_offer_accepting === 0 ? (
                              <>Not Accepting Offers</>
                            ) : null}
                            {item?.selling_price != 0 &&
                              item?.bidder_user?.id !== userId && (
                                <CommonBtn
                                  title="Buy now"
                                  role="btn"
                                  className={`btnBuy`}
                                  onClick={() => buyHandler(item)}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    {isDfaActivityLoading && (
                      <tr>
                        <td colSpan={5} className="text-center">
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    )}
                    {dfaActivity?.user_activity?.length === 0 && (
                      <tr>
                        <td colSpan={5}>
                          <Notfound text={"No activity found."} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </CommonTable>
              </div>
            )}
          </Tab>
          <Tab eventKey="otherBidders" title="Other Bidders">
            {selectedTab === "otherBidders" && (
              <div className={style.tableSec}>
                <CommonTable className={style.bidder_table}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Bidder</th>
                      <th>Bid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isDfaActivityLoading ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    ) : (
                      dfaActivity?.user_activity?.map((item, index) => (
                        <tr key={index}>
                          <td>{formatDateActivityTable(item?.createdAt)}</td>
                          <td>
                            <div className="ownedBy">
                              <img
                                src={
                                  item?.bidder_user?.image_path
                                    ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.bidder_user?.image_path}`
                                    : personIcon
                                }
                                alt="Bidder-Icon"
                              />
                              <h6>{item?.bidder_user?.username}</h6>
                            </div>
                          </td>
                          <td>
                            ₹ {getNumberWithComma(item?.bid_amount / DECIMAL)}
                          </td>
                        </tr>
                      ))
                    )}
                    {/* {isDfaActivityLoading && (
                      <tr>
                        <td colSpan={3} className="text-center">
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    )} */}
                    {dfaActivity?.user_activity?.length === 0 && (
                      <tr>
                        <td colSpan={3}>
                          <Notfound text={"No activity found."} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </CommonTable>
              </div>
            )}
          </Tab>
        </Tabs>
        {dfaActivity?.totalItems > 10 && (
          <div className="filterPagination">
            <div className="selectShow">
              <label>Show</label>
              <div className="selectShow_inner">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  options={limitOption}
                  isSearchable={false}
                  onChange={(option) => {
                    setLimit(option);
                    setActivityPage(1);
                  }}
                  value={limit}
                />
              </div>
            </div>
            <CustomPagination
              className=""
              activePage={activityPage}
              totalPage={dfaActivity?.totalPages}
              setActivePage={setActivityPage}
              maxVisiblePages={5}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AuctionAndRankedAuctionEndedTable;
