// import { API_HOST } from "../constant";
import { fetch } from "../Helper/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;

const getAllMarketPlaceNft = (params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/marketplace/arts`,
    {},
    {},
    params
  );
};

// https://stage-api.niftyartx.com/nft/v1/nft/secondary_marketplace

const getCoupon = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/user/get_valid_coupons`,
    {},
    { token },
    params
  );
};
const getArtDetailById = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/artDetail/id`,
    {},
    { token },
    params
  );
};
const placeOrder = (token, data) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/place_order`,
    data,
    { token },
    {}
  );
};
const getAllSeries = () => {
  return fetch("get", `${API_HOST}/nft/v1/nft/get/series/list`, {}, {}, {});
};

const getAllArtist = () => {
  return fetch("get", `${API_HOST}/users/v1/user/artist/list`, {}, {}, {});
};
const getAllTags = () => {
  return fetch("get", `${API_HOST}/nft/v1/nft/get/popular/tags`, {}, {}, {});
};
const getDigitalArtDetail = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/artDetailById`,
    {},
    { token },
    params
  );
};

const getDfaDetailById = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get/new_artDetailById`,
    {},
    { token },
    params
  );
};

const getDeviceDetailList = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/landing_page/linked_devices`,
    {},
    { token },
    params
  );
};

const getDfaActivityById = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/new_get_activities`,
    {},
    { token },
    params
  );
};

const placeOrderByCurated = (token, data) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/place_auction/order`,
    data,
    { token },
    {}
  );
};
const buyArt = (token, data) => {
  return fetch(
    "post",
    `${API_HOST}/nft/v1/nft/place_order`,
    data,
    { token },
    {}
  );
};

const getActivity = (token, params) => {
  return fetch(
    "get",
    `${API_HOST}/nft/v1/nft/get_activities`,
    {},
    { token },
    params
  );
};

export const marketplaceService = {
  getAllMarketPlaceNft,
  getCoupon,
  getArtDetailById,
  getDfaDetailById,
  getDfaActivityById,
  getDeviceDetailList,
  placeOrder,
  getAllTags,
  getAllArtist,
  getAllSeries,
  getDigitalArtDetail,
  placeOrderByCurated,
  getActivity,
  buyArt,
};
