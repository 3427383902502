import React from "react";
import style from "../Dropaction/Dropaction.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import {
  getDateFromTimestamp,
  getNumberWithComma,
} from "../../../Helper/common";
import Timer from "../Timer";
import { DECIMAL } from "../../../Constant/userConstant";
import HowitWork from "../../Common/HowitWork/HowitWork";
import DOMPurify from "dompurify";

const RankedAuctionComingsoon = ({ artDetail, id }) => {
  const initialValue = {
    search: "",
    filterOption: "",
  };
  const validationSchema = Yup.object({
    search: Yup.string().required("Required"),
    filterOption: Yup.string().required("Please select blockchain"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });
  return (
    <>
      <div className={style.dropaction_ended_rightsec_pricefloor}>
        <div className={style.headprice}>
          <ul>
            <li>
              <label>Floor Price :</label>
              <h6>₹{getNumberWithComma(artDetail.price / DECIMAL)}</h6>
            </li>
            <li>
              <label>Min. Increment :</label>
              <h6>
                ₹{getNumberWithComma(Number(artDetail?.increment) / DECIMAL)}
              </h6>
            </li>
          </ul>
        </div>
        <div className={style.startinfo}>
          <div className={style.auctioncomingsoon}>
            <h4>
              Ranked Auction Starts in :{" "}
              <Timer
                startingTimestamp={artDetail?.start_time}
                type="upcoming"
                id={id}
              />
            </h4>
            {/* <CommonBtn
              role="btn"
              title="Get Notified"
              className={`${style.ApplyToCreateBtn}`}
            /> */}
          </div>
        </div>
      </div>
      <div className={style.dropaction_ended_rightsec_tabpartlist}>
        <Tabs defaultActiveKey="aboutart" id="" className="tablists">
          <Tab eventKey="aboutart" title="About Art">
            <div className={style.artstab_containt}>
              <div
                className='textSection'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(artDetail?.bio),
                }}
              ></div>

              <ul className={style.about_artlist}>
                <li>
                  <label>
                    {artDetail?.no_of_editions == 1 ? "Edition" : "Editions"}
                  </label>
                  <span>:</span>
                  <h6>{artDetail?.no_of_editions}</h6>
                </li>
                <li>
                  <label>Type</label>
                  <span>:</span>
                  <h6>{artDetail?.art_type}</h6>
                </li>
                <li>
                  <label>Release</label>
                  <span>:</span>
                  <h6>{getDateFromTimestamp(artDetail.start_time)}</h6>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab eventKey="howitworks" title="How it Works">
            <div className={`py-0 ${style.artstab_containt}`}>
              {/* <p>
                There’s a stage we reach in life where we start to realize our
                innocence is “gone.” We may have began to realize that we lost
                that childlike innocence ages ago. However, times of innocence
                seem to flutter by in adulthood.
              </p> */}
              <HowitWork artDetail={artDetail} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default RankedAuctionComingsoon;
