import React from "react";
import CommonBtn from "../Ui/CommonBtn/CommonBtn";
import CommonModal from "../Ui/CommonModal/CommonModal";
import "./ConfirmationModal.scss";
import PropTypes from "prop-types";

function ConfirmationModal(props) {
  let { show, handleClose, actionText, handleAccept } = props;

  return (
      <CommonModal
        centered
        className="confirm_modal"
        modalTitle="Confirmation"
        show={show}
        onHide={handleClose}
      >
        <div className="main_content">
          <h5>Are you sure to {actionText} ?</h5>
          <div className="btn_section">
            <CommonBtn
              role="btn"
              title="No"
              className="btn_size bordred-yellow"
              onClick={handleClose}
            />
            <CommonBtn
              role="btn"
              title="Yes"
              className="btn_size btn-yellow-black"
              onClick={handleAccept}
            />
          </div>
        </div>
      </CommonModal>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  actionText: PropTypes.string.isRequired,
  handleAccept: PropTypes.func.isRequired,
};

export default ConfirmationModal;
