import React, { useMemo } from "react";
import AuctionForm from "./Component/AuctionForm/AuctionForm";
import FCFBForm from "./Component/FCFBForm/FCFBForm";
import OpenEditionForm from "./Component/OpenEditionForm/OpenEditionForm";
import RafflesForm from "./Component/RafflesForm/RafflesForm";
import RankedAuctionForm from "./Component/RankedAuctionForm/RankedAuctionForm";
import style from "./DfabuyForm.module.scss";
import {
  DECIMAL,
  DIGITAL_ART_TYPE,
} from "../../../../../Constant/userConstant";

const DfabuyForm = ({
  dfaDetail,
  dfaActivity,
  setShowClosedDrop,
  getDfaDetailByIdHandler,
}) => {
  const getMinBidToBeRankOne = useMemo(() => {
    // if (dfaActivity?.user_activity?.length) {
    if (dfaActivity?.user_activity?.length === 0) {
      return dfaDetail?.floor_price / DECIMAL;
    } else if (dfaActivity?.user_activity?.length > 0) {
      return (
        dfaActivity?.user_activity[0]?.bid_amount / DECIMAL +
        dfaDetail?.increment / DECIMAL
      );
    } else {
      return "--";
    }
  }, [dfaDetail, dfaActivity]);

  return (
    <>
      <div className={style.dfabuyForm}>
        {dfaDetail.drop_type === DIGITAL_ART_TYPE.Auction && (
          <AuctionForm
            dfaDetail={dfaDetail}
            getMinBidToBeRankOne={getMinBidToBeRankOne}
            dfaActivity={dfaActivity}
            setShowClosedDrop={setShowClosedDrop}
            getDfaDetailByIdHandler={getDfaDetailByIdHandler}
          />
        )}
        {dfaDetail.drop_type === DIGITAL_ART_TYPE.RankedAuction && (
          <RankedAuctionForm
            dfaDetail={dfaDetail}
            getMinBidToBeRankOne={getMinBidToBeRankOne}
            dfaActivity={dfaActivity}
            setShowClosedDrop={setShowClosedDrop}
            getDfaDetailByIdHandler={getDfaDetailByIdHandler}
          />
        )}
        {dfaDetail.drop_type === DIGITAL_ART_TYPE.FirstComeFirstBuy && (
          <FCFBForm
            dfaDetail={dfaDetail}
            setShowClosedDrop={setShowClosedDrop}
            getDfaDetailByIdHandler={getDfaDetailByIdHandler}
          />
        )}
        {dfaDetail.drop_type === DIGITAL_ART_TYPE.OpenEdition && (
          <OpenEditionForm
            dfaDetail={dfaDetail}
            setShowClosedDrop={setShowClosedDrop}
            getDfaDetailByIdHandler={getDfaDetailByIdHandler}
          />
        )}
        {dfaDetail.drop_type === DIGITAL_ART_TYPE.Raffle && (
          <RafflesForm
            dfaDetail={dfaDetail}
            setShowClosedDrop={setShowClosedDrop}
            getDfaDetailByIdHandler={getDfaDetailByIdHandler}
          />
        )}
      </div>
    </>
  );
};

export default DfabuyForm;
