import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import style from "./Declinemodal.module.scss";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import {
  checkFileType,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import {
  DECIMAL,
  DIGITAL_ART_TYPE,
  OFFER_STATUS,
} from "../../../../Constant/userConstant";

const Declinemodal = ({
  show,
  onHide,
  makeOfferDetails,
  getUpdateOfferStatus,
}) => {
  const MakeSchema = Yup.object({
    declined_reason: Yup.object().required("*Reason is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: MakeSchema,
    initialValues: {
      declined_reason: "",
    },
    onSubmit: (value) => {
      calldeclineOfferApi(value);
    },
  });

  const calldeclineOfferApi = (value) => {
    let data = {
      status: OFFER_STATUS.declined,
      id: makeOfferDetails.item.id,
      makeOfferDetails: makeOfferDetails,
      declined_reason: value.declined_reason.value,
    };

    getUpdateOfferStatus(data); //api calling method
  };

  const showoption = [
    { label: "Offer price is too low", value: "Offer price is too low" },
    { label: "Not Interested", value: "Not Interested" },
  ];

  const getFetureFileSection = (src) => {
    let type = checkFileType(src);
    if (type == "MP4" || type == "OGG") {
      return (
        <video loop autoPlay muted controlsList="nodownload" key={src}>
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return <img src={src} alt="Dfa-Image" />;
    }
  };

  return (
    <>
      <CommonModal
        className={`${style.offerModal} ${
          makeOfferDetails?.art?.is_portrait
            ? `portraitModal`
            : `landscapeModal`
        }`}
        centered
        show={show}
        onHide={onHide}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-md-row flex-column">
            <div
              className={`${style.offerModalLeft} ${
                makeOfferDetails?.art?.is_portrait
                  ? `portraitLeft`
                  : `landscapeLeft`
              }`}
            >
              <div
                className={`${style.offerModalLeftInner} ${
                  makeOfferDetails?.art?.is_portrait
                    ? `portraitImg`
                    : `landscapeImg`
                }`}
              >
                {getFetureFileSection(
                  `${process.env.REACT_APP_IMG_BASE_URL}${makeOfferDetails?.art?.feature_file}`
                )}
              </div>
            </div>
            <div
              className={`${style.offerModalRight} ${
                makeOfferDetails?.art?.is_portrait
                  ? `portraitRight`
                  : `landscapeRight`
              }`}
            >
              <div className={style.artistDetails}>
                <h6>@{makeOfferDetails?.art?.art_user?.username}</h6>
                <h3 title={makeOfferDetails?.art?.art_name}>
                  {sliceChar(makeOfferDetails?.art?.art_name, 75)}
                </h3>
                <div className={style.artistDetailsEdition}>
                  <p>Edition :</p>
                  <span>
                    {" "}
                    #{makeOfferDetails?.item?.edition_no} of{" "}
                    {makeOfferDetails.art.drop_type ==
                    DIGITAL_ART_TYPE.OpenEdition
                      ? makeOfferDetails?.art?.sold_no_of_editions
                      : makeOfferDetails?.art?.no_of_editions}
                  </span>
                </div>
              </div>
              <ul className={style.pricelist}>
                <li>
                  <h6> Purchase Price </h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.art.art_owners[0]?.purchase_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.art.art_owners[0]
                              ?.purchase_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6> Last Sale Price </h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.art.last_sell_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.art.last_sell_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li className={style.highvalue}>
                  <h6> Highest Offer </h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.art?.highest_offer_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.art?.highest_offer_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
              </ul>
              <hr className={style.horizontal_line} />
              <ul className={style.pricelist}>
                <li>
                  <h6>Offer Price </h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.item?.total_art_price_inc_tax
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.item?.total_art_price_inc_tax /
                            DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6>Basic Price </h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.item?.art_base_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.item?.art_base_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6> Applicable GST</h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.item?.gst_amount
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.item?.gst_amount / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <li>
                  <h6>Service Charge</h6>
                  <span>
                    {" "}
                    {makeOfferDetails?.item?.service_charge_amount
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.item?.service_charge_amount /
                              DECIMAL +
                            makeOfferDetails?.item
                              ?.gst_amount_on_service_charge /
                              DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </li>
                <p>(Inclusive of Artist Royalty & Applicable Taxes)</p>
              </ul>
              <div className={style.receive_payment}>
                <h6>
                  <label>You'll receive </label>
                  <span>
                    {" "}
                    {makeOfferDetails?.item?.offer_price
                      ? "₹ " +
                        getNumberWithComma(
                          (
                            makeOfferDetails?.item?.offer_price / DECIMAL
                          ).toFixed(2)
                        )
                      : "--"}
                  </span>
                </h6>
              </div>
              <div className={style.buy_btn_box}>
                <FormikControls
                  control="select"
                  type="select"
                  name="declined_reason"
                  placeholder="Select Reason"
                  formik={formik}
                  value={formik?.values?.declined_reason}
                  isSearchable={false}
                  className={"border_input select_decline"}
                  options={showoption}
                  onChange={(item) => {
                    formik.setFieldValue("declined_reason", item);
                  }}
                />
                <CommonBtn
                  role="btn"
                  title="Decline"
                  className={`${style.buy_btn}`}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default Declinemodal;
