import React from "react";
import CommonTable from "../../../../Common/Ui/CommonTable/CommonTable";
import style from "./Accountindex.module.scss";
import {
  ARTIST_PROFILE_REQUEST_STATUS,
  ARTIST_PROFILE_REQUEST_TYPE,
} from "../../../../../Constant/userConstant";
import { formatDate, getTime } from "../../../../../Helper/common";

const Requesthistory = ({ accountDetail }) => {
  return (
    <>
      <section className={style.request_edit}>
        <CommonTable className={style.request_table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Activity</th>
              <th>Edit</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {accountDetail?.map((item) => (
              <tr>
                <td>{formatDate(item.created_at)}</td>
                <td>{getTime(item?.created_at)}</td>
                <td>
                  <strong>
                    {item.request_type == ARTIST_PROFILE_REQUEST_TYPE.NAME
                      ? "Name"
                      : item.request_type == ARTIST_PROFILE_REQUEST_TYPE.EMAIL
                      ? "Email"
                      : item.request_type == ARTIST_PROFILE_REQUEST_TYPE.MOBILE
                      ? "Mobile"
                      : item.request_type == ARTIST_PROFILE_REQUEST_TYPE.BIO ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.BIO_PUBLIC
                      ? "Bio"
                      : item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.FACEBOOK_PUBLIC ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.TWITTER_PUBLIC ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.LINKEDIN_PUBLIC ||
                        item.request_type ==
                          ARTIST_PROFILE_REQUEST_TYPE.INSTAGRAM_PUBLIC
                      ? "Social link"
                      : "User name"}
                  </strong>
                </td>
                <td className="description" title={item.description}>
                  {item.description == null ? "---" : item.description}
                </td>
                <td>
                  {item.status == ARTIST_PROFILE_REQUEST_STATUS.PENDING
                    ? "Pending"
                    : item.status == ARTIST_PROFILE_REQUEST_STATUS.APPORVED
                    ? "Approved"
                    : item.status == ARTIST_PROFILE_REQUEST_STATUS.REJECTED
                    ? "Rejected"
                    : "Cancelled by you"}
                </td>
              </tr>
            ))}
          </tbody>
          {accountDetail.length == 0 && (
            <tr>
              <td colSpan={9}>
                <p className="no_recordtable">No records found</p>
              </td>
            </tr>
          )}
        </CommonTable>
      </section>
    </>
  );
};

export default Requesthistory;
