import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./Notificationsettings.module.scss";
import {
  getNotificationSettingList,
  updateNotificationFun,
} from "../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import { DEVICE_NOTIFICATION } from "../../../../Constant/userConstant";
import Checkbox from "../../../Common/Ui/Formik/Checkbox";

const Notificationsettings = () => {
  const [tab, setTab] = useState("emailalerts");
  const [notificationList, setNotificationList] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [groupedDataPhone, setGroupedDataPhone] = useState({});

  const dispatch = useDispatch();

  const [selectedIdsEmail, setSelectedIdsEmail] = useState([]);
  const [selectedIdsPhone, setSelectedIdsPhone] = useState([]);

  useEffect(() => {
    if (
      (tab == "emailalerts" && Object.keys(groupedData).length == 0) ||
      (tab == "phonealerts" && Object.keys(groupedDataPhone).length == 0)
    ) {
      getNotificationListFun();
    }
  }, [tab]);

  const getNotificationListFun = async () => {
    let params;
    if (tab == "emailalerts") {
      params = {
        notificationType: DEVICE_NOTIFICATION.EMAIL,
      };
    } else {
      params = {
        notificationType: DEVICE_NOTIFICATION.PHONE,
      };
    }

    try {
      let response = await dispatch(getNotificationSettingList(params));
      setNotificationList(response?.data?.notificationList);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  useEffect(() => {
    const grouped = {};
    let emailSelectedIds = [...selectedIdsEmail];
    let phoneSelectedIds = [...selectedIdsPhone];

    notificationList.forEach((item) => {
      if (!grouped[item.notification_type]) {
        grouped[item.notification_type] = [];
      }
      grouped[item.notification_type].push(item);
      if (tab === "emailalerts") {
        if (item.emailNotification) {
          emailSelectedIds.push(item.id);
          setSelectedIdsEmail(emailSelectedIds);
        }
        setGroupedData(grouped);
      } else if (tab == "phonealerts") {
        if (item.mobileNotification) {
          phoneSelectedIds.push(item.id);
          setSelectedIdsPhone(phoneSelectedIds);
        }
        setGroupedDataPhone(grouped);
      }
    });
  }, [notificationList]);

  const tabChange = async (tab) => {
    setTab(tab);
  };

  const handleSelectAll = (e) => {
    e.preventDefault();
    if (tab === "emailalerts") {
      const emailSelectedIds = notificationList.map((item) => item.id);
      setSelectedIdsEmail(emailSelectedIds);
    } else if (tab === "phonealerts") {
      const phoneSelectedIds = notificationList.map((item) => item.id);
      setSelectedIdsPhone(phoneSelectedIds);
    }
  };

  const handleUnSelectAll = (e) => {
    e.preventDefault();
    if (tab == "emailalerts") {
      setSelectedIdsEmail([]);
    } else if (tab == "phonealerts") {
      setSelectedIdsPhone([]);
    }
  };

  const onUpdateNotificationsSubmit = async (e) => {
    e.preventDefault();
    let data;
    if (tab == "emailalerts") {
      data = {
        notificationType: DEVICE_NOTIFICATION.EMAIL,
        notificationId: selectedIdsEmail,
      };
    } else if (tab == "phonealerts") {
      data = {
        notificationType: DEVICE_NOTIFICATION.PHONE,
        notificationId: selectedIdsPhone,
      };
    }

    let response = await dispatch(updateNotificationFun(data));
    if (response.status == 200) {
      getNotificationListFun();
      if (tab == "emailalerts") {
        setSelectedIdsEmail([]);
      } else if (tab == "phonealerts") {
        setSelectedIdsPhone([]);
      }
    }
  };

  const handleCheckedUncheckedEmail = (id, checked) => {
    let selectedArr = [...selectedIdsEmail];
    if (checked) {
      //push id
      selectedArr.push(id);
      setSelectedIdsEmail(selectedArr);
    } else {
      //pop id
      const indexToRemove = selectedArr.indexOf(id);
      if (indexToRemove !== -1) {
        selectedArr.splice(indexToRemove, 1);
        setSelectedIdsEmail(selectedArr);
      }
    }
  };

  const handleCheckedUncheckedPhone = (id, checked) => {
    let selectedArr = [...selectedIdsPhone];
    if (checked) {
      //push id
      selectedArr.push(id);
      setSelectedIdsPhone(selectedArr);
    } else {
      //pop id
      const indexToRemove = selectedArr.indexOf(id);
      if (indexToRemove !== -1) {
        selectedArr.splice(indexToRemove, 1);
        setSelectedIdsPhone(selectedArr);
      }
    }
  };

  return (
    <>
      <section className={style.notification_settings}>
        <form>
          <Tabs
            defaultActiveKey="emailalerts"
            id="uncontrolled-tab-example"
            className="tablists"
            onSelect={(e) => tabChange(e)}
          >
            <Tab eventKey="emailalerts" title="Email Alerts">
              <div className={style.alerts_notification}>
                <div className={style.btn_notificat}>
                  <CommonBtn
                    role="btn"
                    className={`bordred-black ${style.btnsize}`}
                    title="Check All"
                    onClick={(e) => handleSelectAll(e)}
                  />

                  <CommonBtn
                    role="btn"
                    className={`black-btn ${style.btnsize}`}
                    title="Uncheck All"
                    onClick={(e) => handleUnSelectAll(e)}
                  />
                </div>

                <div className={style.form_alerts}>
                  {Object.keys(groupedData).map((group) => (
                    <div key={group} className={style.check_list}>
                      <h6>{group}</h6>
                      <div className={style.check_list}>
                        {groupedData[group].map((item) => (
                          <span key={item.id}>
                            <Checkbox
                              className="checkbox_input"
                              label={item.notification_name}
                              onChange={(e) =>
                                handleCheckedUncheckedEmail(
                                  item.id,
                                  e.target.checked
                                )
                              }
                              checked={selectedIdsEmail.includes(item.id)}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Tab>
            <Tab eventKey="phonealerts" title="Phone Alerts">
              <div className={style.alerts_notification}>
                <div className={style.btn_notificat}>
                  <CommonBtn
                    role="btn"
                    className={`bordred-black ${style.btnsize}`}
                    title="Check All"
                    onClick={(e) => handleSelectAll(e)}
                  />
                  <CommonBtn
                    role="btn"
                    className={`black-btn ${style.btnsize}`}
                    title="Uncheck All"
                    onClick={(e) => handleUnSelectAll(e)}
                  />
                </div>
                <div className={style.form_alerts}>
                  {Object.keys(groupedData).map((group) => (
                    <div key={group} className={style.check_list}>
                      <h6>{group}</h6>
                      <div className={style.check_list}>
                        {groupedData[group].map((item) => (
                          <span key={item.id}>
                            <Checkbox
                              className="checkbox_input"
                              label={item.notification_name}
                              onChange={(e) =>
                                handleCheckedUncheckedPhone(
                                  item.id,
                                  e.target.checked
                                )
                              }
                              checked={selectedIdsPhone.includes(item.id)}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Tab>
          </Tabs>
          <CommonBtn
            role="btn"
            type="submit"
            className={`black-btn ${style.btnsize}`}
            title="Submit"
            onClick={(e) => onUpdateNotificationsSubmit(e)}
          />
        </form>
      </section>
    </>
  );
};

export default Notificationsettings;
